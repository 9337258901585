import React, { useState } from "react";
import { Link } from "react-router-dom";
import * as Yup from "yup";
import { useFormik } from "formik";
import { ReactComponent as UploadIcon } from "../../assets/icons/uploadicon.svg";
import Files from "react-files";
import toast from "react-hot-toast";
import DatePicker from "react-datepicker";
import { MdOutlineEdit } from "react-icons/md";
import { useDispatch } from "react-redux";
import { FiLoader } from "react-icons/fi";
import axiosConfig from "../../axios_config/axios_instance";
import { getAllContactUsList } from "../../services/apiService";
const AddContactUs = () => {
  const [showModal, setShowModal] = useState(false);
  const [isLoading, setIsLoading]=useState(false)
  const dispatch=useDispatch()


  //intialData for formik
  const initialValues = {
    name: "",
    deisgnation:"",
    image:null,
  };

  //Yup validator Schema
  const EditInformationSchema = Yup.object({
    image:Yup.mixed().required("file required"),
      name:Yup.string().required(" Name is required"),
      deisgnation:  Yup.string().required(" Deisgnation is required"),
  });

  //formik validation
  const {
    values,
    handleBlur,
    resetForm,
    handleChange,
    handleSubmit,
    setFieldValue,
    errors,
    touched,
  } = useFormik({
    initialValues,
    validationSchema: EditInformationSchema,
    validateOnChange: true,
    validateOnBlur: false,
    onSubmit:async (values, action) => {

        try {
            const formdata= new FormData()
            setIsLoading(true)
            if (!values.image) {
              toast.error("Please upload a image file.");
              return;
            }
            formdata.append("image", values?.image)
            formdata.append("name", values?.name)
            formdata.append("post", values?.deisgnation)
            const {data}= await axiosConfig.post("/save-contact-us", formdata);
            if(data?.status === "success"){
              toast.success(data?.message || "Contact Added successfully")
              setShowModal(false)
              resetForm()
              dispatch(getAllContactUsList())
       
            }
           } catch (error) {
              toast.error("something Went wrong")
           }finally{
            setIsLoading(false)
           }
    },
  });
  const handleImageChange = (files) => {
    if (files.length) {
      setFieldValue("image", files[0]);

    }
  };
  const closeModal = (e) => {
    e?.target?.classList?.forEach((classes) => {
      if (classes === "Modal-Container") {
        setShowModal(false);
        resetForm();

      }
    });
  };
  return (
    <div>
    <div
    onClick={(e) => {
      e.stopPropagation();
      setShowModal(true);
      resetForm();
    }}
    className="flex justify-start cursor-pointer relative items-center flex-grow-0 flex-shrink-0 gap-3"
  >
    <div
      className="flex justify-start items-center flex-grow-0 flex-shrink-0 relative overflow-hidden gap-1 pl-4 pr-5 max-md:py-[7px] py-[10px] rounded-full bg-[#186ced]"
      style={{ boxShadow: "0px 1px 0px 0 rgba(0,0,0,0.08)" }}
    >
      <p className="flex-grow-0 flex-shrink-0 text-sm font-medium text-center text-white">
        Add Contact
      </p>
    </div>
  </div>

      {showModal ? (
        <>
          <div
            className="flex justify-center items-center overflow-x-hidden overflow-y-auto fixed inset-0 z-50 outline-none focus:outline-none shadow-lg w-full Modal-Container"
            onClick={closeModal}
            style={{ backdropFilter: "blur(1px)" }} 

          >
            <div className="relative w-auto my-6 mx-auto max-w-3xl">
              <div className="border-0 rounded-lg shadow-lg relative flex w-full bg-white outline-none focus:outline-none">
                <div className="flex items-start gap-1 p-2 border-b border-solid border-gray-300 rounded-t flex-col ">
                  <h3 className="text-xl font=semibold">
                    Add Contact
                  </h3>
                  <div className="flex justify-between items-start">
                    <div className="flex flex-col mt-3">
                    <p className="text-[12px] text-left text-black/80 font-semibold text-[#202223] relative after:content-['*'] after:ml-0.5 after:text-red-500">max upto 1mb</p>
                    <Files
  className="files-dropzone cursor-pointer"
  onChange={(file) => handleImageChange(file)}
  onError={(error) => toast.error(error?.message)}
  accepts={[".jpeg" ,".jpg", ".png"]}
  maxFileSize={1048576} 
  minFileSize={0}
  multiple
  clickable
>
{values.image ? (
  <div className="flex flex-col items-center relative">
    <img
      src={
        typeof values.image === "object"
          ? values.image?.preview?.url
          : values.image
      }
      className="h-52 w-[200px] object-cover"
    />
 
  </div>
) : (
  <div className="flex flex-col justify-center items-center flex-grow-0 flex-shrink-0 h-[122px] w-[122px] relative gap-1 px-[23.5px] py-7 rounded bg-[#186ced]/[0.04] border-[1.33px] border-[#186ced]/[0.64] border-dashed">
    <UploadIcon />
    <div className="flex justify-start items-center flex-grow-0 flex-shrink-0 relative gap-1 px-[5px] py-0.5 rounded">
      <p className="flex-grow-0 flex-shrink-0 text-xs font-medium text-center text-black/[0.64]">
        Upload
      </p>
    </div>
  </div>
)}
</Files>
<p className="text-[12px] text-left text-black/80 font-semibold text-[#202223] relative after:content-['*'] after:ml-0.5 after:text-red-500">(upload only jpeg)</p>
{/* { locationImages?.length ?  <span>{locationImages.length} selected</span>:null} */}
                    </div>
                    <div className="relative p-6 pt-0 flex-auto">
                      <div className="flex flex-col justify-start items-start self-stretch flex-grow-0 flex-shrink-0 gap-1">
                        <div className="flex justify-start items-start self-stretch flex-grow-0 flex-shrink-0 relative gap-1 flex-col">
                        <div className="flex-grow-0 flex-shrink-0 w-[150px]">
                        <p className="text-[14px] leading-10 py-[1px] font-medium text-left text-black/80">
                          Name
                        </p>
                      </div>
                      <div className="flex flex-col flex-grow w-[300px]">
                      <input
                      name="name"
                      type="text"
                      value={values.name}
                      onChange={handleChange}
                      className="w-full border rounded p-2 capitalize text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                      placeholder="name"
                      style={
                        errors.name && touched.name
                          ? { border: "1px solid red" }
                          : {}
                      }
                    />
                  
                      </div>
                          <div className="flex-grow-0 flex-shrink-0 w-[150px]">
                            <p className="text-[13px] leading-10 py-[1px] font-medium text-left text-black/80">
                              Post
                            </p>
                          </div>
                          <div className="flex flex-col flex-grow w-[300px]">
                          <input
                          name="deisgnation"
                          type="text"
                          value={values.deisgnation}
                          onChange={handleChange}
                          className="w-full border rounded p-2 capitalize text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                          placeholder="post"
                          style={
                            errors.deisgnation && touched.deisgnation
                              ? { border: "1px solid red" }
                              : {}
                          }
                        />
                        

                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="flex justify-start items-start flex-grow-0 flex-shrink-0 gap-2 self-end">
                    <div
                      onClick={(e) => {
                        e.stopPropagation();
                        setShowModal(false);
                      }}
                      className="flex justify-start items-center flex-grow-0 flex-shrink-0 relative overflow-hidden gap-1 pl-4 pr-5 py-[11px] rounded-[94px] bg-[#fff] cursor-pointer"
                      style={{ boxShadow: "0px 1px 0px 0 rgba(0,0,0,0.08)" }}
                    >
                      <p className="flex-grow-0 flex-shrink-0 text-sm font-medium text-center text-black">
                        Cancel
                      </p>
                    </div>
                    <button
                    type="button"
                    className="flex justify-start items-center flex-grow-0  capitalize flex-shrink-0 relative overflow-hidden gap-1 px-5 py-[11px] rounded-[94px] bg-[#186ced] cursor-pointer"
                    style={{ boxShadow: "0px 1px 0px 0 rgba(0,0,0,0.08)" }}
                    onClick={handleSubmit}
                    disabled={isLoading} // Disable the button when loading
                  >
                    {isLoading ? (
                      <div className="spinner-border text-light" role="status">
                        <span className="visually-hidden"><FiLoader /></span>
                      </div>
                    ) : (
                      <p className="flex-grow-0 flex-shrink-0 text-sm font-medium text-center text-white">
                        Save
                      </p>
                    )}
                  </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </>
      ) : null}
    </div>
  );
};

export default AddContactUs;
