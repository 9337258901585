import { createSlice } from "@reduxjs/toolkit";

const NewCityList = createSlice({
    name:"citylist",
    initialState: {
        CityListLoading: false,
        CityList:[],
        AllCityListModule:[],
        page:1,
        total_pages:0,
        TotalCityList:0
    },
    reducers: {
        getCityListRequest: (state) => {
            state.CityListLoading = true;
        },
        getCityListSuccess: (state,{payload}) => {
            state.CityListLoading = false;
            state.CityList = payload?.city_list;
            state.TotalCityList=payload?.total_elements
        },
        getAllCityListModuleSuccess:(state, {payload})=>{
          state.CityListLoading=false;
          state.AllCityListModule=payload?.city_list;
        },
        getCityListFail: (state) => {
            state.CityListLoading = false;
            state.CityList = [];
        },
        setCitiesListPage:(state,{payload})=>{
            state.page=payload
         },
        setTotalPageCities:(state,{payload})=>{
            state.total_pages=payload
        }
    }
})

export default NewCityList.reducer;
export const { getCityListFail,getCityListRequest,getCityListSuccess,setCitiesListPage, setTotalPageCities, getAllCityListModuleSuccess} = NewCityList.actions;