import React, { useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import * as Yup from "yup";
import { useFormik } from "formik";
import { ReactComponent as UploadIcon } from "../../assets/icons/uploadicon.svg";
import Files from "react-files";
import toast from "react-hot-toast";
import axiosConfig from "../../axios_config/axios_instance";
import { useDispatch, useSelector } from "react-redux";
import { getAllCityListAllModule, getAllOtaClubList } from "../../services/apiService";
import moment from "moment/moment";
import Template from '../../components/common/Template'
import {ReactComponent as ArrowLeft} from "../../assets/icons/arrowlefticon.svg"
import { FiLoader } from "react-icons/fi";
import { TimePicker } from "antd";
const AddOtaClub = () => {
  const [showModal, setShowModal] = useState(false);
  const [selectedDate1, setSelectedDate1] = useState(null);
  const [selectedDate2, setSelectedDate2] = useState(null);
  const [uploadedFileName, setUploadedFileName] = useState("");
  const [loading , setLoading]=useState(false)
  const {AllCityListModule}=useSelector((state)=>state.citylist)

  const options = AllCityListModule.map(({ city_id, city_name }) => ({
    value: city_id,
    label: city_name,
  }));
  
  const dispatch=useDispatch()
  const navigate = useNavigate()
  const handleDateChange1 = (date) => {
    setSelectedDate1(date);
  };
  const handleDateChange2 = (date) => {
    setSelectedDate2(date);
  };

  useEffect(()=>{
    dispatch(getAllOtaClubList())
  },[dispatch])


  useEffect(()=>{
    dispatch(getAllCityListAllModule())
  },[dispatch])
  //intialData for formik
  const initialValues = {
    headname: "",
    clubname:"",
    contactnumber: "",
    trainingplace: "",
    fromvalidity: "",
    tovalidity: "",
    regnumber: "",
    coachname:"",
    email:"",
    morning_from_time:"",
    morning_to_time:"",
    evening_to_time:"",
    evening_from_time:"",

  };

  //Yup validator Schema
  const OtaValidationSchema = Yup.object({
    headname: Yup.string().required("  headname is required"),
    coachname:Yup.string().required("  coachname is required"),
    clubname:Yup.string().required("  clubname is required"),
    email: Yup.string()
    .email()
    .matches(
      /^([A-Z|a-z|0-9](\.|_){0,1})+[A-Z|a-z|0-9]@([A-Z|a-z|0-9])+((\.){0,1}[A-Z|a-z|0-9]){2}\.[a-z]{2,3}$/gm,
      "Not a valid email"
    )
    .required("Please enter your email"),
    contactnumber: Yup.string() .required("Mobile number is required")
    .matches(
      /^[0-9]{10}$/,
      "Mobile number must be 10 digits without any spaces or special characters"
    ),
    trainingplace: Yup.string().required(" training place  is required"),

    fromvalidity: Yup.date().required(" Date is required"),
    cityname:Yup.string().required("grade is required"),
    tovalidity: Yup.date().required(" Date is required"),
    regnumber: Yup.string().required(" register number  is required"),
    morning_from_time:Yup.string().required("  required"),
    morning_to_time:Yup.string().required("  required"),
    evening_to_time:Yup.string().required("  required"),
    evening_from_time:Yup.string().required("  required"),
  });



  //formik validation
  const {
    values,
    resetForm,
    handleChange,
    handleSubmit,
    setFieldValue,
    handleBlur,
    errors,
    touched,
  } = useFormik({
    initialValues,
    validationSchema: OtaValidationSchema,
    validateOnChange: true,
    validateOnBlur: false,
    onSubmit: async (values, action) => {
      try {
        setLoading(true);
        const formdata= new FormData()
        if(!values?.image){
          toast.error("please upload a image")
          return ;
        }
        formdata.append("logo",values?.image)
        formdata.append("head_name", values?.headname)
        formdata.append("club_name", values?.clubname)
        formdata.append("coach_name", values?.coachname)
        formdata.append("email", values?.email)
        formdata.append("city_name", AllCityListModule?.find((city)=>city?.city_id)?.city_name);
        formdata.append("city_id", values.cityname); //
        formdata.append("contact_no", values?.contactnumber);

        formdata.append("to_training_time", `${values.evening_from_time}-${values.evening_to_time}`);
  
        formdata.append("from_training_time",`${values.morning_from_time}-${values.morning_to_time}`);

        formdata.append("training_place", values?.trainingplace);


        const fromValidatedFormat = moment(values.fromvalidity).format("DD-MM-YYYY");
        formdata.append("from_validity",fromValidatedFormat);

        const toValidityFormated = moment(values.tovalidity).format("DD-MM-YYYY");
        formdata.append("to_validity", toValidityFormated);
        formdata.append("reg_no", values?.regnumber)
        formdata.append("city_id",2)
        const {data}= await axiosConfig.post("/save-ota-club", formdata);
        if(data?.status === "success"){
          toast.success(data?.message || "Ota Club Added successfully")
          navigate("/otaclub")
    
          resetForm()
          dispatch(getAllOtaClubList())
   
        }
       } catch (error) {
          toast.error("something Went wrong")
       }finally{
            setLoading(false)
       }
    },
  });
  const handleImageChange = (files) => {
    if (files.length) {
      setFieldValue("image", files[0]);
      setUploadedFileName(files[0].name);
    } else {
      toast.error("No image selected. Please upload an image.");
    }
  };
  const CancelButton = () => {
    resetForm(); // Reset the form values
  };
  
  return (
    <Template>
    <div
   
      className="flex justify-start cursor-pointer  flex-grow-0 flex-shrink-0 gap-3 "
    > 
    <div className="flex justify-start bg-[#e8e8f1] w-full items-center self-stretch flex-grow-0 flex-shrink-0 gap-2 px-4 max-md:py-2 py-4">
    <div className="flex justify-between max-md:justify-between flex-row flex-grow relative ">
    <div className='flex max-md:flex gap-3 items-center '>
      <ArrowLeft  onClick={()=>navigate("/otaclub")} className='cursor-pointer max-md:  w-4 h-4'/>
      <p className="flex-grow-0 flex-shrink-0 text-2xl max-md:text-[18px] font-semibold text-left capitalize text-[#202223]">
       Add Ota Club
      </p>
      </div>
      <div className="flex justify-start items-start   flex-grow-0 flex-shrink-0 gap-2 self-end">
        <div
       
        className="flex justify-start items-center flex-grow-0 flex-shrink-0 relative overflow-hidden gap-1 pl-4 pr-5 py-[11px] rounded-[94px] bg-[#fff] cursor-pointer"
        style={{ boxShadow: "0px 1px 0px 0 rgba(0,0,0,0.08)" }}
        onClick={CancelButton}
      >
        <p className="flex-grow-0 flex-shrink-0 text-sm font-medium text-center text-black">
          Cancel
        </p>
      </div>
      <button
      type="button"
      className="flex justify-start items-center flex-grow-0 flex-shrink-0 relative overflow-hidden gap-1 px-5 py-[11px] rounded-[94px] bg-[#186ced] cursor-pointer"
      style={{ boxShadow: "0px 1px 0px 0 rgba(0,0,0,0.08)" }}
      onClick={handleSubmit}
      disabled={loading} // Disable the button when loading
    >
      {loading ? (
        <div className="spinner-border text-light" role="status">
          <span className="visually-hidden"><FiLoader /></span>
        </div>
      ) : (
        <p className="flex-grow-0 flex-shrink-0 text-sm font-medium text-center text-white">
          Save
        </p>
      )}
    </button>
        </div>
    </div>
  </div>

    </div>
    <div className="bg-zinc-50 self-stretch flex pb-0 flex-row items-stretch mt-2.5 pt-2 px-10 max-md:max-w-full max-md:px-5">
    <div className="flex flex-row justify-start items-start self-stretch flex-grow-0 flex-shrink-0 ">
      
      <div className="flex flex-row justify-start items-start flex-grow-0 flex-shrink-0 gap-10 flex-wrap">
        <div>
        <h2 className="text-sm font-semibold text-[#202223] relative after:content-['*'] after:ml-0.5 after:text-red-500">
        Add Ota Club Image
        <span className=""></span>
        </h2>    
        
        <Files
        className="files-dropzone cursor-pointer mt-1 h-[122px] w-[122px]"
        onChange={(file) => handleImageChange(file)}
        onError={(error) => toast.error(error?.message)}
        accepts={[".Jpg", ".jpeg" ]}
        maxFileSize={10000000}
        minFileSize={0}
        multiple
        clickable
        style={
          errors.image && touched.image
            ? { border: "1px solid red" }
            : {}
        }
      >
            {values.image ? (
              <div className="flex flex-col items-center relative">
               <img
                    src={
                      typeof values.image === "object"
                        ? values.image?.preview?.url
                        : values.image
                    }
                    className="h-[122px] w-[122px] object-cover"
                    style={
                      errors.image && touched.image
                        ? { border: "1px solid red" }
                        : {}
                    }
                  />
              </div>
            ) : (
              <div className="flex flex-col justify-center items-center flex-grow-0 flex-shrink-0 h-[122px] w-[122px] relative gap-1 px-[23.5px] py-7 rounded bg-[#186ced]/[0.04] border-[1.33px] border-[#186ced]/[0.64] border-dashed">
                <UploadIcon />
                <div className="flex justify-start items-center flex-grow-0 flex-shrink-0 relative gap-1 px-[5px] py-0.5 rounded">
                  <p className="flex-grow-0 flex-shrink-0 text-xs font-medium text-center text-black/[0.64]">
                    Upload
                  </p>
                </div>
              </div>
            )}
          </Files>
        </div>
  
        <div>
   
        </div>
      </div>
    </div>
  </div>
  
      <div className="pr-1.5 max-md:max-w-full p-4 mx-4">
        <p className='text-lg font-sans font-medium after:content-["*"] after:ml-0.3 mt-2 after:text-red-500'>  Coach Name</p>
        <div className="flex items-center ">
       
        <input
        name="coachname"
        type="text"
        value={values.coachname}
        onChange={handleChange}
        className="form-input  p-2 w-[60%] max-md:w-full h-10 rounded-md   border-[#B2B5C4] border-[1px] focus:bg-white focus:ring-0 placeholder:text-sm"
                    placeholder="Coach name"
                    style={
                      errors.coachname &&
                      touched.coachname
                        ? { border: "1px solid red" }
                        : {}
                    }
      />
        </div>


        <p className='text-black text-xl font-medium leading-10 mt-3 whitespace-nowrap max-md:max-w-full after:content-["*"] after:ml-0.5 after:text-red-500'>
        District
        </p>
        <div className="flex items-center ">
        <select
              name="cityname"
              onChange={handleChange}
              placeholder="Select City"
              className={
                  errors.cityname && touched.cityname
                      ? "formErrorInput"
                      : "form-input  p-2 w-[60%] max-md:w-full h-10 rounded-md capitalize   border-[#B2B5C4] border-[1px] focus:bg-white focus:ring-0 placeholder:text-sm"

              }
              onBlur={() => handleBlur("cityname")}
              value={values.cityname}
          >
          <option>Select district</option>
          {
            AllCityListModule?.map((option)=>(
              <option value={option.city_id}>{option?.city_name}</option>
            ))
          }

          </select>
        </div>

        <p className='text-black text-xl font-medium leading-10 whitespace-nowrap mt-3 max-md:max-w-full after:content-["*"] after:ml-0.5 after:text-red-500'>
        Club Name{" "}
        </p>
        <div className="flex max-md:w-full items-center ">
        <input
                    name="clubname"
                    type="text"
                    value={values.clubname}
                    onChange={handleChange}
                    className="form-input  p-2 w-[60%] max-md:w-full h-10 rounded-md  capitalize  border-[#B2B5C4] border-[1px] focus:bg-white focus:ring-0 placeholder:text-sm"
                    placeholder="Club name"
                                style={
                                  errors.clubname &&
                                  touched.clubname
                                    ? { border: "1px solid red" }
                                    : {}
                                }
                  />
                                        
        </div>
        <div className="max-md:pt-5">
          
        <p className='text-black text-xl font-medium leading-10 whitespace-nowrap mt-3  max-md:max-w-full after:content-["*"] after:ml-0.5 after:text-red-500'>
        Email
        </p>
        <div className="flex items-center ">
        <input
        name="email"
    type="email"
    value={values.email}
    onChange={handleChange}
    className="form-input  p-2 w-[60%] max-md:w-full h-10 rounded-md   capitalize border-[#B2B5C4] border-[1px] focus:bg-white focus:ring-0 placeholder:text-sm"
    placeholder="Enter email"
                style={
                  errors.email &&
                  touched.email
                    ? { border: "1px solid red" }
                    : {}
                }
  />
        </div>
        </div>
        <div className="max-md:pt-5">
          
        <p className='text-black text-xl font-medium leading-10 whitespace-nowrap mt-3 max-md:max-w-full after:content-["*"] after:ml-0.5 after:text-red-500'>
        Contact No
        </p>
        <div className="flex items-center ">
        <input
        name="contactnumber"
type="number"
value={values.contactnumber}
onChange={handleChange}
className="form-input  p-2 w-[60%] max-md:w-full h-10 rounded-md capitalize   border-[#B2B5C4] border-[1px] focus:bg-white focus:ring-0 placeholder:text-sm"
placeholder="Enter contact number"
          style={
            errors.contactnumber &&
            touched.contactnumber
              ? { border: "1px solid red" }
              : {}
          }
/>
  
        </div>
        </div>
        <div className="max-md:pt-5">
          
        <p className='text-black text-xl font-medium leading-10 whitespace-nowrap mt-3 max-md:max-w-full after:content-["*"] after:ml-0.5 after:text-red-500'>
        Regd No
                </p>
        <div className="flex items-center ">
        <input
        name="regnumber"
     type="text"
     value={values.regnumber}
     placeholder="Reg No"
     onChange={handleChange}
     className="form-input  p-2 w-[60%] max-md:w-full h-10 rounded-md   capitalize border-[#B2B5C4] border-[1px] focus:bg-white focus:ring-0 placeholder:text-sm"
     style={ 
                   errors.regnumber &&
                   touched.regnumber
                     ? { border: "1px solid red" }
                     : {}
                 }
   />
        </div>
        </div>
        <div className="max-md:pt-5">
          
        <p className='text-black text-xl font-medium leading-10 whitespace-nowrap mt-3 max-md:max-w-full after:content-["*"] after:ml-0.5 after:text-red-500'>
        Head Name
        </p>
        <div className="flex items-center ">
        <input
        name="headname"
  type="text"
  value={values.headname}
  onChange={handleChange}
  placeholder="Head name"
  className="form-input  p-2 w-[60%] max-md:w-full h-10 rounded-md   capitalize border-[#B2B5C4] border-[1px] focus:bg-white focus:ring-0 placeholder:text-sm"
  style={
                  errors.headname &&
                  touched.headname
                    ? { border: "1px solid red" }
                    : {}
                }
  />
        </div>
        </div>
        <div className="max-md:pt-5">
          
        <p className='text-black text-xl font-medium leading-10 whitespace-nowrap mt-3 max-md:max-w-full after:content-["*"] after:ml-0.5 after:text-red-500'>
        Training Place
        </p>
        <div className="flex items-center ">
        <input
        name="trainingplace"
type="text"
value={values.trainingplace}
placeholder="Training place"
onChange={handleChange}
className="form-input  p-2 w-[60%] max-md:w-full h-10 rounded-md capitalize   border-[#B2B5C4] border-[1px] focus:bg-white focus:ring-0 placeholder:text-sm"
style={
           errors.trainingplace &&
           touched.trainingplace
             ? { border: "1px solid red" }
             : {}
         }
/>
        </div>
        </div>
        <div className="max-md:pt-5">
          
        <p className='text-black text-xl font-medium leading-10 whitespace-nowrap mt-3 max-md:max-w-full after:content-["*"] after:ml-0.5 after:text-red-500'>
        Form Validity
        </p>
        <div className="flex items-center ">
        <input
                   value={values.fromvalidity}
                  name="fromvalidity"
                                placeholder="DD-MM-YYYY"
                                type="date"
                    onChange={handleChange}
                    className="form-input  p-2 w-[60%] max-md:w-full h-10 rounded-md   capitalize border-[#B2B5C4] border-[1px] focus:bg-white focus:ring-0 placeholder:text-sm"
                    style={
                                  errors.regnumber &&
                                  touched.regnumber
                                    ? { border: "1px solid red" }
                                    : {}
                                }
                  />
        </div>
        </div>
        <div className="max-md:pt-5">
          
        <p className='text-black text-xl font-medium leading-10 whitespace-nowrap mt-3 max-md:max-w-full after:content-["*"] after:ml-0.5 after:text-red-500'>
        To Validity
        </p>
        <div className="flex items-center ">
        <input
        value={values.tovalidity}
name="tovalidity"
placeholder="DD-MM-YYYY"
type='date'
  onChange={handleChange}
  className="form-input  p-2 w-[60%] max-md:w-full h-10 rounded-md   capitalize border-[#B2B5C4] border-[1px] focus:bg-white focus:ring-0 placeholder:text-sm"
  style={
                  errors.tovalidity &&
                  touched.tovalidity
                    ? { border: "1px solid red" }
                    : {}
                }
  />
        </div>
        </div>
        <div className="max-md:pt-5">
          
        <p className='text-black text-xl font-medium leading-10 whitespace-nowrap mt-3 max-md:max-w-full after:content-["*"] after:ml-0.5 after:text-red-500'>
        Morning From Time
        </p>
        <div className="flex items-center ">
        <div className="flex flex-col w-[300px]">
        <h2>From Time</h2>
        <TimePicker
        name="fromtime" 
         className="form-input  p-2 w-[60%] max-md:w-full h-10 rounded-md   capitalize  border-[#B2B5C4] border-[1px] focus:bg-white focus:ring-0 placeholder:text-sm"
          
  
       format="hh:mm A"
       style={
        errors.morning_from_time &&
        touched.morning_from_time
          ? { border: "1px solid red" }
          : {}
      }

       value={values.morning_from_time ? moment(values.morning_from_time, 'hh:mm A') : null}
       onChange={(time, timeString) => setFieldValue("morning_from_time", timeString)} 
       />
       <h2>  To Time</h2>
       <TimePicker
       name="morning_to_time" 
       className="form-input  p-2 w-[60%] max-md:w-full h-10 rounded-md    border-[#B2B5C4] border-[1px] focus:bg-white focus:ring-0 placeholder:text-sm"

      format="hh:mm A"
       
      value={values.morning_to_time ? moment(values.morning_to_time, 'hh:mm A') : null}
      onChange={(time, timeString) => setFieldValue("morning_to_time", timeString)} 
      style={
        errors.morning_to_time &&
        touched.morning_to_time
          ? { border: "1px solid red" }
          : {}
      }
      />
        </div>
        </div>

      
      </div>
        <div className="max-md:pt-5">
          
        <p className='text-black text-xl font-medium leading-10 whitespace-nowrap mt-3 max-md:max-w-full after:content-["*"] after:ml-0.5 after:text-red-500'>
        Evening Training Time
        </p>
        <div className="flex items-center ">
        <div className="flex flex-col  w-[300px]">
        <h2>From Time</h2>
        <TimePicker
        name="eveningfromtime" 
        className="form-input  p-2 w-[60%] max-md:w-full h-10 rounded-md    border-[#B2B5C4] border-[1px] focus:bg-white focus:ring-0 placeholder:text-sm"

       format="hh:mm A"

       value={values.evening_from_time ? moment(values.evening_from_time, 'hh:mm A') : null}
       onChange={(time, timeString) => setFieldValue("evening_from_time", timeString)} 
       style={
        errors.evening_from_time &&
        touched.evening_from_time
          ? { border: "1px solid red" }
          : {}
      }
       />
        <h2>  To Time</h2>
        <TimePicker
        name="evening_to_time" 
        className="form-input  p-2 w-[60%] max-md:w-full h-10 rounded-md   border-[#B2B5C4] border-[1px] focus:bg-white focus:ring-0 placeholder:text-sm"

       format="hh:mm A"

       value={values.evening_to_time ? moment(values.evening_to_time, 'hh:mm A') : null}
       onChange={(time, timeString) => setFieldValue("evening_to_time", timeString)} 
       style={
        errors.evening_to_time &&
        touched.evening_to_time
          ? { border: "1px solid red" }
          : {}
      }
       />
        </div> 
                  
        </div>

      
      </div>
      </div>

      </Template>
  );
};

export default AddOtaClub;
