import { createSlice } from "@reduxjs/toolkit";

const NewContactUsList = createSlice({
    name: "contactus",
    initialState: {
        contactUsLoading: false,
        ContactUsList:[],
        page:1,
        total_pages:0
      
    },
    reducers: {
         getContactUsListRequest:(state)=>{
            state.contactUsLoading=true;
         },
         getContactUsListSuccess:(state, {payload})=>{
            state.contactUsLoading=false;
            state.ContactUsList=payload;
         },
         getContactUsListFail:(state)=>{
            state.contactUsLoading=false;
            state.ContactUsList=[];
         },
         setContactUsPage:(state,{payload})=>{
            state.page=payload
         },
         setContactUsTotalPage:(state,{payload})=>{
            state.total_pages=payload
         }
    }
})  

export default NewContactUsList.reducer;
export const {getContactUsListFail, getContactUsListRequest, getContactUsListSuccess, setContactUsPage, setContactUsTotalPage}= NewContactUsList.actions