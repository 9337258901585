import { useFormik } from 'formik';
import React, { useState } from 'react'
import toast from 'react-hot-toast';
import * as Yup from "yup";
import Files from "react-files";
import { TbFileTypePdf } from "react-icons/tb";
import { ReactComponent as UploadIcon } from "../../assets/icons/uploadicon.svg";
import { ReactComponent as AddIcon } from "../../assets/icons/addicon.svg";
import axiosConfig from '../../axios_config/axios_instance';
import { getCompetitionList } from '../../services/api';
import { useDispatch } from 'react-redux';

const AddCompetition = () => {
    const [showModal, setShowModal] = useState(false);
    const [isLoading, setisLoading] = useState(false)

  //intialData for formik
  const initialValues = {
    heading: "",
    logo: "",
    pdf: "",
  };

  //Yup validator Schema
  const EditInformationSchema = Yup.object({
    heading: Yup.string()
   
      .required("required"),
  
    logo: Yup.mixed().required("required"),
    pdf: Yup.mixed().required("required"),
  });
    
    const dispatch=useDispatch()

  //formik validation
  const {
    values,
    handleBlur,
    resetForm,
    handleChange,
    handleSubmit,
    setFieldValue,
    errors,
    touched,
  } = useFormik({
    initialValues,
    validationSchema: EditInformationSchema,
    validateOnChange: true,
    enableReinitialize: true,
    validateOnBlur: false,
    onSubmit: async(values, action) => {
        try {
            const formData = new FormData();
            formData.append("heading",values.heading)
            formData.append("logo",values.logo)
            formData.append("pdf",values.pdf)
            setisLoading(true);
            const { data } = await axiosConfig.post(`/save-competition`,formData);
            if (data?.status === "success") {
                toast.success(data?.message);
                setShowModal(false);
                resetForm();
                dispatch(getCompetitionList())
            setisLoading(false);
            } else {
                toast.error(data?.message);
            setisLoading(false);
        }
    } catch (error) {
        toast.error("Something went wrong")
        setisLoading(false);
        }
    
    },
  });
  const handleImageChange = (files) => {
    if (files.length) {
      setFieldValue("logo", files[0]);
    } else {
      toast.error("No image selected. Please upload an image.");
    }
  };
  const closeModal = (e) => {
    e?.target?.classNameList?.forEach((classNamees) => {
      if (classNamees === "Modal-Container") {
        setShowModal(false);
        resetForm();
      }
    });
  };

  return (
    <div>
    <div
    onClick={(e) => {
      e.stopPropagation();
      setShowModal(true);
      
    }}
    className="flex justify-start cursor-pointer relative items-center flex-grow-0 flex-shrink-0 gap-3"
  >
    <div
      className="flex justify-start items-center flex-grow-0 flex-shrink-0 relative overflow-hidden gap-1 pl-4 pr-5 max-md:py-[7px] py-[10px] rounded-full bg-[#186ced]"
      style={{ boxShadow: "0px 1px 0px 0 rgba(0,0,0,0.08)" }}
              >
                  <AddIcon />
      <p className="flex-grow-0 flex-shrink-0 text-sm font-medium text-center text-white">
        Add Competition
      </p>
    </div>
  </div>
  {showModal ? (
    <>
      <div
        className="flex justify-center items-center overflow-x-hidden overflow-y-auto fixed inset-0 z-50 outline-none focus:outline-none shadow-lg w-full Modal-Container"
        onClick={closeModal}
        style={{ backdropFilter: "blur(1px)" }} 

      >
        <div className="relative w-auto my-6 mx-auto max-w-3xl">
          <div className="border-0 rounded-lg shadow-lg relative flex w-full bg-white outline-none focus:outline-none">
            <div className="flex items-start gap-1 p-2 border-b border-solid border-gray-300 rounded-t flex-col ">
              <h3 className="text-xl font=semibold">
                Add Competition 
              </h3>
                                  <div className="flex justify-between items-start">
                                      <div>
                                      <div className="flex-grow-0 flex-shrink-0">
                        <p className="text-[14px] leading-10 py-[1px] font-medium text-left text-black/80">
                        Logo
                        </p>
                      </div>
                <div className="flex flex-col mt-3">
                  <Files
                    className="files-dropzone cursor-pointer"
                    onChange={(file) => handleImageChange(file)}
                    onError={(error)=>toast.error(error.message)}
                    accepts={["image/png","image/jpeg"]}
                    maxFileSize={10000000}
                    minFileSize={0}
                    multiple
                    clickable
                  >
                    {values.logo ? (
                      <div className="flex flex-col items-center relative">
                        <img
                          src={
                            typeof values.logo === "object"
                              ? values.logo?.preview?.url
                              : values.logo
                          }
                          className="h-20 w-20  rounded-full object-cover"
                        />
                      </div>
                    ) : (
                      <div className="flex flex-col justify-center items-center flex-grow-0 flex-shrink-0 h-[80] w-[80] relative gap-1 px-[23.5px] py-7 rounded-full bg-[#186ced]/[0.04] border-[1.33px] border-[#186ced]/[0.64] border-dashed">
                        <UploadIcon />
                        <div className="flex justify-start items-center flex-grow-0 flex-shrink-0 relative gap-1 px-[5px] py-0.5 rounded">
                          <p className="flex-grow-0 flex-shrink-0 text-xs font-medium text-center text-black/[0.64]">
                            Upload
                          </p>
                        </div>
                      </div>
                    )}
                  </Files>
                </div>
                </div>
                <div className="relative p-6 pt-0 flex-auto">
                  <div className="flex flex-col justify-start items-start self-stretch flex-grow-0 flex-shrink-0 gap-1">
                    <div className="flex justify-start items-start self-stretch flex-grow-0 flex-shrink-0 relative gap-1 flex-col">
                      <div className="flex-grow-0 flex-shrink-0 w-[150px]">
                        <p className="text-[14px] leading-10 py-[1px] font-medium text-left text-black/80">
                          Heading
                        </p>
                      </div>
                      <div className="flex flex-col  w-full">
                      <input
                                                                 className="form-input w-full rounded-md bg-gray-100 border-transparent focus:border-gray-500 focus:bg-white focus:ring-1 placeholder:text-sm"
                                                                type={"text"}
                                                                placeholder="Enter Name "
                                                                name='heading'
                                                                value={values.heading}
                                                                style={errors.heading && touched.heading ?{border:"1px solid red"}:{}}
                                                          onChange={(e) => {
                                                              
                                                                        handleChange(e)
                                                                  
                                                                }}
                                                                onBlur={handleBlur}
                                                      />
                                                      <div className="flex justify-between items-center">
                        
                      
                        </div>
                      </div>
                      <div className="flex-grow-0 flex-shrink-0 w-full">
                        <p className="text-[13px] leading-10 py-[1px] font-medium text-left text-black/80">
                          Pdf File
                        </p>
                      </div>
                      <div className="flex flex-col flex-grow w-[300px]">
                      <div className="flex flex-col">
                      <Files
                              className="files-dropzone"
                                                              onChange={(file) => {
                                                                  if (file.length) {
                                                                     setFieldValue("pdf",file[0])
                                                                     }
                         }}
                         onError={(err) => toast.error(err.message)}
                          accepts={[
                            ".pdf",
                          ]}
                          maxFileSize={2000000}
                          minFileSize={0}
                          clickable
                              >
                              <div id="file-uploader2" className="wrapper js">    
<form className="js--upload-form is-droppable">
		<span className="text--center">
			<i className="fa fa-cloud-upload fa-2"></i>
                                                                          {values?.pdf ?<>
         <span className='text-black'>{values?.pdf?.name} </span>
         <label className="btn btn--link" for="file">replace</label>                                                                     
        </>
                                                                              : <span className="js--advanced-upload">Choose file <label className="btn btn--link" for="file">browse</label></span>}
			<span type="file" name="files[]" id="file" className="js--basic-upload hide" data-multiple-caption="{count} files selected" multiple="" accept=".pdf,.xlsx" />
		</span>
	</form>
                        </div>
                       </Files>

                </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="flex justify-start items-start flex-grow-0 flex-shrink-0 gap-2 self-end">
                <div
                  onClick={(e) => {
                    e.stopPropagation();
                    setShowModal(false);
                  }}
                  className="flex justify-start items-center flex-grow-0 flex-shrink-0 relative overflow-hidden gap-1 pl-4 pr-5 py-[11px] rounded-[94px] bg-[#fff] cursor-pointer"
                  style={{ boxShadow: "0px 1px 0px 0 rgba(0,0,0,0.08)" }}
                >
                  <p className="flex-grow-0 flex-shrink-0 text-sm font-medium text-center text-black">
                    Cancel
                  </p>
                </div>
                <button
                  type="button"
                  className="flex justify-start items-center flex-grow-0 flex-shrink-0 relative overflow-hidden gap-1 px-5 py-[11px] rounded-[94px] bg-[#186ced] cursor-pointer"
                  style={{ boxShadow: "0px 1px 0px 0 rgba(0,0,0,0.08)" }}
                  onClick={handleSubmit}
                  disabled={isLoading?"disable":""}
                >
                  <p className="flex-grow-0 flex-shrink-0 text-sm font-medium text-center text-white">
                    Save
                  </p>
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  ) : null}
    </div>
  )
}

export default AddCompetition
