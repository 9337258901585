import React from 'react'
import { useEffect } from 'react'
import { useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { MdOutlineEdit } from "react-icons/md";


import * as Yup from "yup"
import { useFormik } from 'formik'
import { getAllEventChampoionShipList } from '../../services/apiService'
import toast from 'react-hot-toast'
import axiosConfig from '../../axios_config/axios_instance'


const ManageEvent = () => {
    const [showModal, setshowModal] = useState(false)
    const {EventChampionshipList}=useSelector((state)=>state.eventchampionship)
    const dispatch = useDispatch();
    const closeModal = (e) => {
        e?.target?.classList?.forEach((classes) => {
          if (classes === "Modal-Container") {
            setshowModal(false);
          }
        });
      };
    const openModal = () => {
        setshowModal(true);
    }

    useEffect(() => {
        dispatch(getAllEventChampoionShipList())
    },[dispatch])
  return (
      <>
          <div
      className="flex justify-start items-start flex-grow-0 flex-shrink-0 h-[42px] gap-2.5 rounded-[5px]"
    >
      <button
        className="flex justify-start items-center flex-grow-0 flex-shrink-0 relative overflow-hidden gap-1 pl-4 pr-5 py-[10px] rounded-full bg-blue-600"
        onClick={openModal}
      >

        <p className="flex-grow-0 flex-shrink-0 text-sm font-medium text-center text-white">
          Manage Event
        </p>
      </button>
          </div>
        { showModal ?     <>
    <div
                        className="flex justify-center  items-center overflow-x-hidden overflow-y-auto fixed inset-0 z-50 outline-none focus:outline-none shadow-lg  w-full Modal-Container bg-black/30 " 
                        onClick={closeModal}
                    >
                        <div className="relative w-auto my-6 mx-auto max-w-full">
                            <div className="border-0 rounded-lg shadow-lg relative  w-full bg-white outline-none focus:outline-none">
                                  <div className='w-full rounded-t-lg '>
                                    <div className='flex justify-between py-2 mx-2'>
                                    <h3 className="text-xl font=semibold ">Event List</h3>
                                    </div>

                                  </div>
                                  <div className='relative flex'>
                                <div className="flex items-start  gap-1 p-2 border-b border-solid border-gray-300 rounded-t flex-col ">
                                    <div className="relative p-6 pt-0 flex-auto">
                                        <div className="flex flex-col justify-end items-end self-stretch flex-grow-0 flex-shrink-0 gap-1">
                                            <div className="flex justify-start border-[1px] border-[#e8dcdca3] rounded-lg  items-start self-stretch flex-grow-0 flex-shrink-0 relative gap-1 flex-col w-[600px] max-h-[200px] overflow-x-auto min-h-[200px]">
                                            <table class="table-auto pb-10  w-full">
        <thead>
          <tr>
           
            <th className="border-b border-black/10 text-xs font-semibold px-4 py-3 text-left">
              Event Name
            </th>
            <th className="border-b border-black/10 text-xs font-semibold px-4 py-3 text-left">
              Edit
            </th>
         
          </tr>
        </thead>
        <tbody className='overflow-scroll max-h-[70vh] min-h-[70vh]'>
          {
            EventChampionshipList?.map((list,i)=>
           
            <tr
            className=" w-full group/item bg-white hover:bg-slate-100 transition-all border-b border-black/5" key={i}
          >
           
            
            <td className="text-sm p-4">{list?.event_name}</td>
            <td className="text-sm p-4">
                   <EditEventChampionship category={list} />
            </td>
         
          </tr>
           )
          }
           
        </tbody>


        </table>
                                            </div>
                                        </div>
                                    </div>
                                  {/* Add New */}
                                  <AddEventChanpionship />
                                </div>

                                  </div>
                              
                            </div>
                        </div>
                    </div>
            </>:null}
            
    </>
  )
}

export default ManageEvent

const EditEventChampionship = ({category}) => {
    const [showModal, setShowModal] = useState(false);
    const dispatch = useDispatch();
   const [isLoading, setisLoading] = useState(false)
  
    //intialData for formik
    const initialValues = {
      name: category?.event_name ?category?.event_name:"",
    };
  
      
    //Yup validator Schema
    const EditInformationSchema = Yup.object({
      name: Yup.string()
        .trim()
        .min(2, "Too short")
        .max(25, "Too Long")
        .required("Please enter your name"),
    });
  
    //formik validation
    const {values,resetForm,setErrors,handleBlur,handleChange,handleSubmit,errors,touched,} = useFormik({
      initialValues,
      validationSchema: EditInformationSchema,
      validateOnChange: true,
      enableReinitialize: true,
      validateOnBlur: false,
      onSubmit: async(values, action) => {
          try {
              const postdata = new FormData();
              postdata.append("event_name",values.name)
              postdata.append("event_id",category.event_id)
              setisLoading(true)
            const {data}=await axiosConfig.put(`/edit-event`,postdata)
            if (data?.status === "success") {
              toast.success(data?.data?.message || "Event Updated Successfully");
              setShowModal(false);
                dispatch(getAllEventChampoionShipList())
                resetForm()
                setisLoading(false);
            } else {
              toast.error(data?.data?.message);
              setisLoading(false);
            }
          } catch (error) {
            toast.error("Can't procced your request right now!! ");
            setisLoading(false);
          }
      },
    });
  
  
    const closeModal = (e) => {
       if (isLoading) {
        return;
      }
      e?.target?.classList?.forEach((classes) => {
        if (classes === "Modal-Container") {
          setShowModal(false);
        }
      });
    };
    const openModal = () => {
      setShowModal(true);
      resetForm();
      setErrors({ name: "", is_required: "", product_type: "" });
      };
    return (
        <>
                <div
      className="flex justify-start items-start flex-grow-0 flex-shrink-0 h-[42px] gap-2.5 rounded-[5px]"
    >
      <button
        className="cursor-pointer"
        onClick={openModal}
      >
      <MdOutlineEdit className="w-5 h-5" />
      </button>
            </div>
            {showModal ? (
        <>
          <div
            className=" flex justify-center items-center overflow-x-hidden overflow-y-auto fixed inset-0 z-50 outline-none focus:outline-none shadow-lg w-full Modal-Container bg-black/30"
            onClick={closeModal}
          >
            <div className="w-[360px] relative  my-6 mx-auto max-w-3xl">
              <div className="border-0 rounded-lg shadow-lg relative flex w-full bg-white outline-none focus:outline-none">
                <div className="flex items-start gap-1 p-2 border-b border-solid border-gray-300 rounded-t flex-col ">
                  <h3 className="text-xl font=semibold">Edit Event List</h3>
                  <div className="flex flex-col gap-2">
                    <div className="flex justify-between items-start">
                      <div className="relative p-6 pt-0 flex-auto">
                        <div className="flex flex-col justify-start items-start self-stretch flex-grow-0 flex-shrink-0 gap-1">
                          <div className="flex justify-start items-start self-stretch flex-grow-0 flex-shrink-0 relative gap-1 flex-col">
                            <div className="flex-grow-0 flex-shrink-0 w-[300px]">
                              <p className="text-[13px] leading-10 py-[1px] font-medium text-left text-black/80">
                                Name
                              </p>
                              <div className="flex-grow w-[300px]">
                                <input
                                  className="form-input w-full rounded-md bg-gray-100 border-transparent focus:border-black/30 focus:bg-white focus:ring-0 placeholder:text-sm"
                                  type={"text"}
                                  placeholder="Enter product type name"
                                  value={values.name}
                                  onChange={handleChange}
                                  onBlur={handleBlur}
                                  name="name"
                                  style={
                                    errors.name && touched.name
                                      ? { border: "1px solid red" }
                                      : {}
                                  }
                                />
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="flex justify-start items-start flex-grow-0 flex-shrink-0 gap-2 self-end">
                    <button
                      disabled={isLoading?"disable":""}
                      onClick={(e) => {
                        e.stopPropagation();
                        setShowModal(false);
                      }}
                      className={`flex justify-start items-center flex-grow-0 flex-shrink-0 relative overflow-hidden gap-1 pl-4 pr-5 py-[11px] rounded-[94px] bg-[#fff] ${isLoading?"cursor-wait":"cursor-pointer"}`}
                      style={{ boxShadow: "0px 1px 0px 0 rgba(0,0,0,0.08)" }}
                    >
                      <p className="flex-grow-0 flex-shrink-0 text-sm font-medium text-center text-black">
                        Cancel
                      </p>
                    </button>
                                        <button
                                            type='button'
                      className={`flex justify-start items-center flex-grow-0 flex-shrink-0 relative overflow-hidden gap-1 px-5 py-[11px] rounded-[94px] bg-[#186ced] ${isLoading?"cursor-wait":"cursor-pointer"}`}
                      style={{ boxShadow: "0px 1px 0px 0 rgba(0,0,0,0.08)" }}
                      onClick={handleSubmit}
                      disabled={isLoading?"disable":""}
                    >
                      <p className="flex-grow-0 flex-shrink-0 text-sm font-medium text-center text-white">
                        Save
                      </p>
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </>
      ) : null}
        </>
    )
}

const AddEventChanpionship = () => {
    const [showModal, setShowModal] = useState(false);
    const dispatch = useDispatch();
   const [isLoading, setisLoading] = useState(false)
  
    //intialData for formik
    const initialValues = {
      name: "",
    };
  
      
    //Yup validator Schema
    const EditInformationSchema = Yup.object({
      name: Yup.string()
        .trim()
        .min(2, "Too short")
        .max(25, "Too Long")
        .required("Please enter your name"),
    });
  
    //formik validation
    const {values,resetForm,setErrors,handleBlur,handleChange,handleSubmit,errors,touched,} = useFormik({
      initialValues,
      validationSchema: EditInformationSchema,
      validateOnChange: true,
      enableReinitialize: true,
      validateOnBlur: false,
      onSubmit: async(values, action) => {
        try {
            const postdata = new FormData();
            postdata.append("event_name",values.name)
            setisLoading(true);
            const {data}=await axiosConfig.post(`/event-save`,postdata)
            if (data?.data?.status === "success") {
              toast.success(data?.data?.message);
              setShowModal(false);
                dispatch(getAllEventChampoionShipList())
                resetForm()
                setisLoading(false);
            } else {
              toast.error(data?.data?.message);
              setisLoading(false);
            }
          } catch (error) {
            toast.error("Can't procced your request right now!! ");
            setisLoading(false);
          }
      },
    });
  
  
    const closeModal = (e) => {
       if (isLoading) {
        return;
      }
      e?.target?.classList?.forEach((classes) => {
        if (classes === "Modal-Container") {
          setShowModal(false);
        }
      });
    };
    const openModal = () => {
      setShowModal(true);
      resetForm();
      setErrors({ name: "", is_required: "", product_type: "" });
      };
    return (
        <>
                <div
      className="flex justify-start items-start flex-grow-0 flex-shrink-0 h-[42px] gap-2.5 rounded-[5px]"
    >
      <button
        className="flex justify-start items-center flex-grow-0 flex-shrink-0 relative overflow-hidden gap-1 pl-4 pr-5 py-[10px] rounded-full bg-transparent"
        onClick={openModal}
      >
     
        <p className="flex-grow-0 flex-shrink-0 text-sm font-medium text-center text-black/80">
          Add Event
        </p>
      </button>
            </div>
            {showModal ? (
        <>
          <div
            className=" flex justify-center items-center overflow-x-hidden overflow-y-auto fixed inset-0 z-50 outline-none focus:outline-none shadow-lg w-full Modal-Container bg-black/30"
            onClick={closeModal}
          >
            <div className="w-[360px] relative  my-6 mx-auto max-w-3xl">
              <div className="border-0 rounded-lg shadow-lg relative flex w-full bg-white outline-none focus:outline-none">
                <div className="flex items-start gap-1 p-2 border-b border-solid border-gray-300 rounded-t flex-col ">
                  <h3 className="text-xl font=semibold">Add Event List</h3>
                  <div className="flex flex-col gap-2">
                    <div className="flex justify-between items-start">
                      <div className="relative p-6 pt-0 flex-auto">
                        <div className="flex flex-col justify-start items-start self-stretch flex-grow-0 flex-shrink-0 gap-1">
                          <div className="flex justify-start items-start self-stretch flex-grow-0 flex-shrink-0 relative gap-1 flex-col">
                            <div className="flex-grow-0 flex-shrink-0 w-[300px]">
                              <p className="text-[13px] leading-10 py-[1px] font-medium text-left text-black/80">
                                Name
                              </p>
                              <div className="flex-grow w-[300px]">
                                <input
                                  className="form-input w-full rounded-md bg-gray-100 border-transparent focus:border-black/30 focus:bg-white focus:ring-0 placeholder:text-sm"
                                  type={"text"}
                                  placeholder="Enter product type name"
                                  value={values.name}
                                  onChange={handleChange}
                                  onBlur={handleBlur}
                                  name="name"
                                  style={
                                    errors.name && touched.name
                                      ? { border: "1px solid red" }
                                      : {}
                                  }
                                />
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="flex justify-start items-start flex-grow-0 flex-shrink-0 gap-2 self-end">
                    <button
                      disabled={isLoading?"disable":""}
                      onClick={(e) => {
                        e.stopPropagation();
                        setShowModal(false);
                      }}
                      className={`flex justify-start items-center flex-grow-0 flex-shrink-0 relative overflow-hidden gap-1 pl-4 pr-5 py-[11px] rounded-[94px] bg-[#fff] ${isLoading?"cursor-wait":"cursor-pointer"}`}
                      style={{ boxShadow: "0px 1px 0px 0 rgba(0,0,0,0.08)" }}
                    >
                      <p className="flex-grow-0 flex-shrink-0 text-sm font-medium text-center text-black">
                        Cancel
                      </p>
                    </button>
                                        <button
                                            type='button'
                      className={`flex justify-start items-center flex-grow-0 flex-shrink-0 relative overflow-hidden gap-1 px-5 py-[11px] rounded-[94px] bg-[#186ced] ${isLoading?"cursor-wait":"cursor-pointer"}`}
                      style={{ boxShadow: "0px 1px 0px 0 rgba(0,0,0,0.08)" }}
                      onClick={handleSubmit}
                      disabled={isLoading?"disable":""}
                    >
                      <p className="flex-grow-0 flex-shrink-0 text-sm font-medium text-center text-white">
                        Save
                      </p>
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </>
      ) : null}
        </>
    )
}