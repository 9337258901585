import React, { useState } from "react";

import * as Yup from "yup";
import { useFormik } from "formik";
import { FiLoader } from "react-icons/fi";

import axiosConfig from "../../axios_config/axios_instance";
import toast from "react-hot-toast";
import { useDispatch } from "react-redux";
import { getAllVideosList } from "../../services/apiService";
const AddLatestVideo = () => {
    const [showModal, setShowModal] = useState(false);
   const [isLoading, setIsLoading]=useState(false)
   const dispatch=useDispatch()
    //intialData for formik
    const initialValues = {
      latest_desc: "",
      youtubeurl:"",
    };
  
    //Yup validator Schema
    const videoInformationSchema = Yup.object({
      latest_desc: Yup.string()
        .trim()
        .required("required")
        .max(78, ""),
        
        youtubeurl: Yup.string()
        .url("Invalid YouTube URL")
        .required("YouTube URL is required"),
  
    });
  
    //formik validation
    const {
      values,
      handleBlur,
      resetForm,
      handleChange,
      handleSubmit,
      errors,
      touched,
    } = useFormik({
      initialValues,
      validationSchema: videoInformationSchema,
      validateOnChange: true,

      validateOnBlur: false,
      onSubmit: async(values) => {
        try {

          const formdata= new FormData()
          setIsLoading(true)
          formdata.append("ota_videos_link", values?.youtubeurl);
          formdata.append("description", values?.latest_desc);
          const {data}= await axiosConfig.post("/save-video", formdata);
          if(data?.status === "success"){
            toast.success(data?.message || "Video Added successfully")
            setShowModal(false)
            resetForm()
            dispatch(getAllVideosList())
     
          }
         } catch (error) {
            toast.error("something Went wrong")
         }finally{
          setIsLoading(false)
         }
      },
    });
   

  
    const closeModal = (e) => {
      e?.target?.classList?.forEach((classes) => {
        if (classes === "Modal-Container") {
          setShowModal(false);
          resetForm();
        }
      });
    };


  return (
    <div>
    <div
    onClick={(e) => {
      e.stopPropagation();
      setShowModal(true);
      resetForm();
    }}
    className="flex justify-start cursor-pointer relative items-center flex-grow-0 flex-shrink-0 gap-3"
  >
    <div
      className="flex justify-start items-center flex-grow-0 flex-shrink-0 relative overflow-hidden gap-1 pl-4 pr-5 max-md:py-[7px] py-[10px] rounded-full bg-[#186ced]"
      style={{ boxShadow: "0px 1px 0px 0 rgba(0,0,0,0.08)" }}
    >
      <p className="flex-grow-0 flex-shrink-0 text-sm font-medium text-center text-white">
        Add Latest Video
      </p>
    </div>
  </div>
  {showModal ? (
    <>
      <div
        className="flex justify-center items-center overflow-x-hidden overflow-y-auto fixed inset-0 z-50 outline-none focus:outline-none shadow-lg w-full Modal-Container"
        onClick={closeModal}
        style={{ backdropFilter: "blur(1px)" }} 

      >
        <div className="relative w-auto my-6 mx-auto max-w-3xl">
          <div className="border-0 rounded-lg shadow-lg relative flex w-full bg-white outline-none focus:outline-none">
            <div className="flex items-start gap-1 p-2 border-b border-solid border-gray-300 rounded-t flex-col ">
              <h3 className="text-xl font=semibold">
                Add Video
              </h3>
              <div className="flex justify-between items-start">
                <div className="flex flex-col mt-3">
                
                </div>
                <div className="relative p-6 pt-0 flex-auto">
                  <div className="flex flex-col justify-start items-start self-stretch flex-grow-0 flex-shrink-0 gap-1">
                    <div className="flex justify-start items-start self-stretch flex-grow-0 flex-shrink-0 relative gap-1 flex-col">
                      <div className="flex-grow-0 flex-shrink-0 w-[150px]">
                        <p className="text-[13px] leading-10 py-[1px] font-medium text-left text-black/80">
                          Youtube Link
                        </p>
                      </div>
                      <div className="flex flex-col flex-grow ">
                      <input
                      type="url"
                      className="form-input max-md:w-full  p-2 w-full h-10 rounded-md   border-[#B2B5C4] border-[1px] focus:bg-white focus:ring-0 placeholder:text-sm"
                      placeholder="Youtube Link"
                      name="youtubeurl"
                      value={values.youtubeurl}
                      onChange={handleChange}
                      onBlur={handleBlur}
                      style={
                        errors.youtubeurl && touched.youtubeurl
                          ? { border: "1px solid red" }
                          : {}
                      }
                    />
                    
                       <div className="flex flex-col flex-grow w-[300px]">
                       <p className="text-[13px] leading-10 py-[1px] font-medium text-left text-black/80">
                          Descrption
                        </p>
                       <textarea
                         type="text"
                         className="form-input w-full h-40 rounded-md capitalize bg-gray-100 border-transparent focus:border-gray-500 focus:bg-white focus:ring-0 placeholder:text-sm"
                         placeholder="Type here"
                         value={values.latest_desc}
                         onChange={handleChange} 
                         maxLength={78} // <-- Corrected
                         onBlur={handleBlur}
                         name="latest_desc"
                         style={
                           errors.latest_desc && touched.latest_desc
                             ? { border: "1px solid red" }
                             : {}
                         }
                       ></textarea>

                       <div className="flex justify-between items-center">
                        
                         <div className="flex">
                           <span className="text-green-500">
                             {values.latest_desc?.length}
                           </span>
                           /<span className="text-red-500">78</span>
                         </div>
                       </div>
                     </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="flex justify-start items-start flex-grow-0 flex-shrink-0 gap-2 self-end">
                <div
                  onClick={(e) => {
                    e.stopPropagation();
                    setShowModal(false);
                  }}
                  className="flex justify-start items-center flex-grow-0 flex-shrink-0 relative overflow-hidden gap-1 pl-4 pr-5 py-[11px] rounded-[94px] bg-[#fff] cursor-pointer"
                  style={{ boxShadow: "0px 1px 0px 0 rgba(0,0,0,0.08)" }}
                >
                  <p className="flex-grow-0 flex-shrink-0 text-sm font-medium text-center text-black">
                    Cancel
                  </p>
                </div>
                <button
                type="button"
                className="flex justify-start items-center flex-grow-0 flex-shrink-0 relative overflow-hidden gap-1 px-5 py-[11px] rounded-[94px] bg-[#186ced] cursor-pointer"
                style={{ boxShadow: "0px 1px 0px 0 rgba(0,0,0,0.08)" }}
                onClick={handleSubmit}
                disabled={isLoading} // Disable the button when loading
              >
                {isLoading ? (
                  <div className="spinner-border text-light" role="status">
                    <span className="visually-hidden"><FiLoader /></span>
                  </div>
                ) : (
                  <p className="flex-grow-0 flex-shrink-0 text-sm font-medium text-center text-white">
                    Save
                  </p>
                )}
              </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  ) : null}
    </div>
  )
}

export default AddLatestVideo
