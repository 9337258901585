import { createSlice } from "@reduxjs/toolkit";

const onlineRegistrationSlice = createSlice({
    name: "onlineRegistration",
    initialState: {
        championShipHeadings:[],
        championShipHeadingLoading:false,
        ColorBeltHeadings:[],
        ColorBeltHeadingLoading: false,
        colorBeltHeadingDetails:{},
        colorBeltHeadingDetailsLoading:false,
        championshipHeadingDetails:{},
        championshipHeadingDetailsLoading: false,
        eventListLoading: false,
        eventList:[],
        cityListLoading: false,
        cityList:[],
        clubListLoading: false,
        clubList: [],
        championshipParticipationList:[],
        championshipParticipationListLoading: false,
        participantDetails: {},
        colorBeltParticipants: [],
        totalColorBeltParticipants: 0,
        totalparticipantsList:0
    },
    reducers: {
        getChampionShipHeadingRequest: (state) => {
            state.championShipHeadingLoading = true;
        },
        getChampionShipHeadingSuccess: (state,{payload}) => {
            state.championShipHeadingLoading = false;
            state.championShipHeadings = payload;
        },
        getChampionShipHeadingFail: (state) => {
            state.championShipHeadingLoading = false;
        },
        getColorBeltHeadingsRequest: (state) => {
            state.ColorBeltHeadingLoading = true;
        },
        getColorBeltHeadingsSuccess: (state,{payload}) => {
            state.ColorBeltHeadingLoading = false;
            state.ColorBeltHeadings = payload;
        },
        getColorBeltHeadingsFail: (state) => {
            state.ColorBeltHeadingLoading = false;
        },
        getChampionshipHeadingDetailsRequest: (state) => {
            state.championshipHeadingDetailsLoading = true;
        },
        getChampionshipHeadingDetailsSuccess: (state,{payload}) => {
            state.championshipHeadingDetailsLoading = false;
            state.championshipHeadingDetails = payload;
        },
        getChampionshipHeadingDetailsFail: (state) => {
            state.championshipHeadingDetailsLoading = false;
        },
        getColorBeltHeadingDetailsRequest: (state) => {
            state.colorBeltHeadingDetailsLoading = true;
        },
        getColorBeltHeadingDetailsSuccess: (state,{payload}) => {
            state.colorBeltHeadingDetailsLoading = false;
            state.colorBeltHeadingDetails = payload;
        },
        getColorBeltHeadingDetailsFail: (state) => {
            state.colorBeltHeadingDetailsLoading = false;
        },
        getEventListRequest:(state)=>{
            state.eventListLoading = true;
        },
        getEventListSuccess:(state,{payload})=>{
            state.eventListLoading = false;
            state.eventList = payload;
        },
        getEventListFail:(state)=>{
            state.eventListLoading = false;
        },
        getCityListRequest:(state)=>{
            state.cityListLoading = true;
        },
        getCityListSuccess:(state,{payload})=>{
            state.cityListLoading = false;
            state.cityList = payload;
        },
        getCityListFail:(state)=>{
            state.cityListLoading = false;
        },
        getClubListRequest:(state)=>{
            state.clubListLoading = true;
        },
        getClubListSuccess:(state,{payload})=>{
            state.clubListLoading = false;
            state.clubList = payload;
        },
        getClubListFail:(state)=>{
            state.clubListLoading = false;
        },
        getChampionshipParticipateListRequest: (state) => {
            state.championshipParticipationListLoading = true;
        },
        getChampionshipParticipateListSuccess: (state,{payload}) => {
            state.championshipParticipationListLoading = false;
            state.championshipParticipationList = payload?.championship_registration_List;
            state.totalparticipantsList = payload?.total_elements;
        },
        getChampionshipParticipateListFail: (state) => {
            state.championshipParticipationListLoading = false;
        },
        setParticipantDetails:(state,{payload})=>{
            state.participantDetails = payload;
        },
        getcolorBeltParticipantsRequest: (state) => {
            state.colorBeltHeadingDetailsLoading = true;
        },
        getcolorBeltParticipantsSuccess: (state,{payload}) => {
            state.colorBeltHeadingDetailsLoading = false
            state.colorBeltParticipants = payload?.ota_registration_List;
            state.totalColorBeltParticipants = payload?.total_elements;
        },
        getcolorBeltParticipantsFail: (state) => {
            state.colorBeltHeadingDetailsLoading = false
        },
    }
})

export default onlineRegistrationSlice.reducer;
export const { getChampionShipHeadingFail, getChampionShipHeadingParticipationListFail, getChampionShipHeadingParticipationListRequest, getChampionShipHeadingParticipationListSuccess, getChampionShipHeadingRequest, getChampionShipHeadingSuccess, getColorBeltHeadingParticipationListFail, getColorBeltHeadingParticipationListRequest, getColorBeltHeadingParticipationListSuccess, getColorBeltHeadingsFail, getColorBeltHeadingsRequest, getColorBeltHeadingsSuccess, getColorBeltHeadingDetailsFail, getColorBeltHeadingDetailsRequest, getColorBeltHeadingDetailsSuccess, getChampionshipHeadingDetailsFail, getChampionshipHeadingDetailsRequest, getChampionshipHeadingDetailsSuccess, getCityListFail, getCityListRequest, getCityListSuccess, getClubListFail, getClubListRequest, getClubListSuccess, getEventListFail, getEventListRequest, getEventListSuccess, getChampionshipParticipateListFail, getChampionshipParticipateListRequest, getChampionshipParticipateListSuccess, setParticipantDetails, getcolorBeltParticipantsFail, getcolorBeltParticipantsRequest,getcolorBeltParticipantsSuccess} = onlineRegistrationSlice.actions