import { createSlice } from "@reduxjs/toolkit";

const NewAtheleteForm = createSlice({
    name: "athleteform",
    initialState: {
        AtheleteFormListLoading: false,
        AthleteFormList:[],
        AthleteFormDetaiilsLoading:false,
        AthleteFormDetailsList:[],
        TotalAtheleteForm:0
      
    },
    reducers: {
         getAtheleteFormRequest:(state)=>{
            state.AtheleteFormListLoading=true;
         },
         getAthleteFormListSuccess:(state, {payload})=>{
            state.AtheleteFormListLoading=false;
            state.AthleteFormList=payload?.athlete_form_list;
            state.TotalAtheleteForm=payload?.total_elements
         },
         getAthleteFormListFail:(state)=>{
            state.AtheleteFormListLoading=false;
            state.AthleteFormList=[];
            state.TotalAtheleteForm=0
         },
         getAtheleteFormDetailsRequest:(state)=>{
            state.AthleteFormDetaiilsLoading=true;
         },
         getAthleteFormDetailsListSuccess:(state, {payload})=>{
            state.AthleteFormDetaiilsLoading=false;
            state.AthleteFormDetailsList=payload?.athlete_data;
            state.TotalAtheleteForm=payload?.total_elements
         },
         getAthleteFormDetailsListFail:(state)=>{
            state.AthleteFormDetaiilsLoading=false;
            state.AthleteFormDetailsList=[];
         },
         
   
    }
})  

export default NewAtheleteForm.reducer;
export const {getAtheleteFormDetailsRequest, getAtheleteFormRequest, getAthleteFormDetailsListFail, getAthleteFormDetailsListSuccess, getAthleteFormListFail,getAthleteFormListSuccess}= NewAtheleteForm.actions