

import { Provider } from 'react-redux';
import AppRouter from './router/AppRouter';
import { store } from './redux/store';
import "./App.css"
import { Toaster } from 'react-hot-toast';
function App() {
  return (
    <Provider store={store}>
    <AppRouter />
    <Toaster />
    </Provider>

  );
}

export default App;
