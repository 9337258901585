import React, { useEffect } from 'react'
import Template from '../../components/common/Template'
import Pagination from '../../components/common/pagination';
import AddCompetition from './AddCompetation';
import { useDispatch, useSelector } from 'react-redux';
import { getCompetitionList } from '../../services/api';
import { TbPdf } from 'react-icons/tb';
import fileDownload from 'js-file-download'
import axios from 'axios';
import EditCompetition from './EditCompetition';
import DeleteCompetition from './DeleteCompetition';
const CompetationList = () => {
    const dispatch = useDispatch();
    const { competitionList } = useSelector((state) => state.competition)
    useEffect(() => {
       dispatch(getCompetitionList()) 
    }, [])
    
    const handleDownload = (pdf) => {
        try {
            
            axios.get(pdf, {
                responseType: 'blob',
            },
                
            )
              .then((res) => {
                fileDownload(res.data, pdf?.split("/")?.pop())
              })
        } catch (error) {
            
        }
    }
  return (
      <Template>
                         <div className="flex justify-start items-center self-stretch flex-grow-0 flex-shrink-0 gap-2 px-4 py-4 max-md:flex-col max-md:items-start">
        <div className="flex justify-start items-center flex-grow relative gap-[22px]">
          <p className="flex-grow-0 flex-shrink-0 text-2xl font-semibold text-left capitalize text-[#202223] px-4 max-md:px-0 max-md:text-xl">
          Competition List
          </p>
              </div>
              <div className="flex justify-start items-start flex-grow-0 flex-shrink-0 gap-3">
                  <AddCompetition />
          </div>
        </div>
        <div className="flex flex-col  items-start self-stretch flex-grow min-h-[90vh] justify-between">
      {
        competitionList?.length ?
      <div class=" px-4 sm:px-8 py-4 overflow-x-auto w-full">
      <div
        class="inline-block min-w-full shadow-md rounded-lg overflow-hidden"
      >
        <table class="min-w-full leading-normal">
          <thead>
            <tr>
              <th
                class="px-5 py-3 border-b-2 border-gray-200 bg-gray-100 text-left text-xs font-semibold text-gray-700 uppercase tracking-wider"
              >
               Logo
              </th>
              <th
                class="px-5 py-3 border-b-2 border-gray-200 bg-gray-100 text-left text-xs font-semibold text-gray-700 uppercase tracking-wider"
              >
                Heading
              </th>
              <th
                class="px-5 py-3 border-b-2 border-gray-200 bg-gray-100 text-left text-xs font-semibold text-gray-700 uppercase tracking-wider"
              >
                pdf
              </th>
              <th
                class="px-5 py-3 border-b-2 border-gray-200 bg-gray-100 text-left text-xs font-semibold text-gray-700 uppercase tracking-wider"
              >
                
              </th>
            </tr>
          </thead>
          <tbody>
                    {
                        competitionList?.map((docter,i) => (
                        <tr key={i}>
              <td class="px-5 py-5 border-b border-gray-200 bg-white text-sm">
              <div class="flex items-center">
                  <div class="flex-shrink-0 w-10 h-10">
                    <img
                      class="w-full h-full rounded-full"
                      src={docter?.logo}
                      alt=""
                    />
                  </div>
                </div>
              </td>
              <td class="px-5 py-5 border-b border-gray-200 bg-white text-sm">
                    <p class="text-gray-900 whitespace-nowrap">
                      {docter.heading}
                    </p>
              </td>
              <td class="px-5 py-5 border-b border-gray-200 bg-white text-sm">
                    <p class="text-gray-900 whitespace-nowrap">
                      <PDFICON className='h-4 w-4 cursor-pointer' onClick={()=>handleDownload(docter?.pdf)} />
                    </p>
              </td>
                          <td class="px-5 py-5 border-b border-gray-200 bg-white text-sm">
                                    <div className='flex gap-2 items-center'>        
                            <EditCompetition details={docter} />
                            <DeleteCompetition id={docter?.competition_id} />
                          </div>
              </td>
            </tr>
                      ))
        }
            
          </tbody>
        </table>
      </div>
    </div>:<div className="flex justify-center items-center h-[70vh] w-full">
              <span className="text-center w-full text-xl text-[#7b8794]">
                No competition found
              </span>
            </div>
        }
        </div>
    </Template>
  )
}

export default CompetationList;


export const PDFICON = ({onClick}) => (
    <svg onClick={onClick} width="20px" height="20px" viewBox="0 0 0.6 0.6" fill="none" xmlns="http://www.w3.org/2000/svg" style={{cursor:"pointer"}}><path d="M0.3 0.05a0.025 0.025 0 0 1 0.025 0.025v0.265l0.057 -0.057a0.025 0.025 0 0 1 0.035 0.035l-0.1 0.1a0.025 0.025 0 0 1 -0.035 0l-0.1 -0.1a0.025 0.025 0 1 1 0.035 -0.035L0.275 0.34V0.075a0.025 0.025 0 0 1 0.025 -0.025zM0.125 0.425a0.025 0.025 0 0 1 0.025 0.025v0.05h0.3v-0.05a0.025 0.025 0 1 1 0.05 0v0.05a0.05 0.05 0 0 1 -0.05 0.05H0.15a0.05 0.05 0 0 1 -0.05 -0.05v-0.05a0.025 0.025 0 0 1 0.025 -0.025z" fill="#0D0D0D"/></svg>
);
