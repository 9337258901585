import React, { useEffect, useState } from "react";
import Template from "../../components/common/Template";
import AddLatestVideo from "./AddLatestVideo";
import DeleteLatestVideo from "./DeleteLatestVideo";
import { getAllVideosList } from "../../services/apiService";
import { useDispatch, useSelector } from "react-redux";
import Pagination from "../../components/common/pagination";
import { setLatestVideoPage } from "../../redux/slice/LatestVideoSlice";


const LatestVideo_index = () => {
  const dispatch=useDispatch()
  const { VideoList ,TotalLatestVideoList } = useSelector((state) => state.latestvideo);
  const [page, setpage]=useState(1)
  useEffect(()=>{
    dispatch(getAllVideosList(page-1))  
  },[dispatch, page])

  return (
    <Template>
    <div className="flex justify-start bg-[#F3F5F8] items-center self-stretch flex-grow-0 flex-shrink-0 gap-2 px-4 py-4">
    <div className="flex justify-start items-center flex-grow relative gap-[22px]">
      <p className="flex-grow-0 max-md:text-[20px] flex-shrink-0 text-2xl font-semibold text-left capitalize text-[#202223]">
        Manage Latest Video
      </p>
    </div>
    <div className="flex justify-start items-start flex-grow-0 flex-shrink-0 gap-3">
      <AddLatestVideo />
    </div>
  </div>


 <div className="grid grid-cols-4 w-full  justify-center p-4 gap-4">
        {VideoList.map((videos) => (
          <div key={videos.ota_video_id} className="rounded-lg shadow-lg bg-white w-[100%]">
          <div >
          <img src={`https://img.youtube.com/vi/${videos?.video_link?.split("=")[1]}/0.jpg`} alt="thumbnail" className="w-full rounded-t-lg" />
        </div>
            <div className="p-6 ">
              <p className="text-gray-700 h-[100px] text-base break-all ">{videos.description}</p>
              <div className="">
              <div className="flex justify-end items-center  gap-4 mt-3 ">
            
                <button className=" ">
                  <DeleteLatestVideo setpage={setpage} page={page} videodetailsId={videos?.ota_video_id} />
                </button>
              </div>
            </div>
            </div>
          </div>
        ))}
        
      </div>
      <div className="flex justify-center w-full p-4 mb-4">
      <Pagination
      className="pagination-bar"
      currentPage={page}
      totalCount={TotalLatestVideoList} // Assuming 10 items per page
      pageSize={10}
                onPageChange={(newPage) => { 
                  setpage(newPage)
                    var currentScroll = document.documentElement.scrollTop || document.body.scrollTop;
                      if (currentScroll > 0) {
                        window.scrollTo(0, currentScroll - (currentScroll / 1));
                      }
                }
                 }
    />              
    </div>
    </Template>
  );
};

export default LatestVideo_index;

