import { createSlice } from "@reduxjs/toolkit";

const NewPrideList= createSlice({
    name:"ourpride",
    initialState:{
       PrideListLoading:false,
       PrideList:[],
       page:1,
       total_pages:0,
       TotalPrideList:0
    },

    reducers:{
        getPrideListRequest:(state)=>{
            state.PrideListLoading=true;
        },
        getPrideListSuccess:(state, {payload})=>{
            state.PrideListLoading=false;
            state.PrideList=payload?.otaPridesDTOList;
            state.TotalPrideList=payload?.total_elements;
        },
        getPrideListFail:(state)=>{
            state.PrideListLoading=false;
        },
        setPridePage:(state,{payload})=>{
            state.page=payload
         },
        setTotalPagePride:(state,{payload})=>{
            state.total_pages=payload
        }
    }
})
export default  NewPrideList.reducer;
export const {getPrideListFail, getPrideListRequest, getPrideListSuccess, setPridePage, setTotalPagePride}=NewPrideList.actions
