import React, { useEffect, useState } from 'react'
import Template from '../../components/common/Template'
import ProdeList from './pridelist'
import DeleteGallery from './DeleteGallery'
import AddGallery from './AddGallery'
import { useDispatch, useSelector } from 'react-redux'
import { getGallery } from '../../services/apiService'
import Pagination from '../../components/common/pagination'
import { setGalleryPage } from '../../redux/slice/GallerySlice'
const Gallery_index = () => {
  const [selectedTemplate, setSelectedTemplate]=useState([])
  const {GalleryList,TotalGalleryList}=useSelector((state)=>state.gallery)
  const dispatch=useDispatch()
  const [page, setpage] = useState(1);
  const handleCheckBox = (e,pridee) => {
    const { checked } = e.target;
    if (checked) {
        setSelectedTemplate([...selectedTemplate,pridee])
    } else {
        setSelectedTemplate(selectedTemplate?.filter((pride_item)=>pride_item?.ota_image_id!==pridee?.ota_image_id))
    }
  }



  const handleEllipsisClick = (template) => {
    setSelectedTemplate(template);
  };

  useEffect(()=>{
    dispatch(getGallery(page-1))
  },[dispatch,page])


  return (
    <Template>
    <div>
      <div className='flex flex-1 justify-between p-4  bg-gray-200'>
      <div className='flex flex-1 justify-start text-left items-center'>
        <p className='text-2xl'>Gallery</p>
      </div>
<div className='flex flex-row gap-4 '>
      <div className=''>
      {selectedTemplate.length ? (
       <DeleteGallery page={page}   setpage={setpage} selectedTemplate={selectedTemplate} setSelectedTemplate={setSelectedTemplate} />
     ) : null}
      </div>
      <div className=''>
       <AddGallery />
      </div>
      
      </div>
      </div>  
      
      <div className="">
      <div className="relative">
        <div className="flex justify-start items-center self-stretch flex-grow-0 flex-shrink-0 gap-2 px-4 py-4">
          <div className="px-8 py-4 flex flex-col">
            <div className="flex flex-wrap gap-4">
              {GalleryList?.map((pridee) => (
                <label
                htmlFor={`checkbox-${pridee.ota_image_id}`}
                  key={pridee.ota_image_id}
                  className="flex flex-col relative justify-center items-center shadow-lg gap-3 rounded-lg p-6 group/item bg-white hover:bg-slate-100 transition-all border-b border-black/5 cursor-pointer"
                >
                <input
                type="checkbox"
                id={`checkbox-${pridee?.ota_image_id}`}
                checked={selectedTemplate?.find((prid) => Number(prid?.ota_image_id) === Number(pridee?.ota_image_id))}
                onChange={(e) => handleCheckBox(e, pridee)}
                className={`absolute top-2 right-2 group/edit ${
                  selectedTemplate?.find((v)=>v?.ota_image_id===Number(pridee?.ota_image_id))
                    ? 'visible'
                    : 'invisible'
                } group-hover/item:visible form-checkbox rounded border-gray-300 text-[#186ced] shadow-sm focus:border-indigo-300 focus:ring focus:ring-offset-0 focus:ring-indigo-200 focus:ring-opacity-50`}
              />
              <img src={pridee?.ota_image} alt="pattern_image" className="h-[200px] w-[160px]" />
       
                </label>
              ))}
              <div className="flex justify-center w-full p-4 mb-4 fixed -bottom-3  left-14 right-0">
              <Pagination
              className="pagination-bar"
              currentPage={page}
              totalCount={TotalGalleryList} // Assuming 10 items per page
              pageSize={10}
                        onPageChange={(newPage) => { 
                          setpage(newPage)
                            var currentScroll = document.documentElement.scrollTop || document.body.scrollTop;
                              if (currentScroll > 0) {
                                window.scrollTo(0, currentScroll - (currentScroll / 1));
                              }
                        }
                         }
            />              
            </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    </div>
 </Template>
  )
}

export default Gallery_index
