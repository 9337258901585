import { createSlice } from "@reduxjs/toolkit";

const NewDtaList = createSlice({
    name: "dta",
    initialState: {
        DtaListLoading: false,
        DTAList:[],
        page:1,
        total_pages: 0,
        totalDTAList: 0
    },
    reducers: {
         getDtaListRequest:(state)=>{
            state.DtaListLoading=true;
         },
         getDtaListSuccess:(state, {payload})=>{
            state.DtaListLoading=false;
            state.DTAList=payload?.districtAssociationDTOList;
            state.totalDTAList=payload?.total_elements
         },
         getDtaListFail:(state)=>{
            state.DtaListLoading=false;
            state.DTAList=[];
         },
         setDtaListPage:(state,{payload})=>{
            state.page=payload
         },
         setTotalPageDtaList:(state, {payload})=>{
            state.total_pages=payload
         }
    }
})  

export default NewDtaList.reducer;
export const {getDtaListRequest, getDtaListSuccess, getDtaListFail, setDtaListPage, setTotalPageDtaList}= NewDtaList.actions