import React, { useEffect } from "react";
import Navigation from "./Navigation";
import Header from "./Header";
import { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { clearStatusCode, setIsAuthenticated } from "../../redux/slice/authSlice";
import toast from "react-hot-toast";
import { testAuthentication } from "../../services/api";




const Template = ({ children }) => {
  const { statusCode } = useSelector((state) => state.auth);
  const navigate = useNavigate();
  const dispatch = useDispatch();
  useEffect(() => {
    if (statusCode && statusCode === 401) {
      navigate("/login")
      dispatch(setIsAuthenticated(false))
      toast.error("session is over login to continue...")
      dispatch(clearStatusCode())
      localStorage.clear();
    }
  }, [statusCode])
  useEffect(() => {
    dispatch(testAuthentication())
  },[])
  return (
    <div className="flex min-w-full min-h-screen bg-white">
      <div className="bg-[#000A3A] max-md:hidden fixed left-0 top-0  min-h-screen z-10 max-h-screen overflow-y-auto">
        <Navigation />

      </div>
      <div className="min-w-full  max-md:pl-0 pl-[240px]">
        <Header />
        {children}
      </div>
     
    </div>
  );
};

export default Template;