import React, { useState } from 'react'
import { TrashIcon } from "@heroicons/react/outline";
import { RefreshIcon } from "@heroicons/react/outline";
import { useDispatch, useSelector } from 'react-redux';
import axiosConfig from '../../axios_config/axios_instance';
import { getAllVideosList } from '../../services/apiService';
import toast from 'react-hot-toast';
const DeleteLatestVideo = ({videodetailsId,setpage, page}) => {
  const [dynamicCode, setdynamicCode] = useState(null)
  const [showModal, setShowModal] = useState(false);
  const [typedText, settypedText] = useState("");
  const [isLoading, setIsLoading] = useState(false)
  const dispatch = useDispatch();
  const { VideoList ,TotalLatestVideoList } = useSelector((state) => state.latestvideo);

  const closeModal = (e) => {
    if (isLoading) {
      return;
    }
    e?.target?.classList?.forEach((classes) => {
      if (classes === 'Modal-Container') {
        setShowModal(false)
      }
    })
    }

    const openModal = () => {
        const randomNumber = Math.floor(Math.random() * 10000);
        setdynamicCode(randomNumber.toString().padStart(4, 0))
        settypedText("")
      setShowModal(true)
  }
  
  const changeDynamicCode = () => {
    const randomNumber = Math.floor(Math.random() * 10000);
    setdynamicCode(randomNumber.toString().padStart(4, 0))
    settypedText("")
  }

  const handleDelete = async () => {
    try {
        setIsLoading(true);


        const { data } = await axiosConfig.delete(`/delete-video?ota_videos_id=${videodetailsId}`)
        if (data?.status === "success") {
            toast.success(data?.message);
           setShowModal(false);
            setIsLoading(false);
            if(VideoList?.length===1&&page>1){
              dispatch(getAllVideosList(page-2))
              setpage(page-1)
            }
            dispatch(getAllVideosList(page-1))
            } else {
              toast.error(data?.message);
              setIsLoading(false);
            }
        
    } catch (error) {
        toast.error("Something went wrong... Try again later");
            setIsLoading(false);
    }
}
  return (
    <div>
    <div
    onClick={(e) => {
      e.stopPropagation();
      openModal();
    }}>
    <button

    className=" flex items-center justify-end "
  >
    <TrashIcon className="w-5 h-5" />
  </button>
    </div>
    {showModal ? (
      <>
      <div className="flex justify-center items-center overflow-x-hidden overflow-y-auto fixed inset-0 z-50 outline-none focus:outline-none shadow-lg w-full Modal-Container bg-black/30" onClick={closeModal}>
      <div className="w-[396px] h-[350px] relative">
      <div className="w-[395.59px] h-[350.70px] left-[-0px] top-[0.58px] absolute bg-white rounded-2xl" />
          <div className="left-[38px] top-[242.47px] absolute text-center text-neutral-950 text-opacity-60 text-sm font-normal font-['Roboto']">
            <input type="number" className=" border border-black/50 w-[320px] py-1.5 px-3 rounded-lg" placeholder="Enter code to confirm deletion" value={typedText} onChange={(e)=>settypedText(e.target.value)} />
      </div>
      <div className="top-[93.29px] mx-5 py-2 absolute text-center text-neutral-950 text-opacity-90 text-[26px] font-normal font-['Roboto']">Are you sure want to delete this video?</div>
      {/* <div className="  text-center text-neutral-950 text-opacity-80 text-sm font-normal font-['Roboto']">This action cannot be undone. All values<br/>associated with this field will be lost.</div> */}
      <div className="w-[321px] h-[30.39px] left-[37px] top-[191px] absolute">
      <div className="w-[320.85px] h-[30px] left-0 top-[0.39px] absolute rounded-md " />
      <div className="w-[320.29px] h-[30.52px]  top-[3px] absolute flex justify-between items-center gap-4">
        <div className="border border-black/50 text-center text-neutral-950 text-opacity-80 text-[18px]  font-['Roboto'] font-bold w-[80%] rounded-md py-1">{dynamicCode}</div>
              <div className="flex cursor-pointer justify-center items-center border border-black/50  text-neutral-950 text-opacity-80 text-[18px]  font-['Roboto'] font-bold w-[15%] rounded-lg py-1" onClick={changeDynamicCode}>
                <RefreshIcon className="w-5 h-5"   />
        </div>
      </div>
      </div>
      <div className="w-[320.66px] h-[85.12px] left-[38px] top-[279.50px] absolute">
      <div className="w-[320.57px] h-[35.62px] py-2 left-0 top-[0.80px] absolute flex gap-5">
              <div className={`w-[160.57px] h-[35.62px] flex justify-center items-center   rounded-lg bg-white shadow-lg`} >  
        <button className="  text-black text-base font-semibold font-['Inter']" disabled={isLoading?"disable":""} onClick={()=>setShowModal(false)} >Cancel</button>
        </div>
              <div disabled={(Number(typedText)=== Number(dynamicCode)|| isLoading )?"":"disable"} onClick={handleDelete} className={`w-[160.57px] h-[35.62px] flex justify-center items-center   
                ${Number(typedText) === Number(dynamicCode) ? "bg-rose-600" : "bg-gray-500 "}  rounded-lg shadow-lg`} >  
        <button className="  text-white text-base font-semibold font-['Inter']"  >Delete</button>
        </div>
       
      </div>
      </div>
      <div className="w-3.5 h-3.5 left-[333px] top-[199px] absolute" />
      <div className="w-[54px] h-[54px] left-[171px] top-[21.50px] absolute">
            <div className="w-[54px] h-[54px] left-0 top-0 absolute bg-rose-100 rounded-full flex justify-center items-center" >
           <WarningIcon />
            </div>
      </div>
      </div>
      </div>
      </>
      ) : null}
    </div>

  )
}

export default DeleteLatestVideo

export const WarningIcon = () => {
  return (
    <svg fill="red" width="30" height="30" viewBox="0 0 0.6 0.6" xmlns="http://www.w3.org/2000/svg"><path fill-rule="evenodd" clip-rule="evenodd" d="M0.283 0.038h0.033l0.246 0.459 -0.017 0.027H0.054L0.038 0.498 0.283 0.038zM0.3 0.086 0.086 0.488h0.429L0.3 0.086zM0.324 0.45V0.413H0.277V0.45h0.046zM0.277 0.375v-0.15h0.046v0.15H0.277z"/></svg>
  )
}