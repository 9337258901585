import { configureStore } from "@reduxjs/toolkit";
import GallerySlice from "./slice/GallerySlice";
import NewsSlice from "./slice/NewsSlice";
import OurPideSlice from "./slice/OurPideSlice";
import TemplateSlice from "./slice/TemplateSlice";
import FormSlice from "./slice/FormSlice";
import MedalistProfileSlice from "./slice/MedalistProfileSlice";
import OwnerInfoSlice from "./slice/OwnerInfoSlice";
import DtaSlice from "./slice/DtaSlice";
import OtaClubSlice from "./slice/OtaClubSlice";
import competitionSlice from "./slice/competitionSlice";
import authSlice from "./slice/authSlice";
import LatestInfoSlice from "./slice/LatestInfoSlice";
import LatestVideoSlice from "./slice/LatestVideoSlice";
import ContactUsSlice from "./slice/ContactUsSlice";
import EventCalenderSlice from "./slice/EventCalenderSlice";
import CitySlice from "./slice/CitySlice";
import onlineRegistrationSlice from "./slice/onlineRegistrationSlice";
import CoachesProfileSlice from "./slice/CoachesProfileSlice";
import EventResultSlice from "./slice/EventResultSlice";
import ExecutiveSlice from "./slice/ExecutiveSlice";
import RefreeSlice from "./slice/RefreeSlice";
import OtaRegisteredSlice from "./slice/OtaRegisteredSlice";
import ColorBeltAtheleteSlice from "./slice/ColorBeltAtheleteSlice";
import ColorBeltPromotionSlice from "./slice/ColorBeltPromotionSlice";
import BlackBeltAtheleteSlice from "./slice/BlackBeltAtheleteSlice";
import TechnicalSlice from "./slice/TechnicalSlice";
import EventInChampoionshipSlice from "./slice/EventInChampoionshipSlice";
import AthleteformSlice from "./slice/AthleteformSlice";

export const store = configureStore({
  reducer: {
    gallery: GallerySlice,
    news: NewsSlice,
    ourpride: OurPideSlice,
    template: TemplateSlice,
    forms: FormSlice,
    medalistprofile: MedalistProfileSlice,
    eventcalender: EventCalenderSlice,
    ownerprofile: OwnerInfoSlice,
    dta: DtaSlice,
    latestInfo: LatestInfoSlice,
    latestvideo: LatestVideoSlice,
    citylist: CitySlice,
    otaclub: OtaClubSlice,
    competition: competitionSlice,
    auth: authSlice,
    contactus: ContactUsSlice,
    coachesprofile:CoachesProfileSlice,
    eventresult:EventResultSlice,
    executivecouncil:ExecutiveSlice,
    refree:RefreeSlice,
    otaregistered:OtaRegisteredSlice,
    colorbeltathelete:ColorBeltAtheleteSlice,
    colorbeltpromotion:ColorBeltPromotionSlice,
    blackbeltathelete:BlackBeltAtheleteSlice,
     onlineRegistartion:onlineRegistrationSlice,
     technicalcommitiee:TechnicalSlice,
     eventchampionship:EventInChampoionshipSlice,
     athleteform:AthleteformSlice
    },
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      serializableCheck: false,
    }),
});
