import React, { useEffect, useState } from "react";
import Template from "../../../components/common/Template";
import { Tab } from "@headlessui/react";
import { useDispatch, useSelector } from "react-redux";
import fileDownload from "js-file-download";
import axios from "axios";
import {
  getAllCalenderEventList,
  
} from "../../../services/apiService";
import AddEventCalender from "./AddEventCalender";
import {
  
  setSelectedEventCalender,
} from "../../../redux/slice/EventCalenderSlice";
import EditCalenderEvent from "./EditEventCalender";
import DeleteEventCalender from "./DeleteCalender";
import Pagination from "../../../components/common/pagination";

const EventCalender_index = () => {
  const dispatch = useDispatch();
  const { selectedEventCalender,TotalEventCalenderList } = useSelector((state) => state.eventcalender);

  const [selectedTemplate, setSelectedTemplate] = useState([]);

  const { EventCalenderList } = useSelector((state) => state.eventcalender);


  const [internationalPage, setinternationalPage] = useState(1)
  const [nationalPage, setnationalPage] = useState(1)
  const [statePage, setstatePage] = useState(1)


const pageSIze=10;
  useEffect(() => {
    if (selectedEventCalender === 0) {
      dispatch(getAllCalenderEventList(internationalPage-1,pageSIze,"world"));
    } else if (selectedEventCalender === 1) {  
      dispatch(getAllCalenderEventList(nationalPage-1,pageSIze,"national"));
    } else {
      dispatch(getAllCalenderEventList(statePage-1,pageSIze,"state"));
    }
  }, [dispatch, selectedEventCalender,statePage,internationalPage,nationalPage]);

  const handleDownload = (pdf) => {
    try {
      axios
        .get(pdf, {
          responseType: "blob",
        })
        .then((res) => {
          fileDownload(res.data, pdf?.split("/")?.pop());
        });
    } catch (error) {}
  };
  return (
    <Template>
      <div>
        <div className="flex justify-start items-center self-stretch flex-grow-0 bg-[#F3F5F8] flex-shrink-0 gap-2 px-8 py-3">
          <div className="flex justify-start items-center flex-grow relative gap-[22px]">
            <p className="flex-grow-0 justify-start -ml-2   flex-shrink-0 text-2xl font-semibold text-left capitalize text-[#202223]">
              Manage Event Calender List
            </p>
          </div>
          <div className="flex justify-start items-start flex-grow-0 flex-shrink-0 gap-3">
            <div className="flex justify-start relative items-center flex-grow-0 flex-shrink-0 gap-3">
              <p className="flex-grow-0 flex-shrink-0 text-sm font-medium text-center text-white">
                <AddEventCalender />
              </p>
            </div>
          </div>
        </div>
        <Tab.Group
          selectedIndex={selectedEventCalender}
          onChange={(val) => dispatch(setSelectedEventCalender(val))}
        >
          <Tab.List className="flex justify-start items-center self-stretch flex-grow-0 flex-shrink-0 gap-12 px-4 border border-black/[0.08]">
            <Tab>
              {({ selected }) => (
                <button
                  className={`flex justify-start items-center flex-grow-0 flex-shrink-0 relative gap-1 py-4 text-sm font-medium text-center focus:ring-0 outline-none ${
                    selected
                      ? "border-b-2 border-[#186ced] text-[#186ced]"
                      : "border-b-2 border-transparent text-gray-400 hover:text-gray-700"
                  } `}
                >
                  International Calender
                </button>
              )}
            </Tab>
            <Tab>
              {({ selected }) => (
                <button
                  className={`flex justify-start items-center flex-grow-0 flex-shrink-0 relative gap-1 py-4 text-sm font-medium text-center focus:ring-0 outline-none ${
                    selected
                      ? "border-b-2 border-[#186ced] text-[#186ced]"
                      : "border-b-2 border-transparent text-gray-400 hover:text-gray-700"
                  } `}
                >
                  National Calender
                </button>
              )}
            </Tab>

            <Tab>
              {({ selected }) => (
                <button
                  className={`flex justify-start items-center flex-grow-0 flex-shrink-0 relative gap-1 py-4 text-sm font-medium text-center focus:ring-0 outline-none ${
                    selected
                      ? "border-b-2 border-[#186ced] text-[#186ced]"
                      : "border-b-2 border-transparent text-gray-400 hover:text-gray-700"
                  } `}
                >
                  State Calender
                </button>
              )}
            </Tab>
            {/* Additional Tab elements */}
          </Tab.List>
          <Tab.Panels>
            <Tab.Panel className="md:p-4 w-[100%]">
              <div className="flex flex-col  items-start self-stretch flex-grow min-h-[90vh] justify-between">
                {EventCalenderList?.length ? (
                  <div class=" px-4 sm:px-8 py-4 overflow-x-auto w-full">
                    <div class="inline-block min-w-full shadow-md rounded-lg overflow-hidden">
                      <table class="min-w-full leading-normal">
                        <thead>
                          <tr>
                            <th class="px-5 py-3 border-b-2 border-gray-200 bg-gray-100 text-left text-xs font-semibold text-gray-700 uppercase tracking-wider">
                              event_year
                            </th>
                            <th class="px-5 py-3 border-b-2 border-gray-200 bg-gray-100 text-left text-xs font-semibold text-gray-700 uppercase tracking-wider">
                              description
                            </th>
                            <th class="px-5 py-3 border-b-2 border-gray-200 bg-gray-100 text-left text-xs font-semibold text-gray-700 uppercase tracking-wider">
                              pdf_file
                            </th>
                            <th class="px-5 py-3 border-b-2 border-gray-200 bg-gray-100 text-left text-xs font-semibold text-gray-700 uppercase tracking-wider"></th>
                          </tr>
                        </thead>
                        <tbody>
                          {EventCalenderList?.map((calender) => (
                            <tr key={calender?.ota_event_calender_id}>
                              <td class="px-5 py-5 border-b border-gray-200 bg-white text-sm">
                                <div class="flex items-center">
                                  <div class="flex-shrink-0 w-10 h-10">
                                    <span>{calender?.event_year}</span>
                                  </div>
                                </div>
                              </td>
                              <td class="px-5 py-5 border-b border-gray-200 bg-white text-sm">
                                <p class="text-gray-900 whitespace-nowrap">
                                  {calender?.description}
                                </p>
                              </td>
                              <td class="px-5 py-5 border-b border-gray-200 bg-white text-sm">
                                <p class="text-gray-900 whitespace-nowrap">
                                  <PDFICON
                                    className="h-4 w-4 cursor-pointer"
                                    onClick={() =>
                                      handleDownload(calender?.pdf_file)
                                    }
                                  />
                                </p>
                              </td>
                              <td class="px-5 py-5 border-b border-gray-200 bg-white text-sm">
                                <div className="flex gap-4 items-center">
                                  <EditCalenderEvent
                                  page={internationalPage}
                                    calenderdetails={calender}
                                  />
                                  <DeleteEventCalender
                                  page={internationalPage}
                                  status={"world"}
                                    calenderdetailsId={
                                      calender?.ota_event_calender_id
                                    }
                                  />
                                </div>
                              </td>
                            </tr>
                          ))}
                        </tbody>
                      </table>
                    </div>
                  </div>
                ) : (
                  <div className="flex justify-center items-center h-[70vh] w-full">
                    <span className="text-center w-full text-xl text-[#7b8794]">
                      No Event Calender found
                    </span>
                  </div>
                  
                )}
              </div>
              <div className="flex justify-center w-full p-4 mb-4">
              <Pagination
                className="pagination-bar"
                currentPage={internationalPage}
                totalCount={TotalEventCalenderList}
                pageSize={pageSIze}
                onPageChange={(page) => {
                   setinternationalPage(page)
                  var currentScroll = document.documentElement.scrollTop || document.body.scrollTop;
                        if (currentScroll > 0) {
                          window.scrollTo(0, currentScroll - (currentScroll / 1));
                        }
                }}
              />
            </div>
            </Tab.Panel>
          </Tab.Panels>
          <Tab.Panels>
            <Tab.Panel className="md:p-4 w-[100%]">
              <div className="flex flex-col  items-start self-stretch flex-grow min-h-[90vh] justify-between">
                {EventCalenderList?.length ? (
                  <div class=" px-4 sm:px-8 py-4 overflow-x-auto w-full">
                    <div class="inline-block min-w-full shadow-md rounded-lg overflow-hidden">
                      <table class="min-w-full leading-normal">
                        <thead>
                          <tr>
                            <th class="px-5 py-3 border-b-2 border-gray-200 bg-gray-100 text-left text-xs font-semibold text-gray-700 uppercase tracking-wider">
                              event_year
                            </th>
                            <th class="px-5 py-3 border-b-2 border-gray-200 bg-gray-100 text-left text-xs font-semibold text-gray-700 uppercase tracking-wider">
                              description
                            </th>
                            <th class="px-5 py-3 border-b-2 border-gray-200 bg-gray-100 text-left text-xs font-semibold text-gray-700 uppercase tracking-wider">
                              pdf_file
                            </th>
                            <th class="px-5 py-3 border-b-2 border-gray-200 bg-gray-100 text-left text-xs font-semibold text-gray-700 uppercase tracking-wider"></th>
                          </tr>
                        </thead>
                        <tbody>
                          {EventCalenderList?.map((calender) => (
                            <tr key={calender?.ota_event_calender_id}>
                              <td class="px-5 py-5 border-b border-gray-200 bg-white text-sm">
                                <div class="flex items-center">
                                  <div class="flex-shrink-0 w-10 h-10">
                                    <span>{calender?.event_year}</span>
                                  </div>
                                </div>
                              </td>
                              <td class="px-5 py-5 border-b border-gray-200 bg-white text-sm">
                                <p class="text-gray-900 whitespace-nowrap">
                                  {calender?.description}
                                </p>
                              </td>
                              <td class="px-5 py-5 border-b border-gray-200 bg-white text-sm">
                                <p class="text-gray-900 whitespace-nowrap">
                                  <PDFICON
                                    className="h-4 w-4 cursor-pointer"
                                    onClick={() =>
                                      handleDownload(calender?.pdf_file)
                                    }
                                  />
                                </p>
                              </td>
                              <td class="px-5 py-5 border-b border-gray-200 bg-white text-sm">
                                <div className="flex gap-4 items-center">
                                  <EditCalenderEvent
                                    calenderdetails={calender}
                                    page={nationalPage}
                                  />
                                  <DeleteEventCalender
                                  page={nationalPage}
                                  status={"national"}
                                    calenderdetailsId={
                                      calender?.ota_event_calender_id
                                    }
                                  />
                                </div>
                              </td>
                            </tr>
                          ))}
                        </tbody>
                      </table>
                    </div>
                  </div>
                ) : (
                  <div className="flex justify-center items-center h-[70vh] w-full">
                    <span className="text-center w-full text-xl text-[#7b8794]">
                      No Event Calender found
                    </span>
                  </div>
                )}
              </div>
              <div className="flex justify-center w-full p-4 mb-4">
              <Pagination
                className="pagination-bar"
                currentPage={nationalPage}
                totalCount={TotalEventCalenderList}
                pageSize={pageSIze}
                onPageChange={(page) => {
                   setnationalPage(page)
                  var currentScroll = document.documentElement.scrollTop || document.body.scrollTop;
                        if (currentScroll > 0) {
                          window.scrollTo(0, currentScroll - (currentScroll / 1));
                        }
                }}
              />
            </div>
            </Tab.Panel>
          </Tab.Panels>
          <Tab.Panels>
            <Tab.Panel className="md:p-4 w-[100%]">
              <div className="flex flex-col  items-start self-stretch flex-grow min-h-[90vh] justify-between">
                {EventCalenderList?.length ? (
                  <div class=" px-4 sm:px-8 py-4 overflow-x-auto w-full">
                    <div class="inline-block min-w-full shadow-md rounded-lg overflow-hidden">
                      <table class="min-w-full leading-normal">
                        <thead>
                          <tr>
                            <th class="px-5 py-3 border-b-2 border-gray-200 bg-gray-100 text-left text-xs font-semibold text-gray-700 uppercase tracking-wider">
                              event_year
                            </th>
                            <th class="px-5 py-3 border-b-2 border-gray-200 bg-gray-100 text-left text-xs font-semibold text-gray-700 uppercase tracking-wider">
                              description
                            </th>
                            <th class="px-5 py-3 border-b-2 border-gray-200 bg-gray-100 text-left text-xs font-semibold text-gray-700 uppercase tracking-wider">
                              pdf_file
                            </th>
                            <th class="px-5 py-3 border-b-2 border-gray-200 bg-gray-100 text-left text-xs font-semibold text-gray-700 uppercase tracking-wider"></th>
                          </tr>
                        </thead>
                        <tbody>
                          {EventCalenderList?.map((calender) => (
                            <tr key={calender?.ota_event_calender_id}>
                              <td class="px-5 py-5 border-b border-gray-200 bg-white text-sm">
                                <div class="flex items-center">
                                  <div class="flex-shrink-0 w-10 h-10">
                                    <span>{calender?.event_year}</span>
                                  </div>
                                </div>
                              </td>
                              <td class="px-5 py-5 border-b border-gray-200 bg-white text-sm">
                                <p class="text-gray-900 whitespace-nowrap">
                                  {calender?.description}
                                </p>
                              </td>
                              <td class="px-5 py-5 border-b border-gray-200 bg-white text-sm">
                                <p class="text-gray-900 whitespace-nowrap">
                                  <PDFICON
                                    className="h-4 w-4 cursor-pointer"
                                    onClick={() =>
                                      handleDownload(calender?.pdf_file)
                                    }
                                  />
                                </p>
                              </td>
                              <td class="px-5 py-5 border-b border-gray-200 bg-white text-sm">
                                <div className="flex gap-4 items-center">
                                  <EditCalenderEvent
                                    calenderdetails={calender}
                                    page={statePage}
                                  />
                                  <DeleteEventCalender
                                  page={statePage}
                                  status={"state"}
                                    calenderdetailsId={
                                      calender?.ota_event_calender_id
                                    }
                                  />
                                </div>
                              </td>
                            </tr>
                          ))}
                        </tbody>
                      </table>
                    </div>
                  </div>
                ) : (
                  <div className="flex justify-center items-center h-[70vh] w-full">
                    <span className="text-center w-full text-xl text-[#7b8794]">
                      No Event Calender found
                    </span>
                  </div>
                )}
              </div>
              <div className="flex justify-center w-full p-4 mb-4">
              <Pagination
                className="pagination-bar"
                currentPage={statePage}
                totalCount={TotalEventCalenderList}
                pageSize={pageSIze}
                onPageChange={(page) => {
                   setstatePage(page)
                  var currentScroll = document.documentElement.scrollTop || document.body.scrollTop;
                        if (currentScroll > 0) {
                          window.scrollTo(0, currentScroll - (currentScroll / 1));
                        }
                }}
              />
            </div>
            </Tab.Panel>
          </Tab.Panels>
        </Tab.Group>
      </div>
    </Template>
  );
};

export default EventCalender_index;

export const PDFICON = ({ onClick }) => (
  <svg
    onClick={onClick}
    width="20px"
    height="20px"
    viewBox="0 0 0.6 0.6"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    style={{ cursor: "pointer" }}
  >
    <path
      d="M0.3 0.05a0.025 0.025 0 0 1 0.025 0.025v0.265l0.057 -0.057a0.025 0.025 0 0 1 0.035 0.035l-0.1 0.1a0.025 0.025 0 0 1 -0.035 0l-0.1 -0.1a0.025 0.025 0 1 1 0.035 -0.035L0.275 0.34V0.075a0.025 0.025 0 0 1 0.025 -0.025zM0.125 0.425a0.025 0.025 0 0 1 0.025 0.025v0.05h0.3v-0.05a0.025 0.025 0 1 1 0.05 0v0.05a0.05 0.05 0 0 1 -0.05 0.05H0.15a0.05 0.05 0 0 1 -0.05 -0.05v-0.05a0.025 0.025 0 0 1 0.025 -0.025z"
      fill="#0D0D0D"
    />
  </svg>
);
