export const downloadImage =async (image) => {
    try {
        // Replace 'your-file-url' with the actual URL of the file you want to download
        const response = await fetch(image);
        const blob = await response.blob();
        
        // Create a temporary URL for the blob
        const url = window.URL.createObjectURL(blob);
  
        // Create a hidden link and trigger a click to download the file
        const link = document.createElement('a');
        link.href = url;
        link.setAttribute('download', image?.split("/").pop()); // Specify the desired file name
        document.body.appendChild(link);
        link.click();
  
        // Clean up by revoking the object URL
        window.URL.revokeObjectURL(url);
      } catch (error) {
        console.error('Error downloading file:', error);
      }
  
}