import axiosConfig from "../axios_config/axios_instance"
import { testApiFail } from "../redux/slice/authSlice"
import { getCompetitionListFail, getCompetitionListRequest, getCompetitionListSuccess } from "../redux/slice/competitionSlice"
import { getChampionShipHeadingFail, getChampionShipHeadingRequest, getChampionShipHeadingSuccess, getChampionshipParticipateListFail, getChampionshipParticipateListRequest, getChampionshipParticipateListSuccess, getCityListFail, getCityListRequest, getCityListSuccess, getClubListFail, getClubListRequest, getClubListSuccess, getColorBeltHeadingDetailsFail, getColorBeltHeadingDetailsRequest, getColorBeltHeadingDetailsSuccess, getColorBeltHeadingsFail, getColorBeltHeadingsRequest, getColorBeltHeadingsSuccess, getEventListFail, getEventListRequest, getEventListSuccess, getcolorBeltParticipantsFail, getcolorBeltParticipantsRequest, getcolorBeltParticipantsSuccess, setParticipantDetails } from "../redux/slice/onlineRegistrationSlice"

export const getCompetitionList = () => async (dispatch) => {
    try {
        dispatch(getCompetitionListRequest())
        const { data } = await axiosConfig.get(`/get-competition`)
        dispatch(getCompetitionListSuccess(data?.competitionDTOList))
    } catch (error) {
        dispatch(getCompetitionListFail())
    }
}

export const getChampionshipHeading = () =>async(dispatch)=> {
    try {
        dispatch(getChampionShipHeadingRequest())
        const {data}=await axiosConfig.get(`/championship-headings?page=0&page_size=100`)
        dispatch(getChampionShipHeadingSuccess(data?.data?.championship_heading_List))
    } catch (error) {
        dispatch(getChampionShipHeadingFail())
    }
}
export const getColorBeltHeadings = () =>async(dispatch)=> {
    try {
        dispatch(getColorBeltHeadingsRequest())
        const {data}=await axiosConfig.get(`/color-belt-tests`)
        dispatch(getColorBeltHeadingsSuccess(data?.data?.color_belt_test_data_list))
    } catch (error) {
        dispatch(getColorBeltHeadingsFail())
    }
}
export const getColorBeltHeadingDetails = (color_belt_test_id) =>async(dispatch)=> {
    try {
        dispatch(getColorBeltHeadingDetailsRequest())
        const {data}=await axiosConfig.get(`/color-belt-test-details?color_belt_test_id=${color_belt_test_id}`)
        dispatch(getColorBeltHeadingDetailsSuccess(data?.data))
    } catch (error) {
        dispatch(getColorBeltHeadingDetailsFail())
    }
}

export const getEventList = () => async (dispatch) => {
    try {
        dispatch(getEventListRequest())
        const { data } = await axiosConfig.get(`/events?page=0&page_size=100`);
        dispatch(getEventListSuccess(data?.data?.event_List))
    } catch (error) {
        dispatch(getEventListFail())
    }
}

export const getCitiliesList = () => async (dispatch) => {
    try {
        dispatch(getCityListRequest())
        const { data } = await axiosConfig.get(`/city-list?page=0&page_size=100`);
        dispatch(getCityListSuccess(data?.city_list))
    } catch (error) {
        dispatch(getCityListFail())
    }
}

export const getClubNames = (city_id) => async (dispatch) => {
    try {
        dispatch(getClubListRequest())
        const {data}=await axiosConfig.get(`/ota-club-list?city_id=${city_id}&page=0&page_size=100`)
        dispatch(getClubListSuccess(data?.ota_club_list))
    } catch (error) {
        dispatch(getClubListFail())
        
    }
}

export const getChampionshipRegistrationsList = (championship_heading_id,page,page_size=10) =>async(dispatch)=> {
    try {
        dispatch(getChampionshipParticipateListRequest())
        const {data}=await axiosConfig.get(`/championship_registrations?championship_heading_id=${championship_heading_id}&page=${page-1}&page_size=${page_size}`)
        dispatch(getChampionshipParticipateListSuccess(data?.data))
    } catch (error) {
        dispatch(getChampionshipParticipateListFail())
    }
}

export const getParticipantDetails = (champion_id) =>async(dispatch)=> {
    try {
        const { data } = await axiosConfig.get(`/championship_registration_details?championship_id=${champion_id}`)
        dispatch(setParticipantDetails(data?.data?.championship_player_data))
    } catch (error) {
        
    }
}

export const getColorBeltParticipants = (color_belt_test_id,page,page_size=10) =>async (dispatch) => {
    try {
        dispatch(getcolorBeltParticipantsRequest())
        const { data } = await axiosConfig.get(`/registration-tests?color_belt_test_id=${color_belt_test_id}&page=${page-1}&page_size=${page_size}`);
        dispatch(getcolorBeltParticipantsSuccess(data?.data))
    } catch (error) {
        dispatch(getcolorBeltParticipantsFail())
    }
}

export const testAuthentication = () => async (dispatch) => {
    try {
        const { data } = await axiosConfig.get(`/test?heading_id=6`);
    } catch (error) {
        dispatch(testApiFail(error?.response?.status))
    }
}