import React, { useEffect, useState } from 'react'
import Template from '../../components/common/Template'

import EditContactUs from './EditContactUs'
import { useDispatch, useSelector } from 'react-redux'
import { getAllContactUsList } from '../../services/apiService'
import AddContactUs from './AddContactUs'
import DeleteContactUs from './DeleteContactUs'

const ContactUs_index = () => {
  const [selectedTemplate, setSelectedTemplate]=useState([])

  const {ContactUsList}=useSelector((state)=>state.contactus)

  const dispatch=useDispatch()
  
  useEffect(()=>{
    dispatch(getAllContactUsList())
  },[dispatch])





  const handleCheckBox = (e,pridee) => {
    const { checked } = e.target;
    if (checked) {
        setSelectedTemplate([...selectedTemplate,pridee])
    } else {
        setSelectedTemplate(selectedTemplate?.filter((pride_item)=>pride_item?.otaPridesId!==pridee?.otaPridesId))
    }
  }







  
  return (
    <Template>
     <div>
     <div className='flex flex-1 justify-between p-4  bg-gray-200'>
       <div className='flex flex-1 justify-start text-left items-center'>
         <p className='text-2xl'>Conatct Us List</p>
       </div>
<div className='flex flex-row gap-4 '>
       <div className=''>
       {selectedTemplate.length === 1 ? (
        <EditContactUs
          prideId={selectedTemplate[0]?.otaPridesId}
          setSelectedTemplate={setSelectedTemplate}
          details={selectedTemplate[0]}
        />
      ) : null}
       </div>

       <div className=''>
       {selectedTemplate.length ? (
        <DeleteContactUs selectedTemplate={selectedTemplate} setSelectedTemplate={setSelectedTemplate} />
      ) : null}
       </div>
       <div className=''>
       <AddContactUs />
       </div>
       
       </div>
       </div>   
       
       <div className="">
       <div className="relative">
         <div className="flex justify-center items-center self-stretch flex-grow-0 flex-shrink-0 gap-2 px-4 py-4">
           <div className="px-8 py-4 flex flex-col">
             <div className="flex flex-wrap gap-4">
               {ContactUsList.map((pridee) => (
                 <label
                 htmlFor={`checkbox-${pridee.contact_us_id}`}

                   key={pridee.contact_us_id}
                   className="flex flex-col relative justify-center items-center shadow-lg gap-3 rounded-lg p-6 group/item bg-white hover:bg-slate-100 transition-all border-b border-black/5 cursor-pointer"
                 >
                 <input
                 type="checkbox"
                 id={`checkbox-${pridee?.contact_us_id}`}
                 checked={selectedTemplate?.find((prid) => Number(prid?.contact_us_id) === Number(pridee?.contact_us_id))}
                 onChange={(e) => handleCheckBox(e, pridee)}
                 className={`absolute top-2 right-2 group/edit ${
                   selectedTemplate?.find((v)=>v?.contact_us_id===Number(pridee?.contact_us_id))
                     ? 'visible'
                     : 'invisible'
                 } group-hover/item:visible form-checkbox rounded border-gray-300 text-[#186ced] shadow-sm focus:border-indigo-300 focus:ring focus:ring-offset-0 focus:ring-indigo-200 focus:ring-opacity-50`}
               />
               <img src={pridee?.image} alt="pattern_image" className="h-[180px] w-[180px]" />
               <span className="text-base font-semibold">{pridee?.name}</span>
               <span className="text-base font-semibold">{pridee?.post}</span>
                 </label>
               ))}
             </div>
           </div>
         </div>
       </div>
     </div>
     </div>
  </Template>
  )
}

export default ContactUs_index
