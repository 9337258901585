import React, { useEffect } from 'react'
import {HashRouter as Router,Route,Routes, useNavigate} from "react-router-dom"
import * as routesKey from "../constants/routes"
import * as pages from "../pages"
import { useDispatch, useSelector } from 'react-redux'
import { setIsAuthenticated } from '../redux/slice/authSlice'
import ProtectedRoute from '../components/common/ProtectedRoute'
import axiosConfig from '../axios_config/axios_instance'

const AppRouter = () => {
  const dispatch = useDispatch();
  const { isAuthenticated } = useSelector((state) => state.auth);
  useEffect(() => {
    const privacy_token = localStorage.getItem("privacy_token");
    if (privacy_token) {
      dispatch(setIsAuthenticated(true));
    } else {
      dispatch(setIsAuthenticated(false));
    }
  },[])
  axiosConfig.interceptors.request.use(
    (config) => {
      if (localStorage.getItem("privacy_token")) { 
        config.headers.Authorization = `Bearer ${localStorage.getItem("privacy_token")}`;
      }
      return config;
    },
    (error) => {
      console.error("Authentication request failed:", error);
      return Promise.reject(error);
    }
  );

  return (
    <Router>
       
      <Routes>
        <Route element={<ProtectedRoute isAuthenticated={!isAuthenticated} redirect={"/news"} />} >
          <Route path={routesKey.LOGIN} element={<pages.Login />}  />
      </Route>
          <Route element={<ProtectedRoute isAuthenticated={isAuthenticated} redirect={"/login"} />} >
          <Route path={routesKey.DASHBOARD} element={<pages.Dashboard />}  />
          <Route path={routesKey.Home} element={<pages.HomePage />}  />
          <Route path={routesKey.NEWS} element={<pages.News_index />}  />
          <Route path={routesKey.GALLERY} element={<pages.Gallery_index />}  />

          <Route path={routesKey.OURPRIDE} element={<pages.Our_Pride />}  />
          <Route path={routesKey.LATESTINFORMATION} element={<pages.LatestInformation_index />}  />
          <Route path={routesKey.LATESTVIDEO} element={<pages.LatestVideo_index />}  />
          <Route path={routesKey.OWNERPROFILE} element={<pages.OwnerProdile_index />}  />
          <Route path={routesKey.TEMPLATES} element={<pages.Template_index />}  />
          <Route path={routesKey.CONTACTUS} element={<pages.ContactUs_index />}  />
          <Route path={routesKey.FORMS} element={<pages.Forms_index />}  />


          <Route path={routesKey.DTA} element={<pages.DTA_Index />}  />
          <Route path={routesKey.ADDDTA} element={<pages.AddDta />}  />
          <Route path={routesKey.EDITDTA} element={<pages.EditDta />}  />
          

          <Route path={routesKey.OTACLUB} element={<pages.Ota_Club_Index />}  />
          <Route path={routesKey.ADDOTACLUB} element={<pages.AddOtaClub />}  />
          <Route path={routesKey.EDITOTACLUB} element={<pages.EditOtaClub />}  />
           
          <Route path={routesKey.CITIES} element={<pages.AllCities_index />}  />
          <Route path={routesKey.ATHELETEFORM} element={<pages.Athleteform_index />}  />
          <Route path={routesKey.ATHLETEFORMDETAILS} element={<pages.Athletedetailsform />}  />

          {/* About Page */}
          <Route path={routesKey.COCHESPROFILE} element={<pages.CoachesProfile_index />}  />
          <Route path={routesKey.COLORBELTPROMOTION} element={<pages.ColorBeltPromotionTestList_index />}  />
          <Route path={routesKey.EXECUTIVECOUNCILMEMBERS} element={<pages.ExecutiveCouncilMembers_index />}  />
          <Route path={routesKey.EVENTRESULT} element={<pages.EventResult_index />}  />
          <Route path={routesKey.EVENTCALENDER} element={<pages.EventCalender_index />}  />
          <Route path={routesKey.MEDALISTPROFILE} element={<pages.MedalistsProfile_index />}  />
          <Route path={routesKey.REFREEPROFILE} element={<pages.RefreeProfile_index />}  />

          <Route path={routesKey.BLACKBELTATHELTE} element={<pages.BlackBelt_Athelete />}  />
          <Route path={routesKey.ADDBLACKBELTATHELETE} element={<pages.AddBlackBeltAthelete />}  />
          <Route path={routesKey.EDITBLACKBELTATHELETE} element={<pages.EditBlackBeltAthelete />}  />
          <Route path={routesKey.COLORBELTATHELET} element={<pages.ColorBeltAthelete_index />}  />
          <Route path={routesKey.ADDCOLORBELTATHELET} element={<pages.AddColorBelt/>}  />
          <Route path={routesKey.EDITCOLORBELTATHELETE} element={<pages.EditColorBeltAthelete/>}  />
            
          <Route path={routesKey.TECHNICALCOMMITIE} element={<pages.TechnicalProfile_index />}  />
       
          <Route path={routesKey.COMPETATION} element={<pages.CompetationList />}  />
          <Route path={routesKey.ONLINEREGISTRATIONLIST} element={<pages.OnlineRegistrationList />}  />
          <Route path={routesKey.CHAMPIONSHIPPARTICIPATIONDETAILS} element={<pages.ChampionParticipationDetails />}  />
          <Route path={routesKey.COLORBELTPROMOTIONPARTICIPATIONDETAILS} element={<pages.ColorBeltParticipationDetails />}  />
          <Route path={routesKey.EDITPARTICIPATEDETAILS} element={<pages.EditParticipateDetails />}  />
           </Route>
        </Routes>
  </Router>
  )
}

export default AppRouter