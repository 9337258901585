import { Dialog, Transition } from '@headlessui/react';
import React, { Fragment, useState } from 'react'
import { useDispatch } from 'react-redux';
import { setIsAuthenticated } from '../../redux/slice/authSlice';
import toast from 'react-hot-toast';

const LogoutModal = () => {
    const [isOpen, setisOpen] = useState(false);
    const dispatch = useDispatch();
    const openModal = () => {
        setisOpen(true);
    }

    const closeModal = () => {
        setisOpen(false);
    }

    const handleYes = () => {
        toast.success("Logout successfully")
        localStorage.removeItem("privacy_token");
        dispatch(setIsAuthenticated(false));
    }
  return (
      <Fragment>
          <button onClick={openModal} className='w-full'>
     <li className=""><button className="w-full rounded-b bg-gray-200 hover:bg-gray-400 py-2 px-4 block whitespace-no-wrap" >logout</button></li>
          </button>
      <Transition
        appear
        show={isOpen}
        as={Fragment}
        className="fixed inset-0 max-w-full mx-auto z-10 bg-black/10"
      >
        <Dialog as="div" className="relative z-10 " onClose={closeModal}>
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
        <div className="fixed inset-0 bg-black bg-opacity-25" />
          </Transition.Child>
          <div className="fixed inset-0">
            <div className="flex min-h-full items-center justify-center text-center">
              <Transition.Child
                as={Fragment}
                enter="transition ease-in-out duration-300 transform"
                enterFrom="translate-y-full"
                enterTo="translate-y-0 opacity-100 scale-100"
                leave="transition ease-in duration-200 transform"
                leaveFrom="translate-y-0 opacity-100 scale-100"
                leaveTo="translate-y-full opacity-0 scale-95"
              >
                <Dialog.Panel className="w-screen overflow-y-auto transform rounded-2xl bg-white text-left align-middle shadow-xl transition-all md:max-w-md mx-4">
                  <div className="flex flex-col w-full gap-2">
                    <div className="flex flex-col p-6 md:pl-8 gap-1">
                      <h2 className="hidden md:flex text-xl md:text-2xl font-medium md:font-semibold">
                        Logout
                      </h2>
                                          <p className="text-center md:text-left text-xl md:text-base font-medium md:font-normal">
                                              Are you sure want to logout?
                      </p>
                    </div>
                    <div className="flex justify-around md:justify-end items-center px-6 pb-6 gap-4 md:gap-2">
                      <button
                                              className="btn-primary self-stretch flex-grow md:flex-grow-0 md:w-24 md:rounded-lg md:py-2 bg-[#000A3A] text-white "
                                              style={{backgroundColor:"#000A3A"}}
                        onClick={closeModal}
                      >
                        No
                      </button>
                      <button
                                              className="btn-primary self-stretch flex-grow md:flex-grow-0 md:w-24 md:rounded-lg md:py-2 bg-[#000A3A] text-white"
                                              style={{backgroundColor:"#000A3A"}}
                        onClick={handleYes}
                      >
                        Yes
                      </button>
                    </div>
                  </div>
                </Dialog.Panel>
              </Transition.Child>
            </div>
          </div>
        </Dialog>
      </Transition>
    </Fragment>
  )
}

export default LogoutModal