import { createSlice } from "@reduxjs/toolkit";

const NewOtaClubList = createSlice({
    name: "otaclub",
    initialState: {
        OtaClubListLoading: false,
        OtaClubList:[],
        page:1,
       total_pages: 0,
       totalOtaClubList: 0
    },
    reducers: {
         getOtaClubListRequest:(state)=>{
            state.OtaClubListLoading=true;
         },
         getOtaClubListSuccess:(state, {payload})=>{
            state.OtaClubListLoading=false;
            state.OtaClubList=payload?.ota_club_list
            state.totalOtaClubList=payload?.total_elements
         },
         getOtaClubListFail:(state)=>{
            state.OtaClubListLoading=false;
            state.OtaClubList=[];
         },
         setOtaClubListPage:(state,{payload})=>{
            state.page=payload
         },
         setOtaClubListTotalPage:(state, {payload})=>{
            state.total_pages=payload
         }
    }
})  

export default NewOtaClubList.reducer;
export const {getOtaClubListFail, getOtaClubListRequest, getOtaClubListSuccess, setOtaClubListPage, setOtaClubListTotalPage}= NewOtaClubList.actions