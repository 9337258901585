import React, { useEffect, useState } from 'react'
import Template from '../../components/common/Template'
import AddTemplate from './AddTemplate'
import DeleteTemplate from './DeleteTemplate'
import { useDispatch, useSelector } from 'react-redux'
import { getAllTemplateList } from '../../services/apiService'
import { setTemplateListPage } from '../../redux/slice/TemplateSlice'
import Pagination from '../../components/common/pagination'

const Template_index = () => {
  const [selectedTemplate, setSelectedTemplate]=useState([])
  const{TemplateList,page, total_pages}=useSelector((state)=>state?.template)
  const dispatch=useDispatch()
  const handleCheckBox = (e,pridee) => {
    const { checked } = e.target;
    if (checked) {
        setSelectedTemplate([...selectedTemplate,pridee])
    } else {
        setSelectedTemplate(selectedTemplate?.filter((pride_item)=>pride_item?.template_image_id!==pridee?.template_image_id))
    }
}





  const handleEllipsisClick = (template) => {
    setSelectedTemplate(template);
  };

  useEffect(()=>{
    dispatch(getAllTemplateList())
  },[dispatch])

  const handlePage = (newPage) => {
    dispatch(setTemplateListPage(newPage));
    dispatch(getAllTemplateList(newPage - 1, 10)); 
  };
  return (
    <Template>
    <div>
      <div className='flex flex-1 justify-between p-4  bg-gray-200'>
      <div className='flex flex-1 justify-start text-left items-center'>
        <p className='text-2xl'>Template</p>
      </div>
<div className='flex flex-row gap-4 '>


      <div className=''>
      {selectedTemplate.length ? (
       <DeleteTemplate selectedTemplate={selectedTemplate} setSelectedTemplate={setSelectedTemplate} />
     ) : null}
      </div>
  {TemplateList?.length<4 &&    <div className=''>
       <AddTemplate />
      </div>}
      
      </div>
      </div>  
      
      <div className="">
      <div className="relative">
        <div className="flex justify-start items-center self-stretch flex-grow-0 flex-shrink-0 gap-2 px-4 py-4">
          <div className="px-8 py-4 flex flex-col">
            <div className="flex flex-wrap gap-4">
              {TemplateList.map((pridee) => (
                <label
                  key={pridee.template_image_id}
                  htmlFor={`checkbox-${pridee.template_image_id}`}
                  className="flex flex-col relative justify-center items-center shadow-lg gap-3 rounded-lg p-6 group/item bg-white hover:bg-slate-100 transition-all border-b border-black/5 cursor-pointer"
                >
                <input
                type="checkbox"
                id={`checkbox-${pridee.template_image_id}`}
                checked={selectedTemplate?.find((prid) => Number(prid?.template_image_id) === Number(pridee?.template_image_id))}
                onChange={(e) => handleCheckBox(e, pridee)}
                className={`absolute top-2 right-2 group/edit ${
                  selectedTemplate?.find((pridis) => Number(pridis?.template_image_id) === Number(pridee?.template_image_id))
                    ? 'visible'
                    : 'invisible'
                } group-hover/item:visible form-checkbox rounded border-gray-300 text-[#186ced] shadow-sm focus:border-indigo-300 focus:ring focus:ring-offset-0 focus:ring-indigo-200 focus:ring-opacity-50`}
              />
              <img src={pridee?.template_image} alt="templateImage" className="h-[200px] w-[160px]" />
                </label>
              ))}
            </div>
          </div>
        </div>
      </div>
    </div>
{/*    <div className="flex justify-center w-full p-4 mb-4">
    <Pagination
    className="pagination-bar"
    currentPage={page}
    totalCount={total_pages * 10} // Assuming 10 items per page
    pageSize={10}
    onPageChange={(newPage) => handlePage(newPage)}
  />
              </div> */}
    </div>
 </Template>
  )
}

export default Template_index
