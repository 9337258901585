import { createSlice } from "@reduxjs/toolkit";

const NewGalleryList = createSlice({
    name: "gallery",
    initialState: {
        galleryLoading: false,
        GalleryList:[],
        page:1,
        total_pages:0,
        TotalGalleryList:0,
      
    },
    reducers: {
         getGalleryListRequest:(state)=>{
            state.galleryLoading=true;
         },
         getGalleryListSuccess:(state, {payload})=>{
            state.galleryLoading=false;
            state.GalleryList=payload?.ota_image_list;
            state.TotalGalleryList=payload?.total_elements
         },
         getGalleryListFail:(state)=>{
            state.galleryLoading=false;
            state.GalleryList=[];
         },
         setGalleryPage:(state,{payload})=>{
            state.page=payload
         },
         setGalleryTotalPages:(state,{payload})=>{
            state.total_pages=payload
         }
    }
})  

export default NewGalleryList.reducer;
export const {getGalleryListFail, getGalleryListRequest, getGalleryListSuccess, setGalleryPage, setGalleryTotalPages}= NewGalleryList.actions