import { createSlice } from "@reduxjs/toolkit";

const NewColorBeltAthelete = createSlice({
    name: "colorbeltathelete",
    initialState: {
        ColorBeltAtheleteLoading: false,
        ColorBeltAtheleteList:[],
        TotalColorBeltList:0,
        ColorAtheleteDetailsLoading:false,
        ColorAtheleteDetails:[]

      
    },
    reducers: {
         getColorBeltRequest:(state)=>{
            state.ColorBeltAtheleteLoading=true;
         },
         getColorBeltAtheleteListSuccess:(state, {payload})=>{
            state.ColorBeltAtheleteLoading=false;
            state.ColorBeltAtheleteList=payload?.color_belt_List;
            state.TotalColorBeltList=payload?.total_elements
         },
         getColorBeltAtheleteListFail:(state)=>{
            state.ColorBeltAtheleteLoading=false;
            state.ColorBeltAtheleteList=[];
         },
         getColorAtheleteDetailsSuccess: (state,{payload}) => {
            state.ColorAtheleteDetailsLoading = false;
            state.ColorAtheleteDetails = payload;
        },
         getColorAtheleteDetailsRequest: (state,{payload}) => {
            state.ColorAtheleteDetailsLoading = true;
           
        },
         getColorAtheleteDetailsFail: (state,{payload}) => {
            state.ColorAtheleteDetailsLoading = false;
       
        },
    }
})  

export default NewColorBeltAthelete.reducer;
export const {getColorBeltAtheleteListFail, getColorBeltAtheleteListSuccess, getColorBeltRequest, getColorAtheleteDetailsFail, getColorAtheleteDetailsRequest, getColorAtheleteDetailsSuccess}= NewColorBeltAthelete.actions