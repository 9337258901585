import React, { useEffect, useState } from 'react'
import Template from '../../components/common/Template'
import { Link, useNavigate, useParams } from 'react-router-dom'
import {ReactComponent as BackButton} from "../../assets/icons/back-button.svg"
import { PDFICON } from '../Competation'
import { useDispatch, useSelector } from 'react-redux'
import { PencilIcon } from '@heroicons/react/outline'
import { getChampionshipRegistrationsList } from '../../services/api'
import { downloadImage } from '../../components/helper/downloadImage'
import Pagination from '../../components/common/pagination'
import axiosConfig from '../../axios_config/axios_instance'
import toast from 'react-hot-toast'
const ChampionParticiparionDetails = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [page, setpage] = useState(1)
  const pageSize = 10;
  const { championship_heading_id } = useParams();
  const { championshipHeadingDetails, championshipParticipationList, totalparticipantsList } = useSelector((state) => state.onlineRegistartion)
  const [heading, setheading] = useState("")

  useEffect(() => {
    if (championship_heading_id) { 
      dispatch(getChampionshipRegistrationsList(championship_heading_id,page,pageSize))
    }
  },[championship_heading_id,page])
  
  useEffect(() => {
    if (championship_heading_id) {
      setChampionshipHeading(championship_heading_id);
    }
  }, [championship_heading_id])
  
  const setChampionshipHeading=async(id) => {
    try {
      const { data } = await axiosConfig.get(`/championship-heading-details?championship_heading_id=${id}`)
      if (data?.data?.championship_heading_data) { 
        setheading(data?.data?.championship_heading_data?.heading)
      }
    } catch (error) {
      
    }
  }
  const exportAllData = async (id) => {
    if (!championshipParticipationList?.length) {
      toast.error("No Data to export")
      return;
    }
    try {
      downloadFiles()
    } catch (error) {
      
    }
  }

  const downloadZipFile = (data) => {
    const blob = new Blob([data], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' });
    const link = document.createElement('a');
    link.href = window.URL.createObjectURL(blob);
    link.download = `${heading}.xlsx`;
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  };
  const downloadFiles = () => {

    axiosConfig.get(`/excel-export-champion?heading_id=${championship_heading_id}`, { responseType: 'arraybuffer' })
      .then(response => {
        downloadZipFile(response.data);
      })
      .catch(error => {
        console.error('Error downloading files:', error);
      });
  };


  return (
      <Template>
           <div className="flex justify-start items-start self-stretch flex-grow-0 flex-shrink-0 gap-0 px-8 pr-0 pt-12 pb-20 bg-white relative flex-col">
        <div className="flex flex-col justify-start items-start flex-grow-0 flex-shrink-0 w-[768px] gap-12">
        <div className="flex flex-col justify-start  self-stretch flex-grow-0 flex-shrink-0 relative gap-2 items-start">
              <button onClick={()=>navigate(-1)} className="flex gap-2 items-center cursor-pointer">
              <BackButton />
              <p>
                ChampionShip Headings
                </p>
            </button>
            <div className='flex justify-between items-center w-full'>
              <p className="self-stretch flex-grow-0 flex-shrink-0 w-[768px] text-xl font-semibold text-left text-[#202223]">
          {heading?heading:"Heading Name"}
              </p>
          {championship_heading_id&&      <button
                className="flex justify-start items-center flex-grow-0 flex-shrink-0 relative overflow-hidden gap-1 pl-4 pr-5 max-md:py-[7px] py-[10px] rounded-full bg-[#186ced]"
                style={{ boxShadow: "0px 1px 0px 0 rgba(0,0,0,0.08)" }}
                onClick={exportAllData}
              >
      <p className="flex-grow-0 flex-shrink-0 text-sm font-medium text-center text-white">
        Export Data
      </p>
    </button>}
            </div>
          </div>
          <p className="self-stretch flex-grow-0 flex-shrink-0 w-[768px] text-xl font-semibold text-left text-[#202223]">
                List of Students Participate
              </p>
              </div>
              {
                championshipParticipationList?.length ?
      <div className=" px-4 sm:px-8 py-4 overflow-x-auto w-full">
      <div
        className="inline-block min-w-full shadow-md rounded-lg overflow-hidden"
      >
        <table className="min-w-full leading-normal">
        <thead>
            <tr className='border-b-2 border-gray-200'>
              <th
                className="px-5 py-3  bg-gray-100 text-left text-xs font-semibold text-gray-700 uppercase tracking-wider"
              >
                Name
              </th>
              <th
                className="px-5 py-3  bg-gray-100 text-left text-xs font-semibold text-gray-700 uppercase tracking-wider"
              >
                OTA ID
              </th>
              <th
                className="px-5 py-3  bg-gray-100 text-left text-xs font-semibold text-gray-700 uppercase tracking-wider"
              >
               GENDER
              </th>
              <th
                className="px-5 py-3  bg-gray-100 text-left text-xs font-semibold text-gray-700 uppercase tracking-wider"
              >
               DOB
                                                      </th>
              <th
                className="px-5 py-3  bg-gray-100 text-left text-xs font-semibold text-gray-700 uppercase tracking-wider"
              >
               WEIGHT
                                                      </th>
              <th
                className="px-5 py-3  bg-gray-100 text-left text-xs font-semibold text-gray-700 uppercase tracking-wider"
              >
               DISTRICT 
                                                      </th>
              <th
                className="px-5 py-3  bg-gray-100 text-left text-xs font-semibold text-gray-700 uppercase tracking-wider"
              >
               CLUB NAME
                                                      </th>
              <th
                className="px-5 py-3  bg-gray-100 text-left text-xs font-semibold text-gray-700 uppercase tracking-wider"
              >
               Proof
                                                      </th>
              <th
                className="px-5 py-3  bg-gray-100 text-left text-xs font-semibold text-gray-700 uppercase tracking-wider"
              >
              </th>
            </tr>
          </thead>
          <tbody>
                    {
                      championshipParticipationList?.map((register,i) => (
                        <tr key={i}  className='cursor-pointer'>
                          <td className="px-5 py-5 border-b border-gray-200 bg-white text-sm">
                          <div class="flex items-center">
                  <div class="flex-shrink-0 w-10 h-10">
                    <img
                      class="w-full h-full rounded-full"
                      src={register?.athlete_photo}
                      alt=""
                    />
                  </div>
                  <div class="ml-3">
                    <p class="text-gray-900 whitespace-no-wrap">
                      {register?.name}
                    </p>
                  </div>
                </div>

              </td>
            
              <td className="px-5 py-5 border-b border-gray-200 bg-white text-sm">
                    <p className="text-gray-900 whitespace-nowrap">
                      {register.ota_Id}
                    </p>
              </td>
              <td className="px-5 py-5 border-b border-gray-200 bg-white text-sm">
                    <p className="text-gray-900 whitespace-nowrap">
                      {register.gender}
                    </p>
              </td>
              <td className="px-5 py-5 border-b border-gray-200 bg-white text-sm">
                    <p className="text-gray-900 whitespace-nowrap">
                      {register.dob}
                    </p>
              </td>
              <td className="px-5 py-5 border-b border-gray-200 bg-white text-sm">
                    <p className="text-gray-900 whitespace-nowrap">
                      {register.weight}
                    </p>
              </td>
              <td className="px-5 py-5 border-b border-gray-200 bg-white text-sm">
                    <p className="text-gray-900 whitespace-nowrap">
                      {register.cityName}
                    </p>
              </td>
              <td className="px-5 py-5 border-b border-gray-200 bg-white text-sm">
                    <p className="text-gray-900 whitespace-nowrap">
                      {register.club_name}
                    </p>
              </td>
              <td className="px-5 py-5 border-b border-gray-200 bg-white text-sm">
                 {register?.aadhaar_card?  <PDFICON onClick={()=>downloadImage(register?.aadhaar_card)} />:"N/A"}
              </td>
                          <td className="px-5 py-5 border-b border-gray-200 bg-white text-sm">
                            <div className='flex gap-2 items-center'>
                          {/* <EditChampionShipHeading /> */}
                              <Link to={`/participate_details_edit/${register.championship_id}`}>
                                <PencilIcon className='w-4 h-4' />
                          </Link>
                          </div>
              </td>
              
            
            </tr>
                      ))
        }
            
          </tbody>
        </table>
      </div>
    </div>:<div className="flex justify-center items-center h-[70vh] w-full">
              <span className="text-center w-full text-xl text-[#7b8794]">
                No Registrations found
              </span>
            </div>
        }

        <div className="flex justify-center w-full p-4 mb-4">
            <Pagination
              className="pagination-bar"
              currentPage={page}
              totalCount={totalparticipantsList}
              pageSize={pageSize}
              onPageChange={(page) => {
                 setpage(page)
                var currentScroll = document.documentElement.scrollTop || document.body.scrollTop;
                      if (currentScroll > 0) {
                        window.scrollTo(0, currentScroll - (currentScroll / 1));
                      }
              }}
            />
          </div>
        </div>
    </Template>
  )
}

export default ChampionParticiparionDetails