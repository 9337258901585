import React, { useEffect, useState } from 'react'
import Template from '../../../components/common/Template'
import { Tab } from "@headlessui/react";
import { useDispatch, useSelector } from 'react-redux';
import { setMedalistProfilePage, setSelectedMedalistProfileTab } from '../../../redux/slice/MedalistProfileSlice';
import EditMedalist from './EditMedalist';
import DeleteMedalist from './DeleteMedalist';
import AddMedalist from './AddMedalist';
import { getAllMedalistList } from '../../../services/apiService';
import Pagination from '../../../components/common/pagination';

const MedalistsProfile_index = () => {
  const dispatch =useDispatch()
  const {selectedTab}=useSelector((state)=>state.medalistprofile);
   const {MedalistProfileList,TotalMedalistProfileList}=useSelector((state)=>state.medalistprofile)
  const [selectedTemplate, setSelectedTemplate]=useState([])
  const [internationalPage, setinternationalPage] = useState(1)
  const [nationalPage, setnationalPage] = useState(1)
  const [statePage, setstatePage] = useState(1)







const pageSIze=10;
useEffect(() => {
  if (selectedTab === 0) {
    dispatch(getAllMedalistList(internationalPage-1,pageSIze,"international"));
  } else if (selectedTab === 1) {  
    dispatch(getAllMedalistList(nationalPage-1,pageSIze,"national"));
  } else {
    dispatch(getAllMedalistList(statePage-1,pageSIze,"state"));
  }
}, [dispatch, selectedTab,statePage,internationalPage,nationalPage]);

  return (
    <Template>
    <div className='flex flex-1 justify-between p-4  bg-gray-200'>
    <div className='flex flex-1 justify-start text-left items-center'>
      <p className='text-2xl'>Our Medalist List</p>
    </div>
<div className='flex flex-row gap-4 '>



    <div className=''>
     <AddMedalist pageSIze={pageSIze} />
    </div>
    
    </div>
    </div> 


  <Tab.Group selectedIndex={selectedTab} onChange={(val)=>dispatch(setSelectedMedalistProfileTab(val))}>
    <Tab.List className="flex justify-start items-center self-stretch flex-grow-0 flex-shrink-0 gap-12 px-4 border border-black/[0.08]">
      <Tab>
        {({ selected }) => (
          <button
            className={`flex justify-start items-center flex-grow-0 flex-shrink-0 relative gap-1 py-4 text-sm font-medium text-center focus:ring-0 outline-none ${
              selected
                ? "border-b-2 border-[#186ced] text-[#186ced]"
                : "border-b-2 border-transparent text-gray-400 hover:text-gray-700"
            } `}
          >
          International Medalists 
          </button>
        )}
      </Tab>
      <Tab>
        {({ selected }) => (
          <button
            className={`flex justify-start items-center flex-grow-0 flex-shrink-0 relative gap-1 py-4 text-sm font-medium text-center focus:ring-0 outline-none ${
              selected
                ? "border-b-2 border-[#186ced] text-[#186ced]"
                : "border-b-2 border-transparent text-gray-400 hover:text-gray-700"
            } `}
          >
          National Medalists
          </button>
        )}
      </Tab>
  
      <Tab>
        {({ selected }) => (
          <button
            className={`flex justify-start items-center flex-grow-0 flex-shrink-0 relative gap-1 py-4 text-sm font-medium text-center focus:ring-0 outline-none ${
              selected
                ? "border-b-2 border-[#186ced] text-[#186ced]"
                : "border-b-2 border-transparent text-gray-400 hover:text-gray-700"
            } `}
          >
          State Medalists
          </button>
        )}
      </Tab>
      {/* Additional Tab elements */}
    </Tab.List>

    <Tab.Panels>
      <Tab.Panel className="md:p-4 w-[100%]">

      <div className="flex flex-col max-md:hidden justify-start items-start self-stretch flex-grow overflow-hidden ">
      <div className="flex flex-col  items-start self-stretch flex-grow min-h-[70vh] justify-between w-full">
        <>
        {MedalistProfileList?.length ? (
          <>
            {false ? null : (
              <div className="flex flex-col w-full">
                <table>
                  <thead>
                    <th></th>
                    <th></th>
                    <th></th>
                    <th></th>
                  </thead>
                  <tbody className="flex flex-col gap-4 p-4">
                    {MedalistProfileList?.map((medalist) => (
                      <tr
                        key={medalist?.player_id}
                        className="border group/item  bg-white hover:bg-slate-100 transition-all border-b border-black/5 cursor-pointer  flex items-stretch justify-between gap-2 pl-5 pr-3.5 rounded-lg border-solid border-black border-opacity-10 max-md:flex-wrap max-md:justify-center max-md:pl-5 w-full"
                      >
                         <td className="flex justify-between items-center gap-3 mt-2 mb-2 max-md:max-w-full max-md:flex-wrap">
                          <img
                            className="aspect-[1.29] object-contain object-center w-28 h-24 overflow-hidden"
                             src={medalist?.image}
                            placeholderSrc={""}
                            alt="preview"
                            effect="blur"
                            onError={""}
                          />
                  
  
                        </td>
                        <td className="self-center  flex    flex-col "> 
                        <div className="text-slate-500 text-xs font-medium leading-3 whitespace-nowrap">
                        Player Name
                        </div>
                        <div className="flex items-stretch   mt-2">
                          <div className=" text-xs font-medium leading-3"
                          >
                           {medalist?.player_name}
                          </div>
                        </div>
                      </td>
  
                        <td className="self-center  flex     flex-col "> 
                          <div className="text-slate-500 text-xs font-medium leading-3 whitespace-nowrap">
                          medal Type
                          </div>
                          <div className="flex items-stretch   mt-2">
                            <div className=" text-xs font-medium leading-3"
                            >
                             {medalist?.medal_type}
                            </div>
                          </div>
                        </td>
  
                        <td className="self-center flex    flex-col items-stretch my-auto">
                        <div className="text-slate-500 text-xs font-medium leading-3 whitespace-nowrap">
                        district 
                        </div>
                        <div className="text-stone-900 text-xs font-medium leading-3 whitespace-nowrap mt-2.5">
                          {medalist?.city_name}
                        </div>
                      </td>
                     
  
                        <td className="self-center flex    flex-col items-stretch my-auto">
                          <div className="text-slate-500 text-xs font-medium leading-3 whitespace-nowrap">
                          result
                          </div>
                          <div className="text-stone-900 text-xs font-medium leading-3 whitespace-nowrap mt-2.5">
                            {medalist?.results}
                          </div>
                        </td>
                        <td className="self-center flex    flex-col items-stretch my-auto">
                          <div className="text-slate-500 text-xs font-medium leading-3 whitespace-nowrap">
                          event
                          </div>
                          <div className="text-stone-900 text-xs font-medium leading-3 whitespace-nowrap mt-2.5">
                            {medalist?.event}
                          </div>
                        </td>
                        <td className="self-center flex    flex-col items-stretch my-auto">
                          <div className="text-slate-500 text-xs font-medium leading-3 whitespace-nowrap">
                          category or division
                          </div>
                          <div className="text-stone-900 text-xs font-medium leading-3 whitespace-nowrap mt-2.5">
                            {medalist?.category_or_division}
                          </div>
                        </td>
                        <td className="self-center flex    flex-col items-stretch my-auto">
                          <div className="text-slate-500 text-xs font-medium leading-3 whitespace-nowrap">
                          event date
                          </div>
                          <div className="text-stone-900 text-xs font-medium leading-3 whitespace-nowrap mt-2.5">
                            {medalist?.event_year_or_date}
                          </div>
                        </td>
                        <td className="self-center flex    flex-col items-stretch my-auto">
                          <div className="text-slate-500 text-xs font-medium leading-3 whitespace-nowrap">
                          venue
                          </div>
                          <div className="text-stone-900 text-xs font-medium leading-3 whitespace-nowrap mt-2.5">
                            {medalist?.venue}
                          </div>
                        </td>
                      
                        <td className="self-center flex    flex-col items-stretch my-auto">
                          <div className="text-slate-500 text-xs font-medium leading-3 whitespace-nowrap">
                          tournament name
                          </div>
                          <div className="text-stone-900 text-xs font-medium leading-3 whitespace-nowrap mt-2.5">
                            {medalist?.tournament_name}
                          </div>
                        </td>
                      
                        <td className="self-center flex items-stretch gap-2.5 my-auto">
                          
                             <EditMedalist dtaAssocitaiondetails={medalist}  page={internationalPage} />
                            <DeleteMedalist
                            MedalistStatus={"international"}
                              dtadetailsId={medalist?.player_id}  
                              page={internationalPage}
                              setpage={setinternationalPage}
                              className="group/edit invisible group-hover/item:visible flex justify-center items-center flex-grow-0 flex-shrink-0 relative rounded  p-2 bg-white shadow-sm hover:bg-blue-400 transition-all cursor-pointer  hover:rounded-md text-black"
                            />
                          
                        </td>
                      </tr>
                    ))}
                  </tbody>
                </table>
              </div>
            )}
          </>
        ) : (
          <div className="flex justify-center items-center h-[70vh] w-full">
            <span className="text-center w-full text-xl text-[#7b8794]">
              No Medalist Found
            </span>
          </div>
        )}
        </>
        <div className="flex justify-center w-full p-4 mb-4">
        <Pagination
          className="pagination-bar"
          currentPage={internationalPage}
          totalCount={TotalMedalistProfileList}
          pageSize={pageSIze}
          onPageChange={(page) => {
             setinternationalPage(page)
            var currentScroll = document.documentElement.scrollTop || document.body.scrollTop;
                  if (currentScroll > 0) {
                    window.scrollTo(0, currentScroll - (currentScroll / 1));
                  }
          }}
        />
      </div>
      </div>
     
    </div>
      </Tab.Panel>
    </Tab.Panels> 

    <Tab.Panels>
      <Tab.Panel className="md:p-4 w-[100%]">


      <div className="flex flex-col max-md:hidden justify-start items-start self-stretch flex-grow overflow-hidden ">
      <div className="flex flex-col  items-start self-stretch flex-grow min-h-[70vh] justify-between w-full">
        <>
        {MedalistProfileList?.length ? (
          <>
            {false ? null : (
              <div className="flex flex-col w-full">
                <table>
                  <thead>
                    <th></th>
                    <th></th>
                    <th></th>
                    <th></th>
                  </thead>
                  <tbody className="flex flex-col gap-4 p-4">
                  {MedalistProfileList?.map((medalist) => (
                    <tr
                      key={medalist?.player_id}
                      className="border group/item  bg-white hover:bg-slate-100 transition-all border-b border-black/5 cursor-pointer  flex items-stretch justify-between gap-2 pl-5 pr-3.5 rounded-lg border-solid border-black border-opacity-10 max-md:flex-wrap max-md:justify-center max-md:pl-5 w-full"
                    >
                       <td className="flex justify-between items-center gap-3 mt-2 mb-2 max-md:max-w-full max-md:flex-wrap">
                        <img
                          className="aspect-[1.29] object-contain object-center w-28 h-24 overflow-hidden"
                           src={medalist?.image}
                          placeholderSrc={""}
                          alt="preview"
                          effect="blur"
                          onError={""}
                        />
                

                      </td>
                      <td className="self-center  flex    flex-col "> 
                      <div className="text-slate-500 text-xs font-medium leading-3 whitespace-nowrap">
                      Player Name
                      </div>
                      <div className="flex items-stretch   mt-2">
                        <div className=" text-xs font-medium leading-3"
                        >
                         {medalist?.player_name}
                        </div>
                      </div>
                    </td>

                      <td className="self-center  flex     flex-col "> 
                        <div className="text-slate-500 text-xs font-medium leading-3 whitespace-nowrap">
                        medal Type
                        </div>
                        <div className="flex items-stretch   mt-2">
                          <div className=" text-xs font-medium leading-3"
                          >
                           {medalist?.medal_type}
                          </div>
                        </div>
                      </td>

                      <td className="self-center flex    flex-col items-stretch my-auto">
                      <div className="text-slate-500 text-xs font-medium leading-3 whitespace-nowrap">
                      district 
                      </div>
                      <div className="text-stone-900 text-xs font-medium leading-3 whitespace-nowrap mt-2.5">
                        {medalist?.city_name}
                      </div>
                    </td>
                   

                      <td className="self-center flex    flex-col items-stretch my-auto">
                        <div className="text-slate-500 text-xs font-medium leading-3 whitespace-nowrap">
                        result
                        </div>
                        <div className="text-stone-900 text-xs font-medium leading-3 whitespace-nowrap mt-2.5">
                          {medalist?.results}
                        </div>
                      </td>
                      <td className="self-center flex    flex-col items-stretch my-auto">
                        <div className="text-slate-500 text-xs font-medium leading-3 whitespace-nowrap">
                        event
                        </div>
                        <div className="text-stone-900 text-xs font-medium leading-3 whitespace-nowrap mt-2.5">
                          {medalist?.event}
                        </div>
                      </td>
                      <td className="self-center flex    flex-col items-stretch my-auto">
                        <div className="text-slate-500 text-xs font-medium leading-3 whitespace-nowrap">
                        category or division
                        </div>
                        <div className="text-stone-900 text-xs font-medium leading-3 whitespace-nowrap mt-2.5">
                          {medalist?.category_or_division}
                        </div>
                      </td>
                      <td className="self-center flex    flex-col items-stretch my-auto">
                        <div className="text-slate-500 text-xs font-medium leading-3 whitespace-nowrap">
                        event date
                        </div>
                        <div className="text-stone-900 text-xs font-medium leading-3 whitespace-nowrap mt-2.5">
                          {medalist?.event_year_or_date}
                        </div>
                      </td>
                      <td className="self-center flex    flex-col items-stretch my-auto">
                        <div className="text-slate-500 text-xs font-medium leading-3 whitespace-nowrap">
                        venue
                        </div>
                        <div className="text-stone-900 text-xs font-medium leading-3 whitespace-nowrap mt-2.5">
                          {medalist?.venue}
                        </div>
                      </td>
                    
                      <td className="self-center flex    flex-col items-stretch my-auto">
                        <div className="text-slate-500 text-xs font-medium leading-3 whitespace-nowrap">
                        tournament name
                        </div>
                        <div className="text-stone-900 text-xs font-medium leading-3 whitespace-nowrap mt-2.5">
                          {medalist?.tournament_name}
                        </div>
                      </td>
                    
                      <td className="self-center flex items-stretch gap-2.5 my-auto">
                        
                           <EditMedalist dtaAssocitaiondetails={medalist}  page={internationalPage} />
                          <DeleteMedalist
                          MedalistStatus={"international"}
                            dtadetailsId={medalist?.player_id}  
                            page={internationalPage}
                            setpage={setinternationalPage}
                            className="group/edit invisible group-hover/item:visible flex justify-center items-center flex-grow-0 flex-shrink-0 relative rounded  p-2 bg-white shadow-sm hover:bg-blue-400 transition-all cursor-pointer  hover:rounded-md text-black"
                          />
                        
                      </td>
                    </tr>
                  ))}
                </tbody>
                </table>
              </div>
            )}
          </>
        ) : (
          <div className="flex justify-center items-center h-[70vh] w-full">
            <span className="text-center w-full text-xl text-[#7b8794]">
              No Medalist Found
            </span>
          </div>
        )}
        </>
        <div className="flex justify-center w-full p-4 mb-4">   
        <Pagination
        className="pagination-bar"
        currentPage={nationalPage}
        totalCount={TotalMedalistProfileList}
        pageSize={pageSIze}
        onPageChange={(page) => {
           setnationalPage(page)
          var currentScroll = document.documentElement.scrollTop || document.body.scrollTop;
                if (currentScroll > 0) {
                  window.scrollTo(0, currentScroll - (currentScroll / 1));
                }
        }}
      />             
        </div>
      </div>
     
    </div>
      </Tab.Panel>
    </Tab.Panels> 

    <Tab.Panels>
      <Tab.Panel className="md:p-4 w-[100%]">

      <div className="flex flex-col max-md:hidden justify-start items-start self-stretch flex-grow overflow-hidden ">
      <div className="flex flex-col  items-start self-stretch flex-grow min-h-[70vh] justify-between w-full">
        <>
        {MedalistProfileList?.length ? (
          <>
            {false ? null : (
              <div className="flex flex-col w-full">
                <table>
                  <thead>
                    <th></th>
                    <th></th>
                    <th></th>
                    <th></th>
                  </thead>
                  <tbody className="flex flex-col gap-4 p-4">
                  {MedalistProfileList?.map((medalist) => (
                    <tr
                      key={medalist?.player_id}
                      className="border group/item  bg-white hover:bg-slate-100 transition-all border-b border-black/5 cursor-pointer  flex items-stretch justify-between gap-2 pl-5 pr-3.5 rounded-lg border-solid border-black border-opacity-10 max-md:flex-wrap max-md:justify-center max-md:pl-5 w-full"
                    >
                       <td className="flex justify-between items-center gap-3 mt-2 mb-2 max-md:max-w-full max-md:flex-wrap">
                        <img
                          className="aspect-[1.29] object-contain object-center w-28 h-24 overflow-hidden"
                           src={medalist?.image}
                          placeholderSrc={""}
                          alt="preview"
                          effect="blur"
                          onError={""}
                        />
                

                      </td>
                      <td className="self-center  flex    flex-col "> 
                      <div className="text-slate-500 text-xs font-medium leading-3 whitespace-nowrap">
                      Player Name
                      </div>
                      <div className="flex items-stretch   mt-2">
                        <div className=" text-xs font-medium leading-3"
                        >
                         {medalist?.player_name}
                        </div>
                      </div>
                    </td>

                      <td className="self-center  flex     flex-col "> 
                        <div className="text-slate-500 text-xs font-medium leading-3 whitespace-nowrap">
                        medal Type
                        </div>
                        <div className="flex items-stretch   mt-2">
                          <div className=" text-xs font-medium leading-3"
                          >
                           {medalist?.medal_type}
                          </div>
                        </div>
                      </td>

                      <td className="self-center flex    flex-col items-stretch my-auto">
                      <div className="text-slate-500 text-xs font-medium leading-3 whitespace-nowrap">
                      district 
                      </div>
                      <div className="text-stone-900 text-xs font-medium leading-3 whitespace-nowrap mt-2.5">
                        {medalist?.city_name}
                      </div>
                    </td>
                   

                      <td className="self-center flex    flex-col items-stretch my-auto">
                        <div className="text-slate-500 text-xs font-medium leading-3 whitespace-nowrap">
                        result
                        </div>
                        <div className="text-stone-900 text-xs font-medium leading-3 whitespace-nowrap mt-2.5">
                          {medalist?.results}
                        </div>
                      </td>
                      <td className="self-center flex    flex-col items-stretch my-auto">
                        <div className="text-slate-500 text-xs font-medium leading-3 whitespace-nowrap">
                        event
                        </div>
                        <div className="text-stone-900 text-xs font-medium leading-3 whitespace-nowrap mt-2.5">
                          {medalist?.event}
                        </div>
                      </td>
                      <td className="self-center flex    flex-col items-stretch my-auto">
                        <div className="text-slate-500 text-xs font-medium leading-3 whitespace-nowrap">
                        category or division
                        </div>
                        <div className="text-stone-900 text-xs font-medium leading-3 whitespace-nowrap mt-2.5">
                          {medalist?.category_or_division}
                        </div>
                      </td>
                      <td className="self-center flex    flex-col items-stretch my-auto">
                        <div className="text-slate-500 text-xs font-medium leading-3 whitespace-nowrap">
                        event date
                        </div>
                        <div className="text-stone-900 text-xs font-medium leading-3 whitespace-nowrap mt-2.5">
                          {medalist?.event_year_or_date}
                        </div>
                      </td>
                      <td className="self-center flex    flex-col items-stretch my-auto">
                        <div className="text-slate-500 text-xs font-medium leading-3 whitespace-nowrap">
                        venue
                        </div>
                        <div className="text-stone-900 text-xs font-medium leading-3 whitespace-nowrap mt-2.5">
                          {medalist?.venue}
                        </div>
                      </td>
                    
                      <td className="self-center flex    flex-col items-stretch my-auto">
                        <div className="text-slate-500 text-xs font-medium leading-3 whitespace-nowrap">
                        tournament name
                        </div>
                        <div className="text-stone-900 text-xs font-medium leading-3 whitespace-nowrap mt-2.5">
                          {medalist?.tournament_name}
                        </div>
                      </td>
                    
                      <td className="self-center flex items-stretch gap-2.5 my-auto">
                        
                           <EditMedalist dtaAssocitaiondetails={medalist}  page={internationalPage} />
                          <DeleteMedalist
                          MedalistStatus={"international"}
                            dtadetailsId={medalist?.player_id}  
                            page={internationalPage}
                            setpage={setinternationalPage}
                            className="group/edit invisible group-hover/item:visible flex justify-center items-center flex-grow-0 flex-shrink-0 relative rounded  p-2 bg-white shadow-sm hover:bg-blue-400 transition-all cursor-pointer  hover:rounded-md text-black"
                          />
                        
                      </td>
                    </tr>
                  ))}
                </tbody>
                </table>
              </div>
            )}
          </>
        ) : (
          <div className="flex justify-center items-center h-[70vh] w-full">
            <span className="text-center w-full text-xl text-[#7b8794]">
              No Medalist Found
            </span>
          </div>
        )}
        </>
        <div className="flex justify-center w-full p-4 mb-4"> 
        <Pagination
        className="pagination-bar"
        currentPage={statePage}
        totalCount={TotalMedalistProfileList}
        pageSize={pageSIze}
        onPageChange={(page) => {
           setstatePage(page)
          var currentScroll = document.documentElement.scrollTop || document.body.scrollTop;
                if (currentScroll > 0) {
                  window.scrollTo(0, currentScroll - (currentScroll / 1));
                }
        }}
      />                 
        </div>
      </div>
     
    </div>
      </Tab.Panel>
    </Tab.Panels> 

  </Tab.Group>
    </Template>
  )
}

export default MedalistsProfile_index
