import React, { useEffect } from "react";
import { Disclosure, Transition } from "@headlessui/react";
import logo from "../../assets/images/Logo.png"
import { Link, useNavigate } from "react-router-dom";
import * as routesKey from "../../constants/routes"
import { ChevronRightIcon } from "@heroicons/react/outline";
import OtaLogo from "../../assets/images/image 51.png"
import { useDispatch, useSelector } from "react-redux";
import { setselectedChild, setselectedGrandId, setselectedParent } from "../../redux/slice/authSlice";
const Navigation = () => {
  const { selectedGrandId, selectedParent, selectedChild } = useSelector((state) => state.auth);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const menus = [
    {
      id: 1,
      title: "Home",
      children: [
        {
          id:2,
          title: "News",
          link: routesKey.NEWS,
          parentId:1
        },
        {
          id:3,
          title: "Gallery",
          link: routesKey.GALLERY,
          parentId:1
        },
        {
          id:5,
          title: "Our Pride",
          link: routesKey.OURPRIDE,
          parentId:1
        },
        {
          id:6,
          title: "Latest Information",
          link: routesKey.LATESTINFORMATION,
          parentId:1
        },
        {
          id:7,
          title: "Latest Video",
          link: routesKey.LATESTVIDEO,
          parentId:1
        },
        {
          id:72,
          title: "Forms",
          link: routesKey.FORMS,
          parentId:1
        },
         {
      id:54,
      title: "Template",
      link: routesKey.TEMPLATES,
      parentId:1
    },
      ]
    },
    {
      id:897,
      title: "DTA",
      children: [
        {
          id:78,
          title: "Manage DTA",
          link: routesKey.DTA,
          parentId:897
        },
        {
          id:92,
          title: "Cities",
          link: routesKey.CITIES,
          parentId:897
        },
      ]
  },
   
    {
      id:84,
      title: "Ota Club",
      link: routesKey.OTACLUB,
    },
    {
      id:43,
      title: "Contact",
      link: routesKey.CONTACTUS,
    },
    {
      id:3,
      title: "About",
      children: [
        {
          isChild: true,
          parentId: 3,
          id: 11,
          title: "Coaches Profile",
          link:routesKey.COCHESPROFILE,
        },
        {
          isChild: true,
          parentId: 3,
          id: 13,
          title: "Color Belt Promotion",
          link:routesKey.COLORBELTPROMOTION,
        },
        {
          isChild: true,
          parentId: 3,
          id: 18,
          title: "Event Calender",
          link:routesKey.EVENTCALENDER,
        },
        {
          isChild: true,
          parentId: 3,
          id: 28,
          title: "Event Result",
          link:routesKey.EVENTRESULT,
        },
        {
          isChild: true,
          parentId: 3,
          id: 34,
          title: "Executive Council Member",
          link:routesKey.EXECUTIVECOUNCILMEMBERS,
        },
        {
          isChild: true,
          parentId: 3,
          id: 39,
          title: "Medalists Profile",
          link:routesKey.MEDALISTPROFILE,
        },
        {
          isChild: true,
          parentId: 3,
          id: 78,
          title: "Ota Registered Player",
          children: [
            {
              isSubChild: true,
              subparentId: 78,
              parentId: 3,
              id: 59,
              title: "Color Belt Athelete",
              link:routesKey.COLORBELTATHELET,
            },
            {
              isSubChild: true,
              subparentId: 78,
              parentId: 3,
              id: 69,
              title: "Black Belt Athelete",
              link:routesKey.BLACKBELTATHELTE,
            },
          ]
        },
        {
          isChild: true,
          parentId: 3,
          id: 89,
          title: "Refree Profile",
          link:routesKey.REFREEPROFILE,
        },
        {
          isChild: true,
          parentId: 3,
          id:78,
          title: "Technical Profile",
          link:routesKey.TECHNICALCOMMITIE
        },
      ]
    },
    {
      id:45,
      title: "Competition",
      link: routesKey.COMPETATION,
    },
    {
      id:47,
      title: "Online Registration ",
      link: routesKey.ONLINEREGISTRATIONLIST,
    },
    {
      id:390,
      title: "Athlete Form ",
      link: routesKey.ATHELETEFORM,
    },

  ]
  const allMenus = [
    {
      id:78,
      title: "Manage DTA",
      link: routesKey.DTA,
      parentId:897
    },
    {
      id:54,
      title: "Template",
      link: routesKey.TEMPLATES,
      parentId:1
    },
    {
      id:92,
      title: "Cities",
      link: routesKey.CITIES,
      parentId:897
    },  {
      id:84,
      title: "Ota Club",
      link: routesKey.OTACLUB,
    },
    {
      id:43,
      title: "Contact",
      link: routesKey.CONTACTUS,
    },
    {
      id:45,
      title: "Competation",
      link: routesKey.COMPETATION,
    },
    {
      id:47,
      title: "Online Registration",
      link: routesKey.ONLINEREGISTRATIONLIST,
    },
    {
      isSubChild: true,
      parentId:3,
      subparentId:78 ,
      id: 59,
      title: "Color Belt Athelete",
      link:routesKey.COLORBELTATHELET,
    },
    {
      isSubChild: true,
      subparentId:78 ,
      id: 69,
      parentId:3,
      title: "Black Belt Athelete",
      link:routesKey.BLACKBELTATHELTE,
    },
    {
      id:2,
      title: "News",
      link: routesKey.NEWS,
      parentId:1
    },
    {
      id:38,
      title: "Gallery",
      link: routesKey.GALLERY,
      parentId:1
    },
    {
      id:5,
      title: "Our Pride",
      link: routesKey.OURPRIDE,
      parentId:1
    },
    {
      id:6,
      title: "Latest Information",
      link: routesKey.LATESTINFORMATION,
      parentId:1
    },
    {
      id:7,
      title: "Latest Video",
      link: routesKey.LATESTVIDEO,
      parentId:1
    },
    {
      id:72,
      title: "Forms",
      link: routesKey.FORMS,
      parentId:1
    },
    {
      isChild: true,
      parentId: 3,
      id: 11,
      title: "Coaches Profile",
      link:routesKey.COCHESPROFILE,
    },
    {
      isChild: true,
      parentId: 3,
      id: 13,
      title: "Color Belt Promotion",
      link:routesKey.COLORBELTPROMOTION,
    },
    {
      isChild: true,
      parentId: 3,
      id: 18,
      title: "Event Calender",
      link:routesKey.EVENTCALENDER,
    },
    {
      isChild: true,
      parentId: 3,
      id: 28,
      title: "Event Result",
      link:routesKey.EVENTRESULT,
    },
    {
      isChild: true,
      parentId: 3,
      id: 34,
      title: "Executive Council Member",
      link:routesKey.EXECUTIVECOUNCILMEMBERS,
    },
    {
      isChild: true,
      parentId: 3,
      id: 39,
      title: "Medalists Profile",
      link:routesKey.MEDALISTPROFILE,
    },
    {
      isChild: true,
      parentId: 3,
      id: 89,
      title: "Refree Profile",
      link:routesKey.REFREEPROFILE,
    },
    {
      isChild: true,
      parentId: 3,
      id:78,
      title: "Technical Profile",
      link:routesKey.TECHNICALCOMMITIE
    },
  ]
  useEffect(() => {
    if (window) {
      const currentUrl = window.location.hash?.split("#")[1];
      if (allMenus?.find((menu) => menu.link === currentUrl)) {
        const currentObj = allMenus?.find((menu) => menu.link === currentUrl);
        if (currentObj?.isSubChild) {
          dispatch(setselectedGrandId(currentObj?.parentId))
          dispatch(setselectedParent(currentObj?.subparentId))
          dispatch(setselectedChild(currentObj?.id))
        } else if(currentObj?.parentId)  {
          dispatch(setselectedGrandId(currentObj?.parentId))
          dispatch(setselectedParent(currentObj?.id))
          dispatch(setselectedChild(null))
        } else {
          dispatch(setselectedGrandId(currentObj?.id))
          dispatch(setselectedParent(null))
          dispatch(setselectedChild(null))
        }
      }
    }
  },[])
  const handleMenu = (currentObj) => {
    navigate(currentObj.link)
    if (currentObj?.isSubChild) {
      dispatch(setselectedGrandId(currentObj?.parentId))
      dispatch(setselectedParent(currentObj?.subparentId))
      dispatch(setselectedChild(currentObj?.id))
    } else if(currentObj?.parentId)  {
      dispatch(setselectedGrandId(currentObj?.parentId))
      dispatch(setselectedParent(currentObj?.id))
      dispatch(setselectedChild(null))
    } else {
      dispatch(setselectedGrandId(currentObj?.id))
      dispatch(setselectedParent(null))
      dispatch(setselectedChild(null))
    }
  }
  return (
    <div className="flex flex-col justify-start items-start h-full bg-blue">
    <div className="flex flex-col justify-center items-start flex-grow-0 flex-shrink-0 h-16 gap-2.5 pl-6 pt-4">
      <div className="flex justify-start items-center  flex-grow-0 flex-shrink-0 relative overflow-hidden gap-[3px]">
        <p className="flex-grow-0 flex-shrink-0 text-xl  font-medium text-left capitalize">
         <img className="h-16 w-16" src={logo} />
        </p>
      </div>
    </div>
    <div className="flex flex-col justify-between items-start flex-grow-0 flex-shrink-0 w-60 gap-1 p-3 min-h-full">
      <div className="flex flex-col justify-start items-start self-stretch flex-grow-0 flex-shrink-0 gap-1">

      {menus.map((menu) => (
        menu.children && menu.children.length > 0 ? (
          <Disclosure key={menu.id} defaultOpen={selectedGrandId==menu.id ?true:false}>
            {({ open }) => (
              <div
                className={`${open ? "bg-white/5 transform" : ""
                  } flex flex-col self-stretch rounded-lg hover:bg-white/5 focus:outline-none focus-visible:ring focus-visible:ring-white focus-visible:ring-opacity-75 transition-all   `}
                onClick={(e) => {
                  e.preventDefault();
                  e.stopPropagation();
                  handleMenu(menu)
                  }}
              >
                <Disclosure.Button className="flex flex-col justify-start items-start self-stretch flex-grow-0 flex-shrink-0">
                  <div className="flex justify-start items-center self-stretch flex-grow-0 flex-shrink-0 relative gap-2.5 pl-4 pr-3 py-2.5 rounded-lg">
                    <div className="flex justify-start items-center flex-grow relative gap-4">
                      {menu.icon}
                      <p className={`flex-grow-0 flex-shrink-0 text-[13px] font-medium text-left text-white`}>
                        {menu.title}
                      </p>
                    </div>
                    <ChevronRightIcon
                      className={`${
                        open ? "rotate-90 transform" : ""
                        } h-4 w-4 text-white transition-all`}
                    />
                  </div>
                </Disclosure.Button>
                <Transition
                  enter="transition duration-100 ease-out"
                  enterFrom="transform scale-95 opacity-0"
                  enterTo="transform scale-100 opacity-100"
                  leave="transition duration-75 ease-out"
                  leaveFrom="transform scale-100 opacity-100"
                  leaveTo="transform scale-95 opacity-0"
                >
                  <Disclosure.Panel className="flex flex-col justify-start items-start self-stretch flex-grow-0 flex-shrink-0 p-2 pt-0">
                    {menu.children.map((submenu) => (
                      submenu?.children && submenu?.children?.length ?  <Disclosure key={submenu.id} defaultOpen={selectedParent===submenu.id ?true:false}>
            {({ open }) => (
                          <div
                            onClick={(e) => {
                              e.preventDefault();
                  e.stopPropagation();
                              handleMenu(submenu)
                            }}
                className={`${open ? "bg-white/5 transform" : ""
                  } flex flex-col self-stretch rounded-lg hover:bg-white/5 focus:outline-none focus-visible:ring focus-visible:ring-white focus-visible:ring-opacity-75 transition-all   `}
              >
                <Disclosure.Button className="flex flex-col justify-start items-start self-stretch flex-grow-0 flex-shrink-0">
                  <div className="flex justify-start items-center self-stretch flex-grow-0 flex-shrink-0 relative gap-2.5 pl-4 pr-3 py-2.5 rounded-lg">
                    <div className="flex justify-start items-center flex-grow relative gap-4">
                      {submenu.icon}
                      <p className={`flex-grow-0 flex-shrink-0 text-[13px] font-medium text-left text-white`}>
                        {submenu.title}
                      </p>
                    </div>
                    <ChevronRightIcon
                      className={`${
                        open ? "rotate-90 transform" : ""
                        } h-4 w-4 text-white transition-all`}
                    />
                  </div>
                </Disclosure.Button>
                <Transition
                  enter="transition duration-100 ease-out"
                  enterFrom="transform scale-95 opacity-0"
                  enterTo="transform scale-100 opacity-100"
                  leave="transition duration-75 ease-out"
                  leaveFrom="transform scale-100 opacity-100"
                  leaveTo="transform scale-95 opacity-0"
                >
                  <Disclosure.Panel className="flex flex-col justify-start items-start self-stretch flex-grow-0 flex-shrink-0 p-2 pt-0">
                    {submenu.children.map((subsubmenu) => (
                      <React.Fragment key={subsubmenu.id}>
                        <Link to={subsubmenu.link} className={`flex flex-col justify-center items-start self-stretch flex-grow-0 flex-shrink-0 relative pl-[48px] pr-3 py-3 rounded hover:bg-white/5 transition-all ${selectedParent === submenu.id && "bg-white/5"}`} onClick={(e) => {
                           e.preventDefault();
                  e.stopPropagation();
                          handleMenu(subsubmenu)
                        }}>
                          <p className={`flex-grow-0 flex-shrink-0 text-[13px] font-medium text-left text-white`}>
                            {subsubmenu.title}
                          </p>
                        </Link>
                        {subsubmenu.children && subsubmenu.children.length > 0 && (
                          <div className="ml-6">
                            {subsubmenu.children.map((subsub) => (
                              <Link to={subsub.link} key={subsub.id} className={`flex flex-col justify-center items-start self-stretch flex-grow-0 flex-shrink-0 relative pl-[48px] pr-3 py-3 rounded hover:bg-white/5 transition-all ${selectedChild === subsub.id && "bg-white/5"}`} onClick={(e) => {
                                 e.preventDefault();
                  e.stopPropagation();
                                handleMenu(subsub)
                              }}>
                                <p className={`flex-grow-0 flex-shrink-0 text-[13px] font-medium text-left text-white`}>
                                  {subsub.title}
                                </p>
                              </Link>
                            ))}
                          </div>
                        )}
                      </React.Fragment>
                    ))}
                  </Disclosure.Panel>
                </Transition>
              </div>
            )}
          </Disclosure>:   <Link
                          to={submenu.link}
                          onClick={(e) => {
                            e.preventDefault();
                  e.stopPropagation();
                            handleMenu(submenu)
                          }}
            className={`flex flex-col justify-start items-start self-stretch flex-grow-0 flex-shrink-0 gap-2.5 px-4 py-2.5 rounded-lg hover:bg-white/5 ${selectedParent===submenu.id&&"bg-white/5"}  `}
          >
            <div className="flex justify-start items-center self-stretch flex-grow-0 flex-shrink-0 relative gap-4">
              {submenu.icon}
              <p className="flex-grow-0 flex-shrink-0 text-[13px] font-medium text-left text-white">
                {submenu.title}
              </p>
            </div>
          </Link>
                    ))}
                  </Disclosure.Panel>
                </Transition>
              </div>
            )}
          </Disclosure>
        ) : (
          <Link
              to={menu.link}
              onClick={(e) => {
                e.preventDefault();
                  e.stopPropagation();
                handleMenu(menu)
              }}
            className={`flex flex-col justify-start items-start self-stretch flex-grow-0 flex-shrink-0 gap-2.5 px-4 py-2.5 rounded-lg hover:bg-white/5 ${selectedGrandId===menu.id && "bg-white/5"}    `}
          >
            <div className="flex justify-start items-center self-stretch flex-grow-0 flex-shrink-0 relative gap-4">
              {menu.icon}
              <p className="flex-grow-0 flex-shrink-0 text-[13px] font-medium text-left text-white">
                {menu.title}
              </p>
            </div>
          </Link>
        )
      ))}
      
      </div>
    </div>
  </div>
  
  );
};

export default Navigation;
