import { createSlice } from "@reduxjs/toolkit";

const NewNewsList= createSlice({
    name:"news",
    initialState:{
        newsLoadingList:false,
        NewsList:[],
        page:1,
        total_pages:0,
        TotalNewsList: 0
    },
    reducers:{
        getNewsListRequest:(state)=>{
            state.newsLoadingList=true;
        },
        getNewsListSuccess:(state,{payload})=>{
           state.newsLoadingList=false;
           state.NewsList = payload?.news_image_list;
           state.TotalNewsList=payload?.total_elements
        },
        getNewsListFail:(state)=>{
            state.newsLoadingList=false
            state.NewsList=[]
        },
        setNewsPage:(state,{payload})=>{
            state.page=payload
         },
         setTotalPageNews: (state, { payload }) => {
            state.total_pages = payload;
          },
    }
})

export default NewNewsList.reducer;
export const {getNewsListFail, getNewsListRequest, getNewsListSuccess, setNewsPage, setTotalPageNews}=NewNewsList.actions;