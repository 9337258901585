import React from 'react';
import classnames from 'classnames';
import { usePagination, DOTS } from '../../hook/usePagination';
const Pagination = ({onPageChange,totalCount,currentPage,pageSize,className,siblingCount=2}) => {

  const paginationRange = usePagination({
    currentPage,
    totalCount,
    siblingCount,
    pageSize
  });
    
    
  if (currentPage === 0 || paginationRange?.length < 2) {
    return null;
  }

  const onNext = () => {
    onPageChange(currentPage + 1);
  };

  const onPrevious = () => {
    onPageChange(currentPage - 1);
  };

  let lastPage =paginationRange && paginationRange[paginationRange?.length - 1];
  return (
    <ul
      className={classnames('pagination-container', { [className]: className })}
    >
       {/* Left navigation arrow */}
      <li
        className={classnames('pagination-item', {
          disabled: currentPage === 1
        })}
        style={currentPage===1?{cursor:"not-allowed"}:{cursor:"pointer"}}
        onClick={onPrevious}
      >
        <div className="arrow left" />
      </li>
      {paginationRange?.map(pageNumber => {
         
        if (pageNumber === DOTS) {
          return <li key={pageNumber} className="pagination-item dots">&#8230;</li>;
        }		
        return (
          <li
            key={pageNumber}
            className={classnames('pagination-item', {
              selected: pageNumber === currentPage
            })}
            onClick={() => onPageChange(pageNumber)}
          >
            {pageNumber}
          </li>
        );
      })}
      <li
        className={classnames('pagination-item', {
          disabled: currentPage === lastPage
        })}
        onClick={onNext}
        style={currentPage===lastPage?{cursor:"not-allowed"}:{cursor:"pointer"}}
      >
        <div className="arrow right" />
      </li>
    </ul>
  );
};

export default Pagination;