import { createSlice } from "@reduxjs/toolkit";

const authSlice = createSlice({
    name: "auth",
    initialState: {
        isAuthenticated: false,
        selectedGrandId: 1,
        selectedParent: 2,
        selectedChild: null,
        statusCode:null
    },
    reducers: {
        setIsAuthenticated: (state, { payload }) => {
            state.isAuthenticated = payload;
        },
        setselectedGrandId: (state, { payload }) => {
            state.selectedGrandId = payload;
        },
        setselectedParent: (state, { payload }) => {
            state.selectedParent = payload;
        },
        setselectedChild: (state, { payload }) => {
            state.selectedChild = payload;
        },
        testApiFail: (state, { payload }) => {
            state.statusCode = payload;
        },
        clearStatusCode: (state, { payload }) => {
            state.statusCode = payload;
        }
    }
})

export default authSlice.reducer;
export const {setIsAuthenticated,setselectedChild,setselectedGrandId, setselectedParent,testApiFail,clearStatusCode } = authSlice.actions;