import React, { useState } from 'react'
import axiosConfig from '../../../axios_config/axios_instance';
import toast from 'react-hot-toast';
import { getAllCalenderEventList } from '../../../services/apiService';
import { useFormik } from 'formik';
import { ReactComponent as UploadIcon } from "../../../assets/icons/uploadicon.svg";
import Files from "react-files";
import * as Yup from "yup";
import pdfImage from "../../../assets/images/pdf.png"
import ReactDatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import { FiLoader } from "react-icons/fi";

import { useDispatch } from 'react-redux';
import { setSelectedEventCalender } from '../../../redux/slice/EventCalenderSlice';
import moment from 'moment/moment';
const AddEventCalender = () => {
  const [showModal, setShowModal] = useState(false);
  const [isLoading, setIsLoading]=useState(false)
  const dispatch=useDispatch()
   //intialData for formik
   const initialValues = {

    eventyear:"",
    description:"",
    status:"",
    image:null,
   };

   const statusOptions = [
    { label: 'world', value: 0 },
    { label: 'national', value: 1 },
    { label: 'state', value: 2 },
  ];
  
 
   //Yup validator Schema
   const videoInformationSchema = Yup.object({
    eventyear:Yup.string().required(" event year is required"), 
    description:Yup.string().trim()
    .required("required")
    .max(50),
    status:Yup.string().required("status is required"),
    image:Yup.mixed().required("image is required"),
   });
 
   //formik validation
   const {
     values,
     handleBlur,
     resetForm,
     handleChange,
     setFieldValue,

     handleSubmit,
     errors,
     touched,
   } = useFormik({
     initialValues,
     validationSchema: videoInformationSchema,
     validateOnChange: true,
     validateOnBlur: false,
     onSubmit: async(values) => {
       try {
         const formdata= new FormData()
         setIsLoading(true)
         formdata.append("description", values?.description);
         const formattedEventYear = moment(values?.eventyear).format('YYYY');
         formdata.append("event_year",formattedEventYear);
         formdata.append("event_calender_type", values?.status);
         formdata.append("pdf_file", values?.image);
         const {data}= await axiosConfig.post("/save-ota-event-calender", formdata);
         if(data?.status === "success"){
           toast.success(data?.message || "Video Added successfully")
           if (values.status === "world") {
            dispatch(setSelectedEventCalender(0))
            dispatch(getAllCalenderEventList(0,10,"world"));
          } else if (values.status === "national") {
          dispatch(setSelectedEventCalender(1))
            dispatch(getAllCalenderEventList(0,10,"national"));
          } else if (values.status === "state") {
          dispatch(setSelectedEventCalender(2))
            dispatch(getAllCalenderEventList(0,10,"state"));
          }
           setShowModal(false)
           setIsLoading(false)
           resetForm()
         }
        } catch (error) {
           toast.error("something Went wrong")
        }
     },
   });
  
 
   const closeModal = (e) => {
     e?.target?.classList?.forEach((classes) => {
       if (classes === "Modal-Container") {
         setShowModal(false);
         resetForm();
       }
     });
   };

   const handleImageChange = (files) => {
    if (files.length) {
      setFieldValue("image", files[0]);

    } else {
      toast.error("No image selected. Please upload an image.");
    }
  };
  return (
    <div>
    <div
    onClick={(e) => {
      e.stopPropagation();
      setShowModal(true);

    }}
    className="flex justify-start cursor-pointer relative items-center flex-grow-0 flex-shrink-0 gap-3"
  >
    <div
      className="flex justify-start items-center flex-grow-0 flex-shrink-0 relative overflow-hidden gap-1 pl-4 pr-5 max-md:py-[7px] py-[10px] rounded-full bg-[#186ced]"
      style={{ boxShadow: "0px 1px 0px 0 rgba(0,0,0,0.08)" }}
    >
      <p className="flex-grow-0 flex-shrink-0 text-sm font-medium text-center text-white">
        Add Event 
      </p>
    </div>
  </div>
  {showModal ? (
    <>
    <div>
      <div
        className="flex justify-center items-center overflow-x-hidden overflow-y-auto fixed inset-0 z-50 outline-none focus:outline-none shadow-lg w-full Modal-Container"
        onClick={closeModal}
        style={{ backdropFilter: "blur(1px)" }} 
      >
        <div className="relative w-auto my-6 mx-auto max-w-3xl">
          <div className="border-0 rounded-lg shadow-lg relative flex w-full bg-white outline-none focus:outline-none">
            <div className="flex items-start gap-1 p-2 border-b border-solid border-gray-300 rounded-t flex-col ">
              <h3 className="text-xl text-black font=semibold p-1">
                Add Event Calender
              </h3>
              <div className="flex justify-between items-start">
                <div className="flex flex-col mt-3">
                <p className="text-[12px] text-left text-black/80 font-semibold text-[#202223] relative after:content-['*'] after:ml-0.5 after:text-red-500">max upto 500kb</p>
                <Files
className="files-dropzone cursor-pointer"
onChange={(file) => handleImageChange(file)}
onError={(error) => toast.error(error?.message)}
accepts={[".pdf"]}
maxFileSize={512000}
minFileSize={0}
multiple
clickable
style={
errors.image && touched.image
  ? { border: "1px solid red" }
  : {}
}
>
{values.image ? (
<div className="flex flex-col items-center relative">
<img
  src={
    typeof values.image === "object"
      ? `${pdfImage}`
      : values.image
  }
  className="h-36 w-[160px] object-cover"
  
/>

</div>
) : (
<div className="flex flex-col justify-center items-center flex-grow-0 flex-shrink-0 h-[122px] w-[122px] relative gap-1 px-[23.5px] py-7 rounded bg-[#186ced]/[0.04] border-[1.33px] border-[#186ced]/[0.64] border-dashed">
<UploadIcon />
<div className="flex justify-start items-center flex-grow-0 flex-shrink-0 relative gap-1 px-[5px] py-0.5 rounded">
  <p className="flex-grow-0 flex-shrink-0 text-xs font-medium text-center text-black/[0.64]">
    Upload
  </p>
</div>
</div>
)}
</Files>
<p className="text-[14px] text-left text-black/80 font-semibold text-[#202223] relative after:content-['*'] after:ml-0.5 after:text-red-500">(upload only pdf)</p>
                  {/* { locationImages?.length ?  <span>{locationImages.length} selected</span>:null} */}
                </div>
                <div className="relative p-6 pt-0 flex-auto">
                  <div className="flex flex-col justify-start items-start self-stretch flex-grow-0 flex-shrink-0 gap-1">
                    <div className="flex justify-start items-start self-stretch flex-grow-0 flex-shrink-0 relative gap-1 flex-col">
           
                    <div className="flex-grow-0 flex-shrink-0 w-[150px]">
                    <p className="text-[14px] text-left text-black/80 font-semibold text-[#202223] relative after:content-['*'] after:ml-0.5 after:text-red-500">
                      Event Year
                    </p>
                  </div>
                  <div className="flex flex-col flex-grow w-[300px]">
                  <ReactDatePicker
                  selected={values.eventyear}
                  onChange={(date) => setFieldValue("eventyear", date)}
                  showYearPicker
                  dateFormat="yyyy"
                  className="w-full border rounded p-2 text-gray-700 capitalize leading-tight focus:outline-none focus:shadow-outline"
                  placeholderText="YYYY"
                  style={
                    errors.eventyear && touched.eventyear
                      ? { border: "1px solid red" }
                      : {}
                  }
                />
                  </div>
                    <div className="flex-grow-0 flex-shrink-0 w-[150px]">
                    <p className="text-[14px] text-left mt-1 text-black/80 font-semibold text-[#202223] relative after:content-['*'] after:ml-0.5 after:text-red-500">
                      Description
                    </p>
                  </div>
                  <div className="flex flex-col flex-grow w-[300px]">
                  <textarea
                  type="text"
                  className="form-input w-full h-40  p-2 text-black rounded-md capitalize bg-gray-100 border-transparent focus:border-gray-500 focus:bg-white focus:ring-0 placeholder:text-sm"
                  placeholder="Type here"
                  value={values.description}
                  onChange={handleChange} // <-- Corrected
                  onBlur={handleBlur}
                  maxLength={50}
                  name="description"
                    style={
                      errors.description && touched.description
                        ? { border: "1px solid red" }
                        : {}
                    }
                ></textarea>
                <div className="flex justify-between items-center">
               
                <div className="flex">
                  <span className="text-green-500">
                    {values.description?.length}
                  </span>
                  /<span className="text-red-500">50</span>
                </div>
              </div>
                  </div>
           
          

                      </div>
                      <div className="flex-grow-0 flex-shrink-0 w-[150px]">
                        <p className="text-[14px] mt-1 text-left text-black/80 font-semibold text-[#202223] relative after:content-['*'] after:ml-0.5 after:text-red-500">
                          Status
                        </p>
                      </div>
                      <div className="flex flex-col flex-grow w-[300px]">
                      <select
                      name="status"
                      onChange={handleChange}
                      className={
                        errors.status && touched.status
                          ? "formErrorInput custom-select capitalize "
                          : "custom-select capitalize"
                      }
                     
                      onBlur={() => handleBlur("status")}
                      value={values.status}
                      style={
                        errors.status && touched.status
                          ? { border: "1px solid red" }
                          : {}
                      }
                     
                    >
                    <option>Select</option>
                      {
                        statusOptions?.map((option)=>(
                          <option value={option.label}>{option.label}</option>
                        ))
                      }
                </select>

                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="flex justify-start items-start flex-grow-0 flex-shrink-0 gap-2 self-end p-2">
                <div
                  onClick={(e) => {
                    e.stopPropagation();
                    setShowModal(false);
                  }}
                  className="flex justify-start items-center flex-grow-0 flex-shrink-0 relative overflow-hidden gap-1 pl-4 pr-5 py-[11px] rounded-[94px] bg-[#fff] cursor-pointer"
                  style={{ boxShadow: "0px 1px 0px 0 rgba(0,0,0,0.08)" }}
                >
                  <p className="flex-grow-0 flex-shrink-0 text-sm font-medium text-center text-black">
                    Cancel
                  </p>
                </div>
                <button
                type="button"
                className="flex justify-start items-center flex-grow-0 flex-shrink-0 relative overflow-hidden gap-1 px-5 py-[11px] rounded-[94px] bg-[#186ced] cursor-pointer"
                style={{ boxShadow: "0px 1px 0px 0 rgba(0,0,0,0.08)" }}
                onClick={handleSubmit}
                disabled={isLoading} // Disable the button while loading
              >
                {isLoading ? (
                  <FiLoader className="animate-spin w-5 h-5 mr-2" />
                ) : (
                  <p className="flex-grow-0 flex-shrink-0 text-sm font-medium text-center text-white">
                    Save
                  </p>
                )}
              </button>
              
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  ) : null}
    </div>
  )
}

export default AddEventCalender
