import { createSlice } from "@reduxjs/toolkit";

const NewFormList = createSlice({
    name: "forms",
    initialState: {
        FormListLoading: false,
        FormList:[],
        page:1,
        total_pages:0,
        TotalFormList:0,
    },
    reducers: {
         getFormListRequest:(state)=>{
            state.FormListLoading=true;
         },
         getFormListSuccess:(state, {payload})=>{
            state.FormListLoading=false;
            state.FormList=payload?.ota_form_list;
            state.TotalFormList=payload?.total_elements
         },
         getFormListFail:(state)=>{
            state.FormListLoading=false;
            state.FormList=[];
         },
         setFormPage:(state,{payload})=>{
            state.page=payload
         },
        setTotalFormPage:(state,{payload})=>{
            state.total_pages=payload
        }
    }
})  

export default NewFormList.reducer;
export const {getFormListRequest, getFormListSuccess, getFormListFail, setTotalFormPage, setFormPage}= NewFormList.actions