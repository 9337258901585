import { createSlice } from "@reduxjs/toolkit";

const NewVideoList = createSlice({
    name: "latestvideo",
    initialState: {
        videoListLoading: false,
        VideoList:[],
        page:1,
        total_pages:0,
        TotalLatestVideoList:0
    },
    reducers: {
         getVideoListRequest:(state)=>{
            state.videoListLoading=true;
         },
         getVideoListSuccess:(state, {payload})=>{
            state.videoListLoading=false;
            state.VideoList=payload?.ota_videos_lisst;
            state.TotalLatestVideoList=payload?.total_elements
         },
         getVideoListFail:(state)=>{
            state.videoListLoading=false;
            state.VideoList=[];
         },
         setLatestVideoPage:(state,{payload})=>{
            state.page=payload
         },
        setTotalVideoPage:(state,{payload})=>{
            state.total_pages=payload
        },
    }
})  

export default NewVideoList.reducer;
export const {getVideoListRequest, getVideoListSuccess, getVideoListFail,setLatestVideoPage,setTotalVideoPage}= NewVideoList.actions