import { useDispatch, useSelector } from "react-redux";
import Template from "../../components/common/Template"
import Pagination from "../../components/common/pagination";
import { useEffect, useState } from "react";
import { getAllAthleteFormList } from "../../services/apiService";
import { Link } from "react-router-dom";
import DeleteAtheleteForm from "./DeleteAthleteform";

const Athleteform_index = () => {
    const [page,setpage]=useState(1)
    const dispatch=useDispatch()
    
    const {AthleteFormList,TotalAtheleteForm}=useSelector((state)=>state.athleteform)

   const pageSize=10
    useEffect(()=>{
      dispatch(getAllAthleteFormList(page-1,pageSize))
    },[dispatch, page])
    return (
     <Template>
      <div>
      <div className="flex flex-col justify-start items-start self-stretch flex-grow overflow-hidden bg-white">
      <div className="flex flex-col  rounded-md m-4 items-start self-stretch flex-grow min-h-[70vh]  justify-between">
      {
        AthleteFormList?.length ?
      <div class=" px-4 sm:px-8 py-4 overflow-x-auto w-full">
      <div
        class="inline-block min-w-full shadow-md rounded-lg overflow-hidden"
      >
        <table class="min-w-full leading-normal">
          <thead>
            <tr>
              <th
                class="px-5 py-3 border-b-2 border-gray-200 bg-gray-100 text-left text-xs font-semibold text-gray-700 uppercase tracking-wider"
              >
              Athlete Name
              
              </th>
              <th
                class="px-5 py-3 border-b-2 border-gray-200 bg-gray-100 text-left text-xs font-semibold text-gray-700 uppercase tracking-wider"
              >
                District Name
              </th>
              <th
                class="px-5 py-3 border-b-2 border-gray-200 bg-gray-100 text-left text-xs font-semibold text-gray-700 uppercase tracking-wider"
              >
            Athlete Date Of Birth
              </th>
              <th
                class="px-5 py-3 border-b-2 border-gray-200 bg-gray-100 text-left text-xs font-semibold text-gray-700 uppercase tracking-wider"
              >
              Gender
              </th>
              <th
                class="px-5 py-3 border-b-2 border-gray-200 bg-gray-100 text-left text-xs font-semibold text-gray-700 uppercase tracking-wider"
              >
              Father Name
              </th>
              <th
                class="px-5 py-3 border-b-2 border-gray-200 bg-gray-100 text-left text-xs font-semibold text-gray-700 uppercase tracking-wider"
              >
              Mother Name
              </th>
              <th
                class="px-5 py-3 border-b-2 border-gray-200 bg-gray-100 text-left text-xs font-semibold text-gray-700 uppercase tracking-wider"
              >
              Guardian Name
              </th>
              <th
                class="px-5 py-3 border-b-2 border-gray-200 bg-gray-100 text-left text-xs font-semibold text-gray-700 uppercase tracking-wider"
              >

              </th>
           
            </tr>
          </thead>
          <tbody>
                    {
                        AthleteFormList?.map((category) => (
                        <tr key={category?.athlete_form_id}>
          
              <td class="px-5 py-5 border-b border-gray-200 bg-white text-sm">
              <Link   to={`/atheleteform-details/${category?.athlete_form_id}`}>
                    <p class="text-gray-900 break-all">
                      {category.athlete_name}
                    </p>
                    </Link>
              </td>
              <td class="px-5 py-5 border-b border-gray-200 bg-white text-sm">
                    <p class="text-gray-900 break-all">
                    {category?.district_name}
                    </p>
              </td>
              <td class="px-5 py-5 border-b border-gray-200 bg-white text-sm">
                    <p class="text-gray-900 break-all">
                    {category?.dob}
                    </p>
              </td>
              <td class="px-5 py-5 border-b border-gray-200 bg-white text-sm">
                    <p class="text-gray-900 break-all">
                    {category?.gender}
                    </p>
              </td>
              <td class="px-5 py-5 border-b border-gray-200 bg-white text-sm">
                    <p class="text-gray-900 break-all">
                    {category?.father_name}
                    </p>
              </td>
              <td class="px-5 py-5 border-b border-gray-200 bg-white text-sm">
                    <p class="text-gray-900 break-all">
                    {category?.mother_name}
                    </p>
              </td>
              <td class="px-5 py-5 border-b border-gray-200 bg-white text-sm">
                    <p class="text-gray-900 break-all">
                    {category?.guardian_name}
                    </p>
              </td>
           
    
              <td class="px-5 py-5 border-b border-gray-200 bg-white text-sm">
              <div className='flex gap-2 items-center'>        
              <DeleteAtheleteForm setpage={setpage} page={page} pageSize={pageSize} athleteformdetailsid={category?.athlete_form_id}  />
    </div>
    </td>
            
            </tr>
                      ))
        }
            
          </tbody>
        </table>
      </div>
    </div>:<div className="flex justify-center items-center h-[70vh] w-full">
              <span className="text-center w-full text-xl text-[#7b8794]">
                No Athlete form found
              </span>
            </div>
        }
        <div className="flex justify-center w-full p-4 mb-4">
        <Pagination
        className="pagination-bar"
        currentPage={page}
        totalCount={TotalAtheleteForm} // Assuming 10 items per page
        pageSize={pageSize}
                  onPageChange={(newPage) => { 
                    setpage(newPage)
                      var currentScroll = document.documentElement.scrollTop || document.body.scrollTop;
                        if (currentScroll > 0) {
                          window.scrollTo(0, currentScroll - (currentScroll / 1));
                        }
                  }
                   }
      />    
        </div>
      </div>
    </div>
      </div>
     </Template>
    )
  }
  
  export default Athleteform_index
  