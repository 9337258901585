
import React, { useEffect } from 'react'
import  { useState } from 'react'
import { useFormik } from 'formik';
import * as Yup from "yup";
import Files from "react-files";
import toast from 'react-hot-toast';
import axiosConfig from '../../../../axios_config/axios_instance';
import { ReactComponent as UploadIcon } from "../../../../assets/icons/uploadicon.svg";
import { useDispatch, useSelector } from 'react-redux';
import {  useNavigate, useParams } from "react-router-dom";
import {ReactComponent as ArrowLeft} from "../../../../assets/icons/arrowlefticon.svg"
import { FiLoader } from "react-icons/fi";
import { getAllCityList, getAllCityListAllModule, getAllColoBeltAtheleteList, getAllColorBeltAtheleteDetils, getAllOtaClubList } from '../../../../services/apiService';
import moment from 'moment';
import Template from '../../../../components/common/Template';

const EditColorBeltAthelete = ({colorbeltdetails, city_id, page}) => {
  const [showModal, setShowModal] = useState(false)
  const [loading, setLoading]=useState(false)

  const {OtaClubList}=useSelector((state)=>state.otaclub)

  const { ColorBeltAtheleteList } = useSelector(
    (state) => state.colorbeltathelete
  );

  const dispatch =useDispatch()
  const navigate= useNavigate()
  const { color_belt_id } = useParams();
  
  const options = OtaClubList.map(({ ota_club_id, club_name }) => ({
    value: ota_club_id,
    label: club_name,
  }));

  const {AllCityListModule}=useSelector((state)=>state.citylist)
  const {ColorAtheleteDetails}=useSelector((state)=>state.colorbeltathelete)

  const optionss = AllCityListModule.map(({ city_id, city_name }) => ({
    value: city_id,
    label: city_name,
  }));

  useEffect(()=>{
    dispatch(getAllColorBeltAtheleteDetils(color_belt_id))
  },[dispatch, color_belt_id])



  useEffect(()=>{
    dispatch(getAllCityListAllModule())
  },[dispatch])

  const genderOptions = [
    {
      value: "male",
      label: "male",
    },
    {
      value: " female",
      label: " female",
    },
    {
      value: "other",
      label: "other",
    },
  ];


  
  const validationSchema = Yup.object().shape({
    otaidnumber:Yup.string().required("ota number is required"),
    atheletename:Yup.string().required("athelete name is required"),
    gender:Yup.string().required("gender number is required"),
    clubname:Yup.string().required("club is required"),
    fathername:Yup.string().required("father name is required"),
    mothername:Yup.string().required("mother is required"),
    otaregistereddate:Yup.string().required("date is required")
    });
  
    const {
      values,
      handleChange,
      handleSubmit,
      errors,
      touched,
      setFieldValue,
      handleBlur,
      resetForm
    } = useFormik({
      initialValues: {
        otaidnumber: "",
        atheletename:"",
        gender:"",
        clubname:"",
        fathername:"",
        mothername:"",
        cityname:"",
        otaregistereddate:"",
        dateofjoining:"",
        address:"",
      },
      validationSchema: validationSchema,
      validateOnChange: true,
      enableReinitialize:true,
      validateOnBlur: false,
      
      onSubmit: async (values) => {
        try {
          const formdata= new FormData()
          setLoading(true)
          formdata.append("otaidnumber", ColorAtheleteDetails?.id_number );
          formdata.append("athlete_name", values?.atheletename);
          formdata.append("gender", values?.gender)
          formdata.append("club_name", OtaClubList?.find((otaclub)=>otaclub?.ota_club_id)?.club_name);
          formdata.append("club_id", values.clubname);
          formdata.append("city_name", AllCityListModule?.find((city)=>city?.city_id)?.city_name);
          formdata.append("city_id", values.cityname); 
          formdata.append("father_name", values?.fathername)
          formdata.append("color_belt_id", color_belt_id)
          formdata.append("mother_name", values?.mothername)
          formdata.append("image", values?.image)
          formdata.append("address",values?.address)
          const formatedDated = moment(values.dateofjoining).format("DD-MM-YYYY");
          formdata.append("doj",formatedDated)
          const formatedDate = moment(values.otaregistereddate).format("DD-MM-YYYY");
          formdata.append("dob",formatedDate);
    

          const {data}= await axiosConfig.put("/color-belt-edit", formdata);
          if(data?.data?.status === "success"){
            toast.success("Color Belt Updated Successfully")
            resetForm();
            navigate("/colorbeltathelet")

          }
         } catch (error) {
            toast.error("something Went wrong")
         }finally{
          setLoading(false)
         }
      },
      
    });

    useEffect(()=>{
      if(values.cityname){
        dispatch(getAllOtaClubList(values.cityname))
      }
    },[dispatch,values.cityname ])

    useEffect(()=>{
      if(color_belt_id){
        if(ColorAtheleteDetails){
          setFieldValue("otaidnumber",ColorAtheleteDetails?.id_number)
          setFieldValue("atheletename",ColorAtheleteDetails?.athlete_name)
          setFieldValue("gender",ColorAtheleteDetails?.gender)
          setFieldValue("image",ColorAtheleteDetails?.card)
          setFieldValue("clubname", ColorAtheleteDetails?.club_id);
          setFieldValue("cityname",ColorAtheleteDetails?.city_id )
          setFieldValue("fathername",ColorAtheleteDetails?.father_name )
          setFieldValue("mothername",ColorAtheleteDetails?.mother_name )
          setFieldValue("address",ColorAtheleteDetails?.address)
          setFieldValue("dateofjoining", moment(ColorAtheleteDetails?.doj, "DD-MM-YYYY").format("YYYY-MM-DD"))
          setFieldValue("otaregistereddate", moment(ColorAtheleteDetails?.dob, "DD-MM-YYYY").format("YYYY-MM-DD"));

        }
      } 
    },[color_belt_id,ColorAtheleteDetails])

    const closeModal = (e) => {
      e?.target?.classList?.forEach((classes) => {
        if (classes === "Modal-Container") {
          setShowModal(false);
          resetForm();
        }
      });
    };
  
    const handleImageChange = (files) => {
      if (files.length) {
        setFieldValue("image",files[0]);
      } else {
        toast.error("No image selected. Please upload an image.");
      }
    };
  
      


    const customStyles = {
      option: (provided, state) => ({
        ...provided,
        cursor: "pointer",
        fontSize: "14px",
      }),
      control: (provided) => ({
        ...provided,
        cursor: "pointer",
        fontSize: "14px",
      }),
      singleValue: (provided) => ({
        ...provided,
        fontSize: "14px",
      }),
      placeholder: (provided) => ({
        ...provided,
        fontSize: "14px",
      }),
    };
  
  return (
    <Template>
    <div
   
      className="flex justify-start cursor-pointer  flex-grow-0 flex-shrink-0 gap-3 "
    > 
    <div className="flex justify-start bg-[#e8e8f1] w-full items-center self-stretch flex-grow-0 flex-shrink-0 gap-2 px-4 max-md:py-2 py-4">
    <div className="flex justify-between max-md:justify-between flex-row flex-grow relative ">
    <div className='flex max-md:flex gap-3 items-center '>
      <ArrowLeft  onClick={()=>navigate("/colorbeltathelet")} className='cursor-pointer max-md:  w-4 h-4'/>
      <p className="flex-grow-0 flex-shrink-0 text-2xl max-md:text-[18px] font-semibold text-left capitalize text-[#202223]">
       Edit Color Belt Athelete
      </p>
      </div>
      <div className="flex justify-start items-start   flex-grow-0 flex-shrink-0 gap-2 self-end">
        <div
       
        className="flex justify-start items-center flex-grow-0 flex-shrink-0 relative overflow-hidden gap-1 pl-4 pr-5 py-[11px] rounded-[94px] bg-[#fff] cursor-pointer"
        style={{ boxShadow: "0px 1px 0px 0 rgba(0,0,0,0.08)" }}
      >
        <p className="flex-grow-0 flex-shrink-0 text-sm font-medium text-center text-black">
          Cancel
        </p>
      </div>
      <button
      type="button"
      className="flex justify-start items-center flex-grow-0 flex-shrink-0 relative overflow-hidden gap-1 px-5 py-[11px] rounded-[94px] bg-[#186ced] cursor-pointer"
      style={{ boxShadow: "0px 1px 0px 0 rgba(0,0,0,0.08)" }}
      onClick={handleSubmit}
      disabled={loading} // Disable the button when loading
    >
      {loading ? (
        <div className="spinner-border text-light" role="status">
          <span className="visually-hidden"><FiLoader /></span>
        </div>
      ) : (
        <p className="flex-grow-0 flex-shrink-0 text-sm font-medium text-center text-white">
          Save
        </p>
      )}
    </button>
        </div>
    </div>
  </div>

    </div>
    <div className="bg-zinc-50 self-stretch flex pb-0 flex-row items-stretch mt-2.5 pt-2 px-10 max-md:max-w-full max-md:px-5">
    <div className="flex flex-row justify-start items-start self-stretch flex-grow-0 flex-shrink-0 ">
      
      <div className="flex flex-row justify-start items-start flex-grow-0 flex-shrink-0 gap-10 flex-wrap">
        <div>
        <h2 className="text-sm font-semibold text-[#202223]">
        Add Color Belt Image
        <span className=""></span>
        </h2>    
        <Files
        className="files-dropzone cursor-pointer  "
        onChange={(file) => handleImageChange(file)}
        onError={(error) => toast.error(error?.message)}
        accepts={[".jpg", "jpeg"]}
        maxFileSize={512000} 
        minFileSize={0}
        multiple
        clickable
        
      >
        {values.image ? (
          <div className="flex flex-col items-center relative">
            <img
              src={
                typeof values.image === "object"
                  ? values.image?.preview?.url
                  : values.image
              }
              className="h-32 w-[140px] object-cover"
            />
          </div>
        ) : (
          <div className="flex flex-col justify-center items-center flex-grow-0 flex-shrink-0 h-[122px] w-[122px] relative gap-1 px-[23.5px] py-7 rounded bg-[#186ced]/[0.04] border-[1.33px] border-[#186ced]/[0.64] border-dashed">
            <UploadIcon />
            <div className="flex justify-start items-center flex-grow-0 flex-shrink-0 relative gap-1 px-[5px] py-0.5 rounded">
              <p className="flex-grow-0 flex-shrink-0 text-xs font-medium text-center text-black/[0.64]">
                Upload
              </p>
            </div>
          </div>
        )}
      </Files>
      <p className="text-[12px] text-left text-black/80 font-semibold text-[#202223] relative after:content-['*'] after:ml-0.5 after:text-red-500">max upto 500kb</p>

        </div>
  
        <div>
   
        </div>
      </div>
    </div>
  </div>
  
      <div className="pr-1.5 max-md:max-w-full p-4 mx-4">
        <p className='text-lg font-sans font-medium after:content-["*"] after:ml-0.3 mt-2 after:text-red-500'>Ota Id No.</p>
        <div className="flex items-center ">
       
        <input
        name="otaidnumber"
        type="number"
        value={values.otaidnumber}
        onChange={handleChange}
        className="form-input  p-2 w-[60%] max-md:w-full h-10 rounded-md   border-[#B2B5C4] border-[1px] focus:bg-white focus:ring-0 placeholder:text-sm"
        placeholder="Ota Id No"
        style={
          errors.otaidnumber && touched.otaidnumber
            ? { border: "1px solid red" }
            : {}
        }
      />
        </div>


        <p className='text-black text-xl font-medium leading-10 mt-3 whitespace-nowrap max-md:max-w-full after:content-["*"] after:ml-0.5 after:text-red-500'>
        Athelete Name
        </p>
        <div className="flex items-center ">
        <input
        name="atheletename"
        type="text"
        value={values.atheletename}
        onChange={handleChange}
        className="form-input  p-2 w-[60%] max-md:w-full h-10 rounded-md   border-[#B2B5C4] border-[1px] focus:bg-white focus:ring-0 placeholder:text-sm"
        placeholder="Athelete name"
        style={
          errors.atheletename && touched.atheletename
            ? { border: "1px solid red" }
            : {}
        }
      />
        </div>

        <p className='text-black text-xl font-medium leading-10 whitespace-nowrap mt-3 max-md:max-w-full after:content-["*"] after:ml-0.5 after:text-red-500'>
        Gender{" "}
        </p>
        <div className="flex max-md:w-full items-center ">
        <select
    name="gender"
    onChange={handleChange}
    placeholder="Select Gender"  // Placeholder text added here
    className={
        errors.gender && touched.gender
            ? "1px solid red form-input p-2 w-[60%] max-md:w-full h-10 rounded-md border-[#B2B5C4] border-[1px] focus:bg-white focus:ring-0 placeholder:text-sm"
            : "form-input p-2 w-[60%] max-md:w-full h-10 rounded-md border-[#B2B5C4] border-[1px] focus:bg-white focus:ring-0 placeholder:text-sm"
    }
    onBlur={() => handleBlur("gender")}
    value={values.gender}
>
    <option value="" disabled hidden>Select gender</option>
    {genderOptions?.map((option) => (
        <option key={option.value} value={option.value}>
            {option?.label}
        </option>
    ))}
</select>
                                        
        </div>
        <div className="max-md:pt-5">
          
        <p className='text-black text-xl font-medium leading-10 whitespace-nowrap mt-3  max-md:max-w-full after:content-["*"] after:ml-0.5 after:text-red-500'>
        City
        </p>
        <div className="flex items-center ">
        <select
                    name="cityname"
                    onChange={(e)=>{
                      setFieldValue("clubname","");
                      handleChange(e)
                    }}
                    placeholder="Select City"
                    className={
                      errors.cityname && touched.cityname
                        ? "form-input  p-2 w-[60%] max-md:w-full h-10 rounded-md   border-[#B2B5C4] border-[1px] focus:bg-white focus:ring-0 placeholder:text-sm"
                        :  "form-input  p-2 w-[60%] max-md:w-full h-10 rounded-md   border-[#B2B5C4] border-[1px] focus:bg-white focus:ring-0 placeholder:text-sm"
                        
                    }
                    onBlur={() => handleBlur("cityname")}
                    value={values.cityname}
                  >
                    <option>Select city</option>
                    {AllCityListModule?.map((option) => (
                      <option value={option.city_id}>
                        {option?.city_name}
                      </option>
                    ))}
                  </select>
        </div>
        </div>
        <div className="max-md:pt-5">
          
        <p className='text-black text-xl font-medium leading-10 whitespace-nowrap mt-3 max-md:max-w-full after:content-["*"] after:ml-0.5 after:text-red-500'>
        Club Name
        </p>
        <div className="flex items-center ">
        <select
                    name="clubname"
                    onChange={handleChange}
                    placeholder="Select club"
                    className={
                      errors.clubname && touched.clubname
                        ?"  p-2 w-[60%] max-md:w-full h-10 rounded-md   border-[#B2B5C4] border-[1px] focus:bg-white focus:ring-0 placeholder:text-sm"
                        : "form-input  p-2 w-[60%] max-md:w-full h-10 rounded-md   border-[#B2B5C4] border-[1px] focus:bg-white focus:ring-0 placeholder:text-sm"

                    }
                    onBlur={() => handleBlur("clubname")}
                    value={values.clubname}
                    disabled={values?.cityname?"":"disable"}
                  >
                    <option>Select club</option>
                    {OtaClubList?.map((option) => (
                      <option value={option.ota_club_id}>
                        {option?.club_name}
                      </option>
                    ))}
                  </select>
        </div>
        </div>
        <div className="max-md:pt-5">
          
        <p className='text-black text-xl font-medium leading-10 whitespace-nowrap mt-3 max-md:max-w-full after:content-["*"] after:ml-0.5 after:text-red-500'>
        Father Name
                </p>
        <div className="flex items-center ">
        <input
        name="fathername"
        type="text"
        value={values.fathername}
        onChange={handleChange}
        style={
          errors.fathername && touched.fathername
            ? { border: "1px solid red" }
            : {}
        }
       className= "form-input  p-2 w-[60%] max-md:w-full h-10 rounded-md   border-[#B2B5C4] border-[1px] focus:bg-white focus:ring-0 placeholder:text-sm"        placeholder="Father name"
      />
        </div>
        </div>
        <div className="max-md:pt-5">
          
        <p className='text-black text-xl font-medium leading-10 whitespace-nowrap mt-3 max-md:max-w-full after:content-["*"] after:ml-0.5 after:text-red-500'>
        Mother Name        </p>
        <div className="flex items-center ">
        <input
        name="mothername"
        type="text"
        value={values.mothername}
        onChange={handleChange}
        className= "form-input  p-2 w-[60%] max-md:w-full h-10 rounded-md   border-[#B2B5C4] border-[1px] focus:bg-white focus:ring-0 placeholder:text-sm" 
        placeholder="Mother name"
        style={
          errors.mothername && touched.mothername
            ? { border: "1px solid red" }
            : {}
        }
      />
        </div>
        </div>
        <div className="max-md:pt-5">
          
        <p className='text-black text-xl font-medium leading-10 whitespace-nowrap mt-3 max-md:max-w-full '>
             Address 
        </p>
        <div className="flex items-center ">
        <input
        name="address"
        type="text"
        value={values.address}
        onChange={handleChange}
        className= "form-input  p-2 w-[60%] max-md:w-full h-10 rounded-md   border-[#B2B5C4] border-[1px] focus:bg-white focus:ring-0 placeholder:text-sm" 
        placeholder="Address"
        style={
          errors.address && touched.address
            ? { border: "1px solid red" }
            : {}
        }
      />
        </div>
        </div>
        <div className="max-md:pt-5">
          
        <p className='text-black text-xl font-medium leading-10 whitespace-nowrap mt-3 max-md:max-w-ful'>
          Date of joining 
        </p>
        <div className="flex items-center ">
        <input
        value={values.dateofjoining}
        name="dateofjoining"
        placeholder="DD-MM-YYYY"
        className={`${
          errors.dateofjoining &&
          touched.dateofjoining
            ? "border-red-500 form-input  p-2 w-[60%] max-md:w-full h-10 rounded-md    border-[1px] focus:bg-white focus:ring-0 placeholder:text-sm"
            :  "form-input  p-2 w-[60%] max-md:w-full h-10 rounded-md   border-[#B2B5C4] border-[1px] focus:bg-white focus:ring-0 placeholder:text-sm"        

            
        } form-input  p-2 w-[60%] max-md:w-full h-10 rounded-md   border-[#B2B5C4] border-[1px] focus:bg-white focus:ring-0 placeholder:text-sm`}
        onChange={handleChange}
     
        type='date'
      />
        </div>
        </div>
        <div className="max-md:pt-5">
          
        <p className='text-black text-xl font-medium leading-10 whitespace-nowrap mt-3 max-md:max-w-full after:content-["*"] after:ml-0.5 after:text-red-500'>
        Date
        </p>
        <div className="flex items-center ">
        <input
                  value={values.otaregistereddate}
                  name="otaregistereddate"
                  placeholder="DD-MM-YYYY"
                  className={`${
                    errors.otaregistereddate &&
                    touched.otaregistereddate
                      ? "border-red-500 form-input  p-2 w-[60%] max-md:w-full h-10 rounded-md    border-[1px] focus:bg-white focus:ring-0 placeholder:text-sm"
                      :  "form-input  p-2 w-[60%] max-md:w-full h-10 rounded-md   border-[#B2B5C4] border-[1px] focus:bg-white focus:ring-0 placeholder:text-sm"        

                      
                  } form-input  p-2 w-[60%] max-md:w-full h-10 rounded-md   border-[#B2B5C4] border-[1px] focus:bg-white focus:ring-0 placeholder:text-sm`}
                  onChange={handleChange}
               
                  type='date'
                />
        </div>
        </div>
      
      
        
      </div>

      </Template>
  )
}

export default EditColorBeltAthelete
