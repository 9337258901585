import { createSlice } from "@reduxjs/toolkit";

const NewEventResult = createSlice({
    name: "eventresult",
    initialState: {
        EventResultLoading: false,
        EventResultList:[],
        page:1,
        total_pages:0,
        selectedTabEvent:0,
        totalEventResult:0
      
    },
    reducers: {
         getEventResultRequest:(state)=>{
            state.EventResultLoading=true;
         },
         getEventResultSuccess:(state, {payload})=>{
            state.EventResultLoading=false;
            state.EventResultList=payload?.event_result_List
            state.totalEventResult=payload?.total_elements
         },
         getEventResultFail:(state)=>{
            state.EventResultLoading=false;
            state.EventResultList=[];
         },
         setSelectedTabEvent:(state,{payload})=>{
            state.selectedTabEvent=payload
        },
        setEventResultPage:(state,{payload})=>{
         state.page=payload
      },
     setTotalPageEventResult:(state,{payload})=>{
         state.total_pages=payload
     }
    }
})  

export default NewEventResult.reducer;
export const {getEventResultFail, getEventResultRequest, getEventResultSuccess,setSelectedTabEvent,setEventResultPage,setTotalPageEventResult}= NewEventResult.actions