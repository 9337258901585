import React, { useEffect, useState } from "react";
import toast from "react-hot-toast";
import { useFormik } from "formik";
import * as Yup from "yup";
import Files from "react-files";
import { ReactComponent as UploadIcon } from "../../../assets/icons/uploadicon.svg";
import Select from "react-select";
import axiosConfig from "../../../axios_config/axios_instance";
import { useDispatch, useSelector } from "react-redux";
import {
  getAllCityList,
  getAllCityListAllModule,
  getAllMedalistList,
} from "../../../services/apiService";
import { FiLoader } from "react-icons/fi";
import moment from "moment";
import { setSelectedMedalistProfileTab } from "../../../redux/slice/MedalistProfileSlice";
const AddMedalist = ({pageSIze}) => {
  const [showModal, setShowModal] = useState(false);
  const dispatch = useDispatch();
  const [isLoading, setIsLoading]=useState(false)

  const statusOptions = [
    { label: "international", value: "international" },
    { label: "national", value: "national" },
    { label: "state", value: "state" },
  ];
  const {selectedTab}=useSelector((state)=>state.medalistprofile);
  const {AllCityListModule}=useSelector((state)=>state.citylist)

  const options = AllCityListModule.map(({ city_id, city_name }) => ({
    value: city_id,
    label: city_name,
  }));

  useEffect(() => {
    dispatch(getAllCityListAllModule());
  }, [dispatch]);

  const validationSchema = Yup.object().shape({
    category_division: Yup.string().required("category is required"),
    image: Yup.mixed().required("file required"),
    event: Yup.string().required("event is required"),
    playername: Yup.string().required("player is required"),
    tournament_name: Yup.string().required("tournament name is required"),
    venue: Yup.string().required(" event is required"),
    result: Yup.string().required("result is required"),
    event_date: Yup.string().required("event date  is required"),
    medaltype: Yup.string().required("event is required"),
    cityname: Yup.string().required("city name is required"),
  });

  const {
    values,
    handleChange,
    handleSubmit,
    errors,
    touched,
    setFieldValue,
    handleBlur,
    resetForm,
  } = useFormik({
    initialValues: {
      image: null,
      event: "",
      playername: "",
      tournament_name: "",
      venue: "",
      result: "",
      cityname: "",
      event_date: "",
      medaltype: "",
    },
    validationSchema: validationSchema,
    validateOnChange: true,
    validateOnBlur: false,
    onSubmit: async (values) => {
      try {
        const formdata = new FormData();
        setIsLoading(true)
        formdata.append("category_or_division", values?.category_division);
        if(!values?.image){
          toast.error("upload a image")
          return ;
        }
        formdata.append("image", values?.image);
        formdata.append("event", values?.event);
        formdata.append("player_name", values?.playername);
        formdata.append("tournament_name", values?.tournament_name);
        formdata.append("venue", values?.venue);
        const formatMedalistdate = moment(values.event_date).format(
          "DD-MM-YYYY"
        );
        formdata.append("event_year_or_date", formatMedalistdate);
        formdata.append(
          "city_name",
          AllCityListModule?.find((city) => city?.city_id)?.city_name
        );
        formdata.append("city_id", values.cityname);
        formdata.append("medal_type", values?.medaltype);
        formdata.append("results", values?.result);
        const { data } = await axiosConfig.post("/save-ota-medalist", formdata);
        if (data?.status === "success") {
          toast.success("Medalist Added successfully");
          
          if (values.medaltype === "international") {
            dispatch(setSelectedMedalistProfileTab(0))
            dispatch(getAllMedalistList(0,10,"international"));
          } else if (values.medaltype === "national") {
          dispatch(setSelectedMedalistProfileTab(1))
            dispatch(getAllMedalistList(0,10,"national"));
          } else if (values.medaltype === "state") {
          dispatch(setSelectedMedalistProfileTab(2))
            dispatch(getAllMedalistList(0,10,"state"));
          }



          setShowModal(false)
        resetForm()
        }else {
          toast.error("Something went wrong..!");
          setIsLoading(false);
        }
      } catch (error) {
        toast.error("something Went wrong");
      }finally{
        setIsLoading(false)
      }
    },
  });
  const closeModal = (e) => {
    e?.target?.classList?.forEach((classes) => {
      if (classes === "Modal-Container") {
        setShowModal(false);
        resetForm();
      }
    });
  };

  const handleImageChange = (files) => {
    if (files.length) {
      setFieldValue("image", files[0]);
    } else {
      toast.error("No image selected. Please upload an image.");
    }
  };

  const customStyles = {
    option: (provided, state) => ({
      ...provided,
      cursor: "pointer",
      fontSize: "14px",
    }),
    control: (provided) => ({
      ...provided,
      cursor: "pointer",
      fontSize: "14px",
    }),
    singleValue: (provided) => ({
      ...provided,
      fontSize: "14px",
    }),
    placeholder: (provided) => ({
      ...provided,
      fontSize: "14px",
    }),
  };

  return (
    <div>
      <div
        onClick={(e) => {
          e.stopPropagation();
          setShowModal(true);
        }}
        className="flex justify-start cursor-pointer relative items-center flex-grow-0 flex-shrink-0 gap-3"
      >
        <div
          className="flex justify-start items-center flex-grow-0 flex-shrink-0 relative overflow-hidden gap-1 pl-4 pr-5 max-md:py-[7px] py-[10px] rounded-full bg-[#186ced]"
          style={{ boxShadow: "0px 1px 0px 0 rgba(0,0,0,0.08)" }}
        >
          <p className="flex-grow-0 flex-shrink-0 text-sm font-medium text-center text-white">
            Add Medalist
          </p>
        </div>
      </div>
      {showModal ? (
        <>
          <div
            className="flex justify-center items-center overflow-x-hidden fixed inset-0 z-50 outline-none focus:outline-none shadow-lg w-full Modal-Container"
            onClick={closeModal}
            style={{ backdropFilter: "blur(1px)" }} 
          >
            <div className="relative w-auto my-6 mx-auto  max-w-4xl">
              <div className="border-0 rounded-lg shadow-lg relative flex w-full bg-white outline-none focus:outline-none">
                <div className="flex flex-col p-2 w-[40%] border-b border-solid border-gray-300 rounded-t">
                  <h3 className="text-lg  font-semibold mt-2">Upload Medlist</h3>
                  <p className="text-[12px] text-left text-black/80 font-semibold text-[#202223] relative after:content-['*'] after:ml-0.5 after:text-red-500">max upto 1mb</p>

                  <Files
                    className="files-dropzone cursor-pointer "
                    onChange={(file) => handleImageChange(file)}
                    onError={(error) => toast.error(error?.message)}
                    accepts={[".png"]}
                    maxFileSize={1048576}
                    minFileSize={0}
                    multiple
                    clickable
                 
                  >
                    {values.image ? (
                      <div className="flex flex-col items-center relative ">
                        <img
                          src={
                            typeof values.image === "object"
                              ? values.image?.preview?.url
                              : values.image
                          }
                          className="h-52 w-[200px] object-cover"
                        />
                      </div>
                    ) : (
                      <div className="flex flex-col justify-center items-center flex-grow-0 flex-shrink-0 h-[122px] w-[122px] relative gap-1 px-[23.5px] py-7 rounded bg-[#186ced]/[0.04] border-[1.33px] border-[#186ced]/[0.64] border-dashed">
                        <UploadIcon />
                        <div className="flex justify-start items-center flex-grow-0 flex-shrink-0 relative gap-1 px-[5px] py-0.5 rounded">
                          <p className="flex-grow-0 flex-shrink-0 text-xs font-medium text-center text-black/[0.64]">
                            Upload
                          </p>
                        </div>
                      </div>
                    )}
                  </Files>
                  <p className="text-[12px] text-left text-black/80 font-semibold text-[#202223] relative after:content-['*'] after:ml-0.5 after:text-red-500">(upload only png )</p>

                  <p></p>
                </div>
                <div className="grid grid-cols-2 p-6 pt-0">
                  <div className="flex  gap-6">
                    {/* Group 1 */}
                    <div className="flex flex-col gap-1">
                      {/* City */}

                      <div className="flex-grow-0 flex-shrink-0 w-[150px]">
                        <p className="text-[14px] leading-10 py-[1px] font-medium text-left text-black/80">
                          Category or Division
                        </p>
                      </div>
                      <div className="flex flex-col flex-grow w-[300px]">
                        <input
                          name="category_division"
                          type="text"
                          value={values.category_division}
                          onChange={handleChange}
                          className="w-full border capitalize rounded p-2 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                          placeholder="category or divison"
                          style={
                            errors.category_division && touched.category_division
                              ? { border: "1px solid red" }
                              : {}
                          }
                        />
                     
                      </div>
                      <div className="flex-grow-0 flex-shrink-0 w-[150px]">
                        <p className="text-[13px] leading-10 py-[1px] font-medium text-left text-black/80">
                          Event
                        </p>
                      </div>
                      <div className="flex flex-col flex-grow w-[300px]">
                        <input
                          name="event"
                          type="text"
                          value={values.event}
                          onChange={handleChange}
                          className="w-full border rounded capitalize p-2 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                          placeholder="event"
                          style={
                            errors.event && touched.event
                              ? { border: "1px solid red" }
                              : {}
                          }
                        />
                      
                      </div>
                      <div className="flex-grow-0 flex-shrink-0 w-[150px]">
                        <p className="text-[13px] leading-10 py-[1px] font-medium text-left text-black/80">
                          Player name
                        </p>
                      </div>
                      <div className="flex flex-col flex-grow w-[300px]">
                        <input
                          name="playername"
                          type="text"
                          value={values.playername}
                          onChange={handleChange}
                          className="w-full border rounded p-2 capitalize text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                          placeholder="player name"
                          style={
                            errors.playername && touched.playername
                              ? { border: "1px solid red" }
                              : {}
                          }
                        />
                      </div>

                      {/* President */}
                 
                      {/* Secretary */}
                      <div className="flex-grow-0 flex-shrink-0 w-[150px]">
                        <p className="text-[13px] leading-10 py-[1px] font-medium text-left text-black/80">
                          Tournament Name
                        </p>
                      </div>
                      <div className="flex flex-col  w-[300px]">
                        <input
                          name="tournament_name"
                          type="text"
                          value={values.tournament_name}
                          onChange={handleChange}
                          className="w-full border rounded p-2 capitalize text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                          placeholder="tournament name"
                          style={
                            errors.tournament_name && touched.tournament_name
                              ? { border: "1px solid red" }
                              : {}
                          }
                        />
                      </div>
                   
                      <div className="flex-grow-0 flex-shrink-0 w-[150px]">
                        <p className="text-[13px] leading-10 py-[1px] font-medium text-left text-black/80">
                          venue
                        </p>
                      </div>
                      <div className="flex flex-col  w-[300px]">
                        <input
                          name="venue"
                          type="text"
                          value={values.venue}
                          onChange={handleChange}
                          className="w-full border rounded p-2 capitalize text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                          placeholder="venue"
                          style={
                            errors.venue && touched.venue
                              ? { border: "1px solid red" }
                              : {}
                          }
                        />
                      </div>
                    </div>
                    {/* Group 2 */}
                    <div className="flex flex-col gap-1">
                      {/* Treasurer */}
                      <div className="flex-grow-0 flex-shrink-0 w-[150px]">
                        <p className="text-[13px] leading-10 py-[1px] font-medium text-left text-black/80">
                          Result
                        </p>
                      </div>
                      <div className="flex flex-col  w-full">
                        <input
                          name="result"
                          type="text"
                          value={values.result}
                          onChange={handleChange}
                          className="w-full border rounded p-2 capitalize text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                          placeholder="result"
                          style={
                            errors.result && touched.result
                              ? { border: "1px solid red" }
                              : {}
                          }
                        />
                      </div>
                      {/* Address */}
                      <div className="flex-grow-0 flex-shrink-0 w-[150px]">
                      <p className="text-[13px] leading-10 py-[1px] font-medium text-left text-black/80">
                        District
                      </p>
                    </div>
                    <div className="flex flex-col  w-[300px]">
                      <select
                        name="cityname"
                        onChange={(e) => {
                          setFieldValue("clubname", "");
                          handleChange(e);
                        }}
                        placeholder="Select City"
                        className={
                          errors.cityname && touched.cityname
                            ?  "border-red-500 custom-select" 
                            : "custom-select capitalize"
                        }
                        style={
                          errors.cityname && touched.cityname
                            ? { border: "1px solid red" }
                            : {}
                        }
                        onBlur={() => handleBlur("cityname")}
                        value={values.cityname}
                      >
                        <option>Select City</option>
                        {AllCityListModule?.map((option) => (
                          <option value={option.city_id}>
                            {option?.city_name}
                          </option>
                        ))}
                      </select>
                    </div>
                      <div className="flex-grow-0 flex-shrink-0 w-[300px]">
                        <p className="text-[13px] leading-10 py-[1px] font-medium text-left text-black/80">
                          Event date
                        </p>
                      </div>
                      <div className="flex flex-col  w-full">
                        <input
                          value={values.event_date}
                          name="event_date"
                          placeholder="DD-MM-YYYY"
                          className={`${
                            errors.event_date && touched.event_date
                              ? "border-red-500"
                              : "border-gray-300"
                          } w-full h-10 rounded-md capitalize bg-gray-100 border-transparent focus:border-gray-500 focus:bg-white focus:ring-0 placeholder:text-sm  px-3`}
                          onChange={handleChange}
                          style={
                            errors.medaltype && touched.medaltype
                              ? { border: "1px solid red" }
                              : {}
                          }
                          type="date"
                        />
                      </div>
                      <div className="flex-grow-0 flex-shrink-0 w-[150px]">
                      <p className="text-[13px] leading-10 py-[1px] font-medium text-left text-black/80">
                        Medal Type
                      </p>
                    </div>
                    <div className="flex flex-col flex-grow w-[300px]">
                      <select
                        name="medaltype"
                        onChange={handleChange}
                        className={
                          errors.medaltype && touched.medaltype
                            ? "border-red-500 custom-select capitalize"
                            : "custom-select capitalize"
                        }
                        onBlur={() => handleBlur("medaltype")}
                        value={values.medaltype}
                        style={
                          errors.medaltype && touched.medaltype
                            ? { border: "1px solid red" }
                            : {}
                        }
                      >
                        <option>Select</option>
                        {statusOptions?.map((option) => (
                          <option value={option.label}>{option.label}</option>
                        ))}
                      </select>
                    </div>
                    
                      {/* Email */}
                    </div>
                    {/* Group 3 */}
                    <div className="flex flex-col gap-1"></div>
                  </div>
                </div>
                <div className="flex justify-start items-start p-2 flex-grow-0 flex-shrink-0 gap-2 self-end">
                  <div
                    onClick={(e) => {
                      e.stopPropagation();
                      setShowModal(false);
                      resetForm()
                    }}
                    className="flex justify-start items-center flex-grow-0 flex-shrink-0  relative overflow-hidden gap-1 pl-4 pr-5 py-[11px] rounded-[94px] bg-[#fff] cursor-pointer"
                    style={{ boxShadow: "0px 1px 0px 0 rgba(0,0,0,0.08)" }}
                  >
                    <p className="flex-grow-0 flex-shrink-0 text-sm font-medium text-center text-black">
                      Cancel
                    </p>
                  </div>
                  <button
                  type="button"
                  className="flex justify-start items-center flex-grow-0 flex-shrink-0 relative overflow-hidden gap-1 px-5 py-[11px] rounded-[94px] bg-[#186ced] cursor-pointer"
                  style={{ boxShadow: "0px 1px 0px 0 rgba(0,0,0,0.08)" }}
                  onClick={handleSubmit}
                  disabled={isLoading} // Disable the button when loading
                >
                  {isLoading ? (
                    <div className="spinner-border text-light" role="status">
                      <span className="visually-hidden"><FiLoader /></span>
                    </div>
                  ) : (
                    <p className="flex-grow-0 flex-shrink-0 text-sm font-medium text-center text-white">
                      Save
                    </p>
                  )}
                </button>
                </div>
              </div>
            </div>
          </div>
        </>
      ) : null}
    </div>
  );
};

export default AddMedalist;
