import { useFormik } from 'formik';
import React, { useState } from 'react'
import * as Yup from "yup";
import { useDispatch } from 'react-redux';
import { PencilIcon } from '@heroicons/react/outline';
import { getColorBeltHeadings } from '../../services/api';
import toast from 'react-hot-toast';
import axiosConfig from '../../axios_config/axios_instance';


const EditColorBeltHeading = ({details}) => {
    const [showModal, setShowModal] = useState(false);
    const [isLoading, setisLoading] = useState(false)

  //intialData for formik
  const initialValues = {
    heading: details?.heading,
  };

  //Yup validator Schema
  const EditInformationSchema = Yup.object({
    heading: Yup.string()
      .trim()
      .required("required")
      .min(20, "Too Short")
      .max(200, "Too Long"),
  });
    
    const dispatch=useDispatch()

  //formik validation
  const {
    values,
    handleBlur,
    resetForm,
    handleChange,
    handleSubmit,
    setFieldValue,
    errors,
    touched,
  } = useFormik({
    initialValues,
    validationSchema: EditInformationSchema,
    validateOnChange: true,
    enableReinitialize: true,
    validateOnBlur: false,
    onSubmit: async(values, action) => {
      try {
        const formdata = new FormData();
        formdata.append("heading", values.heading);
        formdata.append("color_belt_test_id", details.color_belt_test_id);
        setisLoading(true)
        const { data } = await axiosConfig.put(`/color-belt-test-edit`, formdata);
        if (data?.data?.status === "success") {
          toast.success(data?.data?.message);
          setShowModal(false);
          dispatch(getColorBeltHeadings())
          setisLoading(false)
        } else {
          toast.error(data?.data?.message);
          setisLoading(false)
        }
    } catch (error) {
      toast.error("Something went wrong");
      setisLoading(false)
    }
    },
  });

  const closeModal = (e) => {
      e?.target?.classList?.forEach((classNamees) => {
      if (classNamees === "Modal-Container") {
        setShowModal(false);
        resetForm();
      }
    });
  };

  return (
    <div>
{details?.is_active &&    <div
    onClick={(e) => {
      e.stopPropagation();
      setShowModal(true);
    }}
    className="flex justify-start cursor-pointer relative items-center flex-grow-0 flex-shrink-0 gap-3"
  >
    <div
      className="flex justify-start items-center flex-grow-0 flex-shrink-0 relative overflow-hidden gap-1"
              >
                  <PencilIcon className='h-4 w-4 cursor-pointer' />
    </div>
      </div>}
{!details?.is_active &&      <div
    className="flex justify-start cursor-pointer relative items-center flex-grow-0 flex-shrink-0 gap-3"
  >
    <div
      className="flex justify-start items-center flex-grow-0 flex-shrink-0 relative overflow-hidden gap-1"
              >
    </div>
  </div>}
  {showModal ? (
    <>
      <div
        className="flex justify-center items-center overflow-x-hidden overflow-y-auto fixed inset-0 z-50 outline-none focus:outline-none shadow-lg w-full Modal-Container"
        onClick={closeModal}
        style={{ backdropFilter: "blur(1px)" }} 

      >
        <div className="relative w-auto my-6 mx-auto max-w-3xl">
          <div className="border-0 rounded-lg shadow-lg relative flex w-full bg-white outline-none focus:outline-none p-6">
            <div className="flex items-start gap-1 p-2 border-b border-solid border-gray-300 rounded-t flex-col ">
              <h3 className="text-xl font=semibold">
                Edit Color Belt Heading 
              </h3>
                                  <div className="flex justify-between items-start">
                <div className="relative p-6 pt-0 flex-auto">
                  <div className="flex flex-col justify-start items-start self-stretch flex-grow-0 flex-shrink-0 gap-1">
                    <div className="flex justify-start items-start self-stretch flex-grow-0 flex-shrink-0 relative gap-1 flex-col">
                      <div className="flex-grow-0 flex-shrink-0 w-[150px]">
                        <p className="text-[14px] leading-10 py-[1px] font-medium text-left text-black/80">
                          Heading
                        </p>
                      </div>
                      <div className="flex flex-col  w-[350px]">
                      <textarea
                                                                 className="form-input w-full rounded-md bg-gray-100 border-transparent focus:border-gray-500 focus:bg-white focus:ring-1 placeholder:text-sm h-40"
                                                                placeholder="Type Headings..."
                                                                name='heading'
                                                                value={values.heading}
                                                                style={errors.heading && touched.heading ?{border:"1px solid red"}:{}}
                                                          onChange={(e) => {
                                                              if (e.target.value?.length <= 200) {
                                                                        handleChange(e)
                                                                    }
                                                                }}
                                                                onBlur={handleBlur}
                                                      />
                                                      <div className="flex justify-between items-center">
                          {values.heading &&
                            values.heading?.length < 20 && (
                              <span className="text-red-500">
                                20 minimum
                              </span>
                            )}
                          <div className="flex">
                            <span className="text-green-500">
                              {values.heading?.length}
                            </span>
                            /<span className="text-red-500">200</span>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="flex justify-start items-start flex-grow-0 flex-shrink-0 gap-2 self-end">
                <div
                  onClick={(e) => {
                    e.stopPropagation()
                    setShowModal(false);
                  }}
                  className="flex justify-start items-center flex-grow-0 flex-shrink-0 relative overflow-hidden gap-1 pl-4 pr-5 py-[11px] rounded-[94px] bg-[#fff] cursor-pointer"
                  style={{ boxShadow: "0px 1px 0px 0 rgba(0,0,0,0.08)" }}
                >
                  <p className="flex-grow-0 flex-shrink-0 text-sm font-medium text-center text-black">
                    Cancel
                  </p>
                </div>
                <button
                  type="button"
                  className="flex justify-start items-center flex-grow-0 flex-shrink-0 relative overflow-hidden gap-1 px-5 py-[11px] rounded-[94px] bg-[#186ced] cursor-pointer"
                  style={{ boxShadow: "0px 1px 0px 0 rgba(0,0,0,0.08)" }}
                  onClick={handleSubmit}
                  disabled={isLoading?"disable":""}
                >
                  <p className="flex-grow-0 flex-shrink-0 text-sm font-medium text-center text-white">
                    Save
                  </p>
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  ) : null}
    </div>
  )
}

export default EditColorBeltHeading
