import React, { useEffect } from 'react'
import Template from '../../components/common/Template'
import { useNavigate } from 'react-router-dom'

const HomePage = () => {
  const navigate = useNavigate();
  useEffect(() => {
    navigate('/news')
  },[navigate])
  return (
    <Template>
       HomePage
    </Template>
  )
}

export default HomePage
