import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux';

import { useFormik } from 'formik';

import toast from 'react-hot-toast';
import * as Yup from "yup";
import Files from "react-files";

import { TbFileTypePdf } from "react-icons/tb";
import { ReactComponent as UploadIcon } from "../../../assets/icons/uploadicon.svg";
import axiosConfig from '../../../axios_config/axios_instance';
import {  getAllCityListAllModule, getAllExecutiveList } from '../../../services/apiService';
import { MdOutlineEdit } from "react-icons/md";
import { FiLoader } from "react-icons/fi";

import moment from 'moment';
import Select from "react-select";
const EditExecutive = ({details,page,setSelectedTemplate,city_id}) => {
  const [showModal, setShowModal] = useState(false);
  const [selectedDate1, setSelectedDate1] = useState(null);
  const [uploadedFileName, setUploadedFileName] = useState("");
  const [isLoading, setIsLoading]=useState(false)
  const dispatch=useDispatch()

  const {AllCityListModule}=useSelector((state)=>state.citylist)

  const options = AllCityListModule.map(({ city_id, city_name }) => ({
    value: city_id,
    label: city_name,
  }));

useEffect(()=>{
  dispatch(getAllCityListAllModule())
},[dispatch])
  const customStyles = {
    option: (provided, state) => ({
      ...provided,
      cursor: "pointer",
      fontSize: "14px",
    }),
    control: (provided) => ({
      ...provided,
      cursor: "pointer",
      fontSize: "14px",
    }),
    singleValue: (provided) => ({
      ...provided,
      fontSize: "14px",
    }),
    placeholder: (provided) => ({
      ...provided,
      fontSize: "14px",
    }),
  };


  const handleDateChange1 = (date) => {
    setSelectedDate1(date);
  };

  //intialData for formik
  const initialValues = {
    cityname:details?.city_id,
    executive_name:details?.name,
    image:details?.image,
    post:details?.post,
    date_executive:details?.created_at?moment(details?.created_at,"DD-MM-YYYY").format("YYYY-MM-DD"):"",
  };
  //Yup validator Schema
  const EditInformationSchema = Yup.object({
    image:Yup.mixed().required("file required"),
    cityname:Yup.string().required("city name is required"),
    post:Yup.string().required("post is required"),
    date_executive:Yup.string().required("date is required"),
    executive_name:Yup.string().required("date is required"),
  });

  //formik validation
  const {
    values,
    handleBlur,
    resetForm,
    handleChange,
    handleSubmit,
    setFieldValue,
    errors,
    touched,
  } = useFormik({
    initialValues,
    validationSchema: EditInformationSchema,
    validateOnChange: true,
    validateOnBlur: false,
    onSubmit:async (values, action) => {
      try {
        const formdata= new FormData()
        setIsLoading(false)
        if ( typeof values?.image === 'object') {
           
          formdata.append("image", values?.image)
         
         } else {
           // Handle the case when values.image is not an object (e.g., handle it according to your requirements)
         }
        formdata.append("name", values?.executive_name)
        formdata.append("id", details?.id)
        formdata.append("post", values?.post);
        const formatExecutivedate = moment(values.date_executive).format("DD-MM-YYYY");
        formdata.append("created_at",formatExecutivedate);

        formdata.append("city_name", AllCityListModule?.find((city)=>city?.city_id)?.city_name);
        formdata.append("city_id", values.cityname); //

        const {data}= await axiosConfig.put("/edit-executive-council-member", formdata);
        if(data?.status === "success"){
          toast.success(data?.message || "Executive Added successfully")
          setShowModal(false)
          resetForm()
          setSelectedTemplate([])
          dispatch(getAllExecutiveList(city_id,page-1,10))
   
        }
       } catch (error) {
          toast.error("something Went wrong")
       }finally{
        setIsLoading(false)
       }
    },
  });
  const handleImageChange = (files) => {
    if (files.length) {
      setFieldValue("image", files[0]);
      setUploadedFileName(files[0].name);
    } else {
      toast.error("No image selected. Please upload an image.");
    }
  };
  const closeModal = (e) => {
    e?.target?.classList?.forEach((classes) => {
      if (classes === "Modal-Container") {
        setShowModal(false);
        resetForm();
      }
    });
  };
  return (
    <div>
    <div   
      onClick={(e) => {
      e.stopPropagation();
      setShowModal(true);
      resetForm();
    }}>
    <button
    className="flex justify-center items-center self-center flex-grow-0 flex-shrink-0 pr-3 h-[42px] gap-2.5 rounded-[5px]"
 
  >
   
  <MdOutlineEdit className="w-5 h-5" />

  </button>
    </div>
  {showModal ? (
    <>
      <div
        className="flex justify-center items-center overflow-x-hidden overflow-y-auto fixed inset-0 z-50 outline-none focus:outline-none shadow-lg w-full Modal-Container p-2"
        onClick={closeModal}
        style={{ backdropFilter: "blur(1px)" }} 
      >
        <div className="relative w-auto my-6 mx-auto max-w-3xl">
          <div className="border-0 rounded-lg shadow-lg relative flex w-full bg-white outline-none focus:outline-none">
            <div className="flex items-start gap-1 p-2 border-b border-solid border-gray-300 rounded-t flex-col ">
              <h3 className="text-xl font=semibold">
                Add executive
              </h3>
              <div className="flex justify-between items-start">
                <div className="flex flex-col mt-3">
                <p className="text-[12px] text-left text-black/80 font-semibold text-[#202223] relative after:content-['*'] after:ml-0.5 after:text-red-500">max upto 500kb</p>

                <Files
                  className="files-dropzone cursor-pointer"
                  onChange={(file) => handleImageChange(file)}
                  onError={(error) => toast.error(error?.message)}
                  accepts={[".jpg", ".jpeg"]}
                  maxFileSize={512000}
                  minFileSize={0}
                  multiple
                  clickable
                >
                    {values.image ? (
                      <div className="flex flex-col items-center relative">
                        <img
                          src={
                            typeof values.image === "object"
                              ? values.image?.preview?.url
                              : values.image
                          }
                          className="h-32 w-[140px] object-cover"
                        />
                      </div>
                    ) : (
                      <div className="flex flex-col justify-center items-center flex-grow-0 flex-shrink-0 h-[122px] w-[122px] relative gap-1 px-[23.5px] py-7 rounded bg-[#186ced]/[0.04] border-[1.33px] border-[#186ced]/[0.64] border-dashed">
                        <UploadIcon />
                        <div className="flex justify-start items-center flex-grow-0 flex-shrink-0 relative gap-1 px-[5px] py-0.5 rounded">
                          <p className="flex-grow-0 flex-shrink-0 text-xs font-medium text-center text-black/[0.64]">
                            Upload
                          </p>
                        </div>
                      </div>
                    )}
                  </Files>
                  <p className="text-[14px] text-left text-black/80 font-semibold text-[#202223] relative after:content-['*'] after:ml-0.5 after:text-red-500">(upload only jpg, jpeg)</p>
                  {/* { locationImages?.length ?  <span>{locationImages.length} selected</span>:null} */}
                </div>
                <div className="relative p-6 pt-0 flex-auto">
                  <div className="flex flex-col justify-start items-start self-stretch flex-grow-0 flex-shrink-0 gap-1">
                    <div className="flex justify-start items-start self-stretch flex-grow-0 flex-shrink-0 relative gap-1 flex-col">
                    <div className="flex-grow-0 flex-shrink-0 w-[150px]">
                    <p className="text-[14px] text-left text-black/80 font-semibold text-[#202223] relative after:content-['*'] after:ml-0.5 after:text-red-500">                      Name
                    </p>
                  </div>
                  <div className="flex flex-col  w-full">
                  <input
                  type="text"
                  className="form-input w-full capitalize  p-2 rounded-md bg-gray-100 border-transparent focus:border-gray-500 focus:bg-white focus:ring-0 placeholder:text-sm"
                  placeholder="Type here"
                  value={values.executive_name}
                  onChange={handleChange} // <-- Corrected
                  onBlur={handleBlur}
                  name="executive_name"
                  style={
                    errors.executive_name && touched.executive_name
                      ? { border: "1px solid red" }
                      : {}
                  }
                />

                  </div>
                      <div className="flex-grow-0 flex-shrink-0 w-[150px]">
                      <p className="text-[14px] text-left text-black/80 font-semibold text-[#202223] relative after:content-['*'] after:ml-0.5 after:text-red-500">                          City name
                        </p>
                      </div>
                      <div className="flex flex-col  w-full">
                      <select
                      name="cityname"
                      onChange={handleChange}
                      placeholder="Select district"
                      className={
                          errors.cityname && touched.cityname
                              ? "formErrorInput custom-select"
                              : "custom-select capitalize"
                      }
                      onBlur={() => handleBlur("cityname")}
                      value={values.cityname}
                  >
                  <option>Select district</option>
                  {
                    AllCityListModule?.map((option)=>(
                      <option value={option.city_id}>{option?.city_name}</option>
                    ))
                  }

                  </select>
                      </div>
                      <div className="flex-grow-0 flex-shrink-0 w-full">
                      <p className="text-[14px] text-left text-black/80 font-semibold text-[#202223] relative after:content-['*'] after:ml-0.5 after:text-red-500">                          Post
                        </p>
                      </div>
                      <div className="flex flex-col flex-grow w-[300px]">
                        <input
                          type="text"
                          className="form-input w-full capitalize  p-2 rounded-md bg-gray-100 border-transparent focus:border-gray-500 focus:bg-white focus:ring-0 placeholder:text-sm"
                          placeholder="Type here"
                          value={values.post}
                          onChange={handleChange} // <-- Corrected
                          onBlur={handleBlur}
                          name="post"
                          style={
                            errors.post && touched.post
                              ? { border: "1px solid red" }
                              : {}
                          }
                        />
                    
                    
                      </div>
                      <div className="flex-grow-0 flex-shrink-0 w-full">
                      <p className="text-[14px] text-left text-black/80 font-semibold text-[#202223] relative after:content-['*'] after:ml-0.5 after:text-red-500">                          Date
                        </p>
                      </div>
                      <div className="flex flex-col flex-grow w-[300px]">
             
                    <input
                    value={values.date_executive}
                    name="date_executive"
                    placeholder="DD-MM-YYYY"
                    className={`${
                      errors.date_executive &&
                      touched.date_executive
                        ? "border-red-500"
                        : "border-gray-300"
                    } w-full h-10 rounded-md capitalize  bg-gray-100 border-transparent text-black focus:border-gray-500 focus:bg-white focus:ring-0 placeholder:text-sm  px-3`}
                    onChange={handleChange}
                 
                    type='date'
                  />

                        
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="flex justify-start items-start flex-grow-0 flex-shrink-0 gap-2 self-end">
                <div
                  onClick={(e) => {
                    e.stopPropagation();
                    setShowModal(false);
                  }}
                  className="flex justify-start items-center flex-grow-0 flex-shrink-0 relative overflow-hidden gap-1 pl-4 pr-5 py-[11px] rounded-[94px] bg-[#fff] cursor-pointer"
                  style={{ boxShadow: "0px 1px 0px 0 rgba(0,0,0,0.08)" }}
                >
                  <p className="flex-grow-0 flex-shrink-0 text-sm font-medium text-center text-black">
                    Cancel
                  </p>
                </div>
                <button
                type="button"
                className="flex justify-start items-center flex-grow-0 flex-shrink-0 relative overflow-hidden gap-1 px-5 py-[11px] rounded-[94px] bg-[#186ced] cursor-pointer"
                style={{ boxShadow: "0px 1px 0px 0 rgba(0,0,0,0.08)" }}
                onClick={handleSubmit}
                disabled={isLoading} // Disable the button when loading
              >
                {isLoading ? (
                  <div className="spinner-border text-light" role="status">
                    <span className="visually-hidden"><FiLoader /></span>
                  </div>
                ) : (
                  <p className="flex-grow-0 flex-shrink-0 text-sm font-medium text-center text-white">
                    Save
                  </p>
                )}
              </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  ) : null}
    </div>
  )
}

export default EditExecutive
