import React, { useState } from 'react'
import { MdOutlineEdit } from "react-icons/md";
import { useFormik } from 'formik';
import Select from "react-select";
import toast from 'react-hot-toast';
import * as Yup from "yup";
import Files from "react-files";
import pdfImage from "../../../assets/images/pdf.png"
import { TbFileTypePdf } from "react-icons/tb";
import { ReactComponent as UploadIcon } from "../../../assets/icons/uploadicon.svg";
import axiosConfig from '../../../axios_config/axios_instance';
import { getAllEventResultList } from '../../../services/apiService';
import { useDispatch } from 'react-redux';
import { setSelectedTabEvent } from '../../../redux/slice/EventResultSlice';
import moment from 'moment';
import ReactDatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import { FiLoader } from "react-icons/fi";



const EditEventResult = ({eventResult, page}) => {
  const dispatch=useDispatch()
    const [showModal, setShowModal] = useState(false);
    const [loading, setLoading]=useState(false)
    const statusOptions = [
      { label: 'international', value: "international" },
      { label: 'national', value: "national"},
      { label: 'state', value: "state" },
    ];
  
    const customStyles = {
      option: (provided, state) => ({
        ...provided,
        cursor: "pointer",
        fontSize: "14px",
      }),
      control: (provided) => ({
        ...provided,
        cursor: "pointer",
        fontSize: "14px",
      }),
      singleValue: (provided) => ({
        ...provided,
        fontSize: "14px",
      }),
      placeholder: (provided) => ({
        ...provided,
        fontSize: "14px",
      }),
    };

    
    const EditInformationSchema = Yup.object({
      image:Yup.mixed().required("file required"),
      latest_desc: Yup.string()
        
        .required("required"),
        
        year: Yup.string().required(" Date is required"),
    });
  
    const initialValues = {
      latest_desc: eventResult?.event_name,
      year:eventResult?.year   ? moment(eventResult?.year, "YYYY").format("YYYY")
      : "",
      image:eventResult?.card,
      status:eventResult?.type
    };

    //formik validation
    const {
      values,
      handleBlur,
      resetForm,
      handleChange,
      handleSubmit,
      setFieldValue,
      errors,
      touched,
    } = useFormik({
      initialValues,
      validationSchema: EditInformationSchema,
      validateOnChange: true,
      enableReinitialize: true,
      validateOnBlur: false,
      onSubmit:async (values, action) => {
        try {
          const formdata= new FormData()
          setLoading(true)
          formdata.append("card", values?.image)
          formdata.append("event_name", values?.latest_desc)
          const formattedEventYear = moment(values?.year).format('YYYY');

          formdata.append("year", formattedEventYear);
          formdata.append("type",values?.status);
          formdata.append("event_id",eventResult?.event_result_id);

          const {data}= await axiosConfig.put("/event-result-edit", formdata);
          if(data?.data?.status === "success"){
            toast.success(data?.message || "Latest Info Added successfully")
            if (values.status === "international") {
              dispatch(setSelectedTabEvent(0))
              dispatch(getAllEventResultList(page-1,10,"international"));
            } else if (values.status === "national") {
            dispatch(setSelectedTabEvent(1))
              dispatch(getAllEventResultList(page-1,10,"national"));
            } else if (values.status === "state") {
            dispatch(setSelectedTabEvent(2))
              dispatch(getAllEventResultList(page-1,10,"state"));
            }
            setShowModal(false)
            resetForm()
        
     
          }
         } catch (error) {
            toast.error("something Went wrong")
         }finally{
          setLoading(false)
         }
      },
    });

    const handleImageChange = (files) => {
      if (files.length) {
        setFieldValue("image", files[0]);
      } else {
        toast.error("No image selected. Please upload an image.");
      }
    };
    const closeModal = (e) => {
      e?.target?.classList?.forEach((classes) => {
        if (classes === "Modal-Container") {
          setShowModal(false);
          resetForm();
        }
      });
    };

  return (
    <div>
    <div
    onClick={(e) => {
      e.stopPropagation();
      setShowModal(true);

    }}
    className="flex justify-start cursor-pointer relative items-center flex-grow-0 flex-shrink-0 gap-3"
  >
  <button
                    
  className="group/edit invisible group-hover/item:visible flex justify-center items-center flex-grow-0 flex-shrink-0 relative rounded  p-2 bg-white shadow-sm hover:bg-blue-400 transition-all cursor-pointer  hover:rounded-md text-black"
>
  <MdOutlineEdit className="w-4 h-4" />
</button>
  </div>
  {showModal ? (
    <>
      <div
        className="flex justify-center items-center overflow-x-hidden overflow-y-auto fixed inset-0 z-50 outline-none focus:outline-none shadow-lg w-full Modal-Container"
        onClick={closeModal}
        style={{ backdropFilter: "blur(1px)" }} 
      >
        <div className="relative w-auto my-6 mx-auto max-w-3xl">
          <div className="border-0 rounded-lg shadow-lg relative flex w-full bg-white outline-none focus:outline-none">
            <div className="flex items-start gap-1 p-2 border-b border-solid border-gray-300 rounded-t flex-col ">
              <h3 className="text-xl font=semibold">
                Edit Evnet
              </h3>
              <div className="flex justify-between items-start">
                <div className="flex flex-col mt-3">
                <p className="text-[12px] text-left text-black/80 font-semibold text-[#202223] relative after:content-['*'] after:ml-0.5 after:text-red-500">max upto 500kb</p>

                  <Files
                    className="files-dropzone cursor-pointer"
                    onChange={(file) => handleImageChange(file)}
                    onError={(error) => toast.error(error?.message)}
                    accepts={[".pdf"]}
                    maxFileSize={512000}
                    minFileSize={0}
                    multiple
                    clickable
                  >
                    {values.image ? (
                      <div className="flex flex-col items-center relative">
                            {
                              typeof values.image==="object"? 
                              <div className="flex flex-col justify-center items-center flex-grow-0 flex-shrink-0 h-[122px] w-[122px] relative gap-1  py-7 rounded bg-[#186ced]/[0.04] border-[1.33px] border-[#186ced]/[0.64] border-dashed px-2">
                              <DocumentIcon />
                                    <span className='break-all'>
                        {`${values?.image?.name?.split(".")[0]?.slice(0,20)}.${values?.image?.name?.split(".")[1]}`}
                        </span>
                              </div>
                :      
                <div className="flex flex-col justify-center items-center flex-grow-0 flex-shrink-0 h-[122px] w-[122px] px-2 relative gap-1  py-7 rounded bg-[#186ced]/[0.04] border-[1.33px] border-[#186ced]/[0.64] border-dashed">
                <DocumentIcon />
                                <span className='break-all'>
                {`${values?.image?.split("/")?.pop()?.split(".")[0]?.slice(0,20)}.${values?.image?.split("/")?.pop()?.split(".")[1]}`}
                        </span>
                      </div>
                }
                      </div>
                    ) : (
                      <div className="flex flex-col justify-center items-center flex-grow-0 flex-shrink-0 h-[122px] w-[122px] relative gap-1 px-[23.5px] py-7 rounded bg-[#186ced]/[0.04] border-[1.33px] border-[#186ced]/[0.64] border-dashed">
                        <UploadIcon />
                        <div className="flex justify-start items-center flex-grow-0 flex-shrink-0 relative gap-1 px-[5px] py-0.5 rounded">
                          <p className="flex-grow-0 flex-shrink-0 text-xs font-medium text-center text-black/[0.64]">
                            Upload
                          </p>
                        </div>
                      </div>
                    )}
                  </Files>
                  <p className="text-[14px] text-left text-black/80 font-semibold text-[#202223] relative after:content-['*'] after:ml-0.5 after:text-red-500">(upload only pdf)</p>
                  {/* { locationImages?.length ?  <span>{locationImages.length} selected</span>:null} */}
                </div>
                <div className="relative p-6 pt-0 flex-auto">
                  <div className="flex flex-col justify-start items-start self-stretch flex-grow-0 flex-shrink-0 gap-1">
                    <div className="flex justify-start items-start self-stretch flex-grow-0 flex-shrink-0 relative gap-1 flex-col">
                      <div className="flex-grow-0 flex-shrink-0 w-[150px]">
                      <p className="text-[14px] text-left text-black/80 font-semibold text-[#202223] relative after:content-['*'] after:ml-0.5 after:text-red-500">                          Year
                        </p>
                      </div>
                      <div className="flex flex-col  w-full">
                      <ReactDatePicker
                      selected={values.year}
                      onChange={(date) => setFieldValue("year", date)}
                      showYearPicker
                      dateFormat="yyyy"
                      className="w-full border rounded p-2 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                      placeholderText="YYYY"
                    />
                      </div>
                      <div className="flex-grow-0 flex-shrink-0 w-full">
                      <p className="text-[14px] text-left text-black/80 font-semibold text-[#202223] relative after:content-['*'] after:ml-0.5 after:text-red-500">                          Event Name
                        </p>
                      </div>
                      <div className="flex flex-col flex-grow w-[300px]">
                        <input
                          type="text"
                          className="form-input w-full capitalize   p-2 rounded-md bg-gray-100 border-transparent focus:border-gray-500 focus:bg-white focus:ring-0 placeholder:text-sm"
                          placeholder="Type here"
                          value={values.latest_desc}
                          onChange={handleChange} // <-- Corrected
                          onBlur={handleBlur}
                          name="latest_desc"
                          style={
                            errors.latest_desc && touched.latest_desc
                              ? { border: "1px solid red" }
                              : {}
                          }
                        />

                      </div>
                      <div className="flex-grow-0 flex-shrink-0 w-full">
                      <p className="text-[14px] text-left text-black/80 font-semibold text-[#202223] relative after:content-['*'] after:ml-0.5 after:text-red-500">                          Type
                        </p>
                      </div>
                      <div className="flex flex-col flex-grow w-[300px]">
                      <select
                      name="status"
                      onChange={handleChange}
                      className={
                        errors.status && touched.status
                          ? "formErrorInput custom-select "
                          : "custom-select capitalize"
                      }
                     
                      onBlur={() => handleBlur("status")}
                      value={values.status}
                      style={
                        errors.status && touched.status
                          ? { border: "1px solid red" }
                          : {}
                      }
                     
                    >
                    <option>Select</option>
                      {
                        statusOptions?.map((option)=>(
                          <option value={option.label}>{option.label}</option>
                        ))
                      }
                </select>

                        
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="flex justify-start items-start flex-grow-0 flex-shrink-0 gap-2 self-end">
                <div
                  onClick={(e) => {
                    e.stopPropagation();
                    setShowModal(false);
                  }}
                  className="flex justify-start items-center flex-grow-0 flex-shrink-0 relative overflow-hidden gap-1 pl-4 pr-5 py-[11px] rounded-[94px] bg-[#fff] cursor-pointer"
                  style={{ boxShadow: "0px 1px 0px 0 rgba(0,0,0,0.08)" }}
                >
                  <p className="flex-grow-0 flex-shrink-0 text-sm font-medium text-center text-black">
                    Cancel
                  </p>
                </div>
                <button
          type="button"
          className="flex justify-start items-center flex-grow-0 flex-shrink-0 relative overflow-hidden gap-1 px-5 py-[11px] rounded-[94px] bg-[#186ced] cursor-pointer"
          style={{ boxShadow: "0px 1px 0px 0 rgba(0,0,0,0.08)" }}
          onClick={handleSubmit}
          disabled={loading} // Disable the button when loading
        >
          {loading ? (
            <div className="spinner-border text-light" role="status">
              <span className="visually-hidden"><FiLoader /></span>
            </div>
          ) : (
            <p className="flex-grow-0 flex-shrink-0 text-sm font-medium text-center text-white">
              Save
            </p>
          )}
        </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  ) : null}
    </div>
  )
}

export default EditEventResult

const DocumentIcon = (props) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 20 20"
    xmlSpace="preserve"
    width={20}
    height={20}
    {...props}
  >
    <path
      fill="none"
      stroke="#000"
      strokeWidth={1.25}
      strokeMiterlimit={10}
      d="M15.625 17.5H4.375V2.5h7.5l3.75 3.75z"
    />
    <path
      fill="none"
      stroke="#000"
      strokeWidth={1.25}
      strokeMiterlimit={10}
      d="M11.875 2.5v3.75h3.75"
    />
    <path d="M6.875 13.125h6.25v1.25H6.875zm0 -2.5h6.25v1.25H6.875zm0 -2.5h6.25v1.25H6.875z" />
  </svg>
);