import React, { useEffect } from 'react'
import Template from '../../components/common/Template'
import ownerback from "../../assets/images/ownerbackground.jpg"
import EditProfile from './EditProfile'
import { useDispatch, useSelector } from 'react-redux'
import { getOwnerProfileDetails } from '../../services/apiService'
import OwnerList from './OwnerList'
const OwnerProdile_index = ({setShowModal}) => {
  const {OwnerProfileDetails}=useSelector((state)=>state.ownerprofile);
  const dispatch=useDispatch();

  useEffect(()=>{
    dispatch(getOwnerProfileDetails())
  },[dispatch])

  return (
    <Template>
    <div className='m-7'>
    {OwnerProfileDetails?.map((ownerdetails)=>(
      <div key={ownerdetails?.owner_id} className='relative w-full'>

      <img alt="" src={ownerback} className='w-full h-[540px]'/>
      <div className='absolute top-0 left-0 w-full  text-white m-7'>
       <div className=' w-full items-center flex justify-center h-full'>
         <p className='text-[50px] font-["Quicksand"] font-bold text-center'>

         Owner info
         </p>
       </div>
       <div className='w-full  flex gap-7 '>
         <div className='w-[25%]'>

          <img alt='' src={ownerdetails?.image} className='w-full h-[375px]'/>
         </div>
          <div className='w-[70%] ml0-0 flex gap-5 flex-col'>
            <div className='text-[42px] font-semibold font-["Quicksand"] text-[#ffffff]'>{ownerdetails?.name}</div>
            <div className='text-[28px] font-normal font-["Quicksand"] text-[#cccccc]'>{ownerdetails?.description}</div>
          </div>
       </div>

      </div>
    </div>
      ))}


    <div className="flex justify-center gap-4 p-5">
        <button  className="py-3 bg-indigo-500 font-semibold text-white w-44 rounded-xl hover:bg-indigo-600">
          <EditProfile />
        </button>
        <button className="py-3 bg-pink-500 font-semibold text-white w-44 rounded-xl hover:bg-pink-600">
          Delete
        </button>
      </div>

    </div>
    </Template>
  )
}

export default OwnerProdile_index
