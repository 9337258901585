


import React, { useEffect, useState } from 'react'
import Template from '../../../components/common/Template'

import { useDispatch, useSelector } from 'react-redux';
import { Tab } from "@headlessui/react";

import { getAllTechnicalCommitie } from '../../../services/apiService';
import { setSelectedTechnicalCommitiee, setSelectedTechnicalPage } from '../../../redux/slice/TechnicalSlice';
import DeleteTechnicalProfile from './DeleteTechnicalProfile';
import AddTechnicalProfile from './AddTechnicalProfile';
import EditTechnicalProfile from './EditTechnicalProfile';
import Pagination from '../../../components/common/pagination';
const TechnicalProfile_index = () => {

  const dispatch =useDispatch()
  const {selectedTechnicalCommitiee}=useSelector((state)=>state.technicalcommitiee);
  const {TechnicalCommitieList,totalTechnicalCommiteeList}=useSelector((state)=>state.technicalcommitiee)
  const [Kyorugi, setKyorugi]=useState([])
  const [poomsae, setPoomsae] = useState([])
  const [KyorugiPage, setKyorugiPage] = useState(1)
  const [poomsaePage, setPoomasePage]=useState(1)


  const handleKyorugi = (e,pridee) => {
    const { checked } = e.target;
    if (checked) {
        setKyorugi([...Kyorugi,pridee])
    } else {
        setKyorugi(Kyorugi?.filter((pride_item)=>pride_item?.id!==pride_item?.id))
    }
}


const handlePoomsae = (e,pridee) => {
  const { checked } = e.target;
  if (checked) {
    setPoomsae([...poomsae,pridee])
  } else {
    setPoomsae(poomsae?.filter((pride_item)=>pride_item?.id!==pridee?.id))
  }
}


const pageSIze=10;
useEffect(() => {
  if (selectedTechnicalCommitiee === 0) {
    dispatch(getAllTechnicalCommitie(KyorugiPage-1,pageSIze,0));
  } else  {  
    dispatch(getAllTechnicalCommitie(poomsaePage-1,pageSIze,1));
  }
}, [dispatch, selectedTechnicalCommitiee,KyorugiPage,poomsaePage]);



  return (
  <Template>
  <div className='flex flex-1 justify-between p-4  bg-gray-200'>
  <div className='flex flex-1 justify-start text-left items-center'>
    <p className='text-2xl'>Our Technical List</p>
  </div>
<div className='flex flex-row gap-4 '>


{selectedTechnicalCommitiee===0 ?
          <>
          <div className='flex gap-2'>
{Kyorugi.length ===1 ? (
 <DeleteTechnicalProfile selectedTemplate={Kyorugi} setSelectedTemplate={setKyorugi} page={KyorugiPage} setPage={setKyorugiPage} />
              ) : null}
              {Kyorugi.length === 1 ? (
 <EditTechnicalProfile
   prideId={Kyorugi[0]?.id}
   selectedTemplate={Kyorugi}
   details={Kyorugi[0]}
                setSelectedTemplate={setKyorugi}
                page={KyorugiPage}
 />
) : null}
</div>
<div className=''>
 <AddTechnicalProfile />
</div>
</>:

        <>
        <div className='flex flex-row gap-4 '>
<div className='flex gap-2'>
{poomsae.length ===1 ? (
 <DeleteTechnicalProfile selectedTemplate={poomsae} setSelectedTemplate={setPoomsae} page={poomsaePage} setPage={setPoomasePage} />
              ) : null}
              <div className=''>
{poomsae.length === 1 ? (
 <EditTechnicalProfile
   prideId={poomsae[0]?.id}
   selectedTemplate={poomsae}
   details={poomsae[0]}
                setSelectedTemplate={setPoomsae}
                page={poomsaePage} setPage={setPoomasePage}
 />
) : null}
</div>
</div>
<div className=''>
 <AddTechnicalProfile />
</div>

</div> 
</>}
</div>
  </div>
  <Tab.Group selectedIndex={selectedTechnicalCommitiee} onChange={(val)=>dispatch(setSelectedTechnicalCommitiee(val))}>
  <Tab.List className="flex justify-start items-center self-stretch flex-grow-0 flex-shrink-0 gap-12 px-4 border border-black/[0.08]">
    <Tab>
      {({ selected }) => (
        <button
          className={`flex justify-start items-center flex-grow-0 flex-shrink-0 relative gap-1 py-4 text-sm font-medium text-center focus:ring-0 outline-none ${
            selected
              ? "border-b-2 border-[#186ced] text-[#186ced]"
              : "border-b-2 border-transparent text-gray-400 hover:text-gray-700"
          } `}
        >
        Kyorugi
        </button>
      )}
    </Tab>
    <Tab>
      {({ selected }) => (
        <button
          className={`flex justify-start items-center flex-grow-0 flex-shrink-0 relative gap-1 py-4 text-sm font-medium text-center focus:ring-0 outline-none ${
            selected
              ? "border-b-2 border-[#186ced] text-[#186ced]"
              : "border-b-2 border-transparent text-gray-400 hover:text-gray-700"
          } `}
        >
        Poomsae
        </button>
      )}
    </Tab>

   
    {/* Additional Tab elements */}
  </Tab.List>
  
  <Tab.Panels>
  <Tab.Panel className="md:p-4 w-[100%] ">
  <div className="flex flex-col max-md:hidden justify-start items-start self-stretch flex-grow overflow-hidden ">
  <div className="flex flex-col  items-start self-stretch flex-grow min-h-[70vh] justify-between w-full p-2">
    
    
    <>
    {TechnicalCommitieList?.length ? (
      <>
      <div className="flex flex-wrap gap-12 ">

      {TechnicalCommitieList.map((pridee) => (
        <label  htmlFor={`checkbox-${pridee.id}`} key={pridee?.id} className="flex group/item justify-start items-start w-[280px] ">
          <div className="max-w-full w-full">
            <div className="bg-white shadow-xl rounded-lg py-3">
              <div className="photo-wrapper relative p-2">
              <input
              type="checkbox"
              id={`checkbox-${pridee.id}`}
              checked={Kyorugi?.find((prid) => Number(prid?.id) === Number(pridee?.id))}
              onChange={(e) => handleKyorugi(e, pridee)}
              className={`absolute top-2 p-1 right-1 group/edit ${
                poomsae?.find((pridis) => Number(pridis?.id) === Number(pridee?.id))
                  ? 'visible'
                  : 'invisible'
              } group-hover/item:visible form-checkbox rounded border-gray-300 text-[#186ced] shadow-sm focus:border-indigo-300 focus:ring focus:ring-offset-0 focus:ring-indigo-200 focus:ring-opacity-50`}
            />
                <img
                  className="w-32 h-32 rounded-full mx-auto"
                  src={pridee?.image}
                  alt="coch image"
                />
              </div>
    
              <div className="p-2">
                <table className="text-xs my-3">
                  <tbody>
                    <tr>
                      <td className="px-2 py-2 text-gray-500 font-semibold"> Name</td>
                      <td className="px-2 py-2">{pridee?.name}</td>
                    </tr>
                    <tr>
                      <td className="px-2 py-2 text-gray-500 font-semibold">Post</td>
                      <td className="px-2 py-2">{pridee?.post}</td>
                    </tr>
                 
                    <tr>
                      <td className="px-2 py-2 text-gray-500 font-semibold">District</td>
                      <td className="px-2 py-2">{pridee?.city_name}</td>
                    </tr>
                    <tr>
                      <td className="px-2 py-2 text-gray-500 font-semibold">Technical Type</td>
                      <td className="px-2 py-2">{pridee?.technical_type}</td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </label>
      ))}
    
    </div>
      </>
    ) : (
      <div className="flex justify-center items-center h-[70vh] w-full">
        <span className="text-center w-full text-xl text-[#7b8794]">
          No Technical Profile Found
        </span>
      </div>
    )}
    </>
    <div className="flex justify-center w-full p-4 mb-4">  
          <Pagination
          className="pagination-bar"
          currentPage={KyorugiPage}
          totalCount={totalTechnicalCommiteeList}
          pageSize={10}
          onPageChange={(page) => {
             setKyorugiPage(page)
            var currentScroll = document.documentElement.scrollTop || document.body.scrollTop;
                  if (currentScroll > 0) {
                    window.scrollTo(0, currentScroll - (currentScroll / 1));
                  }
          }}
        />              
          </div>
  </div>
 
</div>
<div className="justify-center   bottom-0 w-full py-2  flex items-center">
 
</div>
</Tab.Panel>
  </Tab.Panels> 
  <Tab.Panels>
  <Tab.Panel className="md:p-4 w-[100%]">

  <div className="flex flex-col max-md:hidden justify-start items-start self-stretch flex-grow overflow-hidden ">
  <div className="flex flex-col  items-start self-stretch flex-grow min-h-[70vh] justify-between w-full">
    
    
    <>
    {TechnicalCommitieList?.length ? (
      <>
      <div className="flex flex-wrap gap-12 ">

      {TechnicalCommitieList.map((pridee) => (
        <label  htmlFor={`checkbox-${pridee.id}`} key={pridee?.id} className="flex group/item justify-start items-start w-[280px] ">
          <div className="max-w-full w-full">
            <div className="bg-white shadow-xl rounded-lg py-3">
              <div className="photo-wrapper relative p-2">
              <input
              type="checkbox"
              id={`checkbox-${pridee.id}`}
              checked={poomsae?.find((prid) => Number(prid?.id) === Number(pridee?.id))}
              onChange={(e) => handlePoomsae(e, pridee)}
              className={`absolute top-2 p-1 right-1 group/edit ${
                Kyorugi?.find((pridis) => Number(pridis?.id) === Number(pridee?.id))
                  ? 'visible'
                  : 'invisible'
              } group-hover/item:visible form-checkbox rounded border-gray-300 text-[#186ced] shadow-sm focus:border-indigo-300 focus:ring focus:ring-offset-0 focus:ring-indigo-200 focus:ring-opacity-50`}
            />
                <img
                  className="w-32 h-32 rounded-full mx-auto"
                  src={pridee?.image}
                  alt="coch image"
                />
              </div>
    
              <div className="p-2">
                <table className="text-xs my-3">
                  <tbody>
                    <tr>
                      <td className="px-2 py-2 text-gray-500 font-semibold"> Name</td>
                      <td className="px-2 py-2">{pridee?.name}</td>
                    </tr>
                    <tr>
                      <td className="px-2 py-2 text-gray-500 font-semibold">Post</td>
                      <td className="px-2 py-2">{pridee?.post}</td>
                    </tr>
                    <tr>
                      <td className="px-2 py-2 text-gray-500 font-semibold">district</td>
                      <td className="px-2 py-2">{pridee?.city_name}</td>
                    </tr>
                    <tr>
                      <td className="px-2 py-2 text-gray-500 font-semibold">Technical Type</td>
                      <td className="px-2 py-2">{pridee?.technical_type}</td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </label>
      ))}
    
    </div>
      </>
    ) : (
      <div className="flex justify-center items-center h-[70vh] w-full">
        <span className="text-center w-full text-xl text-[#7b8794]">
          No Technical Profile Found
        </span>
      </div>
    )}
    </>
    <div className="flex justify-center w-full p-4 mb-4">  
          <Pagination
          className="pagination-bar"
          currentPage={poomsaePage}
          totalCount={totalTechnicalCommiteeList}
          pageSize={10}
          onPageChange={(page) => {
             setPoomasePage(page)
            var currentScroll = document.documentElement.scrollTop || document.body.scrollTop;
                  if (currentScroll > 0) {
                    window.scrollTo(0, currentScroll - (currentScroll / 1));
                  }
          }}
        />              
          </div>
  </div>
 
</div>
<div className="justify-center   bottom-0 w-full py-2  flex items-center">
 
</div>
</Tab.Panel>
  </Tab.Panels> 

  </Tab.Group>

  </Template>
  )
}

export default TechnicalProfile_index


 	
