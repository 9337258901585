  import React, { useEffect, useState } from "react";
  import { Link } from "react-router-dom";
  import * as Yup from "yup";
  import { useFormik } from "formik";
  import { ReactComponent as UploadIcon } from "../../../assets/icons/uploadicon.svg";
  import Files from "react-files";
  import toast from "react-hot-toast";
  import Select from "react-select";
  import { MdOutlineEdit } from "react-icons/md";
  import { FiLoader } from "react-icons/fi";
  import { useDispatch, useSelector } from "react-redux";
  import axiosConfig from "../../../axios_config/axios_instance";
  import { getAllCityList, getAllCityListAllModule, getAllCoachesProfileList, getAllRefreeList } from "../../../services/apiService";
  import { setSelectedCochesProfile } from "../../../redux/slice/CoachesProfileSlice";
  import { setSelectedTabRefree } from "../../../redux/slice/RefreeSlice";

  const EditRefree = ({details, page,pageSIze}) => {
    const [showModal, setShowModal] = useState(false);
    const [isLoading, setIsLoading]=useState(false)
    const dispatch=useDispatch()
    const { selectedTabRefree } = useSelector((state) => state.refree);

    const {AllCityListModule}=useSelector((state)=>state.citylist)

    const options = AllCityListModule.map(({ city_id, city_name }) => ({
      value: city_id,
      label: city_name,
    }));
    
    const statusOptions = [
      { label: 'international', value: 0 },
      { label: 'national', value: 1 },
      { label: 'state', value: 2 },
    ];
    
    useEffect(()=>{
      dispatch(getAllCityListAllModule())
    },[dispatch])

    //intialData for formik
    const initialValues = {
      refreename: details?.referee_name,
      cityname: details?.city_id,
      image: details?.image,
      grade: details?.grade,
      event: details?.event,
      status: details?.referee_type?.toLowerCase(),
    };

    //Yup validator Schema
    const EditInformationSchema = Yup.object({
      refreename:Yup.string().required(" coachname is required"), 
      grade:Yup.string().required("grade is required"),
      cityname:Yup.string().required("grade is required"),
      status:Yup.string().required("grade is required"),
      image:Yup.mixed().required("image required"),
    });

    //formik validation
    const {
      values,
      handleBlur,
      resetForm,
      handleChange,
      handleSubmit,
      setFieldValue,
      errors,
      touched,
    } = useFormik({
      initialValues,
      validationSchema: EditInformationSchema,
      validateOnChange: true,
      validateOnBlur: false,
      onSubmit:async (values, action) => {

          try {  
              const formdata= new FormData()
              setIsLoading(true)
              formdata.append("referee_name", values?.refreename)
              formdata.append("image", values?.image)
              formdata.append("city_name", AllCityListModule?.find((city)=>city?.city_id)?.city_name);
              formdata.append("city_id", values.cityname); //
              formdata.append("grade", values?.grade)
              formdata.append("referee_id", details?.referee_id)
              formdata.append("status", values?.status)
              const {data}= await axiosConfig.put("/edit-referee-profile", formdata);
              if (data?.status === "success") {
                toast.success("Refree Profile Updted successfully");

                if (values.status === "international") {
                  dispatch(setSelectedTabRefree(0))
                  dispatch(getAllRefreeList(page-1,pageSIze,0));
                } else if (values.status === "national") {
                dispatch(setSelectedTabRefree(1))
                  dispatch(getAllRefreeList(page-1,pageSIze,1));
                } else if (values.status === "state") {
                dispatch(setSelectedTabRefree(2))
                  dispatch(getAllRefreeList(page-1,pageSIze,2));
                }
                setShowModal(false)
                resetForm();
                dispatch(getAllRefreeList(0,100,0))
                setIsLoading(false);
                
              } else {
                toast.error("Something went wrong..!");
                setIsLoading(false);
              }
            } catch (error) {
                toast.error("something Went wrong")
            }finally{
              setIsLoading(false)
            }
      },


    });
    
    const handleImageChange = (files) => {
      if (files.length) {
        setFieldValue("image", files[0]);

      } else {
        toast.error("No image selected. Please upload an image.");
      }
    };
    const closeModal = (e) => {
      e?.target?.classList?.forEach((classes) => {
        if (classes === "Modal-Container") {
          setShowModal(false);
          resetForm();

        }
      });
    };

    const customStyles = {
      option: (provided, state) => ({
        ...provided,
        cursor: "pointer",
        fontSize: "14px",
      }),
      control: (provided) => ({
        ...provided,
        cursor: "pointer",
        fontSize: "14px",
      }),
      singleValue: (provided) => ({
        ...provided,
        fontSize: "14px",
      }),
      placeholder: (provided) => ({
        ...provided,
        fontSize: "14px",
      }),
    };

    return (
      <div>
      <div   
      onClick={(e) => {
      e.stopPropagation();
      setShowModal(true);

    }}>
    <button
    className="flex justify-center items-center self-center flex-grow-0 flex-shrink-0 pr-3 h-[42px] gap-2.5 rounded-[5px]"

  >
  
  <MdOutlineEdit className="w-5 h-5" />

  </button>
    </div>

        {showModal ? (
          <>
            <div
              className="flex justify-center items-center overflow-x-hidden overflow-y-auto fixed inset-0 z-50 outline-none focus:outline-none shadow-lg w-full Modal-Container"
              onClick={closeModal}
              style={{ backdropFilter: "blur(1px)" }} 
            >
              <div className="relative w-auto my-6 mx-auto max-w-3xl">
                <div className="border-0 rounded-lg shadow-lg relative flex w-full bg-white outline-none focus:outline-none">
                  <div className="flex items-start gap-1 p-2 border-b border-solid border-gray-300 rounded-t flex-col ">
                    <h3 className="text-xl font=semibold">
                      Add Referee
                    </h3>
                    <div className="flex justify-between items-start">
                      <div className="flex flex-col mt-3">
                      <p className="text-[12px] text-left text-black/80 font-semibold text-[#202223] relative after:content-['*'] after:ml-0.5 after:text-red-500">max upto 2mb</p>

                      <Files
    className="files-dropzone cursor-pointer"
    onChange={(file) => handleImageChange(file)}
    onError={(error) => toast.error(error?.message)}

    accepts={[".jpeg" ,".jpg",]}
    maxFileSize={2097152} 
    minFileSize={0}
    multiple
    clickable
    style={
      errors.image && touched.image
        ? { border: "1px solid red" }
        : {}
    }
  >
  {values.image ? (
    <div className="flex flex-col items-center relative">
      <img
        src={
          typeof values.image === "object"
            ? values.image?.preview?.url
            : values.image
        }
        className="h-52 w-[200px] object-cover"
        
      />
  
    </div>
  ) : (
    <div className="flex flex-col justify-center items-center flex-grow-0 flex-shrink-0 h-[122px] w-[122px] relative gap-1 px-[23.5px] py-7 rounded bg-[#186ced]/[0.04] border-[1.33px] border-[#186ced]/[0.64] border-dashed">
      <UploadIcon />
      <div className="flex justify-start items-center flex-grow-0 flex-shrink-0 relative gap-1 px-[5px] py-0.5 rounded">
        <p className="flex-grow-0 flex-shrink-0 text-xs font-medium text-center text-black/[0.64]">
          Upload
        </p>
      </div>
    </div>
  )}
  </Files>
  <p className="text-[12px] text-left text-black/80 font-semibold text-[#202223] relative after:content-['*'] after:ml-0.5 after:text-red-500">(upload only jpeg)</p>
                      </div>
                      <div className="relative p-6 pt-0 flex-auto">
                      <div className="flex flex-col justify-start items-start self-stretch flex-grow-0 flex-shrink-0 gap-1">
                        <div className="flex justify-start items-start self-stretch flex-grow-0 flex-shrink-0 relative gap-1 flex-col">
                        <div className="flex-grow-0 flex-shrink-0 w-[150px]">
                        <p className='text-black text-[14px] font-medium leading-10  whitespace-nowrap max-md:max-w-full after:content-["*"] after:ml-0.5 after:text-red-500'>
                        Referee Name
                        </p>
                      </div>
                      <div className="flex flex-col flex-grow w-[300px]">
                      <input
                      name="refreename"
                      type="text"
                      value={values.refreename}
                      onChange={handleChange}
                      className="w-full border rounded p-2 capitalize text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                      placeholder="Grade"
                      style={
                        errors.refreename && touched.refreename
                          ? { border: "1px solid red" }
                          : {}
                      }
                    />
            
          

                      </div>
            
                          <div className="flex-grow-0 flex-shrink-0 w-[150px]">
                          <p className='text-black text-[14px] font-medium leading-10  whitespace-nowrap max-md:max-w-full after:content-["*"] after:ml-0.5 after:text-red-500'>
                          District
                            </p>
                          </div>
                          <div className="flex flex-col flex-grow w-[300px]">
                          <select
                          name="cityname"
                          onChange={handleChange}
                          className={
                              errors.cityname && touched.cityname
                                  ? "formErrorInput capitalize custom-select"
                                  : "custom-select capitalize"
                          }
                          onBlur={() => handleBlur("cityname")}
                          value={values.cityname}
                          style={
                            errors.cityname && touched.cityname
                              ? { border: "1px solid red" }
                              : {}
                          }
                      >
                      <option>Select District</option>
                      {
                        AllCityListModule?.map((option)=>(
                          <option value={option.city_id}>{option?.city_name}</option>
                        ))
                      }

                      </select>
                      
                    
              

                          </div>
                    
                          <div className="flex-grow-0 flex-shrink-0 w-[150px]">
                          <p className='text-black text-[14px] font-medium leading-10  whitespace-nowrap max-md:max-w-full after:content-["*"] after:ml-0.5 after:text-red-500'>
                          Grade
                            </p>
                          </div>
                          <div className="flex flex-col flex-grow w-[300px]">
                          <input
                          name="grade"
                          type="text"
                          value={values.grade}
                          onChange={handleChange}
                          className="w-full border rounded p-2 text-gray-700 capitalize leading-tight focus:outline-none focus:shadow-outline"
                          placeholder="Grade"
                          style={
                            errors.grade && touched.grade
                              ? { border: "1px solid red" }
                              : {}
                          }
                        />
                
              

                          </div>
                    
                          <div className="flex-grow-0 flex-shrink-0 w-[150px]">
                          <p className='text-black text-[14px] font-medium leading-10  whitespace-nowrap max-md:max-w-full after:content-["*"] after:ml-0.5 after:text-red-500'>
                          Status
                            </p>
                          </div>
                          <div className="flex flex-col flex-grow w-[300px]">
                          <select
                          name="status"
                          onChange={handleChange}
                          className={
                            errors.status && touched.status
                              ? "formErrorInput custom-select capitalize"
                              : "custom-select capitalize"
                          }
                        
                          onBlur={() => handleBlur("status")}
                          value={values.status}
                          style={
                            errors.status && touched.status
                              ? { border: "1px solid red" }
                              : {}
                          }
                        
                        >
                        <option>Select</option>
                          {
                            statusOptions?.map((option)=>(
                              <option value={option.label} className="capitalize">{option.label}</option>
                            ))
                          }
                    </select>

                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="flex justify-start items-start flex-grow-0 flex-shrink-0 gap-2 self-end">
                    <div
                      onClick={(e) => {
                        e.stopPropagation();
                        setShowModal(false);
                      }}
                      className="flex justify-start items-center flex-grow-0 flex-shrink-0 relative overflow-hidden gap-1 pl-4 pr-5 py-[11px] rounded-[94px] bg-[#fff] cursor-pointer"
                      style={{ boxShadow: "0px 1px 0px 0 rgba(0,0,0,0.08)" }}
                    >
                      <p className="flex-grow-0 flex-shrink-0 text-sm font-medium text-center text-black">
                        Cancel
                      </p>
                    </div>
                    <button
                    type="button"
                    className="flex justify-start items-center flex-grow-0 flex-shrink-0 relative overflow-hidden gap-1 px-5 py-[11px] rounded-[94px] bg-[#186ced] cursor-pointer"
                    style={{ boxShadow: "0px 1px 0px 0 rgba(0,0,0,0.08)" }}
                    onClick={handleSubmit}
                    disabled={isLoading} // Disable the button when loading
                  >
                    {isLoading ? (
                      <div className="spinner-border text-light" role="status">
                        <span className="visually-hidden"><FiLoader /></span>
                      </div>
                    ) : (
                      <p className="flex-grow-0 flex-shrink-0 text-sm font-medium text-center text-white">
                        Save
                      </p>
                    )}
                  </button>
                  </div>
                  </div>
                </div>
              </div>
            </div>
          </>
        ) : null}
      </div>
    );
  };

  export default EditRefree;
