import { createSlice } from "@reduxjs/toolkit";

const competitionSlice = createSlice({
    name:"competation",
    initialState: {
        competitionList: [],
        isLoading:false
    },
    reducers: {
        getCompetitionListRequest: (state) => {
            state.isLoading = true;
        },
        getCompetitionListSuccess: (state,{payload}) => {
            state.isLoading = false;
            state.competitionList = payload;
        },
        getCompetitionListFail: (state) => {
            state.isLoading = false;
            state.competitionList = [];
        },
    }
})

export default competitionSlice.reducer;
export const { getCompetitionListFail,getCompetitionListRequest,getCompetitionListSuccess,} = competitionSlice.actions;