import React, { useEffect, useState } from "react";
import { useFormik } from "formik";
import * as Yup from "yup";
import toast from "react-hot-toast";
import axiosConfig from "../../../axios_config/axios_instance";
import { FiLoader } from "react-icons/fi";
import moment from "moment";
import { useDispatch, useSelector } from "react-redux";
import {
  getAllCityList,
  getAllCityListAllModule,
  getAllColorBeltPromotionList,
  getAllOtaClubList,
} from "../../../services/apiService";
const AddColorBelt = ({city_id}) => {
  const [showModal, setShowModal] = useState(false);
  const [selectedDate1, setSelectedDate1] = useState(null);
 const [isLoading, setIsLoading]=useState(false)
  const handleDateChange1 = (date) => {
    setSelectedDate1(date);
  };

  const dispatch = useDispatch();

  const { OtaClubList } = useSelector((state) => state.otaclub);

  const options = OtaClubList.map(({ ota_club_id, club_name }) => ({
    value: ota_club_id,
    label: club_name,
  }));

 

  const {AllCityListModule}=useSelector((state)=>state.citylist)

  const optionss = AllCityListModule.map(({ city_id, city_name }) => ({
    value: city_id,
    label: city_name,
  }));

  useEffect(()=>{
    dispatch(getAllCityListAllModule())
  },[dispatch])

  const validationSchema = Yup.object().shape({
    atheletename: Yup.string().required("Name is required"),
    presentbelt: Yup.mixed().required("file required"),
    clubname: Yup.string().required("achievement is required"),
    certificate: Yup.string().required("achievement is required"),
    examdate: Yup.string().required("achievement is required"),
  });

  const {
    values,
    handleChange,
    handleSubmit,
    errors,
    touched,
    setFieldValue,
    handleBlur,
    resetForm,
  } = useFormik({
    initialValues: {
      atheletename: "",
      presentbelt: "",
      clubname: "",
      examdate: "",
      certificate: "",
      cityname:""
    },
    validationSchema: validationSchema,
    validateOnChange: true,
    enableReinitialize: true,
    validateOnBlur: false,
    onSubmit: async (values) => {
      try {
        const formdata = new FormData();
        setIsLoading(true)
        formdata.append("player_name", values?.atheletename);
        formdata.append("present_belt", values?.presentbelt);

        formdata.append(
          "club_name",
          OtaClubList?.find((otaclub) => otaclub?.ota_club_id)?.club_name
        );
        formdata.append("club_id", values.clubname);

        formdata.append(
          "city_name",
          AllCityListModule?.find((city) => city?.city_id)?.city_name
        );
        formdata.append("city_id", values.cityname);

        formdata.append("certificate_no", values.certificate);
        const formatedColorBelt = moment(values.examdate).format("DD-MM-YYYY");
        formdata.append("exam_date", formatedColorBelt);

        const { data } = await axiosConfig.post(
          "/save-colour-belt-promotion-test",
          formdata
        );
        if (data?.status === "success") {
          toast.success("Pride Added successfully");
          resetForm()
          setShowModal(false)
          dispatch(getAllColorBeltPromotionList(city_id,0,10))
        } 
      } catch (error) {
        toast.error("something Went wrong");
      }finally{
        setIsLoading(false)
      }
    },
  });
  const closeModal = (e) => {
    e?.target?.classList?.forEach((classes) => {
      if (classes === "Modal-Container") {
        setShowModal(false);
        resetForm();
      }
    });
  };

  useEffect(()=>{
    if(values.cityname){
      dispatch(getAllOtaClubList(values.cityname))
    }
  },[dispatch,values.cityname ])

  
  const handleImageChange = (files) => {
    if (files.length) {
      setFieldValue("image", files[0]);
    } else {
      toast.error("No image selected. Please upload an image.");
    }
  };

  const customStyles = {
    option: (provided, state) => ({
      ...provided,
      cursor: "pointer",
      fontSize: "14px",
    }),
    control: (provided) => ({
      ...provided,
      cursor: "pointer",
      fontSize: "14px",
    }),
    singleValue: (provided) => ({
      ...provided,
      fontSize: "14px",
    }),
    placeholder: (provided) => ({
      ...provided,
      fontSize: "14px",
    }),
  };

  return (
    <div>
      <div
        onClick={(e) => {
          e.stopPropagation();
          setShowModal(true);
        }}
        className="flex justify-start cursor-pointer relative items-center flex-grow-0 flex-shrink-0 gap-3"
      >
        <div
          className="flex justify-start items-center flex-grow-0 flex-shrink-0 relative overflow-hidden gap-1 pl-4 pr-5 max-md:py-[7px] py-[10px] rounded-full bg-[#186ced]"
          style={{ boxShadow: "0px 1px 0px 0 rgba(0,0,0,0.08)" }}
        >
          <p className="flex-grow-0 flex-shrink-0 text-sm font-medium text-center text-white">
            Add Color Belt
          </p>
        </div>
      </div>
      {showModal ? (
        <>
        <div
        className="flex justify-center items-center overflow-x-hidden fixed inset-0 z-50 outline-none focus:outline-none shadow-lg w-full Modal-Container"
        onClick={closeModal}
        style={{ backdropFilter: "blur(1px)" }} 

      >
        <div className="relative w-auto my-6 mx-auto  max-w-3xl">
          <div className="border-0 rounded-lg shadow-lg relative flex w-full bg-white outline-none focus:outline-none">
         

            <div className="grid grid-cols-2 p-6 pt-0 ">
              <div className="flex  gap-6">
                {/* Group 1 */}
                <div className="flex flex-col gap-1 p-2">
                  {/* City */}
                 
                  <h2 className="text-xl text-black font-bold flex justify-start mt-1">Add Color Belt</h2>
                  <div className="flex-grow-0 flex-shrink-0 w-[150px]">
                  
                            <p className="text-[14px] mt-1 text-left text-black/80 font-semibold text-[#202223] relative after:content-['*'] after:ml-0.5 after:text-red-500">
                              Athelete Name
                            </p>
                          </div>
                          <div className="flex flex-col flex-grow w-[300px]">
                            <input
                              name="atheletename"
                              type="text"
                              value={values.atheletename}
                              onChange={handleChange}
                              className="w-full border rounded p-2 capitalize text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                              placeholder="Athelete name"
                              style={
                                errors.atheletename && touched.atheletename
                                  ? { border: "1px solid red" }
                                  : {}
                              }
                            />
                            
                          </div>

                  {/* President */}
                  <div className="flex-grow-0 flex-shrink-0 w-[150px]">
                  <p className="text-[14px] mt-1 text-left text-black/80 font-semibold text-[#202223] relative after:content-['*'] after:ml-0.5 after:text-red-500">
                    Present Belt
                  </p>
                </div>
                <div className="flex flex-col flex-grow w-[300px]">
                  <input
                    name="presentbelt"
                    type="text"
                    value={values.presentbelt}
                    onChange={handleChange}
                    className="w-full border rounded p-2 text-gray-700  capitalize leading-tight focus:outline-none focus:shadow-outline"
                    placeholder="Present belt"
                    style={
                      errors.presentbelt && touched.presentbelt
                        ? { border: "1px solid red" }
                        : {}
                    }
                  />
          
                </div>
                <div className="flex-grow-0 flex-shrink-0 w-[150px]">
                <p className="text-[14px] mt-1 text-left text-black/80 font-semibold text-[#202223] relative after:content-['*'] after:ml-0.5 after:text-red-500">
                  Certificate No
                </p>
              </div>
              <div className="flex flex-col flex-grow w-[300px]">
                <input
                  name="certificate"
                  type="number"
                  value={values.certificate}
                  onChange={handleChange}
                  className="w-full border rounded p-2 text-gray-700 leading-tight capitalize focus:outline-none focus:shadow-outline"
                  placeholder="Certificate no"
                  style={
                    errors.certificate && touched.certificate
                      ? { border: "1px solid red" }
                      : {}
                  }
                />
             
              </div>
              <div className="flex-grow-0 flex-shrink-0 w-[150px]">
              <p className="text-[14px] mt-1 text-left text-black/80 font-semibold text-[#202223] relative after:content-['*'] after:ml-0.5 after:text-red-500">
                Exam date
              </p>
            </div>
            <div className="flex flex-col flex-grow w-[300px]">
            <input
            value={values.examdate}
            name="examdate"
            placeholder="DD-MM-YYYY"
            className={`
              ${
                errors.examdate && touched.examdate
                  ? "border-red-500"
                  : "border-gray-300"
              }
              w-full h-10 rounded-md text-black  border focus:border-gray-500 capitalize placeholder:text-sm px-3
            `}
            onChange={handleChange}
            type="date"
          />
             
            </div>
                </div>
                
                {/* Group 2 */}
                <div className="flex flex-col gap-1">
                  {/* Treasurer */}
                  
                  <div className="flex-grow-0 flex-shrink-0 w-[150px] mt-11">
                  <p className="text-[14px] mt-1 text-left text-black/80 font-semibold text-[#202223] relative after:content-['*'] after:ml-0.5 after:text-red-500">
                    District

                  </p>
                </div>
                <div className="flex flex-col  w-[300px]">
                  <select
                    name="cityname"
                    onChange={(e)=>{
                      setFieldValue("clubname","");
                      handleChange(e)
                    }}
                    placeholder="Select City"
                    className={
                      errors.cityname && touched.cityname
                        ? "border-red-500 custom-select"
                        : "custom-select capitalize"
                    }
                    
                    onBlur={() => handleBlur("cityname")}
                    value={values.cityname}
                  >
                    <option>Select district</option>
                    {AllCityListModule?.map((option) => (
                      <option value={option.city_id}>
                        {option?.city_name}
                      </option>
                    ))}
                  </select>
                </div>
                 
                <div className="flex-grow-0 flex-shrink-0 w-[150px]">
                <p className="text-[14px] mt-1 text-left text-black/80 font-semibold text-[#202223] relative after:content-['*'] after:ml-0.5 after:text-red-500">
                  Club Name
                </p>
              </div>
              <div className="flex flex-col flex-grow w-[300px]">
                <select
                  name="clubname"
                  onChange={handleChange}
                  placeholder="Select club"
                  className={
                    errors.clubname && touched.clubname
                      ? "border-red-500 custom-select"
                      : "custom-select"
                  }
                  onBlur={() => handleBlur("clubname")}
                  value={values.clubname}
                  disabled={values?.cityname?"":"disable"}
                >
                  <option>Select Club</option>
                  {OtaClubList?.map((option) => (
                    <option value={option.ota_club_id}>
                      {option?.club_name}
                    </option>
                  ))}
                </select>
              </div>
       
                  {/* Address */}
              
             
                
                  {/* Email */}
                 
                </div>
                {/* Group 3 */}
                <div className="flex flex-col gap-1">
               
                </div>
              </div>
            </div>
            <div className="flex justify-start items-start  p-2 flex-grow-0 flex-shrink-0 gap-2 self-end">
            <div
            onClick={(e) => {
              e.stopPropagation();
              setShowModal(false);
            }}
            className="flex justify-start items-center flex-grow-0 flex-shrink-0 relative overflow-hidden gap-1 pl-4 pr-5 py-[11px] rounded-[94px] bg-[#fff] cursor-pointer"
            style={{ boxShadow: "0px 1px 0px 0 rgba(0,0,0,0.08)" }}
          >
            <p className="flex-grow-0 flex-shrink-0 text-sm font-medium text-center text-black">
              Cancel
            </p>
          </div>
          <button
          type="button"
          className="flex justify-start items-center flex-grow-0 flex-shrink-0 relative overflow-hidden gap-1 px-5 py-[11px] rounded-[94px] bg-[#186ced] cursor-pointer"
          style={{ boxShadow: "0px 1px 0px 0 rgba(0,0,0,0.08)" }}
          onClick={handleSubmit}
          disabled={isLoading} // Disable the button when loading
        >
          {isLoading ? (
            <div className="spinner-border text-light" role="status">
              <span className="visually-hidden"><FiLoader /></span>
            </div>
          ) : (
            <p className="flex-grow-0 flex-shrink-0 text-sm font-medium text-center text-white">
              Save
            </p>
          )}
        </button>
            </div>
          </div>
        </div>
      </div>
        </>
      ) : null}
    </div>
  );
};

export default AddColorBelt;
