import React, { useEffect, useState } from 'react'
import Template from '../../components/common/Template'
import { ReactComponent as AddIcon } from "../../assets/icons/addicon.svg";

import Select from 'react-select';
import * as routesKey from "../../constants/routes";
import clubImage from "../../assets/images/Otaplaceimage.png"
import EditDta from './EditDta'
import DeleteDta from './DeleteDta'
import { useDispatch, useSelector } from 'react-redux'
import { getAllCityList, getAllDtaList } from '../../services/apiService'
import { setDtaListPage } from '../../redux/slice/DtaSlice';
import Pagination from '../../components/common/pagination';
import { Link, useNavigate } from 'react-router-dom';
import { MdOutlineEdit } from "react-icons/md";

const DTA_Index = () => {

  const [page, setpage] = useState(1);
  const {DTAList, totalDTAList }=useSelector((state)=>state.dta)


  const dispatch=useDispatch()
 const navigate= useNavigate()




  useEffect(()=>{
    dispatch(getAllDtaList(page-1))
  },[dispatch, page])

  

  return (
    <Template>
      <div>
      <div className="flex justify-start bg-[#F3F5F8] items-center self-stretch flex-grow-0 flex-shrink-0 gap-2 px-4 py-4">
    <div className="flex justify-start items-center flex-grow relative gap-[22px]">
      <p className="flex-grow-0 max-md:text-[20px] flex-shrink-0 text-2xl font-semibold text-left capitalize text-[#202223]">
        Manage Dta
      </p>
    </div>
    <div className="flex justify-start items-start flex-grow-0 flex-shrink-0 gap-3 ">
    {/* Add a Select tab to filter the training place */}
   
    

  </div>
  <Link
  to={routesKey.ADDDTA}
  className="flex justify-start items-center flex-grow-0 flex-shrink-0 relative overflow-hidden gap-1 pl-4 pr-5 py-[10px] rounded-full bg-[#186ced]"
  style={{ boxShadow: "0px 1px 0px 0 rgba(0,0,0,0.08)" }}
>

  <p className="flex-grow-0 flex-shrink-0 text-sm font-medium text-center text-white">
    Add Dta
  </p>
</Link>
  </div>
    <div className="flex flex-col max-md:hidden justify-start items-start self-stretch flex-grow overflow-hidden ">
    <div className="flex flex-col  items-start self-stretch flex-grow min-h-[70vh] justify-between w-full">
      <>
      {DTAList?.length ? (
        <>
          {false ? null : (
            <div className="flex flex-col w-full">
            <table class="min-w-full leading-normal">
          <thead>
            <tr className='border-b-2 border-gray-200'>
              <th
                class="px-5 py-3  bg-gray-100 text-left text-xs font-semibold text-gray-700 uppercase tracking-wider"
              >
                 District
              </th>
              <th
                class="px-5 py-3  bg-gray-100 text-left text-xs font-semibold text-gray-700 uppercase tracking-wider"
              >
               President
              </th>
              <th
                class="px-5 py-3  bg-gray-100 text-left text-xs font-semibold text-gray-700 uppercase tracking-wider"
              >
              Secretary
                                                      </th>
              <th
                class="px-5 py-3  bg-gray-100 text-left text-xs font-semibold text-gray-700 uppercase tracking-wider"
              >
              Dta
                                                      </th>
              <th
                class="px-5 py-3  bg-gray-100 text-left text-xs font-semibold text-gray-700 uppercase tracking-wider"
              >
              Address
              </th>
              <th
                class="px-5 py-3  bg-gray-100 text-left text-xs font-semibold text-gray-700 uppercase tracking-wider"
              >
              Email
              </th>
              <th
                class="px-5 py-3  bg-gray-100 text-left text-xs font-semibold text-gray-700 uppercase tracking-wider"
              >
              Phone
              </th>
              <th
                class="px-5 py-3  bg-gray-100 text-left text-xs font-semibold text-gray-700 uppercase tracking-wider"
              >
              
              </th>
            </tr>
          </thead>
          <tbody>
                    {
                      DTAList?.map((dtaassociation) => (
                        <tr key={dtaassociation?.district_association_id} className='cursor-pointer group/item  '>
                          <td class="px-5 py-5 border-b border-gray-200 bg-white text-sm">
                          <div className='flex gap-2 items-center'>
                          <img src={dtaassociation?.image} alt='logo' className='w-16 h-16 object-cover rounded-full' />
                    <p class="text-gray-900 whitespace-no-wrap">
                  {dtaassociation?.city_name}
                    </p>
                          </div>
              </td>
                          <td class="px-5 py-5 border-b border-gray-200 bg-white text-sm">
                    <p  class="text-gray-900 break-all hover:underline hover:text-blue-600">
                     {dtaassociation?.president}
                    </p>
              </td>
            
              <td class="px-5 py-5 border-b border-gray-200 bg-white text-sm">
                    <p class="text-gray-900 break-all">
                          {dtaassociation?.secretary}
                    </p>
              </td>
              <td class="px-5 py-5 border-b border-gray-200 bg-white text-sm">
                    <p class="text-gray-900 break-all">
                          {dtaassociation?.dta}
                    </p>
              </td>
              <td class="px-5 py-5 border-b border-gray-200 bg-white text-sm">
                    <p class="text-gray-900 break-all">
                          {dtaassociation?.address}
                    </p>
              </td>
              <td class="px-5 py-5 border-b border-gray-200 bg-white text-sm">
                    <p class="text-gray-900 break-all">
                                {dtaassociation?.email}
                    </p>
              </td>
              <td class="px-5 py-5 border-b border-gray-200 bg-white text-sm">
                    <p class="text-gray-900 break-all">
                         {dtaassociation?.phone_number}  {dtaassociation?.alternative_phone_number&& `/ ${dtaassociation?.alternative_phone_number}`}
                    </p>
              </td>
                          <td class="px-5 py-5 border-b border-gray-200 bg-white text-sm">
                          <div className='flex gap-2 items-center'>
                      
                             <Link
                             to={`/editDta/${dtaassociation?.district_association_id}`}

                           >
                             <button  page={page} onClick={() => navigate(`/editDta/${dtaassociation?.district_association_id}`)} className="group/edit invisible group-hover/item:visible flex justify-center items-center flex-grow-0 flex-shrink-0 relative rounded  p-2 bg-white shadow-sm hover:bg-blue-400 transition-all cursor-pointer">
                             <MdOutlineEdit className="w-4 h-4" />
                           </button>
                           </Link>
                          <DeleteDta
                          page={page}
                            dtadetailsId={dtaassociation?.district_association_id}
                            setpage={setpage}
                          
                            className="group/edit invisible group-hover/item:visible flex justify-center items-center flex-grow-0 flex-shrink-0 relative rounded  p-2 bg-white shadow-sm hover:bg-blue-400 transition-all cursor-pointer  hover:rounded-md text-black"
                          />
                          </div>
              </td>
              
            
            </tr>
                      ))
        }
            
          </tbody>
        </table>
            </div>
          )}
        </>
      ) : (
        <div className="flex justify-center items-center h-[70vh] w-full">
          <span className="text-center w-full text-xl text-[#7b8794]">
            No DTA Found
          </span>
        </div>
      )}
      </>
      <div className="flex justify-center w-full p-4 mb-4 fixed -bottom-3  left-14 right-0">  
      <Pagination
      className="pagination-bar"
      currentPage={page}
      totalCount={totalDTAList} // Assuming 10 items per page
      pageSize={10}
                onPageChange={(newPage) => { 
                  setpage(newPage)
                    var currentScroll = document.documentElement.scrollTop || document.body.scrollTop;
                      if (currentScroll > 0) {
                        window.scrollTo(0, currentScroll - (currentScroll / 1));
                      }
                }
                 }
    />              
      </div>
    </div>
   
  </div>
  


  </div>
    </Template>
  )
}

export default DTA_Index

/*

*/