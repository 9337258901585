import { createSlice } from "@reduxjs/toolkit";

const NewBlackBeltAthlete = createSlice({
    name: "blackbeltathelete",
    initialState: {
        BlackBeltAtheletLoading: false,
        BlackBeltAtheleteDetails:[],
        BlackBeltAtheleteDetailsLoading:false,
        BlackBeltAtheleteList:[],

        TotalBlackBeltList:0
      
    },
    reducers: {
         getBlackBeltAtheleteRequest:(state)=>{
            state.BlackBeltAtheletLoading=true;
         },
         getBlackBeltAtheleteSuccess:(state, {payload})=>{
            state.BlackBeltAtheletLoading=false;
            state.BlackBeltAtheleteList=payload?.black_belt_List;
            state.TotalBlackBeltList=payload?.total_elements
         },
         getBlackBeltAtheleteFail:(state)=>{
            state.BlackBeltAtheletLoading=false;
            state.BlackBeltAtheleteList=[];
         },
         getBlackBeltAtheleteDetailsSuccess: (state,{payload}) => {
            state.BlackBeltAtheleteDetailsLoading = false;
            state.BlackBeltAtheleteDetails = payload;
        },
         getBlackBeltAtheleteDetailsRequest: (state,{payload}) => {
            state.BlackBeltAtheleteDetailsLoading = true;
           
        },
         getBlackBeltAtheleteDetailsFail: (state,{payload}) => {
            state.BlackBeltAtheleteDetailsLoading = false;
       
        },
    }
})  

export default NewBlackBeltAthlete.reducer;
export const {getBlackBeltAtheleteDetailsFail, getBlackBeltAtheleteDetailsRequest , getBlackBeltAtheleteDetailsSuccess, getBlackBeltAtheleteFail, getBlackBeltAtheleteRequest, getBlackBeltAtheleteSuccess}= NewBlackBeltAthlete.actions