import React, { useState } from "react";
import { Link } from "react-router-dom";
import * as Yup from "yup";
import { useFormik } from "formik";
import { ReactComponent as UploadIcon } from "../../assets/icons/uploadicon.svg";
import Files from "react-files";
import { MdOutlineEdit } from "react-icons/md";
import axiosConfig from "../../axios_config/axios_instance";
import toast from "react-hot-toast";
import { useDispatch } from "react-redux";
import { getAllLatestInformation } from "../../services/apiService";
const EditLatestInformation = ({details}) => {
  const [showModal, setShowModal] = useState(false);
  const [latestdocs, setLatestDocs] = useState([]);
  const [isLoading, setIsLoading]=useState(false)

  const dispatch=useDispatch()
  //intialData for formik
  const initialValues = {
    latest_desc: details?.description,
    image:details?.ota_notices_pdf
  };

  //Yup validator Schema
  const EditInformationSchema = Yup.object({
    latest_desc: Yup.string()

      .required("required"),
   
  });

  //formik validation
  const {
    values,
    handleBlur,
    resetForm,
    handleChange,
    setFieldValue,
    handleSubmit,
    errors,
    touched,
  } = useFormik({
    initialValues,
    validationSchema: EditInformationSchema,
    validateOnChange: true,
    enableReinitialize: true,
    validateOnBlur: false,
    onSubmit: async(values, action) => {
      try {
        const formdata= new FormData()
        setIsLoading(true)
        if (!values.image) {
          toast.error("Please upload a PDF file.");
          return;
        }
        formdata.append("ota_notice_pdf", values?.image)
        formdata.append("description", values?.latest_desc);
        formdata.append("id", details?.ota_notices_id)
        const {data}= await axiosConfig.put("/edit-notice", formdata);
        if(data?.status === "success"){
          toast.success(data?.message || "Latest Info updated successfully")
          setShowModal(false)
          resetForm()
          dispatch(getAllLatestInformation())
   
        }
       } catch (error) {
          toast.error("something Went wrong")
       }finally{
        setIsLoading(false)
       }
    },
  });
  const handleImageChange = (files) => {
    if (files.length) {
      setFieldValue("image", files[0]);
    } 
  };
  const closeModal = (e) => {
    e?.target?.classList?.forEach((classes) => {
      if (classes === "Modal-Container") {
        setShowModal(false);
        resetForm();
        setLatestDocs([]);
      }
    });
  };
  return (
    <div>
      <div
        onClick={(e) => {
          e.stopPropagation();
          setShowModal(true);
          resetForm();
        }}
        className="flex justify-start cursor-pointer relative items-center flex-grow-0 flex-shrink-0 gap-3"
      >
      <button
                        
      className="group/edit invisible group-hover/item:visible flex justify-center items-center flex-grow-0 flex-shrink-0 relative rounded  p-2 bg-white shadow-sm hover:bg-blue-400 transition-all cursor-pointer  hover:rounded-md text-black"
    >
      <MdOutlineEdit className="w-4 h-4" />
    </button>
      </div>

      {showModal ? (
        <>
          <div
            className="flex justify-center items-center overflow-x-hidden overflow-y-auto fixed inset-0 z-50 outline-none focus:outline-none shadow-lg w-full Modal-Container"
            onClick={closeModal}
          >
            <div className="relative w-auto my-6 mx-auto max-w-3xl">
              <div className="border-0 rounded-lg shadow-lg relative flex w-full bg-white outline-none focus:outline-none">
                <div className="flex items-start gap-1 p-2 border-b border-solid border-gray-300 rounded-t flex-col ">
                  <h3 className="text-xl font=semibold">
                    Edit Latest Information
                  </h3>
                  <div className="flex justify-between items-start">
                    <div className="flex flex-col mt-4">
                   <Files
                    className="files-dropzone cursor-pointer"
                    onChange={(file) => handleImageChange(file)}
                    onError={(error) => toast.error(error?.message)}
                    accepts={[".pdf"]}
                    maxFileSize={512000}
                    minFileSize={0}
                    multiple
                    clickable
                  >
                    {values.image ? (
                      <div className="flex flex-col items-center relative">
                            {
                              typeof values.image==="object"? 
                              <div className="flex flex-col justify-center items-center flex-grow-0 flex-shrink-0 h-[122px] w-[122px] relative gap-1  py-7 rounded bg-[#186ced]/[0.04] border-[1.33px] border-[#186ced]/[0.64] border-dashed px-2">
                              <DocumentIcon />
                                    <span className='break-all'>
                        {`${values?.image?.name?.split(".")[0]?.slice(0,20)}.${values?.image?.name?.split(".")[1]}`}
                        </span>
                              </div>
                :      
                <div className="flex flex-col justify-center items-center flex-grow-0 flex-shrink-0 h-[122px] w-[122px] px-2 relative gap-1  py-7 rounded bg-[#186ced]/[0.04] border-[1.33px] border-[#186ced]/[0.64] border-dashed">
                <DocumentIcon />
                                <span className='break-all'>
                {`${values?.image?.split("/")?.pop()?.split(".")[0]?.slice(0,20)}.${values?.image?.split("/")?.pop()?.split(".")[1]}`}
                        </span>
                      </div>
                }
                      </div>
                    ) : (
                      <div className="flex flex-col justify-center items-center flex-grow-0 flex-shrink-0 h-[122px] w-[122px] relative gap-1 px-[23.5px] py-7 rounded bg-[#186ced]/[0.04] border-[1.33px] border-[#186ced]/[0.64] border-dashed">
                        <UploadIcon />
                        <div className="flex justify-start items-center flex-grow-0 flex-shrink-0 relative gap-1 px-[5px] py-0.5 rounded">
                          <p className="flex-grow-0 flex-shrink-0 text-xs font-medium text-center text-black/[0.64]">
                            Upload
                          </p>
                        </div>
                      </div>
                    )}
                  </Files>
                      <p>(upload only pdf)</p>
                      {/* { locationImages?.length ?  <span>{locationImages.length} selected</span>:null} */}
                    </div>
                    <div className="relative p-6 pt-0 flex-auto">
                      <div className="flex flex-col justify-start items-start self-stretch flex-grow-0 flex-shrink-0 gap-1">
                        <div className="flex justify-start items-start self-stretch flex-grow-0 flex-shrink-0 relative gap-1 flex-col">
                          <div className="flex-grow-0 flex-shrink-0 w-[150px]">
                            <p className="text-[13px] leading-10 py-[1px] font-medium text-left text-black/80">
                              Description
                            </p>
                          </div>
                          <div className="flex flex-col flex-grow w-[300px]">
                            <textarea
                              type="text"
                              className="form-input w-full h-40 rounded-md bg-gray-100 capitalize border-transparent focus:border-gray-500 focus:bg-white focus:ring-0 placeholder:text-sm"
                              placeholder="Type here"
                              value={values.latest_desc}
                              onChange={handleChange} // <-- Corrected
                              onBlur={handleBlur}
                              name="latest_desc"
                              style={
                                errors.latest_desc && touched.latest_desc
                                  ? { border: "1px solid red" }
                                  : {}
                              }
                            ></textarea>

                       
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="flex justify-start items-start flex-grow-0 flex-shrink-0 gap-2 self-end">
                    <div
                      onClick={(e) => {
                        e.stopPropagation();
                        setShowModal(false);
                      }}
                      className="flex justify-start items-center flex-grow-0 flex-shrink-0 relative overflow-hidden gap-1 pl-4 pr-5 py-[11px] rounded-[94px] bg-[#fff] cursor-pointer"
                      style={{ boxShadow: "0px 1px 0px 0 rgba(0,0,0,0.08)" }}
                    >
                      <p className="flex-grow-0 flex-shrink-0 text-sm font-medium text-center text-black">
                        Cancel
                      </p>
                    </div>
                    <button
                      type="button"
                      className="flex justify-start items-center flex-grow-0 flex-shrink-0 relative overflow-hidden gap-1 px-5 py-[11px] rounded-[94px] bg-[#186ced] cursor-pointer"
                      style={{ boxShadow: "0px 1px 0px 0 rgba(0,0,0,0.08)" }}
                      onClick={handleSubmit}
                    >
                      <p className="flex-grow-0 flex-shrink-0 text-sm font-medium text-center text-white">
                        Save
                      </p>
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </>
      ) : null}
    </div>
  );
};

export default EditLatestInformation;

const DocumentIcon = (props) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 20 20"
    xmlSpace="preserve"
    width={20}
    height={20}
    {...props}
  >
    <path
      fill="none"
      stroke="#000"
      strokeWidth={1.25}
      strokeMiterlimit={10}
      d="M15.625 17.5H4.375V2.5h7.5l3.75 3.75z"
    />
    <path
      fill="none"
      stroke="#000"
      strokeWidth={1.25}
      strokeMiterlimit={10}
      d="M11.875 2.5v3.75h3.75"
    />
    <path d="M6.875 13.125h6.25v1.25H6.875zm0 -2.5h6.25v1.25H6.875zm0 -2.5h6.25v1.25H6.875z" />
  </svg>
);