import React from 'react'
import Template from '../../components/common/Template'

const Dashboard = () => {
  return (
   <Template>
     Dashboard
   </Template>
  )
}

export default Dashboard
