import { createSlice } from "@reduxjs/toolkit";

const NewMedalistProfile= createSlice({
    name:"medalistprofile",
    initialState:{
       MedalistProfileLoading:false,
       MedalistProfileList:[],
       page:1,
       total_pages:0,
       selectedTab:0,
       TotalMedalistProfileList:0
    },

    reducers:{
        getMedalistProfileRequest:(state)=>{
            state.MedalistProfileLoading=true;
        },
        getMedalistProfileSuccess:(state, {payload})=>{
            state.MedalistProfileLoading=false;
            state.MedalistProfileList=payload?.otaMedalistDTOList;
            state.TotalMedalistProfileList=payload?.total_elements
        },
        getMedalistProfileFail:(state)=>{
            state.MedalistProfileLoading=false;
        },
        setSelectedMedalistProfileTab:(state,{payload})=>{
            state.selectedTab=payload
        },
        setMedalistProfilePage:(state,{payload})=>{
            state.page=payload
         },
        setTotalPageMedalistProfilePage:(state,{payload})=>{
            state.total_pages=payload
        }
    }
})
export default  NewMedalistProfile.reducer;
export const {getMedalistProfileFail, getMedalistProfileRequest, getMedalistProfileSuccess, setMedalistProfilePage, setSelectedMedalistProfileTab,setTotalPageMedalistProfilePage }=NewMedalistProfile.actions
