import React, { useEffect, useState } from 'react'
import Template from '../../components/common/Template'
import DeleteOtaClub from './DeleteOtaClub'
import AddOtaClub from './AddOtaClub';
import EditOtaClub from './EditOtaClub'
import { useDispatch, useSelector } from 'react-redux'
import { getAllCityList, getAllCityListAllModule, getAllOtaClubList } from '../../services/apiService'
import Pagination from '../../components/common/pagination';
import { setOtaClubListPage } from '../../redux/slice/OtaClubSlice';
import Select from 'react-select';
import { Link, useNavigate } from 'react-router-dom';
import * as routesKey from "../../constants/routes";
import { MdOutlineEdit } from "react-icons/md";
const Ota_Club_Index = () => {
  const [selectedTrainingPlace, setSelectedTrainingPlace] = useState('');
  const [selectedDistrict, setselectedDistrict] = useState({ value: "all", label: "All Cities" })
  const {OtaClubList, totalOtaClubList}=useSelector((state)=>state.otaclub)

  
  const [page, setpage] = useState(1);


  const navigate=useNavigate()
  const dispatch=useDispatch()
  const handleTrainingPlaceChange = (event) => {
    setSelectedTrainingPlace(event.target.value);
  };

  const {AllCityListModule}=useSelector((state)=>state.citylist)
  const options = AllCityListModule.map(({ city_id, city_name, city_image }) => ({
    value: city_id,
    label: (
      <div className="flex items-center justify-between gap-2">
        <div className="flex items-center gap-2">
          <img
            src={city_image}
            alt={city_name}
            className="h-5 w-5 rounded-full object-cover"
          />
          {city_name}
        </div>
       
      </div>
    ),
  }));

    useEffect(()=>{
      dispatch(getAllOtaClubList(selectedDistrict.value==="all"?"":selectedDistrict.value,page-1,10))
    },[dispatch,page])


    useEffect(()=>{
      dispatch(getAllCityListAllModule())
    },[dispatch])
  

    const handleCitySelect = (selectedOption) => {
      setselectedDistrict(selectedOption)
      if (selectedOption.value === "all") {
  
        dispatch(getAllOtaClubList());
      } else {
        const cityId = selectedOption.value;
        dispatch(getAllOtaClubList(cityId));
      }
    };
    

    
const customStyles = {
  control: (provided) => ({
    ...provided,
    width: '100%',
    height: '12px',
    borderRadius: '25px', // Set border-radius for oval shape
    border: '1px solid #ccc', // Add border for clarity
    padding: '0 10px', // Add padding
    margin: '0 auto', // Center the Select component
  }),
  menu: (provided) => ({
    ...provided,
    width: '100%',
    borderRadius: '10px', // Set border-radius for options
  }),
};
  return (
    <Template>
      <div>
      <div className="flex justify-start bg-[#F3F5F8] items-center self-stretch flex-grow-0 flex-shrink-0 gap-2 px-4 py-4">
    <div className="flex justify-start items-center flex-grow relative gap-[22px]">
      <p className="flex-grow-0 max-md:text-[20px] flex-shrink-0 text-2xl font-semibold text-left capitalize text-[#202223]">
        Manage Ota Clubs
      </p>
    </div>
    <div className="flex justify-start items-start flex-grow-0 flex-shrink-0 gap-3">
    {/* Add a Select tab to filter the training place */}
    <Select
    placeholder="Select District here"
    size="lg"
    options={[{ value: "all", label: "All Cities" }, ...options]}    styles={customStyles}
    onChange={handleCitySelect}
    value={selectedDistrict}
  />
  </div>
    <div className="flex justify-start items-start flex-grow-0 flex-shrink-0 gap-3 ml-4">
    <Link
    to={routesKey.ADDOTACLUB}
    className="flex justify-start items-center flex-grow-0 flex-shrink-0 relative overflow-hidden gap-1 pl-4 pr-5 py-[10px] rounded-full bg-[#186ced]"
    style={{ boxShadow: "0px 1px 0px 0 rgba(0,0,0,0.08)" }}
  >
  
    <p className="flex-grow-0 flex-shrink-0 text-sm font-medium text-center text-white">
      Add Ota Club
    </p>
  </Link>
    </div>
   
  </div>
    <div className="flex flex-col max-md:hidden justify-start items-start self-stretch flex-grow overflow-hidden ">
    <div className="flex flex-col  items-start self-stretch flex-grow min-h-[70vh] justify-between w-full">
      <>
      {OtaClubList?.length ? (
        <>
          {false ? null : (
            <div className="flex flex-col w-full">
              <table>
                <thead>
                  <th></th>
                  <th></th>
                  <th></th>
                  <th></th>
                </thead>
                <tbody className="flex flex-col gap-4 p-4">
                  {OtaClubList?.map((club) => (
                    <tr
                      key={club?.ota_club_id}
                      className="border group/item  bg-white hover:bg-slate-100 transition-all border-b border-black/5 cursor-pointer  flex items-stretch justify-between gap-2 pl-5 pr-3.5 rounded-lg border-solid border-black border-opacity-10 max-md:flex-wrap max-md:justify-center max-md:pl-5 w-full"
                    >
                       <td className="flex justify-between items-center gap-3 mt-2 mb-2 max-md:max-w-full max-md:flex-wrap">
                        <img
                          className="aspect-[1.29] object-contain object-center w-20 h-24 overflow-hidden"
                           src={club?.logo}
                          alt="preview"
                          effect="blur"
                        />
                

                      </td>
                      <td className="self-center  flex   flex-col "> 
                      <div className="text-slate-500 text-xs font-medium leading-3 whitespace-nowrap">
                      Coach Name
                      </div>
                      <div className="flex items-stretch   mt-2">
                        <div className=" text-xs font-medium leading-3"
                        >
                         {club?.coach_name}
                        </div>
                      </div>
                    </td>

                      <td className="self-center  flex    flex-col "> 
                        <div className="text-slate-500 text-xs font-medium leading-3 whitespace-nowrap">
                        Contact_no
                        </div>
                        <div className="flex items-stretch   mt-2">
                          <div className=" text-xs font-medium leading-3"
                          >
                           {club?.contact_no}
                          </div>
                        </div>
                      </td>

                      <td className="self-center flex flex-col items-stretch my-auto">
                      <div className="text-slate-500 text-xs font-medium leading-3 whitespace-nowrap">
                        training place
                      </div>
                      <div className="text-stone-900 text-xs font-medium leading-3 whitespace-normal mt-2.5 max-w-[120px]">
                        {club?.training_place}
                      </div>
                    </td>
                      <td className="self-center flex flex-col items-stretch my-auto">
                      <div className="text-slate-500 text-xs font-medium leading-3 whitespace-nowrap">
                        Club Name
                      </div>
                      <div className="text-stone-900 text-xs font-medium leading-3 whitespace-normal mt-2.5 max-w-[120px]">
                        {club?.club_name}
                      </div>
                    </td>
                    

                      <td className="self-center flex   flex-col items-stretch my-auto">
                        <div className="text-slate-500 text-xs font-medium leading-3 whitespace-nowrap">
                        Training time 
                        </div>
                        <div className="text-stone-900 text-xs font-medium leading-3 whitespace-nowrap mt-2.5">
                          {club?.from_training_time}
                        </div>
                        <div className="text-stone-900 text-xs font-medium leading-3 whitespace-nowrap mt-2.5">
                          {club?.to_training_time}
                        </div>
                      </td>
                      <td className="self-center flex   flex-col items-stretch my-auto">
                        <div className="text-slate-500 text-xs font-medium leading-3 whitespace-nowrap">
                         District 
                        </div>
                        <div className="text-stone-900 text-xs font-medium leading-3 whitespace-nowrap mt-2.5">
                          {club?.city_name}
                        </div>
                      </td>
                      <td className="self-center flex     flex-col items-stretch my-auto">
                        <div className="text-slate-500 text-xs font-medium leading-3 whitespace-nowrap">
                        From_validity
                        </div>
                        <div className="text-stone-900 text-xs font-medium leading-3 whitespace-nowrap mt-2.5">
                          {club?.from_validity}
                        </div>
                      </td>
                      <td className="self-center flex     flex-col items-stretch my-auto">
                        <div className="text-slate-500 text-xs font-medium leading-3 whitespace-nowrap">
                        To_validity
                        </div>
                        <div className="text-stone-900 text-xs font-medium leading-3 whitespace-nowrap mt-2.5">
                          {club?.to_validity}
                        </div>
                      </td>
                      <td className="self-center flex     flex-col items-stretch my-auto">
                        <div className="text-slate-500 text-xs font-medium leading-3 whitespace-nowrap">
                        Reg_no
                        </div>
                        <div className="text-stone-900 text-xs font-medium leading-3 whitespace-nowrap mt-2.5">
                          {club?.reg_no}
                        </div>
                      </td>
                      <td className="self-center flex    flex-col items-stretch my-auto">
                        <div className="text-slate-500 text-xs font-medium leading-3 whitespace-nowrap">
                        Head_name
                        </div>
                        <div className="text-stone-900 text-xs font-medium leading-3 whitespace-nowrap mt-2.5">
                          {club?.head_name}
                        </div>
                      </td>
                      <td className="self-center flex    flex-col items-stretch my-auto">
                        <div className="text-slate-500 text-xs font-medium leading-3 whitespace-nowrap">
                        Is Visible
                        </div>
                        <div className="text-stone-900 text-xs font-medium leading-3 whitespace-nowrap mt-2.5">
                          {club?.is_visible ? "Active" :"Disbale"}
                        </div>
                      </td>
                      <td className="self-center flex items-stretch gap-2.5 my-auto">    
                      <Link
                      to={`/editOtaClub/${club?.ota_club_id}-${page}`}
                       
                    >
                      <button city_id={selectedDistrict?.value==="all" ? "":selectedDistrict.value}setpage={setpage} onClick={() => navigate(`/editOtaClub/${club?.ota_club_id}`)} className="group/edit invisible group-hover/item:visible flex justify-center items-center flex-grow-0 flex-shrink-0 relative rounded  p-2 bg-white shadow-sm hover:bg-blue-400 transition-all cursor-pointer">
                      <MdOutlineEdit className="w-4 h-4" />
                    </button>
                    </Link>
                          <DeleteOtaClub
                          page={page}
                          setpage={setpage}
                            className="group/edit invisible group-hover/item:visible flex justify-center items-center flex-grow-0 flex-shrink-0 relative rounded  p-2 bg-white shadow-sm hover:bg-blue-400 transition-all cursor-pointer  hover:rounded-md text-black"
                            otaClubDeleteId={club?.ota_club_id}
                          />
                        
                      </td>
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>
          )}
        </>
      ) : (
        <div className="flex justify-center items-center h-[70vh] w-full">
          <span className="text-center w-full text-xl text-[#7b8794]">
            No Ota Club Found
          </span>
        </div>
      )}
      </>
      <div className="flex justify-center w-full p-4 mb-4">  
      <Pagination
      className="pagination-bar"
      currentPage={page}
      totalCount={totalOtaClubList} // Assuming 10 items per page
      pageSize={10}
                onPageChange={(newPage) => { 
                  setpage(newPage)
                    var currentScroll = document.documentElement.scrollTop || document.body.scrollTop;
                      if (currentScroll > 0) {
                        window.scrollTo(0, currentScroll - (currentScroll / 1));
                      }
                }
                 }
    />              
      </div>
    </div>
   
  </div>
  


  </div>
    </Template>
  )
}

export default Ota_Club_Index
