import React, { useEffect, useState } from 'react'
import Template from '../../components/common/Template'
import { useNavigate, useParams } from 'react-router-dom'
import {ReactComponent as BackButton} from "../../assets/icons/back-button.svg"
import { PDFICON } from '../Competation'
import EditColorBeltParticipateDetails from './EditColorBeltParticipateDetails'
import { useDispatch, useSelector } from 'react-redux'
import { getColorBeltHeadingDetails, getColorBeltParticipants } from '../../services/api'
import axiosConfig from '../../axios_config/axios_instance'
import toast from 'react-hot-toast'
const ColorBeltParticiparionDetails = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [page, setpage] = useState(1);
  const pagesize = 10;
  const {colorBeltHeadingDetails,totalColorBeltParticipants,colorBeltParticipants }=useSelector((state)=>state.onlineRegistartion)
  const {color_belt_id } = useParams();
  
  useEffect(() => {
    if (color_belt_id) { 
      dispatch(getColorBeltHeadingDetails(color_belt_id))
    }
  }, [color_belt_id])
  useEffect(() => {
    dispatch(getColorBeltParticipants(color_belt_id,page,pagesize))
  },[page])

  const exportAllData = async (id) => {
    if (!colorBeltParticipants?.length) {
      toast.error("No data to export");
      return;
    }
    try {
      downloadFiles()
    } catch (error) {
      
    }
  }

  const downloadZipFile = (data) => {
    const blob = new Blob([data], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' });
    const link = document.createElement('a');
    link.href = window.URL.createObjectURL(blob);
    link.download = `${colorBeltHeadingDetails?.color_belt_test_data?.heading}.xlsx`;
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  };
  const downloadFiles = () => {
           axiosConfig.get(`/excel-export-colour-belt-champion?heading_id=${color_belt_id}`, { responseType: 'arraybuffer', })
      .then(response => {
        downloadZipFile(response.data);
      })
      .catch(error => {
        console.error('Error downloading files:', error);
      });
  };
  return (
      <Template>
           <div className="flex justify-start items-start self-stretch flex-grow-0 flex-shrink-0 gap-0 px-8 pr-0 pt-12 pb-20 bg-white relative flex-col">
        <div className="flex flex-col justify-start items-start flex-grow-0 flex-shrink-0 w-[768px] gap-12">
        <div className="flex flex-col justify-start  self-stretch flex-grow-0 flex-shrink-0 relative gap-2 items-start">
              <button onClick={()=>navigate(-1)} className="flex gap-2 items-center cursor-pointer">
              <BackButton />
              <p>
                Color Belt Headings
                </p>
              </button>
              <div className='flex justify-between items-center w-full'>
              <p className="self-stretch flex-grow-0 flex-shrink-0 w-full text-xl font-semibold text-left text-[#202223]">
              {colorBeltHeadingDetails?.color_belt_test_data?.heading}
              </p>
          {color_belt_id&&      <button
                className="flex justify-start items-center flex-grow-0 flex-shrink-0 relative overflow-hidden gap-1 pl-4 pr-5 max-md:py-[7px] py-[10px] rounded-full bg-[#186ced]"
                style={{ boxShadow: "0px 1px 0px 0 rgba(0,0,0,0.08)" }}
                onClick={exportAllData}
              >
      <p className="flex-grow-0 flex-shrink-0 text-sm font-medium text-center text-white">
        Export Data
      </p>
    </button>}
            </div>
          </div>
          <p className="self-stretch flex-grow-0 flex-shrink-0 w-[768px] text-xl font-semibold text-left text-[#202223]">
                List of Students Participate
              </p>
              </div>
              {
                colorBeltParticipants?.length ?
      <div className=" px-4 sm:px-8 py-4 overflow-x-auto w-full">
      <div
        className="inline-block min-w-full shadow-md rounded-lg overflow-hidden"
      >
        <table className="min-w-full leading-normal">
        <thead>
            <tr className='border-b-2 border-gray-200'>
              <th
                className="px-5 py-3  bg-gray-100 text-left text-xs font-semibold text-gray-700 uppercase tracking-wider"
              >
                Name
              </th>
              <th
                className="px-5 py-3  bg-gray-100 text-left text-xs font-semibold text-gray-700 uppercase tracking-wider"
              >
                OTA ID
              </th>
              <th
                className="px-5 py-3  bg-gray-100 text-left text-xs font-semibold text-gray-700 uppercase tracking-wider"
              >
               GENDER
              </th>
              <th
                className="px-5 py-3  bg-gray-100 text-left text-xs font-semibold text-gray-700 uppercase tracking-wider"
              >
               DOB
                                                      </th>
              <th
                className="px-5 py-3  bg-gray-100 text-left text-xs font-semibold text-gray-700 uppercase tracking-wider"
              >
               Present Belt
                                          </th>
                                          <th
                className="px-5 py-3  bg-gray-100 text-left text-xs font-semibold text-gray-700 uppercase tracking-wider"
              >
               Applied Belt
                                                      </th>
              <th
                className="px-5 py-3  bg-gray-100 text-left text-xs font-semibold text-gray-700 uppercase tracking-wider"
              >
               DISTRICT
                                                      </th>
              <th
                className="px-5 py-3  bg-gray-100 text-left text-xs font-semibold text-gray-700 uppercase tracking-wider"
              >
               CLUB NAME
                                                      </th>
            
              <th
                className="px-5 py-3  bg-gray-100 text-left text-xs font-semibold text-gray-700 uppercase tracking-wider"
              >
              </th>
            </tr>
          </thead>
          <tbody>
                    {
                      colorBeltParticipants?.map((register,i) => (
                        <tr key={i}  className='cursor-pointer'>
                          <td className="px-5 py-5 border-b border-gray-200 bg-white text-sm">
                    <p className="text-gray-900 whitespace-nowrap">
                      {register.name}
                    </p>
              </td>
                          <td className="px-5 py-5 border-b border-gray-200 bg-white text-sm">
                    <p className="text-gray-900">
                      {register.ota_id}
                    </p>
              </td>
            
              <td className="px-5 py-5 border-b border-gray-200 bg-white text-sm">
                    <p className="text-gray-900 whitespace-nowrap">
                      {register.gender}
                    </p>
              </td>
              <td className="px-5 py-5 border-b border-gray-200 bg-white text-sm">
                    <p className="text-gray-900 whitespace-nowrap">
                      {register.dob}
                    </p>
              </td>
              <td className="px-5 py-5 border-b border-gray-200 bg-white text-sm">
                    <p className="text-gray-900 whitespace-nowrap">
                      {register.present_belt}
                    </p>
              </td>
              <td className="px-5 py-5 border-b border-gray-200 bg-white text-sm">
                    <p className="text-gray-900 whitespace-nowrap">
                      {register.active_belt}
                    </p>
              </td>
              <td className="px-5 py-5 border-b border-gray-200 bg-white text-sm">
                    <p className="text-gray-900 whitespace-nowrap">
                      {register.city}
                    </p>
              </td>
              <td className="px-5 py-5 border-b border-gray-200 bg-white text-sm">
              <p className="text-gray-900 whitespace-nowrap">
                      {register.club_name}
                    </p>
              </td>
                          <td className="px-5 py-5 border-b border-gray-200 bg-white text-sm">
                            <div className='flex gap-2 items-center'>
                          <EditColorBeltParticipateDetails details={register} page={page} pagesize={pagesize} color_belt_id={color_belt_id} />
                          </div>
              </td>
            </tr>
                      ))
        }
            
          </tbody>
        </table>
      </div>
    </div>:<div className="flex justify-center items-center h-[70vh] w-full">
              <span className="text-center w-full text-xl text-[#7b8794]">
                No Registrations found
              </span>
            </div>
        }
        </div>
    </Template>
  )
}

export default ColorBeltParticiparionDetails