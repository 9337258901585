import { createSlice } from "@reduxjs/toolkit";

const NewTechnicalComittiee = createSlice({
    name: "technicalcommitiee",
    initialState: {
      TechnicalCommitieList:[],
      TechnicalComiittieLoading: false,
      selectedTechnicalCommitiee:0,
      page:1,
      total_pages:0,
      totalTechnicalCommiteeList:0,
    },
    reducers: {
         getTechnicalCommitieeRequest:(state)=>{
            state.TechnicalComiittieLoading=true;
         },
         getTechnicalCommiitieSuccess:(state, {payload})=>{
            state.TechnicalComiittieLoading=false;
            state.TechnicalCommitieList = payload?.technical_committee_data
            state.totalTechnicalCommiteeList=payload?.total_elements
            
         },
         getTechnicalCommittieFail:(state)=>{
            state.TechnicalComiittieLoading=false;
            state.TechnicalCommitieList=[];
         },
         setSelectedTechnicalCommitiee:(state,{payload})=>{
            state.selectedTechnicalCommitiee=payload
         },
         setSelectedTechnicalPage:(state,{payload})=>{
            state.page=payload
         },
        setTotalPageTechnicalCommitiee:(state,{payload})=>{
            state.total_pages=payload
        }
    }
})  

export default NewTechnicalComittiee.reducer;
export const {getTechnicalCommiitieSuccess, getTechnicalCommitieeRequest, getTechnicalCommittieFail, setSelectedTechnicalCommitiee, setSelectedTechnicalPage, setTotalPageTechnicalCommitiee,}= NewTechnicalComittiee.actions