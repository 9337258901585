import { createSlice } from "@reduxjs/toolkit";

const NewCoachesProfile = createSlice({
    name:"coachesprofile",
    initialState: {
        CoachesProfileLoading: false,
        CochesProileList:[],
        page:1,
        total_pages:0,
        selectedTabCoach:0,
        TotalCoachesProfileList:0
    },
    reducers: {
        getCoachesProfileRequest: (state) => {
            state.CoachesProfileLoading = true;
        },
        getCoachesProfileSuccess: (state,{payload}) => {
            state.CoachesProfileLoading = false;
            state.CochesProileList = payload?.coach_profile_data;
            state.TotalCoachesProfileList=payload?.total_elements
        },
        getCoachesProfileFail: (state) => {
            state.CoachesProfileLoading = false;
            state.CochesProileList = [];
        },
        setSelectedCochesProfile:(state,{payload})=>{
            state.selectedTabCoach=payload
        },
        setCochesProfilePage:(state,{payload})=>{
            state.page=payload
         },
        setTotalPageCochesProfile:(state,{payload})=>{
            state.total_pages=payload
        }
    }
})

export default NewCoachesProfile.reducer;
export const { getCoachesProfileFail,getCoachesProfileRequest,getCoachesProfileSuccess, setSelectedCochesProfile, setCochesProfilePage, setTotalPageCochesProfile} = NewCoachesProfile.actions;