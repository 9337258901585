import { createSlice } from "@reduxjs/toolkit";

const NewOwnerProfile= createSlice({
    name:"ownerprofile",
    initialState:{
       OwnerProfileLoading:false,
       OwnerProfileDetails:[],
 
    },

    reducers:{
        getOwnerProfileRequest:(state)=>{
            state.OwnerProfileLoading=true;
        },
        getOwnerProfileSuccess:(state, {payload})=>{
            state.OwnerProfileLoading=false;
            state.OwnerProfileDetails=payload;
        },
        getOwnerProfileFail:(state)=>{
            state.OwnerProfileLoading=false;
            state.OwnerProfileDetails=[]
        },
     
    }
})
export default  NewOwnerProfile.reducer;
export const {getOwnerProfileFail, getOwnerProfileRequest, getOwnerProfileSuccess}=NewOwnerProfile.actions
