import { createSlice } from "@reduxjs/toolkit";

const NewLatestInfo = createSlice({
    name: "latestInfo",
    initialState: {
      LatestInfoList:[],
        LatestInfoLoading: false,
        page:1,
        total_pages:0,
        TotalInformationList:0,
      
    },
    reducers: {
         getLatestInfoRequest:(state)=>{
            state.LatestInfoLoading=true;
         },
         getLatestInfoSuccess:(state, {payload})=>{
            state.LatestInfoLoading=false;
            state.LatestInfoList=payload?.ota_notices_list;
            state.TotalInformationList=payload?.total_elements;
         },
         getLatestInfoFail:(state)=>{
            state.LatestInfoLoading=false;
            state.LatestInfoList=[];
         },
         setLatestInfoPage:(state,{payload})=>{
            state.page=payload
         },
        setTotalInfoPage:(state,{payload})=>{
            state.total_pages=payload
        }
    }
})  

export default NewLatestInfo.reducer;
export const {getLatestInfoFail, getLatestInfoRequest, getLatestInfoSuccess, setLatestInfoPage, setTotalInfoPage}= NewLatestInfo.actions