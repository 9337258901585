import React, { useEffect, useState } from 'react'
import Template from '../../components/common/Template'
import { useNavigate, useParams } from 'react-router-dom'
import {ReactComponent as BackButton} from "../../assets/icons/back-button.svg"
import {ReactComponent as UploadIcon} from "../../assets/icons/uploadicon.svg"
import { useFormik } from 'formik'
import * as Yup from "yup"
import { useDispatch, useSelector } from 'react-redux'
import Files from "react-files"
import toast from 'react-hot-toast'
import { getCitiliesList, getClubNames, getEventList, getParticipantDetails } from '../../services/api'
import axiosConfig from '../../axios_config/axios_instance'
import moment from 'moment'
const EditparticipantDetails = () => {
  const navigate = useNavigate();
  const {participantDetails,eventList,cityList,clubList}=useSelector((state)=>state.onlineRegistartion)
  const dispatch = useDispatch();
  const { id } = useParams();
  //intialData for formik
  const initialValues = {
    student_name: participantDetails?.name? participantDetails?.name:"",
    gender:participantDetails?.gender ?participantDetails?.gender:"",
    dob:participantDetails?.dob ?moment(participantDetails?.dob,"DD-MM-YYYY").format("YYYY-MM-DD"):"",
    weight: participantDetails?.weight ? participantDetails?.weight :"",
    district_name: participantDetails?.city_id ? participantDetails?.city_id :"",
    registred_club: participantDetails?.ota_club_id ? participantDetails?.ota_club_id :"",
    proof: participantDetails?.aadhaar_card ? participantDetails?.aadhaar_card :"",
    athelete_image: participantDetails?.athlete_photo ? participantDetails?.athlete_photo :"",
  };
  const [selectedEvents, setselectedEvents] = useState([])

  useEffect(() => {
    dispatch(getEventList())
    dispatch(getCitiliesList())
  }, [dispatch])
  
  useEffect(() => {
    if (id) {
      dispatch(getParticipantDetails(id))
    }
  },[id])


  useEffect(() => {
    if (participantDetails?.event_Id) {
      setselectedEvents(participantDetails?.event_Id?.map(({event_id})=>event_id))
    }
  },[participantDetails])
  //Yup validator Schema
  const EditparticipantDetailsSchema = Yup.object({
    student_name: Yup.string().required("required"),
    gender: Yup.string().required("required"),
    dob: Yup.string().required("required"),
    weight: Yup.string().required("required"),
    district_name: Yup.string().required("required"),
    registred_club: Yup.string().required("required"),
    athelete_image: Yup.string().required("required"),
    proof: Yup.string(),
  });
  const [isLoading, setisLoading] = useState(false)
  

 


  //formik onSubmit
  const {
    values,
    setFieldValue,
    handleBlur,
    handleChange,
    handleSubmit,
    errors,
    touched,
  } = useFormik({
    initialValues,
    validationSchema: () => {
      return EditparticipantDetailsSchema;
    },
    validateOnChange: true,
    enableReinitialize: true,
    validateOnBlur: false,
    onSubmit: async (values, action) => {
      try {
        if (!selectedEvents?.length) {
          toast.error("Please add atleast one event")
          return;
        }
        const postdata = new FormData();
        if (typeof values.proof === "object") { 
          postdata.append("aadhaar_card", values.proof);
        }
        if (typeof values.athelete_image === "object") { 
          postdata.append("athlete_photo", values.athelete_image);
        }
        postdata.append("championship_heading_id", participantDetails.championship_heading_id);
        postdata.append("championship_id", id);
        postdata.append("city_id", values.district_name);
        postdata.append("dob", moment(values.dob).format("DD-MM-YYYY"));
        postdata.append("event_id", selectedEvents?.join(","));
        postdata.append("gender", values.gender);
        postdata.append("name", values.student_name);
        postdata.append("ota_club_id", values.registred_club);
        postdata.append("weight", values.weight);
        postdata.append("ota_id", participantDetails?.ota_Id);
        setisLoading(true);
        const { data } = await axiosConfig.put(`/champion-registration-edit`, postdata);
        if (data?.data?.status === "success") {
          toast.success(data?.data?.message);
        setisLoading(false);
          navigate(`/champion_participation/${participantDetails?.championship_heading_id}`)
        } else {
          toast.error(data?.data?.message);
        setisLoading(false);
        }
      } catch (error) {
        toast.error("Something went wrong")
        setisLoading(false);
      }
    },
  });

  useEffect(() => {
    if (values.district_name) {
    dispatch(getClubNames(values.district_name))
    }
  }, [values.district_name])
  
  const handleCheckBox = (e, id) => {
    if (e.target.checked) {
      setselectedEvents([...selectedEvents,id])
    } else {
      setselectedEvents(selectedEvents?.filter((val)=>val!==id))
    }
  }
  return (
      <Template>
            <div className="flex justify-start items-start self-stretch flex-grow-0 flex-shrink-0 gap-0 px-8 pr-0 pt-12 pb-20 bg-white relative flex-col">
        <div className="flex flex-col justify-start items-start flex-grow-0 flex-shrink-0 w-[768px] gap-12">
        <div className="flex flex-col justify-start  self-stretch flex-grow-0 flex-shrink-0 relative gap-2 items-start">
              <button onClick={()=>navigate(-1)} className="flex gap-2 items-center cursor-pointer">
              <BackButton />
              <p>
                Participate List
                </p>
            </button>
            <div className='flex w-full justify-between'>
              <p className="self-stretch flex-grow-0 flex-shrink-0 w-[768px] text-xl font-semibold text-left text-[#202223]">
              Edit Participate Details
              </p>
              <div className="flex justify-start items-start flex-grow-0 flex-shrink-0 gap-2">
          <button
            type="button"
            onClick={handleSubmit}
            className="flex justify-start items-center flex-grow-0 flex-shrink-0 relative overflow-hidden gap-1 px-5 py-[11px] rounded-[94px] bg-[#186ced]"
            style={isLoading ? { boxShadow: "0px 1px 0px 0 rgba(0,0,0,0.08)", backgroundColor: "gray" } : { boxShadow: "0px 1px 0px 0 rgba(0,0,0,0.08)" }}
            disabled={isLoading ? "disable" : ""}
          >
            <p className="flex-grow-0 flex-shrink-0 text-sm font-medium text-center text-white">
              Save
            </p>
          </button>
            </div>
            </div>
          </div>
          <div className="flex flex-col justify-start items-start self-stretch flex-grow-0 flex-shrink-0 relative">
              <p className="self-stretch flex-grow-0 flex-shrink-0 w-[768px] text-xl font-semibold text-left text-[#202223]">
                Athelate Image
            </p>
            <Files
                            className="files-dropzone cursor-pointer"
              onChange={(file) => {
                if (file?.length) {
                  setFieldValue("athelete_image", file[0]);
                               }
                             }
                             
                            }
                            onError={(err)=>toast.error(err?.message)}
                            accepts={["image/png", "image/jpeg", "image/jpg"]}
                            maxFileSize={2000000}
                            minFileSize={0}
                            multiple
                            clickable
                          >
                            {values?.athelete_image ? <div
                              // key={i}
                              className="flex flex-col justify-center items-center flex-grow-0 flex-shrink-0 h-[122px] w-[122px] relative gap-1 px-[23.5px] py-7 rounded bg-[#186ced]/[0.04] border-[1.33px] border-[#186ced]/[0.64] border-solid"
                            >
                              {
                                <div>
                                  <img
                                    src={typeof values?.athelete_image === "object" ? values?.athelete_image?.preview?.url : values?.athelete_image}
                                    className="w-[122px] h-[122px] object-cover"
                                    alt="category_images"
                                  />
                                </div>
                              }
                            </div> : <div className="flex flex-col justify-center items-center flex-grow-0 flex-shrink-0 h-[122px] w-[122px] relative gap-1 px-[23.5px] py-7 rounded bg-[#186ced]/[0.04] border-[1.33px] border-[#186ced]/[0.64] border-dashed">
                              <UploadIcon />
                              <div className="flex justify-start items-center flex-grow-0 flex-shrink-0 relative gap-1 px-[5px] py-0.5 rounded">
                                <p className="flex-grow-0 flex-shrink-0 text-xs font-medium text-center text-black/[0.64]">
                                  Upload
                                </p>
                              </div>
                            </div>}
                          </Files>
</div>
          <div
            id="basic_info"
            className="flex flex-col justify-start items-start self-stretch flex-grow-0 flex-shrink-0 gap-4"
          >
            <div className="flex flex-col justify-start items-start self-stretch flex-grow-0 flex-shrink-0 relative">
              <p className="self-stretch flex-grow-0 flex-shrink-0 w-[768px] text-xl font-semibold text-left text-[#202223]">
                Basic Information
              </p>
            </div>
            <div className="flex flex-col justify-start items-start self-stretch flex-grow-0 flex-shrink-0 gap-4">
              <div className="flex justify-start items-center self-stretch flex-grow-0 flex-shrink-0 relative gap-2.5">
                <p className="flex-grow-0 flex-shrink-0 w-[150px] text-[13px] font-medium text-left text-black/80">
                  OTA ID NO
                </p>
                <div className="flex flex-col justify-start items-start flex-grow h-11 gap-2.5 rounded-[5px]">
                  <input
                    className="flex flex-col justify-start items-start self-stretch flex-grow-0 flex-shrink-0 relative overflow-hidden gap-2.5 px-4 py-3 rounded bg-white border border-[#dbe1ea]"
                    type='text'
                    value={participantDetails?.ota_Id}
                    disabled
                  />
                  
                </div>
              </div>
              <div className="flex justify-start items-center self-stretch flex-grow-0 flex-shrink-0 relative gap-2.5">
                <p className="flex-grow-0 flex-shrink-0 w-[150px] text-[13px] font-medium text-left text-black/80">
                  Name
                </p>
                <div className="flex flex-col justify-start items-start flex-grow h-11 gap-2.5 rounded-[5px]">
                  <input
                    className="flex flex-col justify-start items-start self-stretch flex-grow-0 flex-shrink-0 relative overflow-hidden gap-2.5 px-4 py-3 rounded bg-white border border-[#dbe1ea]"
                    type='text'
                    value={values.student_name}
                    name="student_name"
                     onChange={handleChange}
                     onBlur={handleBlur}
                     style={
                       errors.student_name && touched.student_name
                         ? { border: "2px solid red" }
                         : {}
                    }
                  />
                  
                </div>
              </div>
              <div className="flex justify-start items-center self-stretch flex-grow-0 flex-shrink-0 relative gap-2.5">
                <p className="flex-grow-0 flex-shrink-0 w-[150px] text-[13px] font-medium text-left text-black/80">
                Gender
                </p>
                <div className="flex flex-col justify-start items-start flex-grow gap-4 rounded-[5px]">
                  <select
                   className="flex justify-start items-center self-stretch flex-grow relative gap-[5px] px-4 py-2 rounded bg-slate-50 border border-[#dbe1ea]"
                    placeholder="Enter Your Name"
                    value={values.gender}
                    name="gender"
                     onChange={handleChange}
                     onBlur={handleBlur}
                     style={
                       errors.gender && touched.gender
                         ? { border: "2px solid red" }
                         : {}
                    }
                  >
                    <option>Select</option>
                    <option>Male</option>
                    <option>Female</option>
                    <option>Others</option>
                  </select>
                </div>
              </div>
              <div className="flex justify-start items-start self-stretch flex-grow-0 flex-shrink-0 relative gap-2.5">
                <p className="flex-grow-0 flex-shrink-0 w-[150px] h-[38px] text-[13px] font-medium text-left text-black/80">
                  DOB
                </p>
                <div className="flex flex-col justify-start items-start flex-grow h-11 gap-2.5 rounded-[5px]">
                  <input
                    className="flex flex-col justify-start items-start self-stretch flex-grow-0 flex-shrink-0 relative overflow-hidden gap-2.5 px-4 py-3 rounded bg-white border border-[#dbe1ea]"
                    type='date'
                    value={values.dob}
                    name="dob"
                     onChange={handleChange}
                     onBlur={handleBlur}
                     style={
                       errors.dob && touched.dob
                         ? { border: "2px solid red" }
                         : {}
                    }
                  />
                  
                </div>
              </div>
              <div className="flex justify-start items-start self-stretch flex-grow-0 flex-shrink-0 relative gap-2.5">
                <p className="flex-grow-0 flex-shrink-0 w-[150px] h-[38px] text-[13px] font-medium text-left text-black/80">
                  Weight
                </p>
                <div className="flex flex-col justify-start items-start flex-grow h-11 gap-2.5 rounded-[5px]">
                  <input
                    className="flex flex-col justify-start items-start self-stretch flex-grow-0 flex-shrink-0 relative overflow-hidden gap-2.5 px-4 py-3 rounded bg-white border border-[#dbe1ea]"
                    type='number'
                    value={values.weight}
                    name="weight"
                     onChange={handleChange}
                     onBlur={handleBlur}
                     style={
                       errors.weight && touched.weight
                         ? { border: "2px solid red" }
                         : {}
                    }
                  />
                  
                </div>
              </div>
              <div className="flex justify-start items-start self-stretch flex-grow-0 flex-shrink-0 relative gap-2.5">
                <p className="flex-grow-0 flex-shrink-0 w-[150px] h-[38px] text-[13px] font-medium text-left text-black/80">
                  District Name
                </p>
                <div className="flex flex-col justify-start items-start flex-grow gap-4 rounded-[5px]">
                  <select
                   className="flex justify-start items-center self-stretch flex-grow relative gap-[5px] px-4 py-2 rounded bg-slate-50 border border-[#dbe1ea]"
                    placeholder="Enter Your Name"
                    value={values.district_name}
                    name="district_name"
                    onChange={(e) => {
                      setFieldValue("registred_club", "")
                      handleChange(e)
                     }}
                     onBlur={handleBlur}
                     style={
                       errors.district_name && touched.district_name
                         ? { border: "2px solid red" }
                         : {}
                    }
                  >
                    <option>Select</option>
                    {
                      cityList?.map((city,i) => (
                        <option key={i} value={city?.city_id}>{ city?.city_name}</option>
                      ))
                    }
                  </select>
                </div>
              </div>
              <div className="flex justify-start items-start self-stretch flex-grow-0 flex-shrink-0 relative gap-2.5">
                <p className="flex-grow-0 flex-shrink-0 w-[150px] h-[38px] text-[13px] font-medium text-left text-black/80">
                  Registred Club
                </p>
                <div className="flex flex-col justify-start items-start flex-grow gap-4 rounded-[5px]">
                  <select
                   className="flex justify-start items-center self-stretch flex-grow relative gap-[5px] px-4 py-2 rounded bg-slate-50 border border-[#dbe1ea]"
                    placeholder="Enter Your Name"
                    value={values.registred_club}
                    name="registred_club"
                     onChange={handleChange}
                     onBlur={handleBlur}
                     style={
                       errors.registred_club && touched.registred_club
                         ? { border: "2px solid red" }
                         : {}
                    }
                  >
                    <option>Select</option>
                    {
                      clubList?.map((city,i) => (
                        <option key={i} value={city?.ota_club_id}>{ city?.club_name}</option>
                      ))
                    }
                  </select>
                </div>
              </div>
              <div className="flex justify-start items-start self-stretch flex-grow-0 flex-shrink-0 relative gap-2.5">
                <p className="flex-grow-0 flex-shrink-0 w-[150px] h-[38px] text-[13px] font-medium text-left text-black/80">
                  Proof
                </p>
                <div className="flex flex-col justify-start items-start flex-grow gap-4 rounded-[5px]">
                                                <Files
                              className="files-dropzone w-full"
                    onChange={(file) => {
                      if (file?.length) {
                               setFieldValue("proof",file[0])
                             }
                           }}
                    onError={(err) => toast.error(err?.message)}
                          accepts={[
                            ".pdf",
                          ]}
                          maxFileSize={1000000}
                          minFileSize={0}
                          clickable
                              >
                              <div id="file-uploader2" class="wrapper js">    
<form class="js--upload-form is-droppable">
		<span class="text--center">
			<i class="fa fa-cloud-upload fa-2"></i>
                          {values?.proof ? <>
                            {
                              typeof values.proof === "object" ? <>
                              <span class="js--advanced-upload">{values?.proof?.name} <label class="btn btn--link" for="file">replace</label></span>
                              </> : <>
                              <span class="js--advanced-upload">{values?.proof?.split("/").pop()} <label class="btn btn--link" for="file">replace</label></span>
                              </>
                            }
  </>	:	<span class="js--advanced-upload">Choose file <label class="btn btn--link" for="file">browse</label></span>}
			<span type="file" name="files[]" id="file" class="js--basic-upload hide" data-multiple-caption="{count} files selected" multiple="" accept=".pdf,.xlsx" />
		</span>
	</form>
                        </div>
</Files>
                </div>
              </div>
            </div>
          </div>
          <div className="flex flex-col justify-start items-start self-stretch flex-grow-0 flex-shrink-0 relative">
              <p className="self-stretch flex-grow-0 flex-shrink-0 w-[768px] text-xl font-semibold text-left text-[#202223]">
                Events
            </p>
            <div className="flex flex-col justify-start items-start self-stretch flex-grow-0 flex-shrink-0 gap-4 pl-6">
              {eventList?.map((event) => (
                <div className='flex gap-5'>
                <input type='checkbox'  checked={selectedEvents?.includes(event?.event_id)?true:false} onChange={(e)=>handleCheckBox(e,event?.event_id)} />
                <span>{event?.event_name}</span>
            </div>
          ))
              }
                </div>
</div>
              </div>
              </div>
    </Template>
  )
}

export default EditparticipantDetails
/*
                    <div className="flex flex-col justify-start items-start self-stretch flex-grow-0 flex-shrink-0 h-[150px] gap-4 rounded-[5px]">
                      <textarea
                        className="flex flex-col justify-start items-start self-stretch flex-grow-0 flex-shrink-0 relative  gap-2.5 px-4 py-3 h-[120px] rounded bg-white border border-[#dbe1ea] resize-none overflow-auto"
                        placeholder="Type here..."
                        // value={values.desc}
                        // onChange={handleProductDescriptionChange}
                        // onBlur={handleBlur}
                        // name="desc"
                        // style={
                          // errors.desc && touched.desc
                            // ? { border: "2px solid red" }
                            // : {}
                        // }
                      ></textarea>
                     { values.desc.length ? <div className="w-full flex justify-between items-center"> 
                     { values.desc.length <20 && <span className="text-red-500 ">minimum 20 words</span>} 
                        <div className="flex gap-0.5"> 
                           <span style={values.desc.length>=20 ?{color:"green"}:{color:"red"}}>{ values.desc.length}</span>/ 
                       <span className="text-green-500">400</span> 
                       </div> 
                       </div>:null} 
                    </div >
                      */