import { createSlice } from "@reduxjs/toolkit";

const NewColorBeltPromotion = createSlice({
    name:"colorbeltpromotion",
    initialState: {
        ColorBeltPromotionLoading: false,
        ColorBeltList:[],
        page:1,
        total_pages:0,
        TotalColorBeltPromotionList:0,
       
    },
    reducers: {
        getColorBeltPromotionRequest: (state) => {
            state.ColorBeltPromotionLoading = true;
        },
        getColorBeltPromotionSuccess: (state,{payload}) => {
            state.ColorBeltPromotionLoading = false;
            state.ColorBeltList = payload?.colour_belt_promotion_data;
            state.TotalColorBeltPromotionList=payload?.total_elements
        },
        getColorBeltPromotionFail: (state) => {
            state.ColorBeltPromotionLoading = false;
            state.ColorBeltList = [];
        },
       
        setCochesProfilePage:(state,{payload})=>{
            state.page=payload
         },
        setTotalPageCochesProfile:(state,{payload})=>{
            state.total_pages=payload
        }
    }
})

export default NewColorBeltPromotion.reducer;
export const { getColorBeltPromotionFail,getColorBeltPromotionRequest,getColorBeltPromotionSuccess} = NewColorBeltPromotion.actions;