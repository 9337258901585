import { Menu, Transition } from '@headlessui/react';
import { Fragment, useCallback, useRef, useState } from 'react';
import useClickOutside from '../../hook/useClickOutSide';
import { getChampionshipHeading, getColorBeltHeadings } from '../../services/api';
import { useDispatch } from 'react-redux';
import axiosConfig from '../../axios_config/axios_instance';
import toast from 'react-hot-toast';
export const OptionModal = ({ details,tab }) => {
    const [apiLoading, setapiLoading] = useState(false);
  
    const [isOpen, setisOpen] = useState(false);
  const popover = useRef();
  const dispatch = useDispatch();
    const close = useCallback(() => setisOpen(false), []);
  useClickOutside(popover, close);
  const handleSubmit = async(status) => {
    try {
      const formdata = new FormData();
      if (tab === 0) { 
        formdata.append("championship_heading_id", details.championship_heading_id);
      } else {
        formdata.append("color_belt_test_id", details.color_belt_test_id);
      }
      formdata.append("status", status);
      setapiLoading(true)
      let url = "/color-belt-test-status-update";
      if (tab === 0) {
        url=`/championship-heading-status-update`
      }
      const { data } = await axiosConfig.post(url, formdata);
      if (data?.data?.message) {
        toast.success(data?.data?.message);
        setisOpen(false);
        if (tab === 0) { 
          dispatch(getChampionshipHeading())
        } else {
          dispatch(getColorBeltHeadings())
        }
        setapiLoading(false)
      } else {
        toast.error(data?.data?.message);
        setapiLoading(false)
      }
    } catch (error) {
    toast.error("Something went wrong");
    setapiLoading(false)
  }
  }
    return (
      <Menu as="div" className="relative inline-block text-left">
        <div>
          <Menu.Button  onClick={() => setisOpen(true)}>
            <OptionIcon />
          </Menu.Button>
        </div>
        <Transition
          as={Fragment}
          show={isOpen}
          enter="transition ease-out duration-100"
          enterFrom="transform opacity-0 scale-95"
          enterTo="transform opacity-100 scale-100"
          leave="transition ease-in duration-75"
          leaveFrom="transform opacity-100 scale-100"
          leaveTo="transform opacity-0 scale-95"
        >
          <div
            ref={popover}
            className="popover h-full w-full max-w-full  absolute z-10"
          >
            <Menu.Items className="absolute right-0 bottom-8 mt-2 w-56 origin-top-right divide-y divide-gray-100 rounded-md bg-white shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none z-[99]">

          {details?.is_active?    <div className="px-1 py-1">
                <Menu.Item>
                  {({ active }) => (
                    <button
                      className={`${
                        active ? 'bg-violet-500 text-white' : 'text-gray-900'
                                            } group flex w-full items-center rounded-md px-2 py-2 text-sm`}
                      disabled={apiLoading}
                      onClick={()=>handleSubmit(0)}
                    >
                      DeActive
                    </button>
                  )}
                </Menu.Item>
              </div>:
              <div className="px-1 py-1">
                <Menu.Item>
                  {({ active }) => (
                    <button
                      className={`${
                        active ? 'bg-violet-500 text-white' : 'text-gray-900'
                                                } group flex w-full items-center rounded-md px-2 py-2 text-sm`}
                        disabled={apiLoading}
                        onClick={()=>handleSubmit(1)}
                    >
                      Active
                    </button>
                  )}
                </Menu.Item>
              </div>}
            </Menu.Items>
          </div>
        </Transition>
      </Menu>
    );
}


const OptionIcon = () => {
    return (
        <svg width="20" height="20" fill="black" viewBox="0 0 0.6 0.6" xmlns="http://www.w3.org/2000/svg" transform="matrix(0 1 1 0 0 0)"><path d="M.3.25A.05.05 0 1 1 .25.3.05.05 0 0 1 .3.25Zm-.2.1A.05.05 0 1 0 .05.3.05.05 0 0 0 .1.35Zm.4-.1A.05.05 0 1 0 .55.3.05.05 0 0 0 .5.25Z"/></svg>
    )
}