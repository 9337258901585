import { createSlice } from "@reduxjs/toolkit";

const NewEventChampionshipSlice = createSlice({
    name: "eventchampionship",
    initialState: {
        EventChampionshipLoading: false,
        EventChampionshipList:[],
      
      
    },
    reducers: {
         getEventChampoionShipRequest:(state)=>{
            state.EventChampionshipLoading=true;
         },
         getEventChanpionShipSuccess:(state, {payload})=>{
            state.EventChampionshipLoading=false;
            state.EventChampionshipList=payload

         },
         getEventChanpionShipFail:(state)=>{
            state.EventChampionshipLoading=false;
            state.EventChampionshipList=[];
         },
       
    }
})  

export default NewEventChampionshipSlice.reducer;
export const {getEventChampoionShipRequest, getEventChanpionShipFail, getEventChanpionShipSuccess}= NewEventChampionshipSlice.actions