import React, { useEffect, useState } from 'react'
import Template from '../../components/common/Template'

import EditPride from './editpride'
import DeletePride from './deletepride'
import AddPride from './addPride'
import BackFrame from "../../assets/images/Frame 5.png"
import ReactangleFrame from "../../assets/images/Rectangle Frame.png"
import Pagination from '../../components/common/pagination'
import { useDispatch, useSelector } from 'react-redux'
import { setPridePage } from '../../redux/slice/OurPideSlice'
import { getAllPrideList } from '../../services/apiService'
const Our_Pride = () => {
  const [selectedTemplate, setSelectedTemplate]=useState([])

  const {PrideList, TotalPrideList}=useSelector((state)=>state.ourpride);

  const [page, setpage] = useState(1);
  const [editprideId, setEditPrideId]=useState(null)


  

  const dispatch=useDispatch()



const handleCheckBox = (e, pridee) => {
  const { checked } = e.target;
  if (checked) {
    setSelectedTemplate([...selectedTemplate, pridee]);
  } else {
    setSelectedTemplate((prevSelectedTemplate) =>
      prevSelectedTemplate?.filter((pride_item) => pride_item?.otaPridesId !== pridee.otaPridesId)
    );
  }
};



useEffect(()=>{
  dispatch(getAllPrideList(page-1))
},[dispatch,page])



  return (
    <Template>
     <div>
       <div className='flex flex-1 justify-between p-4  bg-gray-200'>
       <div className='flex flex-1 justify-start text-left items-center'>
         <p className='text-2xl'>Our Pride List</p>
       </div>
<div className='flex flex-row gap-4 '>
       <div className=''>
       {selectedTemplate.length === 1 ? (
        <EditPride
          prideId={selectedTemplate[0]?.otaPridesId}
          setSelectedTemplate={setSelectedTemplate}
          page={page}
          details={selectedTemplate[0]}
        />
      ) : null}
       </div>

       <div className=''>
       {selectedTemplate.length ? (
        <DeletePride page={page} setpage={setpage} selectedTemplate={selectedTemplate} setSelectedTemplate={setSelectedTemplate} />
      ) : null}
       </div>
       <div className=''>
        <AddPride />
       </div>
       
       </div>
       </div>  
       
       <div className="">
       <div className="relative">
         <div className="flex justify-start items-center self-stretch flex-grow-0 flex-shrink-0 gap-2 px-4 py-4">
           <div className="px-8 py-4 flex flex-col">
             <div className="flex flex-wrap gap-12">
             
               {PrideList?.map((pridee) => (
                
                 <label
                   key={pridee.otaPridesId}
                   htmlFor={`checkbox-${pridee.otaPridesId}`}
                   className="flex flex-col relative justify-center items-center shadow-lg gap-1 rounded-lg p-6 group/item bg-white hover:bg-slate-100 transition-all border-b border-black/5 cursor-pointer"
                 >
                   {/* Add background image to the card */}
                   <div   className="absolute top-6 left-0 p-1 w-full h-full bg-cover bg-center rounded-lg">
        
                   <img
                 
                   src={ReactangleFrame}
                 />
                 </div>
                   <div   className="absolute top-14 left-2 w-full h-full bg-cover bg-center rounded-lg">
                
                   <img
                   src={BackFrame}
                 />
                 
                 </div>
                 
                 <input
                 type="checkbox"
              id={`checkbox-${pridee?.otaPridesId}`}
              checked={selectedTemplate?.find((prid) => Number(prid?.otaPridesId) === Number(pridee?.otaPridesId))}
              onChange={(e) => handleCheckBox(e, pridee)}
              className={`absolute top-2 right-2 group/edit ${
                selectedTemplate?.find((v)=>v?.otaPridesId===Number(pridee?.otaPridesId))
                  ? 'visible'
                  : 'invisible'
              } group-hover/item:visible form-checkbox rounded border-gray-300 text-[#186ced] shadow-sm focus:border-indigo-300 focus:ring focus:ring-offset-0 focus:ring-indigo-200 focus:ring-opacity-50`}
               />
                   <img src={pridee?.pride_image} alt="pattern_image" className="h-[200px] w-[200px] relative z-10" />
                   
                   <span className="text-base font-semibold">{pridee?.pride_name}</span>
                   <span className="text-base font-semibold">{pridee?.description}</span>
                 </label>
               ))}
              <div className="flex justify-center w-full p-4 mb-4 fixed -bottom-3  left-14 right-0">
               <Pagination
               className="pagination-bar"
               currentPage={page}
               totalCount={TotalPrideList} // Assuming 10 items per page
               pageSize={10}
                         onPageChange={(newPage) => { 
                           setpage(newPage)
                             var currentScroll = document.documentElement.scrollTop || document.body.scrollTop;
                               if (currentScroll > 0) {
                                 window.scrollTo(0, currentScroll - (currentScroll / 1));
                               }
                         }
                          }
             />              
             </div>
             </div>
           </div>
         </div>
       </div>
     </div>
     
     </div>
  </Template>
  )
}

export default Our_Pride
