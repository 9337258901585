import axiosConfig from "../axios_config/axios_instance"
import { getAtheleteFormDetailsRequest, getAtheleteFormRequest, getAthleteFormDetailsListFail, getAthleteFormDetailsListSuccess, getAthleteFormListFail, getAthleteFormListSuccess } from "../redux/slice/AthleteformSlice"
import {  getBlackBeltAtheleteDetailsFail, getBlackBeltAtheleteDetailsRequest, getBlackBeltAtheleteDetailsSuccess, getBlackBeltAtheleteFail, getBlackBeltAtheleteRequest, getBlackBeltAtheleteSuccess } from "../redux/slice/BlackBeltAtheleteSlice"
import { getAllCityListModuleSuccess, getCityListFail, getCityListRequest, getCityListSuccess, setTotalPageCities } from "../redux/slice/CitySlice"
import { getCoachesProfileFail, getCoachesProfileRequest, getCoachesProfileSuccess } from "../redux/slice/CoachesProfileSlice"
import { getColorAtheleteDetailsFail, getColorAtheleteDetailsRequest, getColorAtheleteDetailsSuccess, getColorBeltAtheleteListFail, getColorBeltAtheleteListSuccess, getColorBeltRequest } from "../redux/slice/ColorBeltAtheleteSlice"
import { getColorBeltPromotionFail, getColorBeltPromotionSuccess } from "../redux/slice/ColorBeltPromotionSlice"
import { getContactUsListFail, getContactUsListRequest, getContactUsListSuccess } from "../redux/slice/ContactUsSlice"
import { getDtaListFail, getDtaListRequest, getDtaListSuccess, setTotalPageDtaList } from "../redux/slice/DtaSlice"
import { getEventCalenderFail, getEventCalenderListRequest, getEventCalenderListSuccess } from "../redux/slice/EventCalenderSlice"
import { getEventChampoionShipRequest, getEventChanpionShipFail, getEventChanpionShipSuccess } from "../redux/slice/EventInChampoionshipSlice"
import { getEventResultFail, getEventResultRequest, getEventResultSuccess } from "../redux/slice/EventResultSlice"
import { getExecutivecouncilFail, getExecutivecouncilRequest, getExecutivecouncilSuccess } from "../redux/slice/ExecutiveSlice"
import { getFormListFail, getFormListRequest, getFormListSuccess, setTotalFormPage } from "../redux/slice/FormSlice"
import { getGalleryListFail, getGalleryListRequest, getGalleryListSuccess, setGalleryTotalPages } from "../redux/slice/GallerySlice"
import { getLatestInfoRequest, getLatestInfoSuccess, setTotalInfoPage } from "../redux/slice/LatestInfoSlice"
import { getVideoListFail, getVideoListRequest, getVideoListSuccess, setTotalVideoPage } from "../redux/slice/LatestVideoSlice"
import { getMedalistProfileFail, getMedalistProfileRequest, getMedalistProfileSuccess } from "../redux/slice/MedalistProfileSlice"
import { getNewsListFail, getNewsListRequest, getNewsListSuccess, setTotalPageNews } from "../redux/slice/NewsSlice"
import { getOtaClubListFail, getOtaClubListRequest, getOtaClubListSuccess, setOtaClubListTotalPage } from "../redux/slice/OtaClubSlice"
import { getPrideListFail, getPrideListRequest, getPrideListSuccess, setTotalPagePride } from "../redux/slice/OurPideSlice"
import { getOwnerProfileFail, getOwnerProfileRequest, getOwnerProfileSuccess } from "../redux/slice/OwnerInfoSlice"
import { getRefreeListFail, getRefreeListRequest, getRefreeListSuccess } from "../redux/slice/RefreeSlice"
import { getTechnicalCommiitieSuccess, getTechnicalCommitieeRequest, getTechnicalCommittieFail } from "../redux/slice/TechnicalSlice"
import { getTemplateListFail, getTemplateListRequest, getTemplateListSuccess, setTemplateListTotalPage } from "../redux/slice/TemplateSlice"

export const getGallery=(page = 0, page_size = 10)=>async(dispatch)=>{
    try {
        dispatch(getGalleryListRequest())
        const {data}= await axiosConfig.get(`/gallery-list?page=${page}&page_size=${page_size}`)

            dispatch(getGalleryListSuccess(data))

        
    } catch (error) {
        console.error('Error fetching gallery:', error);
        dispatch(getGalleryListFail());
    }
}

export const getAllNews=(page = 0, page_size = 10)=>async(dispatch)=>{
    try {
        dispatch(getNewsListRequest())
        const {data}= await axiosConfig.get(`/news-image-list?page=${page}&page_size=${page_size}`)
    
            dispatch(getNewsListSuccess(data))
    
        
        
    } catch (error) {
        console.error('Error fetching news list:', error);
        dispatch(getNewsListFail());
    }
}
export const getAllVideosList=(page = 0, page_size = 10)=>async(dispatch)=>{
    try {
        dispatch(getVideoListRequest())
        const {data}= await axiosConfig.get(`/video-list?page=${page}&page_size=${page_size}`)

            dispatch(getVideoListSuccess(data))
         

    } catch (error) {
        console.error('Error fetching videlist:', error);
        dispatch(getVideoListFail());
    }
}

export const getAllTemplateList=(page = 0, page_size = 10)=>async(dispatch)=>{
    try {
        dispatch(getTemplateListRequest())
        const {data}= await axiosConfig.get(`/template-list?page=${page}&page_size=${page_size}`)
    
            dispatch(getTemplateListSuccess(data?.template_image_list))
            dispatch(setTemplateListTotalPage(data?.template_image_list))
        
    } catch (error) {
        console.error('Error fetching template:', error);
        dispatch(getTemplateListFail());
    }
}
export const getAllFormList=(page = 0, page_size = 10)=>async(dispatch)=>{
    try {
        dispatch(getFormListRequest())
        const {data}= await axiosConfig.get(`/ota-form-list?page=${page}&page_size=${page_size}`)
            dispatch(getFormListSuccess(data))

    } catch (error) {

        dispatch(getFormListFail());
    }
}


export const getAllPrideList=(page=0, page_size=10)=>async(dispatch)=>{
    try {
        dispatch(getPrideListRequest())
        const {data}= await axiosConfig.get(`/ota-prides-list?page=${page}&page_size=${page_size}`)
   
            dispatch(getPrideListSuccess(data))

    } catch (error) {

        dispatch(getPrideListFail());
    }
}
export const getAllLatestInformation=(page=0, page_size=10)=>async(dispatch)=>{
    try {
        dispatch(getLatestInfoRequest())
        const {data}= await axiosConfig.get(`/notice-list?page=${page}&page_size=${page_size}`)
     
            dispatch(getLatestInfoSuccess(data))

    } catch (error) {

        dispatch(getPrideListFail());
    }
}


export const getOwnerProfileDetails=(ownerid=1)=>async(dispatch)=>{
    try {
        dispatch(getOwnerProfileRequest())
        const {data}= await axiosConfig.get(`/get-owner-info?owner_id=${ownerid}`)
        if(data?.ownerInfoDTO){
            dispatch(getOwnerProfileSuccess(data?.ownerInfoDTO))
        }
    } catch (error) {

        dispatch(getOwnerProfileFail());
    }
}

export const getAllDtaList=( page=0, page_size=10)=>async(dispatch)=>{
    try {
        dispatch(getDtaListRequest())
        const {data}= await axiosConfig.get(`/district-association-list?page=${page}&page_size=${page_size}`)
            dispatch(getDtaListSuccess(data))
    } catch (error) {
        dispatch(getDtaListFail());
    }
}

export const getAllOtaClubList=(cityId,page=0, page_size=10,)=>async(dispatch)=>{
    try {
        let url=`/ota-club-list?page=${page}&page_size=${page_size}`
        if(cityId){
            url=`/ota-club-list?city_id=${cityId}&page=${page}&page_size=${page_size}`
        }

        dispatch(getOtaClubListRequest())
        if(cityId){

            const {data}= await axiosConfig.get(`/ota-club-list?city_id=${cityId}&page=${page}&page_size=${page_size}`)
                dispatch(getOtaClubListSuccess(data))
        }else{
            const {data}= await axiosConfig.get(`/ota-club-list?page=${page}&page_size=${page_size}`)
            dispatch(getOtaClubListSuccess(data))
        }

    } catch (error) {
        dispatch(getOtaClubListFail());
    }
}
export const getAllContactUsList=(page=0, page_size=1000)=>async(dispatch)=>{
    try {
        dispatch(getContactUsListRequest())
        const {data}= await axiosConfig.get(`/contact-us-list?page=${page}&page_size=${page_size}`)
 
            dispatch(getContactUsListSuccess(data?.contactUsDTOList))
      
    } catch (error) {

        dispatch(getContactUsListFail());
    }
}
export const getAllCalenderEventList=(page=0, page_size=10, payload)=>async(dispatch)=>{
    try {
        dispatch(getEventCalenderListRequest())
        const {data}= await axiosConfig.get(`/ota-event-calender-list?event_calender_type=${payload}&page=${page}&page_size=${page_size}`)
 
            dispatch(getEventCalenderListSuccess(data))
      
    } catch (error) {

        dispatch(getEventCalenderFail());
    }
}
export const getAllCityList=(page=0, page_size=10)=>async(dispatch)=>{
    try {
        dispatch(getCityListRequest())
        const {data}= await axiosConfig.get(`/city-list?page=${page}&page_size=${page_size}`)
 
            dispatch(getCityListSuccess(data))
      
    } catch (error) {

        dispatch(getCityListFail());
    }
}

export const getAllCityListAllModule=()=>async(dispatch)=>{
    try {
        dispatch(getCityListRequest())
        const {data}= await axiosConfig.get(`/city-list?page=${0}&page_size=${1000}`)
 
            dispatch(getAllCityListModuleSuccess(data))
      
    } catch (error) {

        dispatch(getCityListFail());
    }
}


export const getAllCoachesProfileList=(page=0, page_size=10, payload)=>async(dispatch)=>{
    try {
        dispatch(getCoachesProfileRequest())
        const {data}= await axiosConfig.get(`/coach-profiles?status=${payload}&page=${page}&page_size=${page_size}`)
 
            dispatch(getCoachesProfileSuccess(data?.data))
      
    } catch (error) {

        dispatch(getCoachesProfileFail());
    }
}
export const getAllColorBeltPromotionList=(cityId,page=0, page_size=10)=>async(dispatch)=>{
    try {
        let url=`/colour-belt-promotion-tests?page=${page}&page_size=${page_size}`
        if(cityId){
            url=`/colour-belt-promotion-tests?city_id=${cityId}&page=${page}&page_size=${page_size}`
        }
        dispatch(getCoachesProfileRequest())
        const {data}= await axiosConfig.get(url)
 
            dispatch(getColorBeltPromotionSuccess(data?.data))
      
    } catch (error) {

        dispatch(getColorBeltPromotionFail());
    }
}
export const getAllMedalistList=(page=0, page_size=10, payload)=>async(dispatch)=>{
    try {
        dispatch(getMedalistProfileRequest())
        const {data}= await axiosConfig.get(`/ota-medalist-list?type=${payload}&page=${page}&page_size=${page_size}`)
 
            dispatch(getMedalistProfileSuccess(data))
      
    } catch (error) {
        dispatch(getMedalistProfileFail());
    }
}
export const getAllEventResultList=(page=0, page_size=1000, payload)=>async(dispatch)=>{
    try {
        dispatch(getEventResultRequest())
        const {data}= await axiosConfig.get(`/event-results?type=${payload}&page=${page}&page_size=${page_size}`)
 
            dispatch(getEventResultSuccess(data?.data))
      
    } catch (error) {
        dispatch(getEventResultFail());
    }
}
export const getAllExecutiveList=(cityId,page=0, page_size=10)=>async(dispatch)=>{
    try {
        let url=`/executive-council-members?page=${page}&page_size=${page_size}`
        if(cityId){
            url=`/executive-council-members?city_id=${cityId}&page=${page}&page_size=${page_size}`
        }
        dispatch(getExecutivecouncilRequest())
        const {data}= await axiosConfig.get(url)
 
            dispatch(getExecutivecouncilSuccess(data?.data))
      
    } catch (error) {   
        dispatch(getExecutivecouncilFail());
    }
}
export const getAllRefreeList=(page=0, page_size=10, payload)=>async(dispatch)=>{
    try {
        dispatch(getRefreeListRequest())
        const {data}= await axiosConfig.get(`/referee-profiles?status=${payload}&page=${page}&page_size=${page_size}`)
            dispatch(getRefreeListSuccess(data?.data))
    } catch (error) {   
        dispatch(getRefreeListFail());
    }
}
export const getAllColoBeltAtheleteList=(cityId,page=0, page_size=10, payload)=>async(dispatch)=>{
    try {
        let url=`/color-belts?page=${page}&page_size=${page_size}`
        if(cityId){
            url=`/color-belts?city_id=${cityId}&page=${page}&page_size=${page_size}`
        }
        dispatch(getColorBeltRequest())
        const {data}= await axiosConfig.get(url)
 
            dispatch(getColorBeltAtheleteListSuccess(data?.data))
      
    } catch (error) {   
        dispatch(getColorBeltAtheleteListFail());
    }
}
export const getAllColorBeltAtheleteDetils = (color_belt_id) =>async(dispatch)=> {
    try {
        dispatch(getColorAtheleteDetailsRequest())
        const {data}=await axiosConfig.get(`/color-belts-details?color_belt_id=${color_belt_id}`)
        dispatch(getColorAtheleteDetailsSuccess(data?.data?.color_belt_data))
    } catch (error) {
        dispatch(getColorAtheleteDetailsFail())
    }
}
export const getAllBlackBeltAtheleteList=(page=0, page_size=10, payload)=>async(dispatch)=>{
    try {
        dispatch(getBlackBeltAtheleteRequest())
        const {data}= await axiosConfig.get(`/black-belts?page=${page}&page_size=${page_size}`)
 
            dispatch(getBlackBeltAtheleteSuccess(data?.data))
      
    } catch (error) {   
        dispatch(getBlackBeltAtheleteFail   ());
    }
}

export const getAllBlackBeltAtheleteDetails = (black_belt_id) =>async(dispatch)=> {
    try {
        dispatch(getBlackBeltAtheleteDetailsRequest())
        const {data}=await axiosConfig.get(`/black-belts-details?black_belt_id=${black_belt_id}`)
        dispatch(getBlackBeltAtheleteDetailsSuccess(data?.data?.black_belt_data))
    } catch (error) {
        dispatch(getBlackBeltAtheleteDetailsFail())
    }
}
export const getAllTechnicalCommitie=(page=0, page_size=1000, payload)=>async(dispatch)=>{
    try {
        dispatch(getTechnicalCommitieeRequest())
        const {data}= await axiosConfig.get(`/technical-committees?status=${payload}&page=${page}&page_size=${page_size}`)
            dispatch(getTechnicalCommiitieSuccess(data?.data))
      
    } catch (error) {   
        dispatch(getTechnicalCommittieFail());
    }
}

export const getAllEventChampoionShipList=(page=0, page_size=1000, payload)=>async(dispatch)=>{
    try {
        dispatch(getEventChampoionShipRequest())
        const {data}= await axiosConfig.get(`/events?page=${page}&page_size=${page_size}`)
            dispatch(getEventChanpionShipSuccess(data?.data?.event_List))
      
    } catch (error) {   
        dispatch(getEventChanpionShipFail());
    }
}

export const getAllAthleteFormList=(page=0, page_size=10,)=>async(dispatch)=>{
    try {
        dispatch(getAtheleteFormRequest())
        const {data}= await axiosConfig.get(`/athlete-form-list?page=${page}&page_size=${page_size}`)
            dispatch(getAthleteFormListSuccess(data?.data))
      
    } catch (error) {   
        dispatch(getAthleteFormListFail());
    }
}


export const getAllAthleteDetailsFormList=(athlete_form_id)=>async(dispatch)=>{
    try {
        dispatch(getAtheleteFormDetailsRequest())
        const {data}= await axiosConfig.get(`/athlete-form-details?id=${athlete_form_id}`)
        
            dispatch(getAthleteFormDetailsListSuccess(data?.data))
      
    } catch (error) {   
        dispatch(getAthleteFormDetailsListFail());
    }
}

