import { createSlice } from "@reduxjs/toolkit";

const NewTemplateList = createSlice({
    name: "template",
    initialState: {
        templateLoading: false,
        TemplateList:[],
        page:1,
        total_pages:0
    },
    reducers: {
         getTemplateListRequest:(state)=>{
            state.templateLoading=true;
         },
         getTemplateListSuccess:(state, {payload})=>{
            state.templateLoading=false;
            state.TemplateList=payload;
         },
         getTemplateListFail:(state)=>{
            state.templateLoading=false;
            state.TemplateList=[];
         },
         setTemplateListPage:(state,{payload})=>{
            state.page=payload
         },
         setTemplateListTotalPage:(state,{payload})=>{
            state.total_pages=payload
         }
    }
})  

export default NewTemplateList.reducer;
export const {getTemplateListFail, getTemplateListRequest, getTemplateListSuccess, setTemplateListPage, setTemplateListTotalPage}= NewTemplateList.actions
