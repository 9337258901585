import React, { useEffect, useState } from "react";
import Template from "../../components/common/Template";


import DeleteLatestInfo from "./DeleteLatestInfo";
import pdfImage from "../../assets/images/pdf.png"
import AddLatestInformation from "./AddLatestInformation";
import { useDispatch, useSelector } from "react-redux";
import { getAllLatestInformation } from "../../services/apiService";
import Pagination from "../../components/common/pagination";
import EditLatestInformation from "./EditLatestInformation";
const LatestInformation_index = () => {
  const [page, setpage] = useState(1);
  const {LatestInfoList,TotalInformationList}=useSelector((state)=>state.latestInfo)

  const dispatch=useDispatch()

  useEffect(()=>{
    dispatch(getAllLatestInformation(page-1))
  },[dispatch, page])


  return (
    <Template>
    <div className="flex justify-start bg-[#F3F5F8] items-center self-stretch flex-grow-0 flex-shrink-0 gap-2 px-4 py-4">
    <div className="flex justify-start items-center flex-grow relative gap-[22px]">
      <p className="flex-grow-0 max-md:text-[20px] flex-shrink-0 text-2xl font-semibold text-left capitalize text-[#202223]">
        Manage Latest Information
      </p>
    </div>
    <div className="flex justify-start items-start flex-grow-0 flex-shrink-0 gap-3">
      {/* Add New Video and Add Live Video elements */}
        <AddLatestInformation />
    </div>
  </div>
      <div className="flex flex-col max-md:hidden justify-start p-6 items-start self-stretch flex-grow overflow-hidden ">
        <div className="flex flex-col   items-start self-stretch flex-grow min-h-[70vh] justify-between w-full">
          <>
     
            <div className="flex flex-col w-full mt-3">
              <table>
                <thead>
                  <th></th>
                  <th></th>
                  <th></th>
                  <th></th>
                </thead>
                <tbody className="flex flex-col gap-4">
                  {LatestInfoList?.map((latestinfo) => (
                    <tr
                      key={latestinfo?.ota_notices_id}
                      className="border group/item w-full  bg-white hover:bg-slate-100 transition-all border-b border-black/5 cursor-pointer  flex items-stretch justify-between gap-2 pl-5 pr-3.5 rounded-lg border-solid border-black border-opacity-10 max-md:flex-wrap max-md:justify-center max-md:pl-5 "
                    >
                      <td className="flex justify-between items-center gap-3 mt-2 mb-2 max-md:max-w-full max-md:flex-wrap">
                        <img
                          className="aspect-[1.29] object-contain object-center w-28 h-20 overflow-hidden"
                          src={latestinfo?.ota_notices_pdf ? `${pdfImage}` : "" }
                          alt="preview"
                          effect="blur"
                          onError={""}
                        />
                        <div className="flex grow basis-[0%] w-[800px] flex-col items-stretch gap-2 ">
                          <div className="text-slate-500 text-sm font-medium leading-4 mt-1">
                            {latestinfo?.description}
                  
                          </div>
                        </div>
                      </td>
                   
                      

                      <td className="self-center flex items-stretch gap-2.5 my-auto">
                       <EditLatestInformation details={latestinfo} />
                        <DeleteLatestInfo
                        page={page}
                          latestInfoId={latestinfo?.ota_notices_id}
                          setpage={setpage}
                          className="group/edit invisible group-hover/item:visible flex justify-center items-center flex-grow-0 flex-shrink-0 relative rounded  p-2 bg-white shadow-sm hover:bg-blue-400 transition-all cursor-pointer  hover:rounded-md text-black"
                        />
                  
                    </td>
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>
          </>
          <div className="flex justify-center w-full p-4 mb-4 fixed -bottom-3  left-14 right-0">
               <Pagination
               className="pagination-bar"
               currentPage={page}
               totalCount={TotalInformationList} // Assuming 10 items per page
               pageSize={10}
                         onPageChange={(newPage) => { 
                           setpage(newPage)
                             var currentScroll = document.documentElement.scrollTop || document.body.scrollTop;
                               if (currentScroll > 0) {
                                 window.scrollTo(0, currentScroll - (currentScroll / 1));
                               }
                         }
                          }
             />              
             </div>
        </div>
      </div>
    </Template>
  );
};

export default LatestInformation_index;
