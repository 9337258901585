export const Home="/"
export const DASHBOARD="/dashboard"
export const NEWS="/news"
export const GALLERY="/gallery"
export const OURPRIDE="/pride"
export const LATESTINFORMATION="/latestinformation"
export const LATESTVIDEO="/latestvideo"
export const OWNERPROFILE="/ownerprofile"
export const TEMPLATES="/template"
export const CONTACTUS="/contact"
export const FORMS="/forms"
export const ATHELETEFORM="/athleteform"
export const ATHLETEFORMDETAILS="/atheleteform-details/:athlete_form_id"
//DTA
export const DTA="/dta"
export const ADDDTA="/addDta"
export const EDITDTA="/editDta/:district_association_id"

export const OTACLUB="/otaclub"
export const ADDOTACLUB="/addOtaClub"
export const EDITOTACLUB="/editOtaClub/:ota_club_id"
export const COMPETATION="/competation"
export const ONLINEREGISTRATIONLIST="/online_registration_list"
export const CITIES= "/cities"


//About Section 
export const COCHESPROFILE="/coachesprofile"
export const COLORBELTPROMOTION="/colorbeltpromotionlist"
export const EVENTCALENDER="/eventcalender"
export const EVENTRESULT="/eventresult"
export const EXECUTIVECOUNCILMEMBERS="/executivecouncilmembers"
export const MEDALISTPROFILE="/medalistsrofile"
export const COLORBELTATHELET="/colorbeltathelet"
export const ADDCOLORBELTATHELET="/addColorBeltAthelte"
export const EDITCOLORBELTATHELETE="editColorBeltAthelete/:color_belt_id"
export const REFREEPROFILE="/refreeprofile"
export const BLACKBELTATHELTE="/blackbeltathelete"
export const ADDBLACKBELTATHELETE="/addBlackBeltAthelete"
export const EDITBLACKBELTATHELETE="/editBlackBeltAthelete/:black_belt_id"
export const TECHNICALCOMMITIE="/technicalcommiitie"
export const POOMSASECOMMITIEE = "/Poomsase"
export const TECHNICALPROFILE = "/technicalprofile"
export const CHAMPIONSHIPPARTICIPATIONDETAILS="/champion_participation/:championship_heading_id"
export const COLORBELTPROMOTIONPARTICIPATIONDETAILS = "/color_belt_participation/:id"
export const EDITPARTICIPATEDETAILS = "participate_details_edit/:id"
export const LOGIN="/login"