import React, { useEffect } from "react";
import Template from "../../../components/common/Template";
import { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Tab } from "@headlessui/react";
import { getAllRefreeList } from "../../../services/apiService";
import { setSelectedTabRefree } from "../../../redux/slice/RefreeSlice";
import EditRefree from "./EditRefree";
import DeleteRefree from "./DeleteRefree";
import AddRefree from "./AddRefree";
import Pagination from "../../../components/common/pagination";
const RefreeProfile_index = () => {
  const dispatch = useDispatch();
  const { selectedTabRefree } = useSelector((state) => state.refree);

  const [selectedTemplate, setSelectedTemplate] = useState([])
  ;
  const [nationalSelectedCoaches, setnationalSelectedCoaches] = useState([])

  const [stateSelectedTemplate, setstateSelectedTemplate] = useState([])


  
  const [internationalPage, setinternationalPage] = useState(1)

  const [nationalPage, setnationalPage] = useState(1)

  const [statePage, setstatePage] = useState(1)

  const {RefreeList,TotalRefreeList}=useSelector((state)=>state.refree)



const handleCheckBox = (e,pridee) => {
  const { checked } = e.target;
  if (checked) {
      setSelectedTemplate([...selectedTemplate,pridee])
  } else {
      setSelectedTemplate(selectedTemplate?.filter((pride_item)=>pride_item?.referee_id!==pridee?.referee_id))
  }
}
const handleSelectNationalCheckBox = (e,pridee) => {
  const { checked } = e.target;
  if (checked) {
    setnationalSelectedCoaches([...nationalSelectedCoaches,pridee])
  } else {
    setnationalSelectedCoaches(nationalSelectedCoaches?.filter((pride_item)=>pride_item?.referee_id!==pridee?.referee_id))
  }
}
const handleSelectStateCheckBox = (e,pridee) => {
  const { checked } = e.target;
  if (checked) {
      setstateSelectedTemplate([...stateSelectedTemplate,pridee])
  } else {
      setstateSelectedTemplate(stateSelectedTemplate?.filter((pride_item)=>pride_item?.referee_id!==pridee?.referee_id))
  }
}




  const pageSize=10;
useEffect(() => {
  if (selectedTabRefree === 0) {
    dispatch(getAllRefreeList(internationalPage-1,pageSize,0));
  } else if (selectedTabRefree === 1) {  
    dispatch(getAllRefreeList(nationalPage-1,pageSize,1));
  } else {
    dispatch(getAllRefreeList(statePage-1,pageSize,2));
  }
}, [dispatch, selectedTabRefree,statePage,internationalPage,nationalPage]);

  return (
    <Template>
    <div className='flex flex-1 justify-between p-4  bg-gray-200'>
    <div className='flex flex-1 justify-start text-left items-center'>
      <p className='text-2xl'> Referee  List</p>
    </div>
    {
      selectedTabRefree===0 ?<div className='flex flex-row gap-4 '>
      <div className=''>
      {selectedTemplate.length === 1 ? (
       <EditRefree
         prideId={selectedTemplate[0]?.referee_id}
         selectedTemplate={selectedTemplate}
         details={selectedTemplate[0]}
         setSelectedTemplate={setSelectedTemplate}
                  page={internationalPage}
                  pageSize={pageSize}
       />
     ) : null}
      </div>
    
      <div className=''>
      {selectedTemplate.length ===1 ? (
       <DeleteRefree refreeStatus={"international"} selectedTemplate={selectedTemplate} setSelectedTemplate={setSelectedTemplate} page={internationalPage} pageSize={pageSize} setPage={setinternationalPage}  />
     ) : null}
      </div>
      <div className=''>
       <AddRefree pageSize={pageSize}  />
      </div>
      
      </div>:selectedTabRefree===1?<div className='flex flex-row gap-4 '>
      <div className=''>
      {nationalSelectedCoaches.length === 1 ? (
       <EditRefree
         prideId={nationalSelectedCoaches[0]?.referee_id}
         selectedTemplate={nationalSelectedCoaches}
         details={nationalSelectedCoaches[0]}
         setSelectedTemplate={setnationalSelectedCoaches}
                    page={nationalPage}
                    pageSize={pageSize}
       />
     ) : null}
      </div>
    
      <div className=''>
      {nationalSelectedCoaches.length ===1 ?(
       <DeleteRefree  refreeStatus={"national"} selectedTemplate={nationalSelectedCoaches} setSelectedTemplate={setnationalSelectedCoaches} pageSize={pageSize} page={nationalPage} setPage={setnationalPage}  />
     ) : null}
      </div>
      <div className=''>
       <AddRefree pageSize={pageSize} />
      </div>
      
      </div>:<div className='flex flex-row gap-4 '>
      <div className=''>
      {stateSelectedTemplate.length === 1 ? (
       <EditRefree
         prideId={stateSelectedTemplate[0]?.referee_id}
         selectedTemplate={stateSelectedTemplate}
         details={stateSelectedTemplate[0]}
         setSelectedTemplate={setstateSelectedTemplate}
                      page={statePage}
                      pageSize={pageSize}
       />
     ) : null}
      </div>
    
      <div className=''>
      {stateSelectedTemplate.length===1 ? (
       <DeleteRefree  refreeStatus={"state"} selectedTemplate={stateSelectedTemplate} setSelectedTemplate={setstateSelectedTemplate} pageSize={pageSize} setPage={setstatePage} page={statePage} />
     ) : null}
      </div>
      <div className=''>
       <AddRefree pageSize={pageSize}  />
      </div>
      
      </div>
    }
    </div> 
      <Tab.Group
        selectedIndex={selectedTabRefree}
        onChange={(val) => dispatch(setSelectedTabRefree(val))}
      >
        <Tab.List className="flex justify-start items-center self-stretch flex-grow-0 flex-shrink-0 gap-12 px-4 border border-black/[0.08]">
          <Tab>
            {({ selected }) => (
              <button
                className={`flex justify-start items-center flex-grow-0 flex-shrink-0 relative gap-1 py-4 text-sm font-medium text-center focus:ring-0 outline-none ${
                  selected
                    ? "border-b-2 border-[#186ced] text-[#186ced]"
                    : "border-b-2 border-transparent text-gray-400 hover:text-gray-700"
                } `}
              >
                International Refree
              </button>
            )}
          </Tab>
          <Tab>
            {({ selected }) => (
              <button
                className={`flex justify-start items-center flex-grow-0 flex-shrink-0 relative gap-1 py-4 text-sm font-medium text-center focus:ring-0 outline-none ${
                  selected
                    ? "border-b-2 border-[#186ced] text-[#186ced]"
                    : "border-b-2 border-transparent text-gray-400 hover:text-gray-700"
                } `}
              >
                National Refree
              </button>
            )}
          </Tab>
          <Tab>
            {({ selected }) => (
              <button
                className={`flex justify-start items-center flex-grow-0 flex-shrink-0 relative gap-1 py-4 text-sm font-medium text-center focus:ring-0 outline-none ${
                  selected
                    ? "border-b-2 border-[#186ced] text-[#186ced]"
                    : "border-b-2 border-transparent text-gray-400 hover:text-gray-700"
                } `}
              >
                State Refree
              </button>
            )}
          </Tab>

          {/* Additional Tab elements */}
        </Tab.List>

        <Tab.Panels>
          <Tab.Panel className="md:p-4 w-[100%]">
          <div className="relative">
          <div className="flex flex-col max-md:hidden justify-start items-start self-stretch flex-grow overflow-hidden ">
          <div className="flex flex-col  items-start self-stretch flex-grow min-h-[70vh] justify-between w-full">
            
            
            <>
            {RefreeList?.length ? (
              <>
              <div className="flex flex-wrap gap-2 ">
      
              {RefreeList.map((pridee) => (
                <label  htmlFor={`checkbox-${pridee.referee_id}`} key={pridee?.referee_id} className="flex group/item justify-start items-start w-[280px] ">
                  <div className="max-w-full w-full">
                    <div className="bg-white shadow-xl rounded-lg py-3">
                      <div className="photo-wrapper relative p-2">
                      <input
                      type="checkbox"
                      id={`checkbox-${pridee?.referee_id}`}
                      checked={selectedTemplate?.find((prid) => Number(prid?.referee_id) === Number(pridee?.referee_id))}
                      onChange={(e) => handleCheckBox(e, pridee)}
                      className={`absolute top-2 right-2 group/edit ${
                        selectedTemplate?.find((v)=>v?.referee_id===Number(pridee?.referee_id))
                          ? 'visible'
                          : 'invisible'
                      } group-hover/item:visible form-checkbox rounded border-gray-300 text-[#186ced] shadow-sm focus:border-indigo-300 focus:ring focus:ring-offset-0 focus:ring-indigo-200 focus:ring-opacity-50`}
                    />
                        <img
                          className="w-32 h-32 rounded-full mx-auto"
                          src={pridee?.image}
                          alt="coch image"
                        />
                      </div>
            
                      <div className="p-2">
                        <table className="text-xs my-3">
                          <tbody>
                            <tr>
                              <td className="px-2 py-2 text-gray-500 font-semibold">Referee Name</td>
                              <td className="px-2 py-2">{pridee?.referee_name}</td>
                            </tr>
                            <tr>
                              <td className="px-2 py-2 text-gray-500 font-semibold">Referee Type</td>
                              <td className="px-2 py-2">{pridee?.referee_type}</td>
                            </tr>
                            <tr>
                              <td className="px-2 py-2 text-gray-500 font-semibold">District</td>
                              <td className="px-2 py-2">{pridee?.city_name}</td>
                            </tr>
                        
                         
                            <tr>
                              <td className="px-2 py-2 text-gray-500 font-semibold">Grade</td>
                              <td className="px-2 py-2">{pridee?.grade}</td>
                            </tr>
                          </tbody>
                        </table>
                      </div>
                    </div>
                  </div>
                </label>
              ))}
            
            </div>
              </>
            ) : (
              <div className="flex justify-center items-center h-[70vh] w-full">
                <span className="text-center w-full text-xl text-[#7b8794]">
                  No Refree Found
                </span>
              </div>
            )}
            </>
            <div className="flex justify-center w-full p-4 mb-4">   
            <Pagination
            className="pagination-bar"
            currentPage={internationalPage}
            totalCount={TotalRefreeList}
            pageSize={pageSize}
            onPageChange={(page) => {
               setinternationalPage(page)
              var currentScroll = document.documentElement.scrollTop || document.body.scrollTop;
                    if (currentScroll > 0) {
                      window.scrollTo(0, currentScroll - (currentScroll / 1));
                    }
            }}
          />             
            </div>
          </div>
         
        </div>
                </div> 
          </Tab.Panel>
        </Tab.Panels>
        <Tab.Panels>
        <Tab.Panel className="md:p-4 w-[100%]">
        <div className="relative">
        <div className="flex flex-col max-md:hidden justify-start items-start self-stretch flex-grow overflow-hidden ">
        <div className="flex flex-col  items-start self-stretch flex-grow min-h-[70vh] justify-between w-full">
          
          
          <>
          {RefreeList?.length ? (
            <>
            <div className="flex flex-wrap gap-12 ">
    
            {RefreeList.map((pridee) => (
              <label  htmlFor={`checkbox-${pridee.referee_id}`} key={pridee?.referee_id} className="flex group/item justify-start items-start w-max ">
              <div className="max-w-xs">
                <div className="bg-white shadow-xl rounded-lg py-3">
                  <div className="photo-wrapper relative p-2">
                  <input
                  type="checkbox"
                  id={`checkbox-${pridee?.referee_id}`}
                  checked={selectedTemplate?.find((prid) => Number(prid?.referee_id) === Number(pridee?.referee_id))}
                  onChange={(e) => handleSelectNationalCheckBox(e, pridee)}
                  className={`absolute top-2 right-2 group/edit ${
                    selectedTemplate?.find((v)=>v?.referee_id===Number(pridee?.referee_id))
                      ? 'visible'
                      : 'invisible'
                  } group-hover/item:visible form-checkbox rounded border-gray-300 text-[#186ced] shadow-sm focus:border-indigo-300 focus:ring focus:ring-offset-0 focus:ring-indigo-200 focus:ring-opacity-50`}
                />
                    <img
                      className="w-32 h-32 rounded-full mx-auto"
                      src={pridee?.image}
                      alt="coch image"
                    />
                  </div>
        
                  <div className="p-2">
                    <table className="text-xs my-3">
                      <tbody>
                        <tr>
                          <td className="px-2 py-2 text-gray-500 font-semibold">Referee Name</td>
                          <td className="px-2 py-2">{pridee?.referee_name}</td>
                        </tr>
                        <tr>
                          <td className="px-2 py-2 text-gray-500 font-semibold">Referee Type</td>
                          <td className="px-2 py-2">{pridee?.referee_type}</td>
                        </tr>
                        <tr>
                          <td className="px-2 py-2 text-gray-500 font-semibold">City</td>
                          <td className="px-2 py-2">{pridee?.city_name}</td>
                        </tr>
                    
                     
                        <tr>
                          <td className="px-2 py-2 text-gray-500 font-semibold">Grade</td>
                          <td className="px-2 py-2">{pridee?.grade}</td>
                        </tr>
                      </tbody>
                    </table>
                  </div>
                </div>
              </div>
            </label>
            ))}
          
          </div>
            </>
          ) : (
            <div className="flex justify-center items-center h-[70vh] w-full">
              <span className="text-center w-full text-xl text-[#7b8794]">
                No Refree Found
              </span>
            </div>
          )}
          </>
          <div className="flex justify-center w-full p-4 mb-4">  
          <Pagination
          className="pagination-bar"
          currentPage={nationalPage}
          totalCount={TotalRefreeList}
          pageSize={pageSize}
          onPageChange={(page) => {
             setnationalPage(page)
            var currentScroll = document.documentElement.scrollTop || document.body.scrollTop;
                  if (currentScroll > 0) {
                    window.scrollTo(0, currentScroll - (currentScroll / 1));
                  }
          }}
        />         
          </div>
        </div>
       
      </div>
              </div> 
        </Tab.Panel>
        </Tab.Panels>
        <Tab.Panels>
        <Tab.Panel className="md:p-4 w-[100%]">
        <div className="relative">
        <div className="flex flex-col max-md:hidden justify-start items-start self-stretch flex-grow overflow-hidden ">
        <div className="flex flex-col  items-start self-stretch flex-grow min-h-[70vh] justify-between w-full">
          
          
          <>
          {RefreeList?.length ? (
            <>
            <div className="flex flex-wrap gap-12 ">
    
            {RefreeList.map((pridee) => (
              <div key={pridee?.referee_id} className="flex group/item justify-start items-start w-max ">
                <div className="max-w-xs">
                  <div className="bg-white shadow-xl rounded-lg py-3">
                    <div className="photo-wrapper relative p-2">
                    <input
                type="checkbox"
                id={`checkbox-${pridee?.referee_id}`}
                checked={stateSelectedTemplate?.find((prid) => Number(prid?.referee_id) === Number(pridee?.referee_id))}
                onChange={(e) => handleSelectStateCheckBox(e, pridee)}
                className={`absolute top-2 right-2 group/edit ${
                  stateSelectedTemplate?.find((v)=>v?.referee_id===Number(pridee?.referee_id))
                    ? 'visible'
                    : 'invisible'
                } group-hover/item:visible form-checkbox rounded border-gray-300 text-[#186ced] shadow-sm focus:border-indigo-300 focus:ring focus:ring-offset-0 focus:ring-indigo-200 focus:ring-opacity-50`}
              />
                      <img
                        className="w-32 h-32 rounded-full mx-auto"
                        src={pridee?.image}
                        alt="coch image"
                      />
                    </div>
          
                    <div className="p-2">
                      <table className="text-xs my-3">
                        <tbody>
                          <tr>
                            <td className="px-2 py-2 text-gray-500 font-semibold">Referee Name</td>
                            <td className="px-2 py-2">{pridee?.referee_name}</td>
                          </tr>
                          <tr>
                            <td className="px-2 py-2 text-gray-500 font-semibold">Referee Type</td>
                            <td className="px-2 py-2">{pridee?.referee_type}</td>
                          </tr>
                          <tr>
                            <td className="px-2 py-2 text-gray-500 font-semibold">City</td>
                            <td className="px-2 py-2">{pridee?.city_name}</td>
                          </tr>
                      
                  
                          <tr>
                            <td className="px-2 py-2 text-gray-500 font-semibold">Grade</td>
                            <td className="px-2 py-2">{pridee?.grade}</td>
                          </tr>
                        </tbody>
                      </table>
                    </div>
                  </div>
                </div>
              </div>
            ))}
          
          </div>
            </>
          ) : (
            <div className="flex justify-center items-center h-[70vh] w-full">
              <span className="text-center w-full text-xl text-[#7b8794]">
                No Refree Found
              </span>
            </div>
          )}
          </>
          <div className="flex justify-center w-full p-4 mb-4">  
          <Pagination
          className="pagination-bar"
          currentPage={statePage}
          totalCount={TotalRefreeList}
          pageSize={pageSize}
          onPageChange={(page) => {
             setstatePage(page)
            var currentScroll = document.documentElement.scrollTop || document.body.scrollTop;
                  if (currentScroll > 0) {
                    window.scrollTo(0, currentScroll - (currentScroll / 1));
                  }
          }}
        />              
          </div>
        </div>
       
      </div>
              </div> 
        </Tab.Panel>
        </Tab.Panels>
      </Tab.Group>
    </Template>
  );
};

export default RefreeProfile_index;
