import { useFormik } from 'formik';
import React, { useState } from 'react'
import * as Yup from "yup";
import { ReactComponent as AddIcon } from "../../../../assets/icons/addicon.svg";
import Files from "react-files"
import { useDispatch } from 'react-redux';
import toast from 'react-hot-toast';
import axiosConfig from '../../../../axios_config/axios_instance';
import { getAllBlackBeltAtheleteList, getAllColoBeltAtheleteList } from '../../../../services/apiService';
import colorBeltFile from "../../../../assets/files/BLACKBELTATHLETE.xlsx"
import { DownloadIcon } from '@heroicons/react/outline';
const ImportExcelFiles = () => {
    const [showModal, setShowModal] = useState(false);
    const [isLoading, setisLoading] = useState(false)
    const dispatch = useDispatch();


  const validationSchema = Yup.object().shape({
    pdf:Yup.mixed().required("image is required"),
    });
  
    const {
      values,
      handleChange,
      handleSubmit,
      errors,
      touched,
      setFieldValue,
      handleBlur,
      resetForm
    } = useFormik({
      initialValues: {
        pdf:null,
      },
      validationSchema: validationSchema,
      validateOnChange: true,
      validateOnBlur: false,
      
        onSubmit: async (values) => {
            let loading;
     try {
         const formData = new FormData();
         formData.append("file", values.pdf);
         setisLoading(true);
          loading = toast.loading("Please wait file uploading......");
         const { data } = await axiosConfig.post(`/excel-save-black-belt-athlete`, formData)
         if (data?.status === "success") {
             toast.dismiss(loading);
             toast.success("File Upload Successfully")
             setShowModal(false);
             resetForm();
            dispatch(getAllBlackBeltAtheleteList());
         } else {
            toast.dismiss(loading);
            toast.error("Something went wrong try again later") 
         }
     } catch (error) {
        toast.dismiss(loading);
            toast.error("Something went wrong try again later") 
     }
      },
      
    });
    const closeModal = (e) => {
        if (isLoading) return;
      e?.target?.classList?.forEach((classNamees) => {
      if (classNamees === "Modal-Container") {
        setShowModal(false);
        resetForm();
      }
    });
  };

    const downloadFile =async () => {
        try {
            let link = document.createElement('a');
            link.href = colorBeltFile;
            link.download = "black_belt_sample.xlsx"
            link.click();
        } catch (error) {
            
        }
    }
    
  return (
    <div>
    <div
    onClick={(e) => {
      e.stopPropagation();
      setShowModal(true);
    }}
    className="flex justify-start cursor-pointer relative items-center flex-grow-0 flex-shrink-0 gap-3"
  >
    <div
      className="flex justify-start items-center flex-grow-0 flex-shrink-0 relative overflow-hidden gap-1 pl-4 pr-5 max-md:py-[7px] py-[10px] rounded-full bg-[#186ced]"
      style={{ boxShadow: "0px 1px 0px 0 rgba(0,0,0,0.08)" }}
              >
                  <AddIcon />
      <p className="flex-grow-0 flex-shrink-0 text-sm font-medium text-center text-white">
        import Excel 
      </p>
    </div>
  </div>
  {showModal ? (
    <>
      <div
        className="flex justify-center items-center overflow-x-hidden overflow-y-auto fixed inset-0 z-50 outline-none focus:outline-none shadow-lg w-full Modal-Container"
        onClick={closeModal}
      >
        <div className="relative w-auto my-6 mx-auto max-w-3xl">
          <div className="border-0 rounded-lg shadow-lg relative flex w-full bg-white outline-none focus:outline-none p-6">
                              <div className="flex items-start gap-1 p-2 border-b border-solid border-gray-300 rounded-t flex-col ">
                                  <div className='flex w-full justify-between'>
              <h3 className="text-xl font=semibold">
                Import Excel File
                                      </h3>
                                      <DownloadIcon className='h-4 w-4 cursor-pointer' onClick={downloadFile} />
                                  </div>
                                  <div className="flex justify-between items-start">
                <div className="relative p-6 pt-0 flex-auto">
                  <div className="flex flex-col justify-start items-start self-stretch flex-grow-0 flex-shrink-0 gap-1">
                    <div className="flex justify-start items-start self-stretch flex-grow-0 flex-shrink-0 relative gap-1 flex-col">
                    <div className="flex-grow-0 flex-shrink-0 w-full">
                        <p className="text-[13px] leading-10 py-[1px] font-medium text-left text-black/80">
                          Excel File
                        </p>
                      </div>
                      <div className="flex flex-col flex-grow w-[300px]">
                      <div className="flex flex-col">
                      <Files
                              className="files-dropzone"
                                                              onChange={(file) => {
                                                                  if (file.length) {
                                                                     setFieldValue("pdf",file[0])
                                                                     }
                         }}
                         onError={(err) => toast.error(err.message)}
                          accepts={[
                            ".xlsx",
                          ]}
                          maxFileSize={100000000}
                          minFileSize={0}
                          clickable
                              >
                              <div id="file-uploader2" className="wrapper js" style={errors.pdf&&touched.pdf?{border:"1px solid red"}:{}}>    
<form className="js--upload-form is-droppable">
		<span className="text--center">
			<i className="fa fa-cloud-upload fa-2"></i>
                                                                          {values?.pdf ?<>
         <span className='text-black'>{values?.pdf?.name} </span>
         <label className="btn btn--link" for="file">replace</label>                                                                     
        </>
                                                                              : <span className="js--advanced-upload">Choose file <label className="btn btn--link" for="file">browse</label></span>}
			<span type="file" name="files[]" id="file" className="js--basic-upload hide" data-multiple-caption="{count} files selected" multiple="" accept=".pdf,.xlsx" />
		</span>
	</form>
                        </div>
                       </Files>

                </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="flex justify-start items-start flex-grow-0 flex-shrink-0 gap-2 self-end">
                <div
                  onClick={(e) => {
                    e.stopPropagation()
                    setShowModal(false);
                  }}
                  className="flex justify-start items-center flex-grow-0 flex-shrink-0 relative overflow-hidden gap-1 pl-4 pr-5 py-[11px] rounded-[94px] bg-[#fff] cursor-pointer"
                  style={{ boxShadow: "0px 1px 0px 0 rgba(0,0,0,0.08)" }}
                >
                  <p className="flex-grow-0 flex-shrink-0 text-sm font-medium text-center text-black">
                    Cancel
                  </p>
                </div>
                <button
                  type="button"
                  className="flex justify-start items-center flex-grow-0 flex-shrink-0 relative overflow-hidden gap-1 px-5 py-[11px] rounded-[94px] bg-[#186ced] cursor-pointer"
                  style={{ boxShadow: "0px 1px 0px 0 rgba(0,0,0,0.08)" }}
                  onClick={handleSubmit}
                  disabled={isLoading?"disable":""}
                >
                  <p className="flex-grow-0 flex-shrink-0 text-sm font-medium text-center text-white">
                    Save
                  </p>
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  ) : null}
    </div>
  )
}

export default ImportExcelFiles
