import React, { useEffect, useState } from 'react'
import Template from '../../../components/common/Template'
import { Tab } from "@headlessui/react";
import { useDispatch, useSelector } from 'react-redux';


import pdfImage from "../../../assets/images/pdf.png"

import DeleteEventResult from './DeleteEventResult';
import AddEventResult from './AddEventResult';
import EditEventResult from './EditEventResult';
import { getAllEventResultList } from '../../../services/apiService';
import { setEventResultPage, setSelectedTabEvent } from '../../../redux/slice/EventResultSlice';
import Pagination from '../../../components/common/pagination'


const EventResult_index = () => {
  const dispatch =useDispatch()
 const [internationalPage, setinternationalPage] = useState(1)
 const [nationalPage, setnationalPage] = useState(1)
 const [statePage, setstatePage] = useState(1)


  const {EventResultList,totalEventResult,selectedTabEvent}=useSelector((state)=>state.eventresult)
  const [selectedTemplate, setSelectedTemplate]=useState([])

const pageSIze=10; //total 9 ta a6i boli dekhauni

useEffect(() => {
  if (selectedTabEvent === 0) {
    dispatch(getAllEventResultList(internationalPage-1,pageSIze,"international"));
  } else if (selectedTabEvent === 1) {
    dispatch(getAllEventResultList(nationalPage-1,pageSIze,"national"));
  } else {
    dispatch(getAllEventResultList(statePage-1,pageSIze,"state"));
  }
}, [dispatch, selectedTabEvent,statePage,internationalPage,nationalPage]);





  return (
    <Template>
    <div className='flex flex-1 justify-between p-4  bg-gray-200'>
    <div className='flex flex-1 justify-start text-left items-center'>
      <p className='text-2xl'>Manage Event Result</p>
    </div>
<div className='flex flex-row gap-4 '>
    <div className=''>
     <AddEventResult selectedIndex={selectedTabEvent} />
    </div>
    
    </div>
    </div> 


  <Tab.Group selectedIndex={selectedTabEvent} onChange={(val)=>dispatch(setSelectedTabEvent(val))}>
    <Tab.List className="flex justify-start items-center self-stretch flex-grow-0 flex-shrink-0 gap-12 px-4 border border-black/[0.08]">
      <Tab>
        {({ selected }) => (
          <button
            className={`flex justify-start items-center flex-grow-0 flex-shrink-0 relative gap-1 py-4 text-sm font-medium text-center focus:ring-0 outline-none ${
              selected
                ? "border-b-2 border-[#186ced] text-[#186ced]"
                : "border-b-2 border-transparent text-gray-400 hover:text-gray-700"
            } `}
          >
          International Event 
          </button>
        )}
      </Tab>
      <Tab>
        {({ selected }) => (
          <button
            className={`flex justify-start items-center flex-grow-0 flex-shrink-0 relative gap-1 py-4 text-sm font-medium text-center focus:ring-0 outline-none ${
              selected
                ? "border-b-2 border-[#186ced] text-[#186ced]"
                : "border-b-2 border-transparent text-gray-400 hover:text-gray-700"
            } `}
          >
          National Event
          </button>
        )}
      </Tab>
  
      <Tab>
        {({ selected }) => (
          <button
            className={`flex justify-start items-center flex-grow-0 flex-shrink-0 relative gap-1 py-4 text-sm font-medium text-center focus:ring-0 outline-none ${
              selected
                ? "border-b-2 border-[#186ced] text-[#186ced]"
                : "border-b-2 border-transparent text-gray-400 hover:text-gray-700"
            } `}
          >
          State Event
          </button>
        )}
      </Tab>
      {/* Additional Tab elements */}
    </Tab.List>

    <Tab.Panels>
    <Tab.Panel className="md:p-4 w-[100%]">


    <div className="flex flex-col max-md:hidden justify-start items-start self-stretch flex-grow overflow-hidden ">
      <div className="flex flex-col  items-start self-stretch flex-grow min-h-[70vh] justify-between w-full">
        
        
        <>
        {EventResultList?.length ? (
          <>
          <div className="flex flex-col w-full mt-3">
          <table>
            <thead>
              <th></th>
              <th></th>
              <th></th>
              <th></th>
            </thead>
            <tbody className="flex flex-col gap-4">
              {EventResultList?.map((event) => (
                <tr
                  key={event?.event_result_id}
                  className="border group/item w-full  bg-white hover:bg-slate-100 transition-all border-b border-black/5 cursor-pointer  flex items-stretch justify-between gap-2 pl-5 pr-3.5 rounded-lg border-solid border-black border-opacity-10 max-md:flex-wrap max-md:justify-center max-md:pl-5 "
                >
                  <td className="flex justify-between items-center gap-3 mt-2 mb-2 max-md:max-w-full max-md:flex-wrap">
                  <img
                  className="aspect-[1.29] object-contain object-center w-28 h-20 overflow-hidden"
                  src={event?.card ? `${pdfImage}` : "" }
                  alt="preview"
                  effect="blur"
                  onError={""}
                />
                    <div className="flex grow basis-[0%] w-[800px] flex-col items-stretch gap-2 ">
                      <div className="text-slate-500 text-sm font-medium leading-4 mt-1">
                        {event?.event_name}
                       
                      </div>
                    </div>
                  </td>
                  <td className="flex justify-between items-center  mt-2 mb-2 max-md:max-w-full max-md:flex-wrap">
            
                    <div className="flex grow basis-[0%] flex-col items-stretch gap-2 ">
                      <div className="text-slate-500 text-sm font-medium leading-4 mt-1">
                        {event?.year}
                      </div>
                    </div>
                  </td>
                  

                  <td className="self-center flex items-stretch gap-2.5 my-auto">
                  <div
               
                  >
                   <EditEventResult page={internationalPage} eventResult={event} />
                  </div>
                 
                    <DeleteEventResult
                      EventResultId={event?.event_result_id}
                      selectedTab={""}
                      setPage={setinternationalPage}
                      page={internationalPage}
                      resultstatus={"international"}
                      status={event?.type}
                      className="group/edit invisible group-hover/item:visible flex justify-center items-center flex-grow-0 flex-shrink-0 relative rounded  p-2 bg-white shadow-sm hover:bg-blue-400 transition-all cursor-pointer  hover:rounded-md text-black"
                    />
              
                </td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
          </>
        ) : (
          <div className="flex justify-center items-center h-[70vh] w-full">
            <span className="text-center w-full text-xl text-[#7b8794]">
              No Event Found
            </span>
          </div>
        )}
        </>
        <div className="flex justify-center w-full p-4 mb-4">
        <Pagination
          className="pagination-bar"
          currentPage={internationalPage}
          totalCount={totalEventResult}
          pageSize={pageSIze}
          onPageChange={(page) => {
             setinternationalPage(page)
            var currentScroll = document.documentElement.scrollTop || document.body.scrollTop;
                  if (currentScroll > 0) {
                    window.scrollTo(0, currentScroll - (currentScroll / 1));
                  }
          }}
        />
      </div>
      </div>
     
    </div>
    <div className="justify-center   bottom-0 w-full py-2  flex items-center">
     
</div>
  </Tab.Panel>
    </Tab.Panels> 

    <Tab.Panels>
      <Tab.Panel className="md:p-4 w-[100%]">


      <div className="flex flex-col max-md:hidden justify-start items-start self-stretch flex-grow overflow-hidden ">
      <div className="flex flex-col  items-start self-stretch flex-grow min-h-[70vh] justify-between w-full">
        
        
        <>
        {EventResultList?.length ? (
          <>
          <div className="flex flex-col w-full mt-3">
          <table>
            <thead>
              <th></th>
              <th></th>
              <th></th>
              <th></th>
            </thead>
            <tbody className="flex flex-col gap-4">
              {EventResultList?.map((event) => (
                <tr
                  key={event?.event_result_id}
                  className="border group/item w-full  bg-white hover:bg-slate-100 transition-all border-b border-black/5 cursor-pointer  flex items-stretch justify-between gap-2 pl-5 pr-3.5 rounded-lg border-solid border-black border-opacity-10 max-md:flex-wrap max-md:justify-center max-md:pl-5 "
                >
                  <td className="flex justify-between items-center gap-3 mt-2 mb-2 max-md:max-w-full max-md:flex-wrap">
                  <img
                  className="aspect-[1.29] object-contain object-center w-28 h-20 overflow-hidden"
                  src={event?.card ? `${pdfImage}` : "" }
                  alt="preview"
                  effect="blur"
                  onError={""}
                />
                    <div className="flex grow basis-[0%] w-[800px] flex-col items-stretch gap-2 ">
                      <div className="text-slate-500 text-sm font-medium leading-4 mt-1">
                        {event?.event_name}
                       
                      </div>
                    </div>
                  </td>
                  <td className="flex justify-between items-center  mt-2 mb-2 max-md:max-w-full max-md:flex-wrap">
            
                    <div className="flex grow basis-[0%] flex-col items-stretch gap-2 ">
                      <div className="text-slate-500 text-sm font-medium leading-4 mt-1">
                        {event?.year}
                      </div>
                    </div>
                  </td>
                  

                  <td className="self-center flex items-stretch gap-2.5 my-auto">
                  <div
               
                  >
                   <EditEventResult eventResult={event} page={nationalPage} />
                  </div>
                 
                    <DeleteEventResult
                    setPage={setnationalPage}
                    EventResultId={event?.event_result_id}
                    page={nationalPage}
                    resultstatus={"national"}
                    
                      selectedTab={""}
                      className="group/edit invisible group-hover/item:visible flex justify-center items-center flex-grow-0 flex-shrink-0 relative rounded  p-2 bg-white shadow-sm hover:bg-blue-400 transition-all cursor-pointer  hover:rounded-md text-black"
                    />
              
                </td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
          </>
        ) : (
          <div className="flex justify-center items-center h-[70vh] w-full">
            <span className="text-center w-full text-xl text-[#7b8794]">
              No Event Found
            </span>
          </div>
        )}
        </>
        <div className="flex justify-center w-full p-4 mb-4">
        <Pagination
          className="pagination-bar"
          currentPage={nationalPage}
          totalCount={totalEventResult}
          pageSize={pageSIze}
          onPageChange={(page) => {
             setnationalPage(page)
            var currentScroll = document.documentElement.scrollTop || document.body.scrollTop;
                  if (currentScroll > 0) {
                    window.scrollTo(0, currentScroll - (currentScroll / 1));
                  }
          }}
        />
      </div>
      </div>
     
    </div>
        <div className="justify-center   bottom-0 w-full py-2  flex items-center">
         
   </div>
      </Tab.Panel>
    </Tab.Panels> 

    <Tab.Panels>
    <Tab.Panels>
      <Tab.Panel className="md:p-4 w-[100%]">


      <div className="flex flex-col max-md:hidden justify-start items-start self-stretch flex-grow overflow-hidden ">
      <div className="flex flex-col  items-start self-stretch flex-grow min-h-[70vh] justify-between w-full">
        
        
        <>
        {EventResultList?.length ? (
          <>
          <div className="flex flex-col w-full mt-3">
          <table>
            <thead>
              <th></th>
              <th></th>
              <th></th>
              <th></th>
            </thead>
            <tbody className="flex flex-col gap-4">
              {EventResultList?.map((event) => (
                <tr
                  key={event?.event_result_id}
                  className="border group/item w-full  bg-white hover:bg-slate-100 transition-all border-b border-black/5 cursor-pointer  flex items-stretch justify-between gap-2 pl-5 pr-3.5 rounded-lg border-solid border-black border-opacity-10 max-md:flex-wrap max-md:justify-center max-md:pl-5 "
                >
                  <td className="flex justify-between items-center gap-3 mt-2 mb-2 max-md:max-w-full max-md:flex-wrap">
                  <img
                  className="aspect-[1.29] object-contain object-center w-28 h-20 overflow-hidden"
                  src={event?.card ? `${pdfImage}` : "" }
                  alt="preview"
                  effect="blur"
                  onError={""}
                />
                    <div className="flex grow basis-[0%] w-[800px] flex-col items-stretch gap-2 ">
                      <div className="text-slate-500 text-sm font-medium leading-4 mt-1">
                        {event?.event_name}
                       
                      </div>
                    </div>
                  </td>
                  <td className="flex justify-between items-center  mt-2 mb-2 max-md:max-w-full max-md:flex-wrap">
            
                    <div className="flex grow basis-[0%] flex-col items-stretch gap-2 ">
                      <div className="text-slate-500 text-sm font-medium leading-4 mt-1">
                        {event?.year}
                      </div>
                    </div>
                  </td>
                  

                  <td className="self-center flex items-stretch gap-2.5 my-auto">
                  <div
               
                  >
                   <EditEventResult page={statePage} eventResult={event} />
                  </div>
                 
                    <DeleteEventResult
                    EventResultId={event?.event_result_id}
                    setPage={setstatePage}
                      selectedTab={""}
                      page={statePage}
                      resultstatus={"state"}
                      className="group/edit invisible group-hover/item:visible flex justify-center items-center flex-grow-0 flex-shrink-0 relative rounded  p-2 bg-white shadow-sm hover:bg-blue-400 transition-all cursor-pointer  hover:rounded-md text-black"
                    />
              
                </td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
          </>
        ) : (
          <div className="flex justify-center items-center h-[70vh] w-full">
            <span className="text-center w-full text-xl text-[#7b8794]">
              No Event Found
            </span>
          </div>
        )}
        </>
        <div className="flex justify-center w-full p-4 mb-4">
        <Pagination
          className="pagination-bar"
          currentPage={statePage}
          totalCount={totalEventResult}
          pageSize={pageSIze}
          onPageChange={(page) => {
             setstatePage(page)
            var currentScroll = document.documentElement.scrollTop || document.body.scrollTop;
                  if (currentScroll > 0) {
                    window.scrollTo(0, currentScroll - (currentScroll / 1));
                  }
          }}
        />
      </div>
      </div>
     
    </div>
        <div className="justify-center   bottom-0 w-full py-2  flex items-center">
         
   </div>
      </Tab.Panel>
    </Tab.Panels> 
    </Tab.Panels> 

  </Tab.Group>
    </Template>
  )
}

export default EventResult_index
