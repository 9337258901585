import React, { useEffect } from "react";
import Template from "../../../../components/common/Template";
import { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { getAllCityList, getAllCityListAllModule, getAllColoBeltAtheleteList } from "../../../../services/apiService";
import EditColorBeltAthelet from "./EditColorBeltAthelet";
import DeleteAthelete from "./DeleteAthelete";
import AddColorBeltAthelete from "./AddColorBelt";
import Select from 'react-select';
import * as routesKey from "../../../../constants/routes";
import { MdOutlineEdit } from "react-icons/md";
import ImportExcelFiles from "./ImportFolder";
import { Link, useNavigate } from "react-router-dom";
import Pagination from '../../../../components/common/pagination';

const ColorBeltAthelete_index = () => {
  const { ColorBeltAtheleteList,TotalColorBeltList } = useSelector(
    (state) => state.colorbeltathelete
  );
  const [showModal, setShowModal] = useState(false);
  const [page, setpage]=useState(1)
  const [selectedDistrict, setselectedDistrict] = useState({ value: "all", label: "All Cities" })

  const dispatch = useDispatch();
  const navigate=useNavigate()


  useEffect(() => {
    dispatch(getAllColoBeltAtheleteList(selectedDistrict.value==="all"?"":selectedDistrict.value,page-1,10));
  }, [dispatch, page]);

  
  const {AllCityListModule}=useSelector((state)=>state.citylist)
  const options = AllCityListModule.map(({ city_id, city_name, city_image }) => ({
  value: city_id,
  label: (
    <div className="flex items-center justify-between gap-2">
      <div className="flex items-center gap-2">
        <img
          src={city_image}
          alt={city_name}
          className="h-5 w-5 rounded-full object-cover"
        />
        {city_name}
      </div>
     
    </div>
  ),
}));

useEffect(() => {
  dispatch(getAllCityListAllModule());
}, [dispatch]);


const handleCitySelect = (selectedOption) => {
  setselectedDistrict(selectedOption)
  if (selectedOption.value === "all") {

    dispatch(getAllColoBeltAtheleteList());
  } else {
    const cityId = selectedOption.value;
    dispatch(getAllColoBeltAtheleteList(cityId));
  }
};

const customStyles = {
  control: (provided) => ({
    ...provided,
    width: '100%',
    height: '12px',
    borderRadius: '25px', // Set border-radius for oval shape
    border: '1px solid #ccc', // Add border for clarity
    padding: '0 10px', // Add padding
    margin: '0 auto', // Center the Select component
  }),
  menu: (provided) => ({
    ...provided,
    width: '100%',
    borderRadius: '10px', // Set border-radius for options
  }),
};


  return (
    <Template>
      <div className="hidden md:block xl:block">
        <div className="flex justify-start items-center self-stretch flex-grow-0 bg-[#F3F5F8] flex-shrink-0 gap-2 px-8 py-3">
          <div className="flex justify-start items-center flex-grow relative gap-[22px]">
            <p className="flex-grow-0 justify-start -ml-2   flex-shrink-0 text-2xl font-semibold text-left capitalize text-[#202223]">
              Manage Ota Color Belt Athelete
            </p>
          </div>
          <div className="flex justify-start items-start flex-grow-0 flex-shrink-0 gap-3">
            <div className="flex justify-start relative items-center flex-grow-0 flex-shrink-0 gap-3">
            <ImportExcelFiles />
            <Link
            to={routesKey.ADDCOLORBELTATHELET}
            className="flex justify-start items-center flex-grow-0 flex-shrink-0 relative overflow-hidden gap-1 pl-4 pr-5 py-[10px] rounded-full bg-[#186ced]"
            style={{ boxShadow: "0px 1px 0px 0 rgba(0,0,0,0.08)" }}
          >
          
            <p className="flex-grow-0 flex-shrink-0 text-sm font-medium text-center text-white">
              Add ColorBelt Athelete
            </p>
          </Link>
            </div>
            <div className="w-full flex justify-center    items-center">
            <Select
            placeholder="Select District here"
            size="lg"
            options={[{ value: "all", label: "All Cities" }, ...options]}
            styles={customStyles}
            onChange={handleCitySelect} 
            value={selectedDistrict}
          />
          </div>
          </div>
        </div>

        <div className="flex flex-col justify-start items-start self-stretch flex-grow overflow-hidden bg-white">
          <div className="flex flex-col  rounded-md m-4 items-start self-stretch flex-grow min-h-[70vh]  justify-between">
            {ColorBeltAtheleteList?.length ? (
              <div class=" px-4 sm:px-8 py-4 overflow-x-auto w-full">
                <div class="inline-block min-w-full shadow-md rounded-lg overflow-hidden">
                  <table class="min-w-full leading-normal">
                    <thead>
                      <tr>
                        <th class="px-5 py-3 border-b-2 border-gray-200 bg-gray-100 text-left text-xs font-semibold text-gray-700 uppercase tracking-wider">
                          Ota Id No
                        </th>
                        <th class="px-5 py-3 border-b-2 border-gray-200 bg-gray-100 text-left text-xs font-semibold text-gray-700 uppercase tracking-wider">
                          Athelete Name
                        </th>
                        <th class="px-5 py-3 border-b-2 border-gray-200 bg-gray-100 text-left text-xs font-semibold text-gray-700 uppercase tracking-wider">
                          Gender
                        </th>
                        <th class="px-5 py-3 border-b-2 border-gray-200 bg-gray-100 text-left text-xs font-semibold text-gray-700 uppercase tracking-wider">
                          Club Name
                        </th>
                        <th class="px-5 py-3 border-b-2 border-gray-200 bg-gray-100 text-left text-xs font-semibold text-gray-700 uppercase tracking-wider">
                          Address
                        </th>
                        <th class="px-5 py-3 border-b-2 border-gray-200 bg-gray-100 text-left text-xs font-semibold text-gray-700 uppercase tracking-wider">
                          Father  Name
                        </th>
                        <th class="px-5 py-3 border-b-2 border-gray-200 bg-gray-100 text-left text-xs font-semibold text-gray-700 uppercase tracking-wider">
                          Mother  Name
                        </th>
                        <th class="px-5 py-3 border-b-2 border-gray-200 bg-gray-100 text-left text-xs font-semibold text-gray-700 uppercase tracking-wider">
                          Date of joining
                        </th>
                      
                         
                        <th class="px-5 py-3 border-b-2 border-gray-200 bg-gray-100 text-left text-xs font-semibold text-gray-700 uppercase tracking-wider">
                        </th>
                      </tr>
                    </thead>
                    <tbody>
                      {ColorBeltAtheleteList?.map((category) => (
                        <tr key={category?.color_belt_id}>
                          <td class="px-5 py-5 border-b border-gray-200 bg-white text-sm">
                            <p class="text-gray-900 whitespace-nowrap">
                              {category.id_number}
                            </p>
                          </td>
                          <td class="px-5 py-5 border-b border-gray-200 bg-white text-sm">
                            <p class="text-gray-900 whitespace-nowrap">
                              {category?.athlete_name}
                            </p>
                          </td>
                          <td class="px-5 py-5 border-b border-gray-200 bg-white text-sm">
                            <p class="text-gray-900 whitespace-nowrap">
                              {category?.gender}
                            </p>
                          </td>
                          <td class="px-5 py-5 border-b border-gray-200 bg-white text-sm">
                            <p class="text-gray-900 whitespace-nowrap">
                              {category?.club_name}
                            </p>
                          </td>
                          <td class="px-5 py-5 border-b border-gray-200 bg-white text-sm">
                            <p class="text-gray-900 whitespace-nowrap">
                              {category?.address}
                            </p>
                          </td>
                            <td class="px-5 py-5 border-b border-gray-200 bg-white text-sm">
                              <p class="text-gray-900 whitespace-nowrap">
                                {category?.father_name}
                              </p>
                            </td>
                            <td class="px-5 py-5 border-b border-gray-200 bg-white text-sm">
                              <p class="text-gray-900 whitespace-nowrap">
                                {category?.mother_name}
                              </p>
                            </td>
                            <td class="px-5 py-5 border-b border-gray-200 bg-white text-sm">
                              <p class="text-gray-900 whitespace-nowrap">
                               {category?.doj}
                              </p>
                            </td>
                          <td class="px-5 py-5 border-b border-gray-200 bg-white text-sm">
                            <div className="flex gap-4 items-center">
                            <Link
                            to={`/editColorBeltAthelete/${category?.color_belt_id}`}
                            colorbeltdetails={category} colorbeltdetailsId={category?.color_belt_id} city_id={selectedDistrict?.value==="all" ? "":selectedDistrict.value}
                          >
                            <button onClick={() => navigate(`/editColorBeltAthelete/${category?.color_belt_id}`)} className=" transition-all cursor-pointer">
                            <MdOutlineEdit className="w-4 h-4" />
                          </button>
                          </Link>
                              <DeleteAthelete
                              page={page}
                                colorbeltdetailsDelete={category?.color_belt_id}
                                city_id={selectedDistrict?.value==="all" ? "":selectedDistrict.value}
                              />
                            </div>
                          </td>
                        </tr>
                      ))}
                    </tbody>
                  </table>
                </div>
              </div>
            ) : (
              <div className="flex justify-center items-center h-[70vh] w-full">
                <span className="text-center w-full text-xl text-[#7b8794]">
                  No Color Belt found
                </span>
              </div>
            )}
            <div className="flex justify-center w-full p-4 mb-4">  
      <Pagination
      className="pagination-bar"
      currentPage={page}
      totalCount={TotalColorBeltList} // Assuming 10 items per page
      pageSize={10}
                onPageChange={(newPage) => { 
                  setpage(newPage)
                    var currentScroll = document.documentElement.scrollTop || document.body.scrollTop;
                      if (currentScroll > 0) {
                        window.scrollTo(0, currentScroll - (currentScroll / 1));
                      }
                }
                 }
    />              
      </div>
          </div>
        </div>
      </div>
    </Template>
  );
};

export default ColorBeltAthelete_index;
