import React, { useEffect } from "react";
import Template from "../../../../components/common/Template";
import { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { getAllBlackBeltAtheleteDetails, getAllBlackBeltAtheleteList } from "../../../../services/apiService";
import * as routesKey from "../../../../constants/routes";
import EditBlackBeltAthelete from "./EditBlackBeltAthelete";
import DeleteBlackBeltAtelete from "./DeleteBlackBeltAtelete";
import AddBlackBeltAthelete from "./AddBlackBeltAthelete";
import ImportExcelFiles from "./ImportFile";
import { Link, useNavigate } from "react-router-dom";
import { MdOutlineEdit } from "react-icons/md";
import Pagination from "../../../../components/common/pagination";
const BlackBelt_Athelete = () => {
  const { BlackBeltAtheleteList ,TotalBlackBeltList} = useSelector(
    (state) => state.blackbeltathelete
  );

  const [page,setpage]=useState(1)

  const [showModal, setShowModal] = useState(false);

  const navigate=useNavigate()
  const dispatch = useDispatch();


  useEffect(() => {
    dispatch(getAllBlackBeltAtheleteList(page-1));
  }, [dispatch,page]);

  return (
    <Template>
      <div className="hidden md:block xl:block">
        <div className="flex justify-start items-center self-stretch flex-grow-0 bg-[#F3F5F8] flex-shrink-0 gap-2 px-8 py-3">
          <div className="flex justify-start items-center flex-grow relative gap-[22px]">
            <p className="flex-grow-0 justify-start -ml-2   flex-shrink-0 text-2xl font-semibold text-left capitalize text-[#202223]">
              Manage Black Belt Athelete
            </p>
          </div>
          <div className="flex justify-start items-start flex-grow-0 flex-shrink-0 gap-3">
            <div className="flex justify-start relative items-center flex-grow-0 flex-shrink-0 gap-3">
              <p className="flex-grow-0 flex-shrink-0 text-sm font-medium text-center text-white">
              <Link
              to={routesKey.ADDBLACKBELTATHELETE}
              className="flex justify-start items-center flex-grow-0 flex-shrink-0 relative overflow-hidden gap-1 pl-4 pr-5 py-[10px] rounded-full bg-[#186ced]"
              style={{ boxShadow: "0px 1px 0px 0 rgba(0,0,0,0.08)" }}
            >
            
              <p className="flex-grow-0 flex-shrink-0 text-sm font-medium text-center text-white">
                Add Black Belt Athelete
              </p>
            </Link>
              </p>
              <ImportExcelFiles />
            </div>
          </div>
        </div>

        <div className="flex flex-col justify-start items-start self-stretch flex-grow overflow-hidden bg-white">
          <div className="flex flex-col  rounded-md m-4 items-start self-stretch flex-grow min-h-[70vh]  justify-between">
            {BlackBeltAtheleteList?.length ? (
              <div class=" px-4 sm:px-8 py-4 overflow-x-auto w-full">
                <div class="inline-block min-w-full shadow-md rounded-lg overflow-hidden">
                  <table class="min-w-full leading-normal">
                    <thead>
                      <tr>
                        <th class="px-5 py-3 border-b-2 border-gray-200 bg-gray-100 text-left text-xs font-semibold text-gray-700 uppercase tracking-wider">
                          Ota Id No
                        </th>
                        <th class="px-5 py-3 border-b-2 border-gray-200 bg-gray-100 text-left text-xs font-semibold text-gray-700 uppercase tracking-wider">
                          Athelete Name
                        </th>
                        <th class="px-5 py-3 border-b-2 border-gray-200 bg-gray-100 text-left text-xs font-semibold text-gray-700 uppercase tracking-wider">
                          Gender
                        </th>
                        <th class="px-5 py-3 border-b-2 border-gray-200 bg-gray-100 text-left text-xs font-semibold text-gray-700 uppercase tracking-wider">
                        kukkiwn Cert No
                        </th>
                        <th class="px-5 py-3 border-b-2 border-gray-200 bg-gray-100 text-left text-xs font-semibold text-gray-700 uppercase tracking-wider">
                        dan grade
                        </th>
                        <th class="px-5 py-3 border-b-2 border-gray-200 bg-gray-100 text-left text-xs font-semibold text-gray-700 uppercase tracking-wider">
                        Issuance Date
                        </th>
                        <th class="px-5 py-3 border-b-2 border-gray-200 bg-gray-100 text-left text-xs font-semibold text-gray-700 uppercase tracking-wider">
                        Address
                        </th>
                      
                        <th class="px-5 py-3 border-b-2 border-gray-200 bg-gray-100 text-left text-xs font-semibold text-gray-700 uppercase tracking-wider"></th>
                      </tr>
                    </thead>
                    <tbody>
                      {BlackBeltAtheleteList?.map((category) => (
                        <tr key={category?.black_belt_id}>
                          <td class="px-5 py-5 border-b border-gray-200 bg-white text-sm">
                            <p class="text-gray-900 whitespace-nowrap">
                              {category.ota_id_number}
                            </p>
                          </td>
                          <td class="px-5 py-5 border-b border-gray-200 bg-white text-sm">
                            <p class="text-gray-900 whitespace-nowrap">
                              {category?.athlete_name}
                            </p>
                          </td>
                          <td class="px-5 py-5 border-b border-gray-200 bg-white text-sm">
                            <p class="text-gray-900 whitespace-nowrap">
                              {category?.gender}
                            </p>
                          </td>
                          <td class="px-5 py-5 border-b border-gray-200 bg-white text-sm">
                            <p class="text-gray-900 whitespace-nowrap">
                              {category?.kukkiwn_cert_no}
                            </p>
                          </td>
                          <td class="px-5 py-5 border-b border-gray-200 bg-white text-sm">
                            <p class="text-gray-900 whitespace-nowrap">
                              {category?.dan_grade}
                            </p>
                          </td>
                          <td class="px-5 py-5 border-b border-gray-200 bg-white text-sm">
                            <p class="text-gray-900 whitespace-nowrap">
                              {category?.issuance_date}
                            </p>
                          </td>
                          <td class="px-5 py-5 border-b border-gray-200 bg-white text-sm">
                            <p class="text-gray-900 whitespace-nowrap">
                              {category?.address}
                            </p>
                          </td>

                          <td class="px-5 py-5 border-b border-gray-200 bg-white text-sm">
                            <div className="flex gap-2 items-center">
                            <Link
                            to={`/editBlackBeltAthelete/${category?.black_belt_id}`}
                            page={page}
                          >
                            <button onClick={() => navigate(`/editBlackBeltAthelete/${category?.black_belt_id}`)} className=" transition-all cursor-pointer">
                            <MdOutlineEdit className="w-4 h-4" />
                          </button>
                          </Link>
                              <DeleteBlackBeltAtelete
                              page={page}
                                colorbeltdetailsDelete={category?.black_belt_id}
                              />
                            </div>
                          </td>
                        </tr>
                      ))}
                    </tbody>
                  </table>
                </div>
              </div>
            ) : (
              <div className="flex justify-center items-center h-[70vh] w-full">
                <span className="text-center w-full text-xl text-[#7b8794]">
                  No Color Belt found
                </span>
              </div>
            )}
            <div className="flex justify-center w-full p-4 mb-4">  
      <Pagination
      className="pagination-bar"
      currentPage={page}
      totalCount={TotalBlackBeltList} // Assuming 10 items per page
      pageSize={10}
                onPageChange={(newPage) => { 
                  setpage(newPage)
                    var currentScroll = document.documentElement.scrollTop || document.body.scrollTop;
                      if (currentScroll > 0) {
                        window.scrollTo(0, currentScroll - (currentScroll / 1));
                      }
                }
                 }
    />              
      </div>
          </div>
        </div>
      </div>
    </Template>
  );
};

export default BlackBelt_Athelete;
