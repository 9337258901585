import { createSlice } from "@reduxjs/toolkit";

const NewExecutiveCouncil = createSlice({
    name: "executivecouncil",
    initialState: {
        ExecutiveCouncilLoading: false,
        ExecutiveCouncilList:[],
        TotalExecutiveList:0,
        
    },
    reducers: {
         getExecutivecouncilRequest:(state)=>{
            state.ExecutiveCouncilLoading=true;
         },
         getExecutivecouncilSuccess:(state, {payload})=>{
            state.ExecutiveCouncilLoading=false;
            state.ExecutiveCouncilList=payload?.executive_council_member_data;
            state.TotalExecutiveList=payload?.total_elements
         },
         getExecutivecouncilFail:(state)=>{
            state.ExecutiveCouncilLoading=false;
            state.ExecutiveCouncilList=[];
         }
    }
})  

export default NewExecutiveCouncil.reducer;
export const {getExecutivecouncilFail, getExecutivecouncilRequest, getExecutivecouncilSuccess}= NewExecutiveCouncil.actions