import React, { useEffect, useState } from 'react'
import Template from '../../../components/common/Template'
import DeleteColorBelt from './DeleteColorBelt'
import { useDispatch, useSelector } from 'react-redux'
import AddColorBelt from './AddColorBelt'
import EditColorBelt from './EditColorBelt'
import Select from 'react-select';
import { getAllCityList, getAllColorBeltPromotionList } from '../../../services/apiService'
import Pagination from '../../../components/common/pagination';
const ColorBeltPromotionTestList_index = () => {
  const dispatch =useDispatch()
  const {selectedTab}=useSelector((state)=>state.medalistprofile);
  const [showModal, setShowModal] = useState(false)
   const [page, setpage]=useState(1)
   
  const {ColorBeltList,TotalColorBeltPromotionList}=useSelector((state)=>state?.colorbeltpromotion)
  const [selectedDistrict, setselectedDistrict] = useState({ value: "all", label: "All Cities" })

  const { CityList } = useSelector((state) => state.citylist);
const options = CityList.map(({ city_id, city_name, city_image }) => ({
  value: city_id,
  label: (
    <div className="flex items-center justify-between gap-2">
      <div className="flex items-center gap-2">
        <img
          src={city_image}
          alt={city_name}
          className="h-5 w-5 rounded-full object-cover"
        />
        {city_name}
      </div>
     
    </div>
  ),
}));
useEffect(() => {
  dispatch(getAllCityList());
}, [dispatch]);


  useEffect(()=>{
    dispatch(getAllColorBeltPromotionList(selectedDistrict.value==="all"?"":selectedDistrict.value,page-1,10))
  },[dispatch, page])

  const handleCitySelect = (selectedOption) => {
    setselectedDistrict(selectedOption)
    if (selectedOption.value === "all") {

      dispatch(getAllColorBeltPromotionList());
    } else {
      const cityId = selectedOption.value;
      dispatch(getAllColorBeltPromotionList(cityId));
    }
  };
  
  
  const customStyles = {
    control: (provided) => ({
      ...provided,
      width: '100%',
      height: '12px',
      borderRadius: '25px', // Set border-radius for oval shape
      border: '1px solid #ccc', // Add border for clarity
      padding: '0 10px', // Add padding
      margin: '0 auto', // Center the Select component
    }),
    menu: (provided) => ({
      ...provided,
      width: '100%',
      borderRadius: '10px', // Set border-radius for options
    }),
  };
  
  return (
    <Template>
    <div>
   
    <div className='hidden md:block xl:block'>
    <div className="flex justify-start items-center self-stretch flex-grow-0 bg-[#F3F5F8] flex-shrink-0 gap-2 px-8 py-3">
    <div className="flex justify-start items-center flex-grow relative gap-[22px]">
      <p className="flex-grow-0 justify-start -ml-2   flex-shrink-0 text-2xl font-semibold text-left capitalize text-[#202223]">
       Manage Color Belt List
      </p>
    </div>
    <div className="flex justify-start items-start flex-grow-0 flex-shrink-0 gap-3">
      <div className="flex justify-start relative items-center flex-grow-0 flex-shrink-0 gap-3">

        
   
        <p className="flex-grow-0 flex-shrink-0 text-sm font-medium text-center text-white">
          <AddColorBelt city_id={selectedDistrict?.value==="all" ? "":selectedDistrict.value} />
        </p>
        
        
      </div>
      <div className="w-full flex justify-center    items-center">
      <Select
      placeholder="Select District here"
      size="lg"
      options={[{ value: "all", label: "All Cities" }, ...options]}
      styles={customStyles}
      onChange={handleCitySelect} 
      value={selectedDistrict}
    />
    </div>
  </div>
  
</div>
  
  <div className="flex flex-col justify-start items-start self-stretch flex-grow overflow-hidden bg-white">
  <div className="flex flex-col  rounded-md m-4 items-start self-stretch flex-grow min-h-[70vh]  justify-between">
  {
    ColorBeltList?.length ?
  <div class=" px-4 sm:px-8 py-4 overflow-x-auto w-full">
  <div
    class="inline-block min-w-full shadow-md rounded-lg overflow-hidden"
  >
    <table class="min-w-full leading-normal">
      <thead>
        <tr>
          <th
            class="px-5 py-3 border-b-2 border-gray-200 bg-gray-100 text-left text-xs font-semibold text-gray-700 uppercase tracking-wider"
          >
           Player Name
          </th>
          <th
            class="px-5 py-3 border-b-2 border-gray-200 bg-gray-100 text-left text-xs font-semibold text-gray-700 uppercase tracking-wider"
          >
            Present Belt
          </th>
          <th
            class="px-5 py-3 border-b-2 border-gray-200 bg-gray-100 text-left text-xs font-semibold text-gray-700 uppercase tracking-wider"
          >
          Certificate No
          </th>
          <th
            class="px-5 py-3 border-b-2 border-gray-200 bg-gray-100 text-left text-xs font-semibold text-gray-700 uppercase tracking-wider"
          >
          District
          </th>
          <th
            class="px-5 py-3 border-b-2 border-gray-200 bg-gray-100 text-left text-xs font-semibold text-gray-700 uppercase tracking-wider"
          >
          Ota Club Name
          </th>
          <th
            class="px-5 py-3 border-b-2 border-gray-200 bg-gray-100 text-left text-xs font-semibold text-gray-700 uppercase tracking-wider"
          >
          Exam Date
          </th>
          <th
            class="px-5 py-3 border-b-2 border-gray-200 bg-gray-100 text-left text-xs font-semibold text-gray-700 uppercase tracking-wider"
          >

          </th>
       
        </tr>
      </thead>
      <tbody>
                {
                  ColorBeltList?.map((category) => (
                    <tr key={category?.id}>
      
          <td class="px-5 py-5 border-b border-gray-200 bg-white text-sm">
                <p class="text-gray-900 whitespace-nowrap">
                  {category.player_name}
                </p>
          </td>
          <td class="px-5 py-5 border-b border-gray-200 bg-white text-sm">
                <p class="text-gray-900 whitespace-nowrap">
                {category?.player_present_belt}
                </p>
          </td>
          <td class="px-5 py-5 border-b border-gray-200 bg-white text-sm">
                <p class="text-gray-900 whitespace-nowrap">
                {category?.certificate_no}
                </p>
          </td>
          <td class="px-5 py-5 border-b border-gray-200 bg-white text-sm">
                <p class="text-gray-900 whitespace-nowrap">
                {category?.city_name}
                </p>
          </td>
          <td class="px-5 py-5 border-b border-gray-200 bg-white text-sm">
                <p class="text-gray-900 whitespace-nowrap">
                {category?.ota_club_name}
                </p>
          </td>
          <td class="px-5 py-5 border-b border-gray-200 bg-white text-sm">
                <p class="text-gray-900 whitespace-nowrap">
                {category?.exam_date}
                </p>
          </td>
       

          <td class="px-5 py-5 border-b border-gray-200 bg-white text-sm">
          <div className='flex gap-2 items-center'>        
          <EditColorBelt page={page} colorbeltdetails={category} colorbeltdetailsId={category?.id} city_id={selectedDistrict?.value==="all" ? "":selectedDistrict.value}/>
          <DeleteColorBelt setpage={setpage} page={page} colorbeltdetailsDelete={category?.id} city_id={selectedDistrict?.value==="all" ? "":selectedDistrict.value}  />
</div>
</td>
        
        </tr>
                  ))
    }
        
      </tbody>
    </table>
  </div>
</div>:<div className="flex justify-center items-center h-[70vh] w-full">
          <span className="text-center w-full text-xl text-[#7b8794]">
            No Color Belt found
          </span>
        </div>
    }
    <div className="flex justify-center w-full p-4 mb-4">
    <Pagination
    className="pagination-bar"
    currentPage={page}
    totalCount={TotalColorBeltPromotionList} // Assuming 10 items per page
    pageSize={10}
              onPageChange={(newPage) => { 
                setpage(newPage)
                  var currentScroll = document.documentElement.scrollTop || document.body.scrollTop;
                    if (currentScroll > 0) {
                      window.scrollTo(0, currentScroll - (currentScroll / 1));
                    }
              }
               }
  />    
    </div>
  </div>
</div>




</div>
</div>
    </Template>
  )
}

export default ColorBeltPromotionTestList_index
