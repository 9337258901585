import React, { useEffect, useState } from 'react'
import * as Yup from "yup"
import { useFormik } from 'formik';
import { PencilIcon } from '@heroicons/react/outline';
import moment from 'moment';
import { useDispatch, useSelector } from 'react-redux';
import { getCitiliesList, getClubNames, getColorBeltParticipants } from '../../services/api';
import axiosConfig from '../../axios_config/axios_instance';
import toast from 'react-hot-toast';

const EditColorBeltParticipateDetails = ({details,page,pagesize,color_belt_id}) => {
    const [showModal, setShowModal] = useState(false);
    const [isLoading, setisLoading] = useState(false);
    const {cityList,clubList}=useSelector((state)=>state.onlineRegistartion)
    const initialValues = {
        ota_id_no: details?.ota_id_no,
        present_belt:details?.present_belt,
        applied_belt: details?.active_belt,
        gender:details?.gender?.toUpperCase(),
        date_of_birth:details?.dob ? moment(details?.dob,"DD-MM-YYYY").format("YYYY-MM-DD"):"",
        district_name:details?.city_id,
        club_name: details?.ota_club_id,
        student_name:details?.name
    };
    //Yup validator Schema
    const EditInformationSchema = Yup.object({
      ota_id_: Yup.string(),
            present_belt: Yup.string().required("Required"),
            applied_belt: Yup.string().required("Enter price"),
            gender:Yup.string().required("Required"),
            district_name:Yup.string().required("Required"),
            club_name:Yup.string().required("Required"),
            date_of_birth:Yup.string().required("Required"),
            student_name:Yup.string().required("Required"),
    });
    const {
        values,
        resetForm,
        handleBlur,
        handleChange,
        handleSubmit,
        setFieldValue,
        errors,
        touched,
      } = useFormik({
        initialValues,
        validationSchema: EditInformationSchema,
        validateOnChange: true,
        enableReinitialize: true,
        validateOnBlur: false,
          onSubmit: async (values, action) => {
              try {
                  const postdata = new FormData();
                  postdata.append("active_belt",values.applied_belt)
                  postdata.append("present_belt",values.present_belt)
                  postdata.append("city_id",values.district_name)
                  postdata.append("gender",values.gender)
                  postdata.append("name",values.student_name)
                  postdata.append("ota_club_id",values.club_name)
                  postdata.append("dob",moment(values.date_of_birth,"YYYY-MM-DD").format("DD-MM-YYYY"))
                  postdata.append("color_belt_test_id",details.colorBeltTestId)
                  postdata.append("color_test_id",details.color_test_id)
                  postdata.append("ota_id",details.ota_id)
                  const { data } = await axiosConfig.put(`/registration-test-edit`, postdata);
                  if (data?.data?.status === "success") {
                      toast.success(data?.data?.message);
                      setShowModal(false);
                      dispatch(getColorBeltParticipants(color_belt_id,page,pagesize))
                  } else {
                    toast.error(data?.data?.message);
                  }
            } catch (error) {
                toast.error("Something went wrong")
            }
        },
      });
    const dispatch = useDispatch();
    useEffect(() => {
        if (values?.district_name) {
            dispatch(getClubNames(values?.district_name));
        }
    }, [values.district_name])
    
    useEffect(() => {
       dispatch(getCitiliesList()) 
    },[])

    const presentBelts = [
    "WHITE","YELLOW","GREEN","GREEN ONE","BLUE","BLUE ONE","RED","RED ONE"
]
    const appliedBelts = [
    "NOVICE","WHITE","YELLOW","GREEN","GREEN ONE","BLUE","BLUE ONE","RED","RED ONE"
]

    
    const openModal = () => {
        setShowModal(true);
        resetForm()
    }
    const closeModal = (e) => {
        e?.target?.classList?.forEach((classes) => {
          if (classes === 'Modal-Container') {
            setShowModal(false)
          }
        })
    }
    
    return (
        <>
            <div
                className="flex justify-start items-start flex-grow-0 flex-shrink-0 h-[42px] gap-2.5 rounded-[5px]"
            >
                <button
                    className="flex justify-start items-center flex-grow-0 flex-shrink-0 relative overflow-hidden gap-1"
                    onClick={openModal}
                    
                >
                    <PencilIcon className='h-4 w-4' />
                </button>
            </div>


            {showModal ? (
                <>
                    <div
                        className="flex justify-center items-center overflow-x-hidden overflow-y-auto fixed md:inset-0 z-50 outline-none focus:outline-none shadow-lg w-full Modal-Container bg-[black]/30 max-md:h-[90vh] max-md:max-h-[90vh] max-md:overflow-y-auto max-md:bottom-0 max-md:top-[31px] max-md:left-[0px] max-md:block max-md:shadow-none" onClick={closeModal}
                    >
                        <div className="relative w-auto my-6 mx-auto max-w-full">
                            <div className="border-0 rounded-lg shadow-lg relative flex w-full bg-white outline-none focus:outline-none">
                                <div className="flex items-start gap-1 p-2 border-b border-solid border-gray-300 rounded-t flex-col ">
                                    <h3 className="text-xl font=semibold">Edit Participate Details</h3>
                                    <div className="relative p-6 pt-0 flex-auto">
                                        <div className="flex flex-col justify-start items-start self-stretch flex-grow-0 flex-shrink-0 gap-1">
                                            <div className="flex justify-start items-start self-stretch flex-grow-0 flex-shrink-0 relative gap-1 flex-col">
                                                <div className="flex  w-[900px] gap-[10px] max-md:w-full max-md:flex-col">
                                                <div className="flex-grow-0 flex-shrink-0 w-auto">
                                                        <p className="text-[13px] leading-10 py-[1px] font-medium text-left text-black/80">
                                                            Name
                                                        </p>
                                                        <div className="flex gap-3 flex-grow w-[300px] max-md:w-w-full">
                                                            <input
                                                                 className="form-input w-full rounded-md bg-gray-100 border-transparent focus:border-gray-500 focus:bg-white focus:ring-1 placeholder:text-sm"
                                                                type={"text"}
                                                                placeholder="Enter student name "
                                                                name='student_name'
                                                                value={values.student_name}
                                                                onChange={handleChange}
                                                                onBlur={handleBlur}
                                                            />
                                                        </div>
                                                    </div>
                                                    <div className="flex-grow-0 flex-shrink-0 w-auto">
                                                        <p className="text-[13px] leading-10 py-[1px] font-medium text-left text-black/80">
                                                        Gender
                                                        </p>
                                                        <div className="flex gap-3 flex-grow w-[300px] max-md:w-w-full">
                                                            <select
                                                                 className="form-input w-full rounded-md bg-gray-100 border-transparent focus:border-gray-500 focus:bg-white focus:ring-1 placeholder:text-sm"
                                                                type={"text"}
                                                                placeholder="Enter Name "
                                                                name='gender'
                                                                value={values.gender}
                                                                style={errors.gender && touched.gender ?{border:"1px solid red"}:{}}
                                                                onChange={handleChange}
                                                                onBlur={handleBlur}
                                                            >
                                                                <option>Select</option>
                                                                <option>MALE</option>
                                                                <option>FEMALE</option>
                                                                <option>OTHERS</option>
                                                            </select>
                                                        </div>

                                                    </div>
                                                    <div className="flex-grow-0 flex-shrink-0 w-auto">
                                                        <p className="text-[13px] leading-10 py-[1px] font-medium text-left text-black/80">
                                                            DOB
                                                        </p>
                                                        <div className="flex gap-3 flex-grow w-[300px] max-md:w-w-full">
                                                            <input
                                                                 className="form-input w-full rounded-md bg-gray-100 border-transparent focus:border-gray-500 focus:bg-white focus:ring-1 placeholder:text-sm"
                                                                type={"date"}
                                                                placeholder="Enter Name "
                                                                name='date_of_birth'
                                                                value={values.date_of_birth}
                                                                style={errors.date_of_birth && touched.date_of_birth ?{border:"1px solid red"}:{}}
                                                                onChange={handleChange}
                                                                onBlur={handleBlur}
                                                            />
                                                        </div>

                                                    </div>
                                                </div>
                                                <div className="flex  w-[900px] gap-[10px] max-md:w-full max-md:flex-col">
                                                <div className="flex-grow-0 flex-shrink-0 w-auto">
                                                        <p className="text-[13px] leading-10 py-[1px] font-medium text-left text-black/80">
                                                            Present Belt
                                                        </p>
                                                        <div className="flex gap-3 flex-grow w-[300px] max-md:w-w-full">
                                                            <select
                                                                className="form-input w-full rounded-md bg-gray-100 border-transparent focus:border-gray-500 focus:bg-white focus:ring-1 placeholder:text-sm"
                                                                type={"text"}
                                                                placeholder="Enter brand name "
                                                                name='present_belt'
                                                                value={values.present_belt}
                                                                style={errors.present_belt && touched.present_belt ?{border:"1px solid red"}:{}}
                                                            onChange={handleChange}
                                                                onBlur={handleBlur}
                                                            >
                                                                <option>Select</option>
                                                                {
                                                                    presentBelts?.map((belt,i) => (
                                                                        <option key={i} value={belt}>{ belt}</option>
                                                                    ))
                                                               }
                                                            </select>
                                                        </div>

                                                    </div>
                                                <div className="flex-grow-0 flex-shrink-0 w-auto">
                                                        <p className="text-[13px] leading-10 py-[1px] font-medium text-left text-black/80">
                                                            Applied Belt
                                                        </p>
                                                        <div className="flex gap-3 flex-grow w-[300px] max-md:w-w-full">
                                                            <select
                                                                className="form-input w-full rounded-md bg-gray-100 border-transparent focus:border-gray-500 focus:bg-white focus:ring-1 placeholder:text-sm"
                                                                type={"text"}
                                                                placeholder="Enter brand name "
                                                                name='applied_belt'
                                                                value={values.applied_belt}
                                                                style={errors.applied_belt && touched.applied_belt ?{border:"1px solid red"}:{}}
                                                            onChange={handleChange}
                                                                onBlur={handleBlur}
                                                            >
                                                                <option>Select</option>
                                                                {
                                                                    appliedBelts?.map((belt,i) => (
                                                                        <option key={i} value={belt}>{ belt}</option>
                                                                    ))
                                                               }
                                                            </select>
                                                        </div>

                                                    </div>
                                                <div className="flex-grow-0 flex-shrink-0 w-auto">
                                                        <p className="text-[13px] leading-10 py-[1px] font-medium text-left text-black/80">
                                                            District Name
                                                        </p>
                                                        <div className="flex gap-3 flex-grow w-[300px] max-md:w-w-full">
                                                            <select
                                                                className="form-input w-full rounded-md bg-gray-100 border-transparent focus:border-gray-500 focus:bg-white focus:ring-1 placeholder:text-sm"
                                                                type={"text"}
                                                                placeholder="Enter brand name "
                                                                name='district_name'
                                                                value={values.district_name}
                                                                style={errors.district_name && touched.district_name ?{border:"1px solid red"}:{}}
                                                                onChange={(e) => {
                      setFieldValue("club_name", "")
                      handleChange(e)
                     }}
                                                                onBlur={handleBlur}
                                                            >
                                                                <option>Select</option>
                                                                {
                                                                    cityList?.map((city,i) => (
                                                                        <option key={i} value={city?.city_id}>{ city?.city_name}</option>
                                                                    ))
                                                              }
                                                            </select>
                                                        </div>

                                                    </div>
                                                
                                                 
                                                </div>
                                                <div className="flex  w-[900px] gap-[10px] max-md:w-full max-md:flex-col">
                                                <div className="flex-grow-0 flex-shrink-0 w-auto">
                                                        <p className="text-[13px] leading-10 py-[1px] font-medium text-left text-black/80">
                                                            Club Name
                                                        </p>
                                                        <div className="flex gap-3 flex-grow w-[300px] max-md:w-w-full">
                                                            <select
                                                                className="form-input w-full rounded-md bg-gray-100 border-transparent focus:border-gray-500 focus:bg-white focus:ring-1 placeholder:text-sm"
                                                                type={"text"}
                                                                placeholder="Enter brand name "
                                                                name='club_name'
                                                                value={values.club_name}
                                                                style={errors.club_name && touched.club_name ?{border:"1px solid red"}:{}}
                                                            onChange={handleChange}
                                                                onBlur={handleBlur}
                                                            >
                                                                <option>Select</option>
                                                                {
                      clubList?.map((city,i) => (
                        <option key={i} value={city?.ota_club_id}>{ city?.club_name}</option>
                      ))
                    }
                                                            </select>
                                                        </div>

                                                    </div>
                                                </div>

                                            </div>

                                        </div>
                                    </div>
                                    <div className="flex justify-end w-full p-6">
                                        <div className="flex justify-start items-start flex-grow-0 flex-shrink-0 gap-2 self-end">
                                            <div
                                                className="flex justify-start items-center flex-grow-0 flex-shrink-0 relative overflow-hidden gap-1 pl-4 pr-5 py-[11px] rounded-[94px] bg-[#fff] cursor-pointer"
                                                style={{ boxShadow: "0px 1px 0px 0 rgba(0,0,0,0.08)" }}
                                                onClick={() => setShowModal(false)}
                                            >
                                                <p className="flex-grow-0 flex-shrink-0 text-sm font-medium text-center text-black">
                                                    Cancel
                                                </p>
                                            </div>
                                            <button
                                                type="button"
                                                className="flex justify-start items-center flex-grow-0 flex-shrink-0 relative overflow-hidden gap-1 px-5 py-[11px] rounded-[94px] bg-[#17252A] cursor-pointer"
                                                onClick={handleSubmit}
                    disabled={isLoading?"disable":""}
                                            >
                                                <p className="flex-grow-0 flex-shrink-0 text-sm font-medium text-center text-white">
                                                    Save
                                                </p>
                                            </button>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </>
            ) : null}


        </>

    )
}

export default EditColorBeltParticipateDetails