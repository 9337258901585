import React, { useState } from 'react'

import * as Yup from "yup";
import { useFormik } from "formik";
import axiosConfig from '../../axios_config/axios_instance';
import toast from 'react-hot-toast';
import Files from "react-files";
import { useDispatch } from 'react-redux';
import { getAllCityList } from '../../services/apiService';
import { ReactComponent as UploadIcon } from "../../assets/icons/uploadicon.svg";
import { MdOutlineEdit } from "react-icons/md";
import { FiLoader } from "react-icons/fi";
const EditCities = ({details, page}) => {
    const [showModal, setShowModal] = useState(false);
    const [isLoading, setIsLoading ]=useState(false)

const dispatch=useDispatch()

    const initialValues = {
      City_name:details?.city_name,
      city_Image:details?.city_image,
      };
    
      //Yup validator Schema
      const videoInformationSchema = Yup.object({
    
          
          city_Image:Yup.mixed().required("file required")
    
      });
    
      //formik validation
      const {
        values,
        handleBlur,
        resetForm,
        handleChange,
        handleSubmit,
        setFieldValue,
        errors,
        touched,
      } = useFormik({
        initialValues,
        validationSchema: videoInformationSchema,
        validateOnChange: true,
        validateOnBlur: false,
        onSubmit: async(values) => {
          try {
            const formdata= new FormData()
            setIsLoading(true)
            formdata.append("city_name", values?.City_name);
            formdata.append("city_image", values?.city_Image);

            formdata.append("city_id", details?.city_id);
            const {data}= await axiosConfig.post("/update-city", formdata);
            if(data?.status === "success"){
              toast.success(data?.message || "city updated successfully")
              setShowModal(false)
              resetForm()
              dispatch(getAllCityList(page-1))
       
            }
           } catch (error) {
              toast.error("something Went wrong")
           }finally{
            setIsLoading(false)
           }
        },
      });
     


    const closeModal = (e) => {
        e?.target?.classList?.forEach((classes) => {
          if (classes === "Modal-Container") {
            setShowModal(false);
          }
        });
      };

      const handleImageChange = (files) => {
        if (files.length) {
          setFieldValue("city_Image",files[0]);
        } else {
          toast.error("No image selected. Please upload an image.");
        }
      };
  
  
  return (
    <div>
    <div
    onClick={(e) => {
      e.stopPropagation();
      setShowModal(true);
      resetForm();
    }}
    className="flex justify-start cursor-pointer relative items-center flex-grow-0 flex-shrink-0 gap-3"
  >
  <button
                    
  className="e flex justify-center items-center flex-grow-0 flex-shrink-0 relative rounded  p-2 bg-white shadow-sm hover:bg-blue-400 transition-all cursor-pointer  hover:rounded-md text-black"
>
  <MdOutlineEdit className="w-4 h-4" />
</button>
  </div>
  {showModal ? (
    <>
      <div
        className="flex justify-center items-center overflow-x-hidden overflow-y-auto fixed inset-0 z-50 outline-none focus:outline-none shadow-lg w-full Modal-Container"
        onClick={closeModal}
        style={{ backdropFilter: "blur(1px)" }} 

      >
        <div className="relative w-auto my-6 mx-auto max-w-3xl">
          <div className="border-0 rounded-lg shadow-lg relative flex w-full bg-white outline-none focus:outline-none">
            <div className="flex items-start gap-1 p-2 border-b border-solid border-gray-300 rounded-t flex-col ">
              <h3 className="text-xl font=semibold">
                Edit City
              </h3>
              <div className="flex justify-between items-start">
                <div className="flex flex-col mt-3">
                
                </div>
                <div className="relative p-6 pt-0 flex-auto">
                  <div className="flex  justify-start items-start self-stretch flex-grow-0 flex-shrink-0 gap-1">
                    <div className="flex justify-start items-start self-stretch flex-grow-0 flex-shrink-0 relative gap-1 flex-col">
                    <div className="flex flex-col mt-3 w-[330px]">
                    <Files
                      className="files-dropzone cursor-pointer"
                      onChange={(file) => handleImageChange(file)}
                      onError={(error) => toast.error(error?.message)}
                      accepts={[".jpeg", ".jpg", ".png"]}
                      maxFileSize={512000}
                      minFileSize={0}
                      multiple
                      clickable
                    >
                      {values.city_Image ? (
                        <div className="flex flex-col items-center relative">
                          <img
                            src={
                              typeof values.city_Image === "object"
                                ? values.city_Image?.preview?.url
                                : values.city_Image
                            }
                            alt='city_preview'
                            className="h-52 w-[200px] object-cover"
                          />
                        </div>
                      ) : (
                        <div className="flex flex-col justify-center items-center flex-grow-0 flex-shrink-0 h-[122px] w-[122px] relative gap-1 px-[23.5px] py-7 rounded bg-[#186ced]/[0.04] border-[1.33px] border-[#186ced]/[0.64] border-dashed">
                          <UploadIcon />
                          <div className="flex justify-start items-center flex-grow-0 flex-shrink-0 relative gap-1 px-[5px] py-0.5 rounded">
                            <p className="flex-grow-0 flex-shrink-0 text-xs font-medium text-center text-black/[0.64]">
                              Upload
                            </p>
                          </div>
                        </div>
                      )}
                    </Files>
                    <p className="text-[12px] text-left text-black/80 font-semibold text-[#202223] relative after:content-['*'] after:ml-0.5 after:text-red-500">(upload only jpeg max 500kb)</p>
                    {/* { locationImages?.length ?  <span>{locationImages.length} selected</span>:null} */}
                  </div>
                      <div className="flex-grow-0 flex-shrink-0 w-[150px]">
                        <p className="text-[13px] leading-10 py-[1px] font-medium text-left text-black/80">
                          City Name
                        </p>
                      </div>
                      <div className="flex flex-col flex-grow ">
                      <input
                      type="text"
                      className="form-input max-md:w-full  p-2 w-full h-10 rounded-md   border-[#B2B5C4] border-[1px] focus:bg-white focus:ring-0 placeholder:text-sm"
                      placeholder="City Name"
                      name="City_name"
                      value={values.City_name}
                      onChange={handleChange}
                      onBlur={handleBlur}
                    />
                    
                
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="flex justify-start items-start flex-grow-0 flex-shrink-0 gap-2 self-end">
                <div
                  onClick={(e) => {
                    e.stopPropagation();
                    setShowModal(false);
                  }}
                  className="flex justify-start items-center flex-grow-0 flex-shrink-0 relative overflow-hidden gap-1 pl-4 pr-5 py-[11px] rounded-[94px] bg-[#fff] cursor-pointer"
                  style={{ boxShadow: "0px 1px 0px 0 rgba(0,0,0,0.08)" }}
                >
                  <p className="flex-grow-0 flex-shrink-0 text-sm font-medium text-center text-black">
                    Cancel
                  </p>
                </div>
                <button
                type="button"
                className="flex justify-start items-center flex-grow-0 flex-shrink-0 relative overflow-hidden gap-1 px-5 py-[11px] rounded-[94px] bg-[#186ced] cursor-pointer"
                style={{ boxShadow: "0px 1px 0px 0 rgba(0,0,0,0.08)" }}
                onClick={handleSubmit}
                disabled={isLoading} // Disable the button when loading
              >
                {isLoading ? (
                  <div className="spinner-border text-light" role="status">
                    <span className="visually-hidden"><FiLoader /></span>
                  </div>
                ) : (
                  <p className="flex-grow-0 flex-shrink-0 text-sm font-medium text-center text-white">
                    Save
                  </p>
                )}
              </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  ) : null}
    </div>
  )
}

export default EditCities
