import React, { useEffect, useState } from "react";
import { Link, useNavigate, useParams } from "react-router-dom";
import * as Yup from "yup";
import { useFormik } from "formik";
import { ReactComponent as UploadIcon } from "../../assets/icons/uploadicon.svg";
import Files from "react-files";
import toast from "react-hot-toast";
import DatePicker from "react-datepicker";
import { MdOutlineEdit } from "react-icons/md";
import axiosConfig from "../../axios_config/axios_instance";
import { useDispatch, useSelector } from "react-redux";
import {ReactComponent as ArrowLeft} from "../../assets/icons/arrowlefticon.svg"
import { FiLoader } from "react-icons/fi";

import { getAllCityList, getAllCityListAllModule, getAllDtaList } from "../../services/apiService";
import Template from "../../components/common/Template";
const EditDta = ({dtaAssocitaiondetails, page}) => {
  const [showModal, setShowModal] = useState(false);
  const [selectedDate1, setSelectedDate1] = useState(null);
  const [uploadedFileName, setUploadedFileName] = useState("");
  const [loading, setLoading] = useState(false);
  const {DTAList}=useSelector((state)=>state.dta)
  const dispatch=useDispatch()
  const navigate= useNavigate()

  const { district_association_id } = useParams();

  const handleDateChange1 = (date) => {
    setSelectedDate1(date);
  };

  const {AllCityListModule}=useSelector((state)=>state.citylist)

  const options = AllCityListModule.map(({ city_id, city_name }) => ({
    value: city_id,
    label: city_name,
  }));

  useEffect(()=>{
    dispatch(getAllCityListAllModule())
  },[dispatch])

  //intialData for formik
  const initialValues = {
    logo:"",
    image:"",
    president_name:"",
    cityname:"",
    secretary_name:"",
    address:  "",
    email:  "",
    phonenumber_one:"",
    phonenumber_two: "",
  };

  //Yup validator Schema
  const EditInformationSchema = Yup.object({
    president_name: Yup.string().required(" president name is required"),
    cityname: Yup.string().required(" city name is required"),
    secretary_name: Yup.string().required(" secretary name is required"),
    address: Yup.string().required(" address  is required"),
    email: Yup.string().required(" email  is required"),
    image:Yup.mixed().required("file required"),
    phonenumber_one: Yup.string().required(" phonenumber_one  is required"),
  });

  //formik validation
  const {
    values,
    handleBlur,
    resetForm,
    handleChange,
    handleSubmit,
    setFieldValue,
    errors,
    touched,
  } = useFormik({
    initialValues,
    validationSchema: EditInformationSchema,
    validateOnChange: true,
    enableReinitialize: true,
    validateOnBlur: false,
    onSubmit:async (values, action) => {
      try {
        setLoading(true)
        const formdata=new FormData()
        formdata.append("logo",values?.image)
        formdata.append("district_association_id",district_association_id)
          formdata.append("city_name", AllCityListModule?.find((city)=>city?.city_id)?.city_name);
      formdata.append("city_id", values.cityname);
        formdata.append("president", values?.president_name);
        formdata.append("secretary", values?.secretary_name);
        formdata.append("dta", values?.dta_name);
        formdata.append("address", values?.address);
        formdata.append("email", values?.email);
        formdata.append("phone_number", values?.phonenumber_one);
        formdata.append("alternative_phone_number", values?.phonenumber_two);
        formdata.append("city_id",2)
  
        const {data}= await axiosConfig.put("/update-district-association", formdata);
        if(data?.status==="success"){
            toast.success(data?.message || "Distric Association Updated Successfully");
            resetForm()
            navigate("/dta");
            dispatch(getAllDtaList(page-1,10))
        }
       } catch (error) {
        toast.error("something Went wrong")
       }finally{
        setLoading(false)
       }
    },
  });

  useEffect(()=>{
    if(district_association_id){
      const DtaListDetails=DTAList?.find((dtaa)=>Number(dtaa?.district_association_id)===Number(district_association_id))
      if(DtaListDetails){
        setFieldValue("logo",DtaListDetails?.logo)
        setFieldValue("image",DtaListDetails.image)
        setFieldValue("president_name",DtaListDetails?.president)
        setFieldValue("district_association_id",DtaListDetails?.district_association_id)
        setFieldValue("cityname",DtaListDetails?.city_id )
        setFieldValue("dta_name",DtaListDetails?.dta )
        setFieldValue("secretary_name",DtaListDetails?.secretary )
        setFieldValue("address",DtaListDetails?.address )
        setFieldValue("email",DtaListDetails?.email )
        setFieldValue("phonenumber_one",DtaListDetails?.phone_number )
        setFieldValue("phonenumber_two",DtaListDetails?.alternative_phone_number )
      }
    } 
  },[district_association_id,DTAList])
  const handleImageChange = (files) => {
    if (files.length) {
      setFieldValue("image", files[0]);
      setUploadedFileName(files[0].name);
    } else {
      toast.error("No image selected. Please upload an image.");
    }
  };

  const handleImageChangeLogo = (files) => {
    if (files.length) {
      setFieldValue("logo", files[0]);
      setUploadedFileName(files[0].name);
    } else {
      toast.error("No logo selected. Please upload an image.");
    }
  };
  const closeModal = (e) => {
    e?.target?.classList?.forEach((classes) => {
      if (classes === "Modal-Container") {
        setShowModal(false);
        resetForm();
      }
    });
  };


  return (
    <Template>
    <div>
    <div
   
      className="flex justify-start cursor-pointer  flex-grow-0 flex-shrink-0 gap-3 "
    > 
    <div className="flex justify-start bg-[#e8e8f1] w-full items-center self-stretch flex-grow-0 flex-shrink-0 gap-2 px-4 max-md:py-2 py-4">
    <div className="flex justify-between max-md:justify-between flex-row flex-grow relative ">
    <div className='flex max-md:flex gap-3 items-center '>
      <ArrowLeft  onClick={()=>navigate("/dta")} className='cursor-pointer max-md:  w-4 h-4'/>
      <p className="flex-grow-0 flex-shrink-0 text-2xl max-md:text-[18px] font-semibold text-left capitalize text-[#202223]">
       Edit Dta
      </p>
      </div>
      <div className="flex justify-start items-start   flex-grow-0 flex-shrink-0 gap-2 self-end">
        <div
        onClick={(e) => {
          e.stopPropagation();
          navigate("/dta")
          resetForm();
        }}
        className="flex justify-start items-center flex-grow-0 flex-shrink-0 relative overflow-hidden gap-1 pl-4 pr-5 py-[11px] rounded-[94px] bg-[#fff] cursor-pointer"
        style={{ boxShadow: "0px 1px 0px 0 rgba(0,0,0,0.08)" }}
      >
        <p className="flex-grow-0 flex-shrink-0 text-sm font-medium text-center text-black">
          Cancel
        </p>
      </div>
      <button
        type="button"
        className="flex justify-start items-center flex-grow-0 flex-shrink-0 relative overflow-hidden gap-1 px-5 py-[11px] rounded-[94px] bg-[#186ced] cursor-pointer"
        style={{ boxShadow: "0px 1px 0px 0 rgba(0,0,0,0.08)" }}
        onClick={handleSubmit}
        disabled={loading} // Disable the button when loading
      >
        {loading ? (
          <div className="spinner-border text-light" role="status">
            <span className="visually-hidden"><FiLoader /></span>
          </div>
        ) : (
          <p className="flex-grow-0 flex-shrink-0 text-sm font-medium text-center text-white">
            Save
          </p>
        )}
      </button>
        </div>
    </div>
  </div>

    </div>
    <div className="bg-zinc-50 self-stretch flex pb-0 flex-row items-stretch mt-2.5 pt-2 px-10 max-md:max-w-full max-md:px-5">
    <div className="flex flex-row justify-start items-start self-stretch flex-grow-0 flex-shrink-0 ">
      
    <div className="flex flex-row justify-start items-start flex-grow-0 flex-shrink-0 gap-10 flex-wrap">
    <div>
    <h2 className="text-sm font-semibold text-[#202223] relative after:content-['*'] after:ml-0.5 after:text-red-500">
    Add Data Image
    <span className=""></span>
    </h2>    
      <Files
        className="files-dropzone cursor-pointer mt-1"
        onChange={(file) => handleImageChange(file)}
        onError={(error) => toast.error(error?.message)}
        accepts={[".jpg", ".jpeg" , ]}
        maxFileSize={2097152} 
        minFileSize={0}
        multiple={false}
        clickable
      >
        {values.image ? (
          <div className="flex flex-col items-center relative">
            <img
              src={
                typeof values.image === "object"
                  ? values.image?.preview?.url
                  : values.image
              }
              className="h-[122px] w-[122px] object-cover"
            />
          </div>
        ) : (
          <div className="flex flex-col justify-center items-center flex-grow-0 flex-shrink-0 h-[122px] w-[122px] relative gap-1 px-[23.5px] py-7 rounded bg-[#186ced]/[0.04] border-[1.33px] border-[#186ced]/[0.64] border-dashed">
            <UploadIcon />
            <div className="flex justify-start items-center flex-grow-0 flex-shrink-0 relative gap-1 px-[5px] py-0.5 rounded">
              <p className="flex-grow-0 flex-shrink-0 text-xs font-medium text-center text-black/[0.64]">
                Upload
              </p>
            </div>
          </div>
        )}
      </Files>
      <p className="text-[12px] text-left text-black/80 font-semibold text-[#202223] relative after:content-['*'] after:ml-0.5 after:text-red-500">(upload only jpg max 2mb)</p>

    </div>

    <div>
    <h2 className="text-sm font-semibold text-[#202223] relative after:content-['*'] after:ml-0.5 after:text-red-500">
    Add Data Logo
    <span className=""></span>
  </h2>            <Files
        className="files-dropzone cursor-pointer mt-1"
        onChange={(file) => handleImageChangeLogo(file)}
        onError={(error) => toast.error(error?.message)}
        accepts={[".jpg", ".jpeg", ".png"]}
        maxFileSize={1048576}            
          minFileSize={0}
        multiple={false}
        clickable
      >
        {values.logo ? (
          <div className="flex flex-col items-center relative">
            <img
              src={
                typeof values.logo === "object"
                  ? values.logo?.preview?.url
                  : values.logo
              }
              className="h-[122px] w-[122px] object-cover"
            />
          </div>
        ) : (
          <div className="flex flex-col justify-center items-center flex-grow-0 flex-shrink-0 h-[122px] w-[122px] relative gap-1 px-[23.5px] py-7 rounded bg-[#186ced]/[0.04] border-[1.33px] border-[#186ced]/[0.64] border-dashed">
            <UploadIcon />
            <div className="flex justify-start items-center flex-grow-0 flex-shrink-0 relative gap-1 px-[5px] py-0.5 rounded">
              <p className="flex-grow-0 flex-shrink-0 text-xs font-medium text-center text-black/[0.64]">
                Upload
              </p>
            </div>
          </div>
        )}
      </Files>
      <p className="text-[12px] text-left text-black/80 font-semibold text-[#202223] relative after:content-['*'] after:ml-0.5 after:text-red-500">(upload only jpeg and png max 1mb)</p>

    </div>
  </div>
    </div>
  </div>
  
  <div className="pr-1.5 max-md:max-w-full p-4 mx-4">
  <p className='text-lg font-sans font-medium after:content-["*"] after:ml-0.3 mt-2 after:text-red-500'>District</p>
  <div className="flex items-center ">
  <select
        name="cityname"
        onChange={handleChange}
        placeholder="Select City"
        className={
            errors.cityname && touched.cityname
                ? ` { border: "1px solid red" } form-input w-[60%] max-md:w-full capitalize p-2 max-md:bg-white  h-10 rounded-md   border-[#B2B5C4] border-[1px] focus:bg-white focus:ring-0 placeholder:text-sm `
                : "form-input w-[60%] max-md:w-full p-2 max-md:bg-white  h-10 rounded-md capitalize   border-[#B2B5C4] border-[1px] focus:bg-white focus:ring-0 placeholder:text-sm "
        }
        onBlur={() => handleBlur("cityname")}
        value={values.cityname}
    >
    <option>Select district</option>
    {
      AllCityListModule?.map((option)=>(
        <option value={option.city_id}>{option?.city_name}</option>
      ))
    }

    </select>
  </div>


  <p className='text-black text-xl font-medium leading-10 mt-3 whitespace-nowrap max-md:max-w-full after:content-["*"] after:ml-0.5 after:text-red-500'>
  President
  </p>
  <div className="flex items-center ">
  <input
  name="president_name"
  type="text"
  value={values.president_name}
  onChange={handleChange}
  className="form-input  p-2 w-[60%] max-md:w-full h-10 rounded-md capitalize  border-[#B2B5C4] border-[1px] focus:bg-white focus:ring-0 placeholder:text-sm"
  placeholder="President name"
  style={
    errors.president_name && touched.president_name
      ? { border: "1px solid red" }
      : {}
  }
/>
  </div>

  <p className='text-black text-xl font-medium leading-10 whitespace-nowrap mt-3 max-md:max-w-full after:content-["*"] after:ml-0.5 after:text-red-500'>
  Secretary{" "}
  </p>
  <div className="flex max-md:w-full items-center ">
  <input
  name="secretary_name"
  type="text"
  value={values.secretary_name}
  onChange={handleChange}
  className="form-input  p-2 w-[60%] max-md:w-full h-10 rounded-md capitalize  border-[#B2B5C4] border-[1px] focus:bg-white focus:ring-0 placeholder:text-sm"
  placeholder="Secretary name"
  style={
    errors.secretary_name && touched.secretary_name
      ? { border: "1px solid red" }
      : {}
  }
/>
  </div>
  <p className='text-black text-xl font-medium leading-10 whitespace-nowrap mt-3 max-md:max-w-full '>
  Dta{" "}
  </p>
  <div className="flex max-md:w-full items-center ">
  <input
  name="dta_name"
  type="text"
  value={values.dta_name}
  onChange={handleChange}
  className="form-input  p-2 w-[60%] max-md:w-full h-10 rounded-md  capitalize  border-[#B2B5C4] border-[1px] focus:bg-white focus:ring-0 placeholder:text-sm"
  placeholder="Secretary name"
  style={
    errors.dta_name && touched.dta_name
      ? { border: "1px solid red" }
      : {}
  }
/>
  </div>
  <div className="max-md:pt-5">
    
  <p className='text-black text-xl font-medium leading-10 whitespace-nowrap mt-3  max-md:max-w-full after:content-["*"] after:ml-0.5 after:text-red-500'>
  primary phone
  </p>
  <div className="flex items-center ">
  <input
  name="phonenumber_one"
  type="number"
  value={values.phonenumber_one}
  onChange={handleChange}
  className="form-input  p-2 w-[60%] max-md:w-full h-10 rounded-md  capitalize   border-[#B2B5C4] border-[1px] focus:bg-white focus:ring-0 placeholder:text-sm"
  placeholder="Phonenumber one"
  style={
    errors.phonenumber_one && touched.phonenumber_one
      ? { border: "1px solid red" }
      : {}
  }
/>
  </div>
  </div>
  <div className="max-md:pt-5">
    
  <p className='text-black text-xl font-medium leading-10 whitespace-nowrap mt-3 max-md:max-w-full after:content-["*"] after:ml-0.5 after:text-red-500'>
  Email
  </p>
  <div className="flex items-center ">
  <input
  name="email"
  type="text"
  value={values.email}
  onChange={handleChange}
  className="form-input  p-2 w-[60%] max-md:w-full h-10 rounded-md  capitalize  border-[#B2B5C4] border-[1px] focus:bg-white focus:ring-0 placeholder:text-sm"
  placeholder="Email"
  style={
    errors.email && touched.email
      ? { border: "1px solid red" }
      : {}
  }
/>
  </div>
  </div>
  <div className="max-md:pt-5">
    
  <p className='text-black text-xl font-medium leading-10 whitespace-nowrap mt-3 max-md:max-w-full '>
  Alternative phone number  
  </p>
  <div className="flex items-center ">
  <input
          name="phonenumber_two"
          type="number"
          value={values.phonenumber_two}
          onChange={handleChange}
          className="form-input  p-2 w-[60%] max-md:w-full h-10 rounded-md  capitalize border-[#B2B5C4] border-[1px] focus:bg-white focus:ring-0 placeholder:text-sm"
          placeholder="secondry phone"
        
        />
  </div>
  </div>
  <div className="max-md:pt-5">
    
  <p className='text-black text-xl font-medium leading-10 whitespace-nowrap mt-3 max-md:max-w-full after:content-["*"] after:ml-0.5 after:text-red-500'>
  Address
  </p>
  <div className="flex items-center ">
  <input
  name="address"
  type="text"
  value={values.address}
  onChange={handleChange}
  className="form-input  p-2 w-[60%] max-md:w-full h-10 rounded-md capitalize   border-[#B2B5C4] border-[1px] focus:bg-white focus:ring-0 placeholder:text-sm"
  placeholder="Address"
  style={
    errors.address && touched.address
      ? { border: "1px solid red" }
      : {}
  }
/>
  </div>
  </div>


</div>
      </div>
      </Template>
  );
};

export default EditDta;
