import { createSlice } from "@reduxjs/toolkit";

const NewRefree = createSlice({
    name: "refree",
    initialState: {
        refreeListLoading: false,
        RefreeList:[],
        selectedTabRefree:0,
        TotalRefreeList:0
    },
    reducers: {
         getRefreeListRequest:(state)=>{
            state.refreeListLoading=true;
         },
         getRefreeListSuccess:(state, {payload})=>{
            state.refreeListLoading=false;
            state.RefreeList=payload?.referee_profile_data;
            state.TotalRefreeList=payload?.total_elements
         },
         getRefreeListFail:(state)=>{
            state.refreeListLoading=false;
            state.RefreeList=[];
         },
         setSelectedTabRefree:(state,{payload})=>{
            state.selectedTabRefree=payload
        },
    }
})  

export default NewRefree.reducer;
export const {getRefreeListFail, getRefreeListRequest, getRefreeListSuccess, setSelectedTabRefree}= NewRefree.actions