import React, { useState } from "react";
import { Link } from "react-router-dom";
import * as Yup from "yup";
import { useFormik } from "formik";
import { ReactComponent as UploadIcon } from "../../assets/icons/uploadicon.svg";
import Files from "react-files";
import toast from "react-hot-toast";
import DatePicker from "react-datepicker";
import axiosConfig from "../../axios_config/axios_instance";
import { useDispatch } from "react-redux";
import { getAllFormList } from "../../services/apiService";
import pdfImage from "../../assets/images/pdf.png"
import { FiLoader } from "react-icons/fi";
const AddForms = () => {
  const [showModal, setShowModal] = useState(false);
  const [selectedDate1, setSelectedDate1] = useState(null);
  const [uploadedFileName, setUploadedFileName] = useState("");
  const [isLoading, setIsLoading]=useState(false)
  const dispatch=useDispatch()
  const handleDateChange1 = (date) => {
    setSelectedDate1(date);
  };


  //intialData for formik
  const initialValues = {
    form_desc: "",
  };

  //Yup validator Schema
  const FormValidationSchema = Yup.object({
    form_desc: Yup.string()
    .required("required"),
   
      image:Yup.mixed().required("file required")
  });

  //formik validation
  const {
    values,
    handleBlur,
    resetForm,
    handleChange,
    handleSubmit,
    setFieldValue,
    errors,
    touched,
  } = useFormik({
    initialValues,
    validationSchema: FormValidationSchema,
    validateOnChange: true,
    validateOnBlur: false,
    onSubmit: async (values, action) => {
      try {
        const formdata= new FormData()
        setIsLoading(true)
        if (!values.image) {
          toast.error("Please upload a PDF file.");
          return;
        }
        formdata.append("ota_form", values?.image)
        formdata.append("description", values?.form_desc)
        
        const {data}= await axiosConfig.post("/save-ota-form", formdata);
        if(data?.status === "success"){
          toast.success(data?.message || " Form Added successfully")
          setShowModal(false)
          resetForm()
          dispatch(getAllFormList())
   
        }
       } catch (error) {
          toast.error("something Went wrong")
       }finally{
        setIsLoading(false)
       }
    },
  });
  const handleImageChange = (files) => {
    if (files.length) {
      setFieldValue("image", files[0]);
    } 
  };
  const closeModal = (e) => {
    e?.target?.classList?.forEach((classes) => {
      if (classes === "Modal-Container") {
        setShowModal(false);
        resetForm();

      }
    });
  };
  return (
    <div>
      <div
        onClick={(e) => {
          e.stopPropagation();
          setShowModal(true);
          resetForm();
        }}
        className="flex justify-start cursor-pointer relative items-center flex-grow-0 flex-shrink-0 gap-3"
      >
        <div
          className="flex justify-start items-center flex-grow-0 flex-shrink-0 relative overflow-hidden gap-1 pl-4 pr-5 max-md:py-[7px] py-[10px] rounded-full bg-[#186ced]"
          style={{ boxShadow: "0px 1px 0px 0 rgba(0,0,0,0.08)" }}
        >
          <p className="flex-grow-0 flex-shrink-0 text-sm font-medium text-center text-white">
            Add Forms
          </p>
        </div>
      </div>

      {showModal ? (
        <>
          <div
            className="flex justify-center items-center overflow-x-hidden overflow-y-auto fixed inset-0 z-50 outline-none focus:outline-none shadow-lg w-full Modal-Container"
            onClick={closeModal}
            style={{ backdropFilter: "blur(1px)" }} 

          >
            <div className="relative w-auto my-6 mx-auto max-w-3xl">
              <div className="border-0 rounded-lg shadow-lg relative flex w-full bg-white outline-none focus:outline-none">
                <div className="flex items-start gap-1 p-2 border-b border-solid border-gray-300 rounded-t flex-col ">
                  <h3 className="text-xl font=semibold">
                    Add Form
                  </h3>
                  <div className="flex justify-between items-start p-4">
                    <div className="flex flex-col mt-3">
                    <p className="text-[14px] text-left text-black/80 font-semibold text-[#202223] relative after:content-['*'] after:ml-0.5 after:text-red-500">max upto 500kb</p>

                    <Files
  className="files-dropzone cursor-pointer"
  onChange={(file) => handleImageChange(file)}
  onError={(error) => toast.error(error?.message)}
  accepts={[".pdf"]}
  maxFileSize={512000}
  minFileSize={0}
  multiple
  clickable
>
{values.image ? (
  <div className="flex flex-col items-center relative">
    <img
      src={
        typeof values.image === "object"
          ? `${pdfImage}`
          : values.image
      }
      className="h-36 w-[130px] object-cover"
    />
   
  </div>
) : (
  <div className="flex flex-col justify-center items-center flex-grow-0 flex-shrink-0 h-[122px] w-[122px] relative gap-1 px-[23.5px] py-7 rounded bg-[#186ced]/[0.04] border-[1.33px] border-[#186ced]/[0.64] border-dashed">
    <UploadIcon />
    <div className="flex justify-start items-center flex-grow-0 flex-shrink-0 relative gap-1 px-[5px] py-0.5 rounded">
      <p className="flex-grow-0 flex-shrink-0 text-xs font-medium text-center text-black/[0.64]">
        Upload
      </p>
    </div>
  </div>
)}
</Files>
<p className="text-[14px] text-left text-black/80 font-semibold text-[#202223] relative after:content-['*'] after:ml-0.5 after:text-red-500">(upload only pdf)</p>
{/* { locationImages?.length ?  <span>{locationImages.length} selected</span>:null} */}
                    </div>
                    <div className="relative p-6 pt-0 flex-auto">
                      <div className="flex flex-col justify-start items-start self-stretch flex-grow-0 flex-shrink-0 gap-1">
                        <div className="flex justify-start items-start self-stretch flex-grow-0 flex-shrink-0 relative gap-1 flex-col">
                    
                     
                          <div className="flex-grow-0 flex-shrink-0 w-[150px]">
                            <p className="text-[13px] leading-10 py-[1px] font-medium text-left text-black/80">
                              Description
                            </p>
                          </div>
                          <div className="flex flex-col flex-grow w-[300px]">
                            <textarea
                              type="text"
                              className="form-input w-full h-36 capitalize  p-2 rounded-md bg-gray-100 border-transparent focus:border-gray-500 focus:bg-white focus:ring-0 placeholder:text-sm"
                              placeholder="Type here"
                              value={values.form_desc}
                              onChange={handleChange}
                              onBlur={handleBlur}
                            
                              name="form_desc"
                              style={
                                errors.form_desc && touched.form_desc
                                  ? { border: "1px solid red" }
                                  : {}
                              }
                            ></textarea>

                          
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="flex justify-start items-start flex-grow-0 flex-shrink-0 gap-2 self-end">
                    <div
                      onClick={(e) => {
                        e.stopPropagation();
                        setShowModal(false);
                      }}
                      className="flex justify-start items-center flex-grow-0 flex-shrink-0 relative overflow-hidden gap-1 pl-4 pr-5 py-[11px] rounded-[94px] bg-[#fff] cursor-pointer"
                      style={{ boxShadow: "0px 1px 0px 0 rgba(0,0,0,0.08)" }}
                    >
                      <p className="flex-grow-0 flex-shrink-0 text-sm font-medium text-center text-black">
                        Cancel
                      </p>
                    </div>
                    <button
                    type="button"
                    className="flex justify-start items-center flex-grow-0 flex-shrink-0 relative overflow-hidden gap-1 px-5 py-[11px] rounded-[94px] bg-[#186ced] cursor-pointer"
                    style={{ boxShadow: "0px 1px 0px 0 rgba(0,0,0,0.08)" }}
                    onClick={handleSubmit}
                    disabled={isLoading} // Disable the button when loading
                  >
                    {isLoading ? (
                      <div className="spinner-border text-light" role="status">
                        <span className="visually-hidden"><FiLoader /></span>
                      </div>
                    ) : (
                      <p className="flex-grow-0 flex-shrink-0 text-sm font-medium text-center text-white">
                        Save
                      </p>
                    )}
                  </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </>
      ) : null}
    </div>
  );
};

export default AddForms;
