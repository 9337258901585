import React, { Fragment, useEffect, useState } from 'react'
import Template from '../../components/common/Template'
import { Tab } from '@headlessui/react';
import { Link, useNavigate } from 'react-router-dom';
import AddChampionShipHeading from './AddChampionShipHeading';
import AddColorBeltHeading from './AddColorBeltHeading';
import EditChampionShipHeading from './EditChampionShipHeading';
import EditColorBeltHeading from './EditColorBeltHeading';
import { OptionModal } from './ChangeStatusModal';
import { useDispatch, useSelector } from 'react-redux';
import { getChampionshipHeading, getColorBeltHeadings } from '../../services/api';
import ManageEvent from './ManageEvent';

const OnlineRegistrationList = () => {
  const [selectedIndex, setselectedIndex] = useState(0);
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const {championShipHeadings,ColorBeltHeadings}=useSelector((state)=>state.onlineRegistartion)


  useEffect(() => {
    if (selectedIndex === 0) {
      dispatch(getChampionshipHeading())
    } else {
      dispatch(getColorBeltHeadings())
    }
},[selectedIndex])


  return (
    <Template>
               {selectedIndex===0 ?     <div className="flex justify-start items-center self-stretch flex-grow-0 flex-shrink-0 gap-2 px-4 py-4 max-md:flex-col max-md:items-start">
        <div className="flex justify-start items-center flex-grow relative gap-[22px]">
          <p className="flex-grow-0 flex-shrink-0 text-2xl font-semibold text-left capitalize text-[#202223] px-4 max-md:px-0 max-md:text-xl">
          Tournament
          </p>
              </div>
              <div className="flex justify-start items-start flex-grow-0 flex-shrink-0 gap-3">
              <ManageEvent />
            </div>
              <div className="flex justify-start items-start flex-grow-0 flex-shrink-0 gap-3">
            <AddChampionShipHeading />
          </div>
        </div>:    <div className="flex justify-start items-center self-stretch flex-grow-0 flex-shrink-0 gap-2 px-4 py-4 max-md:flex-col max-md:items-start">
        <div className="flex justify-start items-center flex-grow relative gap-[22px]">
          <p className="flex-grow-0 flex-shrink-0 text-2xl font-semibold text-left capitalize text-[#202223] px-4 max-md:px-0 max-md:text-xl">
          Belt Promotion Test

          </p>
              </div>
              
              <div className="flex justify-start items-start flex-grow-0 flex-shrink-0 gap-3">
         <AddColorBeltHeading />
          </div>
        </div>}
          <Tab.Group selectedIndex={selectedIndex} onChange={setselectedIndex}>
          <Tab.List className="flex justify-start items-center self-stretch flex-grow-0 flex-shrink-0 gap-8 px-8 border border-black/[0.08]">
          <Tab as={Fragment}>
                {({ selected }) => (
                  /* Use the `selected` state to conditionally style the selected tab. */
                  <button
                    className={`flex justify-start items-center flex-grow-0 flex-shrink-0 relative gap-1 py-4 text-sm font-medium text-center focus:ring-0 outline-none transition-all ${
                      selected
                        ? "border-b-2 border-[#186ced] text-[#186ced]"
                        : "border-b-2 border-transparent text-gray-400 hover:text-gray-700"
                    } `}
                  >
                    Tournament
                  </button>
                )}
              </Tab>
              <Tab as={Fragment}>
                {({ selected }) => (
                  <button
                    className={`flex justify-start items-center flex-grow-0 flex-shrink-0 relative gap-1 py-4 text-sm font-medium text-center focus:ring-0 outline-none transition-all ${
                      selected
                        ? "border-b-2 border-[#186ced] text-[#186ced]"
                        : "border-b-2 border-transparent text-gray-400 hover:text-gray-700"
                    } `}
                  >
                   Belt Promotion Test
                  </button>
                )}
              </Tab>
           
              </Tab.List>
              <Tab.Panels>
                  <Tab.Panel>
                  <div className="flex flex-col  items-start self-stretch flex-grow min-h-[90vh] justify-between">
      {
        championShipHeadings?.length ?
      <div class=" px-4 sm:px-8 py-4 overflow-x-auto w-full">
      <div
        class="inline-block min-w-full shadow-md rounded-lg overflow-hidden"
      >
        <table class="min-w-full leading-normal">
        <thead>
            <tr className='border-b-2 border-gray-200'>
              <th
                class="px-5 py-3  bg-gray-100 text-left text-xs font-semibold text-gray-700 uppercase tracking-wider"
              >
                SR NO
              </th>
              <th
                class="px-5 py-3  bg-gray-100 text-left text-xs font-semibold text-gray-700 uppercase tracking-wider"
              >
               HEADING
              </th>
              <th
                class="px-5 py-3  bg-gray-100 text-left text-xs font-semibold text-gray-700 uppercase tracking-wider"
              >
               Is Active
                                                      </th>
              <th
                class="px-5 py-3  bg-gray-100 text-left text-xs font-semibold text-gray-700 uppercase tracking-wider"
              >
              </th>
            </tr>
          </thead>
          <tbody>
                    {
                      championShipHeadings?.map((cases,i) => (
                        <tr key={i}  className='cursor-pointer'>
                          <td class="px-5 py-5 border-b border-gray-200 bg-white text-sm">
                    <p class="text-gray-900 whitespace-no-wrap">
                      {i+1}
                    </p>
              </td>
                          <td class="px-5 py-5 border-b border-gray-200 bg-white text-sm">
                    <Link to={`/champion_participation/${cases.championship_heading_id}`} class="text-gray-900 hover:underline hover:text-blue-600">
                      {cases.heading}
                    </Link>
              </td>
            
              <td class="px-5 py-5 border-b border-gray-200 bg-white text-sm">
                    <p class="text-gray-900 whitespace-nowrap">
                      {cases.is_active ? "Yes":"No"}
                    </p>
              </td>
                          <td class="px-5 py-5 border-b border-gray-200 bg-white text-sm">
                            <div className='flex gap-2 items-center'>
                          <EditChampionShipHeading details={cases}  />
                            <OptionModal details={cases} tab={0} />
                          </div>
              </td>
              
            
            </tr>
                      ))
        }
            
          </tbody>
        </table>
      </div>
    </div>:<div className="flex justify-center items-center h-[70vh] w-full">
              <span className="text-center w-full text-xl text-[#7b8794]">
                No ChampionShip found
              </span>
            </div>
        }

      
        </div>
                  </Tab.Panel>
                  <Tab.Panel>
                  <div className="flex flex-col  items-start self-stretch flex-grow min-h-[90vh] justify-between">
      {
        ColorBeltHeadings?.length ?
      <div class=" px-4 sm:px-8 py-4 overflow-x-auto w-full">
      <div
        class="inline-block min-w-full shadow-md rounded-lg overflow-hidden"
      >
        <table class="min-w-full leading-normal">
          <thead>
            <tr className='border-b-2 border-gray-200'>
              <th
                class="px-5 py-3  bg-gray-100 text-left text-xs font-semibold text-gray-700 uppercase tracking-wider"
              >
                SR NO
              </th>
              <th
                class="px-5 py-3  bg-gray-100 text-left text-xs font-semibold text-gray-700 uppercase tracking-wider"
              >
               HEADING
              </th>
              <th
                class="px-5 py-3  bg-gray-100 text-left text-xs font-semibold text-gray-700 uppercase tracking-wider"
              >
               Is Active
                                                      </th>
              <th
                class="px-5 py-3  bg-gray-100 text-left text-xs font-semibold text-gray-700 uppercase tracking-wider"
              >
              </th>
            </tr>
          </thead>
          <tbody>
                    {
                      ColorBeltHeadings?.map((cases,i) => (
                        <tr key={i} className='cursor-pointer'>
                          <td class="px-5 py-5 border-b border-gray-200 bg-white text-sm">
                    <p class="text-gray-900 whitespace-no-wrap">
                      {i+1}
                    </p>
              </td>
                          <td class="px-5 py-5 border-b border-gray-200 bg-white text-sm">
                    <Link to={`/color_belt_participation/${cases.color_belt_test_id}`} class="text-gray-900 whitespace-nowrap hover:underline hover:text-blue-600">
                      {cases.heading}
                    </Link>
              </td>
            
              <td class="px-5 py-5 border-b border-gray-200 bg-white text-sm">
                    <p class="text-gray-900 whitespace-nowrap">
                      {cases.is_active ? "Yes":"No"}
                    </p>
              </td>
                          <td class="px-5 py-5 border-b border-gray-200 bg-white text-sm">
                          <div className='flex gap-2 items-center'>
                          <EditColorBeltHeading details={cases} />
                              <OptionModal details={cases} tab={1} />
                          </div>
              </td>
              
            
            </tr>
                      ))
        }
            
          </tbody>
        </table>
      </div>
    </div>:<div className="flex justify-center items-center h-[70vh] w-full">
              <span className="text-center w-full text-xl text-[#7b8794]">
                No Headings found
              </span>
            </div>
        }
        </div>
                  </Tab.Panel>
              </Tab.Panels>
          </Tab.Group>

    </Template>
  )
}

export default OnlineRegistrationList