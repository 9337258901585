import React, { useState } from "react";
import { Link } from "react-router-dom";
import * as Yup from "yup";
import { useFormik } from "formik";
import { ReactComponent as UploadIcon } from "../../assets/icons/uploadicon.svg";
import Files from "react-files";
import toast from "react-hot-toast";
import DatePicker from "react-datepicker";
import { MdOutlineEdit } from "react-icons/md";
const EditProfile = () => {
  const [showModal, setShowModal] = useState(false);



  //intialData for formik
  const initialValues = {
    name: "",
    description:"",
    
  };

  //Yup validator Schema
  const EditInformationSchema = Yup.object({
    image:Yup.mixed().required("file required"),
      name:Yup.string().required(" Name is required"),
      description:  Yup.string().required(" description is required"),
      image:Yup.mixed().required("file required"),
  });

  //formik validation
  const {
    values,
    handleBlur,
    resetForm,
    handleChange,
    handleSubmit,
    setFieldValue,
    errors,
    touched,
  } = useFormik({
    initialValues,
    validationSchema: EditInformationSchema,
    validateOnChange: true,
    enableReinitialize: true,
    validateOnBlur: false,
    onSubmit: (values, action) => {


      toast.success("Form submitted successfully!");
      setShowModal(false);
      resetForm();
    },
  });
  const handleImageChange = (files) => {
    if (files.length) {
      setFieldValue("image", files[0]);

    } else {
      toast.error("No image selected. Please upload an image.");
    }
  };
  const closeModal = (e) => {
    e?.target?.classList?.forEach((classes) => {
      if (classes === "Modal-Container") {
        setShowModal(false);
        resetForm();

      }
    });
  };
  return (
    <div>
      <div
        onClick={(e) => {
          e.stopPropagation();
          setShowModal(true);
          resetForm();
        }}
        className="flex justify-center cursor-pointer relative items-center flex-grow-0 flex-shrink-0 gap-3"
      >
        <div
          
         className="justify-center text-center"
        >
          <p className="flex-grow-0 text-center flex-shrink-0 text-lg font-medium text-white">
          Edit
          </p>
        </div>
      </div>

      {showModal ? (
        <>
          <div
            className="flex justify-center items-center overflow-x-hidden overflow-y-auto fixed inset-0 z-50 outline-none focus:outline-none shadow-lg w-full Modal-Container"
            onClick={closeModal}
          >
            <div className="relative w-auto my-6 mx-auto max-w-3xl">
              <div className="border-0 rounded-lg shadow-lg relative flex w-full bg-white outline-none focus:outline-none">
                <div className="flex items-start gap-1 p-2 border-b border-solid border-gray-300 rounded-t flex-col ">
                  <h3 className="text-xl text-black font-semibold">
                     Owner Profile
                  </h3>
                  <div className="flex justify-between items-start">
                    <div className="flex flex-col mt-3">
                    <Files
  className="files-dropzone cursor-pointer"
  onChange={(file) => handleImageChange(file)}
  onError={""}
  accepts={[".jpeg" ,".jpg", ".png"]}
  maxFileSize={10000000}
  minFileSize={0}
  multiple
  clickable
>
{values.image ? (
  <div className="flex flex-col items-center relative">
    <img
      src={
        typeof values.image === "object"
          ? values.image?.preview?.url
          : values.image
      }
      className="h-52 w-[200px] object-cover"
    />
 
  </div>
) : (
  <div className="flex flex-col justify-center items-center flex-grow-0 flex-shrink-0 h-[122px] w-[122px] relative gap-1 px-[23.5px] py-7 rounded bg-[#186ced]/[0.04] border-[1.33px] border-[#186ced]/[0.64] border-dashed">
    <UploadIcon />
    <div className="flex justify-start items-center flex-grow-0 flex-shrink-0 relative gap-1 px-[5px] py-0.5 rounded">
      <p className="flex-grow-0 flex-shrink-0 text-xs font-medium text-center text-black/[0.64]">
        Upload
      </p>
    </div>
  </div>
)}
</Files>
                      <p>(upload only jpeg)</p>
                      {/* { locationImages?.length ?  <span>{locationImages.length} selected</span>:null} */}
                    </div>
                    <div className="relative p-6 pt-0 flex-auto">
                      <div className="flex flex-col justify-start items-start self-stretch flex-grow-0 flex-shrink-0 gap-1">
                        <div className="flex justify-start items-start self-stretch flex-grow-0 flex-shrink-0 relative gap-1 flex-col">
                        <div className="flex-grow-0 flex-shrink-0 w-[150px]">
                        <p className="text-[14px] leading-10 py-[1px] font-medium text-left text-black/80">
                          Name
                        </p>
                      </div>
                      <div className="flex flex-col flex-grow w-[300px]">
                      <input
                      name="name"
                      type="text"
                      value={values.name}
                      onChange={handleChange}
                      className="w-full border rounded p-2 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                      placeholder="Name"
                    />
                    {touched.name && errors.name ? (
                      <div className="text-red-600 text-xs">{errors.name}</div>
                    ) : null}
          
                      </div>
                          <div className="flex-grow-0 flex-shrink-0 w-[150px]">
                            <p className="text-[13px] leading-10 py-[1px] font-medium text-left text-black/80">
                              Description
                            </p>
                          </div>
                          <div className="flex flex-col flex-grow w-[300px]">
                          <textarea
                          name="description"
                          type="text"
                          value={values.description}
                          onChange={handleChange}
                          className="w-full border rounded p-2 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                          placeholder="Deisgnation"
                        />
                        {touched.description && errors.description ? (
                          <div className="text-red-600 text-xs">{errors.description}</div>
                        ) : null}
              

                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="flex justify-start items-start flex-grow-0 flex-shrink-0 gap-2 self-end">
                    <div
                      onClick={(e) => {
                        e.stopPropagation();
                        setShowModal(false);
                      }}
                      className="flex justify-start items-center flex-grow-0 flex-shrink-0 relative overflow-hidden gap-1 pl-4 pr-5 py-[11px] rounded-[94px] bg-[#fff] cursor-pointer"
                      style={{ boxShadow: "0px 1px 0px 0 rgba(0,0,0,0.08)" }}
                    >
                      <p className="flex-grow-0 flex-shrink-0 text-sm font-medium text-center text-black">
                        Cancel
                      </p>
                    </div>
                    <button
                      type="button"
                      className="flex justify-start items-center flex-grow-0 flex-shrink-0 relative overflow-hidden gap-1 px-5 py-[11px] rounded-[94px] bg-[#186ced] cursor-pointer"
                      style={{ boxShadow: "0px 1px 0px 0 rgba(0,0,0,0.08)" }}
                      onClick={handleSubmit}
                    >
                      <p className="flex-grow-0 flex-shrink-0 text-sm font-medium text-center text-white">
                        Save
                      </p>
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </>
      ) : null}
    </div>
  );
};

export default EditProfile;
