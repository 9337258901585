import React, { useEffect, useState } from 'react'
import Template from '../../../components/common/Template'
import { useDispatch, useSelector } from 'react-redux';
import EditExecutive from './EditExecutive';
import DeleteExecutive from './DeleteExecutive';
import AddExecutive from './AddExecutive';
import Select from 'react-select';
import { getAllCityList, getAllExecutiveList } from '../../../services/apiService';
import Pagination from '../../../components/common/pagination';
const ExecutiveCouncilMembers_index = () => {
  const dispatch =useDispatch()
 const {ExecutiveCouncilList,TotalExecutiveList}=useSelector((state)=>state.executivecouncil)
 const [selectedDistrict, setselectedDistrict] = useState({ value: "all", label: "All Cities" })

  const [selectedTemplate, setSelectedTemplate]=useState([])
  const [page, setpage]=useState(1)
//kau file??

const { CityList } = useSelector((state) => state.citylist);
const options = CityList.map(({ city_id, city_name, city_image }) => ({
  value: city_id,
  label: (
    <div className="flex items-center justify-between gap-2">
      <div className="flex items-center gap-2">
        <img
          src={city_image}
          alt={city_name}
          className="h-5 w-5 rounded-full object-cover"
        />
        {city_name}
      </div>
     
    </div>
  ),
}));

const handleCheckBox = (e,pridee) => {
  const { checked } = e.target;
  if (checked) {
      setSelectedTemplate([...selectedTemplate,pridee])
  } else {
      setSelectedTemplate(selectedTemplate?.filter((pride_item)=>pride_item?.id!==pridee?.id))
  }
}
useEffect(()=>{
  dispatch(getAllExecutiveList(selectedDistrict.value==="all"?"":selectedDistrict.value,page-1,10))
},[dispatch, page])

const handleCitySelect = (selectedOption) => {
  setselectedDistrict(selectedOption)
  if (selectedOption.value === "all") {
    // If "All Cities" is selected, fetch executive council members without specifying a city id
    dispatch(getAllExecutiveList());
  } else {
    const cityId = selectedOption.value;
    dispatch(getAllExecutiveList(cityId));
  }
};


const customStyles = {
  control: (provided) => ({
    ...provided,
    width: '100%',
    height: '12px',
    borderRadius: '25px', // Set border-radius for oval shape
    border: '1px solid #ccc', // Add border for clarity
    padding: '0 10px', // Add padding
    margin: '0 auto', // Center the Select component
  }),
  menu: (provided) => ({
    ...provided,
    width: '100%',
    borderRadius: '10px', // Set border-radius for options
  }),
};

useEffect(() => {
  dispatch(getAllCityList());
}, [dispatch]);

  return (
    <Template>
   <div className='flex flex-1 justify-between p-4  bg-gray-200'>
    <div className='flex flex-1 justify-start text-left items-center'>
      <p className='text-2xl'>Our Executive Council List</p>
    </div>
<div className='flex flex-row gap-4 '>
    <div className=''>
    {selectedTemplate.length === 1 ? (
     <EditExecutive
     page={page}
       prideId={selectedTemplate[0]?.id}
       setSelectedTemplate={setSelectedTemplate}
       details={selectedTemplate[0]}
       colorbeltdetails={selectedTemplate[0]} colorbeltdetailsId={selectedTemplate[0]?.id} city_id={selectedDistrict?.value==="all" ? "":selectedDistrict.value}
     />
   ) : null}
    </div>

    <div className=''>
    {selectedTemplate.length===1 ? (
     <DeleteExecutive  colorbeltdetails={selectedTemplate[0]} colorbeltdetailsId={selectedTemplate[0]?.id} city_id={selectedDistrict?.value==="all" ? "":selectedDistrict.value} page={page}  setpage={setpage} selectedTemplate={selectedTemplate} setSelectedTemplate={setSelectedTemplate} />
   ) : null}
    </div>
    <div className=''>
     <AddExecutive />
    </div>
    
    <div className="w-full flex justify-center    items-center">
    <Select
    placeholder="Select District here"
    size="lg"
    options={[{ value: "all", label: "All Cities" }, ...options]}
    styles={customStyles}
    onChange={handleCitySelect}
    value={selectedDistrict}
  />
  </div>
    </div>
    </div> 
    <div className="relative">
    <div className="">
    <div className="">
      
      
      <>
      {ExecutiveCouncilList?.length ? (
        <>
        <div className="flex flex-wrap gap-2 p-4 mt-4 justify-start">

        {ExecutiveCouncilList.map((pridee) => (
          <label  htmlFor={`checkbox-${pridee.id}`} key={pridee?.id} className="flex group/item justify-start items-start w-[280px] ">
          <div className="max-w-full w-full ">
            <div className="bg-white shadow-xl rounded-lg py-3">
              <div className="photo-wrapper relative p-2">
              <input
              type="checkbox"
              id={`checkbox-${pridee.id}`}
              checked={selectedTemplate?.find((prid) => Number(prid?.id) === Number(pridee?.id))}
              onChange={(e) => handleCheckBox(e, pridee)}
              className={`absolute top-2 p-1 right-1 group/edit ${
                selectedTemplate?.find((pridis) => Number(pridis?.id) === Number(pridee?.id))
                  ? 'visible'
                  : 'invisible'
              } group-hover/item:visible form-checkbox rounded border-gray-300 text-[#186ced] shadow-sm focus:border-indigo-300 focus:ring focus:ring-offset-0 focus:ring-indigo-200 focus:ring-opacity-50`}
            />
                <img
                  className="w-32 h-32 rounded-full mx-auto"
                  src={pridee?.image}
                  alt="coch image"
                />
              </div>
    
              <div className="p-2">
                <table className="text-xs my-3">
                  <tbody>
                    <tr>
                      <td className="px-2 py-2 text-gray-500 font-semibold"> Name</td>
                      <td className="px-2 py-2">{pridee?.name}</td>
                    </tr>
                    <tr>
                      <td className="px-2 py-2 text-gray-500 font-semibold">Post</td>
                      <td className="px-2 py-2">{pridee?.post}</td>
                    </tr>
                    <tr>
                      <td className="px-2 py-2 text-gray-500 font-semibold">District</td>
                      <td className="px-2 py-2">{pridee?.city_name}</td>
                    </tr>
                   
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </label>
        ))}
      
      </div>
        </>
      ) : (
        <div className="flex justify-center items-center h-[70vh] w-full">
          <span className="text-center w-full text-xl text-[#7b8794]">
            No Executive Found
          </span>
        </div>
      )}
      </>
      <div className="flex justify-center w-full p-4 mb-4">  
      <Pagination
      className="pagination-bar"
      currentPage={page}
      totalCount={TotalExecutiveList} // Assuming 10 items per page
      pageSize={10}
                onPageChange={(newPage) => { 
                  setpage(newPage)
                    var currentScroll = document.documentElement.scrollTop || document.body.scrollTop;
                      if (currentScroll > 0) {
                        window.scrollTo(0, currentScroll - (currentScroll / 1));
                      }
                }
                 }
    />              
      </div>
    </div>
   
  </div>
          </div> 
       
    </Template>
  )
}

export default ExecutiveCouncilMembers_index
