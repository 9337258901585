import React, { useEffect, useState } from 'react'
import Template from '../../../components/common/Template'
import { useDispatch, useSelector } from 'react-redux';
import { Tab } from "@headlessui/react";
import EditCoach from './EditCoach';
import { setSelectedCochesProfile } from '../../../redux/slice/CoachesProfileSlice';
import DeleteCoach from './DeleteCoach';
import AddCoach from './AddCoach';
import { getAllCoachesProfileList } from '../../../services/apiService';
import Pagination from '../../../components/common/pagination';


const CoachesProfile_index = () => {
  const dispatch =useDispatch()
  const {selectedTabCoach,TotalCoachesProfileList}=useSelector((state)=>state.coachesprofile);
  const {CochesProileList}=useSelector((state)=>state.coachesprofile)
  const [selectedTemplate, setSelectedTemplate]=useState([])
  const [nationalSelectedCoaches, setnationalSelectedCoaches] = useState([])
  const [stateSelectedTemplate, setstateSelectedTemplate] = useState([])
  const [internationalPage, setinternationalPage] = useState(1)
  const [nationalPage, setnationalPage] = useState(1)
  const [statePage, setstatePage] = useState(1)




const handleCheckBox = (e,pridee) => {
  const { checked } = e.target;
  if (checked) {
      setSelectedTemplate([...selectedTemplate,pridee])
  } else {
      setSelectedTemplate(selectedTemplate?.filter((pride_item)=>pride_item?.coach_id!==pridee?.coach_id))
  }
}
const handleSelectNationalCheckBox = (e,pridee) => {
  const { checked } = e.target;
  if (checked) {
    setnationalSelectedCoaches([...nationalSelectedCoaches,pridee])
  } else {
    setnationalSelectedCoaches(nationalSelectedCoaches?.filter((pride_item)=>pride_item?.coach_id!==pridee?.coach_id))
  }
}
const handleSelectStateCheckBox = (e,pridee) => {
  const { checked } = e.target;
  if (checked) {
      setstateSelectedTemplate([...stateSelectedTemplate,pridee])
  } else {
      setstateSelectedTemplate(stateSelectedTemplate?.filter((pride_item)=>pride_item?.coach_id!==pridee?.coach_id))
  }
}



const pageSIze=10;

useEffect(() => {
  if (selectedTabCoach === 0) {
    dispatch(getAllCoachesProfileList(internationalPage-1,pageSIze,0));
  } else if (selectedTabCoach === 1) {  
    dispatch(getAllCoachesProfileList(nationalPage-1,pageSIze,1));
  } else {
    dispatch(getAllCoachesProfileList(statePage-1,pageSIze,2));
  }
}, [dispatch, selectedTabCoach,statePage,internationalPage,nationalPage]);
  return (
    <Template>
    <div className='flex flex-1 justify-between p-4  bg-gray-200'>
    <div className='flex flex-1 justify-start text-left items-center'>
      <p className='text-2xl'>Our Coaches List</p>
    </div>
{
  selectedTabCoach===0 ?<div className='flex flex-row gap-4 '>
  <div className=''>
  {selectedTemplate.length === 1 ? (
   <EditCoach
     prideId={selectedTemplate[0]?.coach_id}
     selectedTemplate={selectedTemplate}
     page={internationalPage} 
     details={selectedTemplate[0]}
     setSelectedTemplate={setSelectedTemplate}
   />
 ) : null}
  </div>

  <div className=''>
  {selectedTemplate.length ===1 ? (
   <DeleteCoach page={internationalPage} pageSize={pageSIze} setpage={setinternationalPage} status={"international"} selectedTemplate={selectedTemplate} setSelectedTemplate={setSelectedTemplate}  />
 ) : null}
  </div>
  <div className=''>
   <AddCoach />
  </div>
  
  </div>:selectedTabCoach===1?<div className='flex flex-row gap-4 '>
  <div className=''>
  {nationalSelectedCoaches.length === 1 ? (
   <EditCoach
     prideId={nationalSelectedCoaches[0]?.coach_id}
     selectedTemplate={nationalSelectedCoaches}
     details={nationalSelectedCoaches[0]}
     page={nationalPage}
     setSelectedTemplate={setnationalSelectedCoaches}
   />
 ) : null}
  </div>

  <div className=''>
  {nationalSelectedCoaches.length ===1 ?(
   <DeleteCoach page={nationalPage} status={"national"} setpage={setnationalPage}  pageSize={pageSIze} selectedTemplate={nationalSelectedCoaches} setSelectedTemplate={setnationalSelectedCoaches}  />
 ) : null}
  </div>
  <div className=''>
   <AddCoach />
  </div>
  
  </div>:<div className='flex flex-row gap-4 '>
  <div className=''>
  {stateSelectedTemplate.length === 1 ? (
   <EditCoach
     prideId={stateSelectedTemplate[0]?.coach_id}
     selectedTemplate={stateSelectedTemplate}
     details={stateSelectedTemplate[0]}
     setSelectedTemplate={setstateSelectedTemplate}
     page={statePage}
   />
 ) : null}
  </div>

  <div className=''>
  {stateSelectedTemplate.length===1 ? (
   <DeleteCoach page={statePage} setpage={setstatePage} status={"state"} pageSize={pageSIze}   selectedTemplate={stateSelectedTemplate} setSelectedTemplate={setstateSelectedTemplate} />
 ) : null}
  </div>
  <div className=''>
   <AddCoach />
  </div>
  
  </div>
}
    </div> 
      

  <Tab.Group selectedIndex={selectedTabCoach} onChange={(val)=>dispatch(setSelectedCochesProfile(val))}>
    <Tab.List className="flex justify-start items-center self-stretch flex-grow-0 flex-shrink-0 gap-12 px-4 border border-black/[0.08]">
      <Tab>
        {({ selected }) => (
          <button
            className={`flex justify-start items-center flex-grow-0 flex-shrink-0 relative gap-1 py-4 text-sm font-medium text-center focus:ring-0 outline-none ${
              selected
                ? "border-b-2 border-[#186ced] text-[#186ced]"
                : "border-b-2 border-transparent text-gray-400 hover:text-gray-700"
            } `}
          >
          International Coaches 
          </button>
        )}
      </Tab>
      <Tab>
        {({ selected }) => (
          <button
            className={`flex justify-start items-center flex-grow-0 flex-shrink-0 relative gap-1 py-4 text-sm font-medium text-center focus:ring-0 outline-none ${
              selected
                ? "border-b-2 border-[#186ced] text-[#186ced]"
                : "border-b-2 border-transparent text-gray-400 hover:text-gray-700"
            } `}
          >
          National Coaches
          </button>
        )}
      </Tab>
  
      <Tab>
        {({ selected }) => (
          <button
            className={`flex justify-start items-center flex-grow-0 flex-shrink-0 relative gap-1 py-4 text-sm font-medium text-center focus:ring-0 outline-none ${
              selected
                ? "border-b-2 border-[#186ced] text-[#186ced]"
                : "border-b-2 border-transparent text-gray-400 hover:text-gray-700"
            } `}
          >
          State Coaches
          </button>
        )}
      </Tab>
      {/* Additional Tab elements */}
    </Tab.List>

    <Tab.Panels>
    <Tab.Panel className="md:p-4 w-[100%] ">


    <div className="flex flex-col max-md:hidden justify-start items-start self-stretch flex-grow overflow-hidden ">
      <div className="flex flex-col  items-start self-stretch flex-grow min-h-[70vh] justify-between w-full">
        
        
        <>
        {CochesProileList?.length ? (
          <>
          <div className="flex flex-wrap  gap-2 ">

          {CochesProileList.map((pridee) => (
            <label  htmlFor={`checkbox-${pridee.coach_id}`} key={pridee?.coach_id} className="flex group/item justify-start items-start w-[280px]">
              <div className="max-w-full w-full">
                <div className="bg-white shadow-xl rounded-lg py-3">
                  <div className="photo-wrapper relative p-2">
                  <input
                  type="checkbox"
                  id={`checkbox-${pridee?.coach_id}`}
                  checked={selectedTemplate?.find((prid) => Number(prid?.coach_id) === Number(pridee?.coach_id))}
                  onChange={(e) => handleCheckBox(e, pridee)}
                  className={`absolute top-2 right-2 group/edit ${
                    selectedTemplate?.find((v)=>v?.coach_id===Number(pridee?.coach_id))
                      ? 'visible'
                      : 'invisible'
                  } group-hover/item:visible form-checkbox rounded border-gray-300 text-[#186ced] shadow-sm focus:border-indigo-300 focus:ring focus:ring-offset-0 focus:ring-indigo-200 focus:ring-opacity-50`}
                />
                    <img
                      className="w-32 h-32 rounded-full mx-auto"
                      src={pridee?.image}
                      alt="coch image"
                    />
                  </div>
        
                  <div className="p-2">
                    <table className="text-xs my-3">
                      <tbody>
                        <tr>
                          <td className="px-2 py-2 text-gray-500 font-semibold">Coach Name</td>
                          <td className="px-2 py-2 capitalize">{pridee?.coach_name}</td>
                        </tr>
                        <tr>
                          <td className="px-2 py-2 text-gray-500 font-semibold">Grade</td>
                          <td className="px-2 py-2 capitalize">{pridee?.grade}</td>
                        </tr>
                        <tr>
                          <td className="px-2 py-2 text-gray-500 font-semibold">Status</td>
                          <td className="px-2 py-2 capitalize">{pridee?.status}</td>
                        </tr>
                        <tr>
                          <td className="px-2 py-2 text-gray-500 font-semibold">District</td>
                          <td className="px-2  py-2" capitalize>{pridee?.city_name}</td>
                        </tr>
                      </tbody>
                    </table>
                  </div>
                </div>
              </div>
            </label>
          ))}
        
        </div>
          </>
        ) : (
          <div className="flex justify-center items-center h-[70vh] w-full">
            <span className="text-center w-full text-xl text-[#7b8794]">
              No Coaches Found
            </span>
          </div>
        )}
        </>
        <div className="flex justify-center w-full p-4 mb-4 fixed -bottom-3  left-14 right-0">
        <Pagination
      className="pagination-bar"
      currentPage={internationalPage}
      totalCount={TotalCoachesProfileList}
      pageSize={pageSIze}
      onPageChange={(page) => {
         setinternationalPage(page)
        var currentScroll = document.documentElement.scrollTop || document.body.scrollTop;
              if (currentScroll > 0) {
                window.scrollTo(0, currentScroll - (currentScroll / 1));
              }
      }}
    />
  </div>
      </div>
     
    </div>
    <div className="justify-center   bottom-0 w-full py-2  flex items-center">
     
</div>
  </Tab.Panel>
    </Tab.Panels> 

    <Tab.Panels>
    <Tab.Panel className="md:p-4 w-[100%]">


    <div className="flex flex-col max-md:hidden justify-start items-start self-stretch flex-grow overflow-hidden ">
      <div className="flex flex-col  items-start self-stretch flex-grow min-h-[70vh] justify-between w-full">
        
        
        <>
        {CochesProileList?.length ? (
          <>
          <div className="flex flex-wrap gap-2">

          {CochesProileList.map((pridee) => (
            <label  htmlFor={`checkbox-${pridee.coach_id}`} key={pridee?.coach_id} className="flex group/item justify-start items-start w-[280px] ">
            <div className="max-w-full w-full">
              <div className="bg-white shadow-xl rounded-lg py-3">
                <div className="photo-wrapper relative p-2">
                <input
                type="checkbox"
                id={`checkbox-${pridee?.coach_id}`}
                checked={nationalSelectedCoaches?.find((prid) => Number(prid?.coach_id) === Number(pridee?.coach_id))}
                onChange={(e) => handleSelectNationalCheckBox(e, pridee)}
                className={`absolute top-2 right-2 group/edit ${
                  nationalSelectedCoaches?.find((v)=>v?.coach_id===Number(pridee?.coach_id))
                    ? 'visible'
                    : 'invisible'
                } group-hover/item:visible form-checkbox rounded border-gray-300 text-[#186ced] shadow-sm focus:border-indigo-300 focus:ring focus:ring-offset-0 focus:ring-indigo-200 focus:ring-opacity-50`}
              />
                  <img
                    className="w-32 h-32 rounded-full mx-auto"
                    src={pridee?.image}
                    alt="coch image"
                  />
                </div>
      
                <div className="p-2">
                  <table className="text-xs my-3">
                    <tbody>
                      <tr>
                        <td className="px-2 py-2 text-gray-500 font-semibold">Coach Name</td>
                        <td className="px-2 py-2">{pridee?.coach_name}</td>
                      </tr>
                      <tr>
                        <td className="px-2 py-2 text-gray-500 font-semibold">Grade</td>
                        <td className="px-2 py-2">{pridee?.grade}</td>
                      </tr>
                      <tr>
                        <td className="px-2 py-2 text-gray-500 font-semibold">Status</td>
                        <td className="px-2 py-2">{pridee?.status}</td>
                      </tr>
                      <tr>
                        <td className="px-2 py-2 text-gray-500 font-semibold">City Name</td>
                        <td className="px-2 py-2">{pridee?.city_name}</td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
          </label>
          ))}
        
        </div>
          </>
        ) : (
          <div className="flex justify-center items-center h-[70vh] w-full">
            <span className="text-center w-full text-xl text-[#7b8794]">
              No Coches Found
            </span>
          </div>
        )}
        </>
        <div className="flex justify-center w-full p-4 mb-4">
        <Pagination
          className="pagination-bar"
          currentPage={nationalPage}
          totalCount={TotalCoachesProfileList}
          pageSize={pageSIze}
          onPageChange={(page) => {
             setnationalPage(page)
            var currentScroll = document.documentElement.scrollTop || document.body.scrollTop;
                  if (currentScroll > 0) {
                    window.scrollTo(0, currentScroll - (currentScroll / 1));
                  }
          }}
        />
      </div>
      </div>
     
    </div>
    <div className="justify-center   bottom-0 w-full py-2  flex items-center">
     
</div>
  </Tab.Panel>
    </Tab.Panels> 

    <Tab.Panels>
    <Tab.Panel className="md:p-4 w-[100%]">


    <div className="flex flex-col max-md:hidden justify-start items-start self-stretch flex-grow overflow-hidden ">
      <div className="flex flex-col  items-start self-stretch flex-grow min-h-[70vh] justify-between w-full">
        
        
        <>
        {CochesProileList?.length ? (
          <>
          <div className="flex flex-wrap gap-2 ">

          {CochesProileList.map((pridee) => (
            <label  htmlFor={`checkbox-${pridee.coach_id}`} key={pridee?.coach_id} className="flex group/item justify-start items-start w-[280px]">
            <div className="max-w-full w-full">
              <div className="bg-white shadow-xl rounded-lg py-3">
                <div className="photo-wrapper relative p-2">
                <input
                type="checkbox"
                id={`checkbox-${pridee?.coach_id}`}
                checked={stateSelectedTemplate?.find((prid) => Number(prid?.coach_id) === Number(pridee?.coach_id))}
                onChange={(e) => handleSelectStateCheckBox(e, pridee)}
                className={`absolute top-2 right-2 group/edit ${
                  stateSelectedTemplate?.find((v)=>v?.coach_id===Number(pridee?.coach_id))
                    ? 'visible'
                    : 'invisible'
                } group-hover/item:visible form-checkbox rounded border-gray-300 text-[#186ced] shadow-sm focus:border-indigo-300 focus:ring focus:ring-offset-0 focus:ring-indigo-200 focus:ring-opacity-50`}
              />
                  <img
                    className="w-32 h-32 rounded-full mx-auto"
                    src={pridee?.image}
                    alt="coch image"
                  />
                </div>
      
                <div className="p-2">
                  <table className="text-xs my-3">
                    <tbody>
                      <tr>
                        <td className="px-2 py-2 text-gray-500 font-semibold">Coach Name</td>
                        <td className="px-2 py-2">{pridee?.coach_name}</td>
                      </tr>
                      <tr>
                        <td className="px-2 py-2 text-gray-500 font-semibold">Grade</td>
                        <td className="px-2 py-2">{pridee?.grade}</td>
                      </tr>
                      <tr>
                        <td className="px-2 py-2 text-gray-500 font-semibold">Status</td>
                        <td className="px-2 py-2">{pridee?.status}</td>
                      </tr>
                      <tr>
                        <td className="px-2 py-2 text-gray-500 font-semibold">City Name</td>
                        <td className="px-2 py-2">{pridee?.city_name}</td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
          </label>
          ))}
        
        </div>
          </>
        ) : (
          <div className="flex justify-center items-center h-[70vh] w-full">
            <span className="text-center w-full text-xl text-[#7b8794]">
              No Coaches Found
            </span>
          </div>
        )}
        </>
        <div className="flex justify-center w-full p-4 mb-4">
        <Pagination
          className="pagination-bar"
          currentPage={statePage}
          totalCount={TotalCoachesProfileList}
          pageSize={pageSIze}
          onPageChange={(page) => {
             setstatePage(page)
            var currentScroll = document.documentElement.scrollTop || document.body.scrollTop;
                  if (currentScroll > 0) {
                    window.scrollTo(0, currentScroll - (currentScroll / 1));
                  }
          }}
        />
      </div>
      </div>
     
    </div>
   
  </Tab.Panel>
    </Tab.Panels> 

  </Tab.Group>
    </Template>
  )
}

export default CoachesProfile_index
