import React, { useEffect } from 'react'
import { getAllAthleteDetailsFormList } from '../../services/apiService'
import { useNavigate, useParams } from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux'
import Template from "../../components/common/Template"
import {ReactComponent as ArrowLeft} from "../../assets/icons/arrowlefticon.svg"
import pdfimage from "../../assets/images/pdf.png"
import { DownloadIcon } from '@heroicons/react/outline';
const AthleteFormDetailsListform = () => {
 

   const downloadFile =async (image) => {
    try {
        // Replace 'your-file-url' with the actual URL of the file you want to download
        const response = await fetch(image);
        const blob = await response.blob();
  
        // Create a temporary URL for the blob
        const url = window.URL.createObjectURL(blob);
  
        // Create a hidden link and trigger a click to download the file
        const link = document.createElement('a');
        link.href = url;
        link.setAttribute('download', image?.split("/").pop()); // Specify the desired file name
        document.body.appendChild(link);
        link.click();
  
        // Clean up by revoking the object URL
        window.URL.revokeObjectURL(url);
      } catch (error) {
      }
  
  }

  const {athlete_form_id}=useParams()
  const dispatch=useDispatch()
  const navigate=useNavigate()
 const {AthleteFormDetailsList}=useSelector((state)=>state.athleteform)

  useEffect(()=>{
    dispatch(getAllAthleteDetailsFormList(athlete_form_id))
  },[dispatch, athlete_form_id])


  return (
    <Template>
    <div
     
    className="flex justify-start cursor-pointer  flex-grow-0 flex-shrink-0 gap-3 "
  > 
  <div className="flex justify-start bg-[#e8e8f1] w-full items-center self-stretch flex-grow-0 flex-shrink-0 gap-2 px-4 max-md:py-2 py-4">
  <div className="flex justify-between max-md:justify-between flex-row flex-grow relative ">
  <div className='flex max-md:flex gap-3 items-center '>
    <ArrowLeft  onClick={()=>navigate("/athleteform")} className='cursor-pointer max-md:  w-4 h-4'/>
    <p className="flex-grow-0 flex-shrink-0 text-2xl max-md:text-[18px] font-semibold text-left capitalize text-[#202223]">
     Athlete Form Details
    </p>
    </div>
    <div className="flex justify-start items-start   flex-grow-0 flex-shrink-0 gap-2 self-end">
 
  
      </div>
  </div>
</div>

  </div>
    <div className="font-sans-serif bg-white">
    <div className="p-6 lg:max-w-7xl max-w-4xl mx-auto">
      <div className="flex  w-full items-start  gap-12 shadow-[0_2px_10px_-3px_rgba(6,81,237,0.3)] p-6">
        <div className="grid grid-cols-1 lg:sticky top-0 text-center">
          <div className="px-4 py-10 rounded-xl shadow-[0_2px_10px_-3px_rgba(6,81,237,0.3)] relative">
          <div className='flex justify-center'>
       
            <img src={AthleteFormDetailsList?.athlete_image} alt="Product" className="w-4/5 rounded object-cover" />
            </div>
          </div>
          <div className="px-4 py-10  shadow-[0_2px_10px_-3px_rgba(6,81,237,0.3)] relative">
          <div className='flex justify-center'>
            <img src={AthleteFormDetailsList?.proof_of_birth_certificate ? `${pdfimage}` : ""} alt="Product" className="w-48 rounded object-cover" />
            <span onClick={()=>downloadFile(AthleteFormDetailsList?.proof_of_birth_certificate)} className='cursor-pointer'><DownloadIcon className='w-6 h-6' /></span>

            </div>
            <p className='text-center p-1 font-semibold text-xl'>Athlete proof of birth certificate</p>
            
          </div>
      
        </div>
    
      </div>
      <div className="mt-16 shadow-[0_2px_10px_-3px_rgba(6,81,237,0.3)] p-6">
        <h3 className="text-2xl font-bold text-[#333]">Athlete Form details</h3>
        <ul key={AthleteFormDetailsList?.athlete_form_id} className="mt-6 space-y-6 text-[#333]">
        <li className="text-lg">
          District name <span className="ml-4 float-right">{AthleteFormDetailsList?.district_name}</span>
        </li>
        <li className="text-lg">
          Athlete name <span className="ml-4 float-right">{AthleteFormDetailsList?.athlete_name}</span>
        </li>
        <li className="text-lg">
          Athlete date of birth <span className="ml-4 float-right">{AthleteFormDetailsList?.dob}</span>
        </li>
        <li className="text-lg">
          Gender  <span className="ml-4 float-right">{AthleteFormDetailsList?.gender}</span>
        </li>
        <li className="text-lg">Father name  <span className="ml-4 float-right">{AthleteFormDetailsList?.father_name}</span></li>
        <li className="text-lg">Mother name <span className="ml-4 float-right">{AthleteFormDetailsList?.mother_name}</span></li>
        <li className="text-lg">Guardian Name <span className="ml-4 float-right">{AthleteFormDetailsList?.guardian_name}</span></li>
        <li className="text-lg">Permanent Address <span className="ml-4 float-right">{AthleteFormDetailsList?.address_at}</span></li>
        <li className="text-lg">Ota club name <span className="ml-4 float-right">{AthleteFormDetailsList?.ota_club_name}</span></li>
        <li className="text-lg">Club Admission date <span className="ml-4 float-right">{AthleteFormDetailsList?.club_admission_date}</span></li>
        <li className="text-lg">Athelete mobile contact no <span className="ml-4 float-right">{AthleteFormDetailsList?.mobile}</span></li>
        <li className="text-lg">Address at <span className="ml-4 float-right">{AthleteFormDetailsList?.address_at}</span></li>
        <li className="text-lg">Address Post <span className="ml-4 float-right">{AthleteFormDetailsList?.address_post}</span></li>
        <li className="text-lg">Address Pin <span className="ml-4 float-right">{AthleteFormDetailsList?.address_pin}</span></li>
        <li className="text-lg">Club admission date <span className="ml-4 float-right">{AthleteFormDetailsList?.club_admission_date}</span></li>
        {/* ... (similar pattern for other list items) */}
      </ul>
      </div>
   
    </div>
  </div>
    </Template>
  )
}

export default AthleteFormDetailsListform
