import { createSlice } from "@reduxjs/toolkit";

const NewEventCalender = createSlice({
    name: "eventcalender",
    initialState: {
      EventCalenderList:[],
      EventCalenderLoading: false,
      selectedEventCalender:0,
      page:1,
      total_pages:0,
      TotalEventCalenderList:0
      
    },
    reducers: {
         getEventCalenderListRequest:(state)=>{
            state.EventCalenderLoading=true;
         },
         getEventCalenderListSuccess:(state, {payload})=>{
            state.EventCalenderLoading=false;
            state.EventCalenderList=payload?.otaEventCalenderDTOList;
            state.TotalEventCalenderList=payload?.total_elements
         },
         getEventCalenderFail:(state)=>{
            state.EventCalenderLoading=false;
            state.EventCalenderList=[];
         },
         setSelectedEventCalender:(state,{payload})=>{
            state.selectedEventCalender=payload
         },
         setEventCalenderPage:(state,{payload})=>{
            state.page=payload
         },
        setTotalPageEventCalender:(state,{payload})=>{
            state.total_pages=payload
        }
    }
})  

export default NewEventCalender.reducer;
export const {getEventCalenderFail, getEventCalenderListRequest, getEventCalenderListSuccess, setEventCalenderPage, setTotalPageEventCalender, setSelectedEventCalender}= NewEventCalender.actions