import React, { useEffect, useState } from 'react';
import Template from '../../components/common/Template';
import Select from 'react-select';
import AddCities from './AddCities';
import { useDispatch, useSelector } from 'react-redux';
import { getAllCityList } from '../../services/apiService';
import { FaEdit, FaTrash } from 'react-icons/fa';
import EditCities from './EditCities';
import DeleteCities from './DeleteCities';
import Pagination from '../../components/common/pagination';
import { setCitiesListPage } from '../../redux/slice/CitySlice';

const AllCities_index = () => {
  const dispatch = useDispatch();
  const { CityList, TotalCityList } = useSelector((state) => state.citylist);
  const [page, setpage]=useState(1)
  const options = CityList.map(({ city_id, city_name, city_image }) => ({
    value: city_id,
    label: (
      <div className="flex items-center justify-between gap-2">
        <div className="flex items-center gap-2">
          <img
            src={city_image}
            alt={city_name}
            className="h-5 w-5 rounded-full object-cover"
          />
          {city_name}
        </div>
        <div className="flex gap-4">
          <EditCities cityId={city_id} image={city_image} name={city_name} />
          <FaTrash className="cursor-pointer" onClick={() => handleDelete(city_id)} />
        </div>
      </div>
    ),
  }));

  // Custom styles for the Select component
  const customStyles = {
    control: (provided) => ({
      ...provided,
      width: '700px',
      height: '50px',
      borderRadius: '25px', // Set border-radius for oval shape
      border: '1px solid #ccc', // Add border for clarity
      padding: '0 10px', // Add padding
      margin: '0 auto', // Center the Select component
    }),
    menu: (provided) => ({
      ...provided,
      width: '700px',
      borderRadius: '10px', // Set border-radius for options
    }),
  };


  const handleDelete = (cityId) => {
    // Handle delete logic here
  };

  useEffect(() => {
    dispatch(getAllCityList(page-1));
  }, [dispatch, page]);


  return (
    <Template>
      <div className="flex justify-start bg-[#F3F5F8] items-center self-stretch flex-grow-0 flex-shrink-0 gap-2 px-4 py-4">
        <div className="flex justify-start items-center flex-grow relative gap-[22px]">
          <p className="flex-grow-0 max-md:text-[20px] flex-shrink-0 text-2xl font-semibold text-left capitalize text-[#202223]">
            Manage Cities
          </p>
        </div>
       
      </div>
      <div className="w-full flex justify-center mt-4   items-center">
      {
        CityList?.length ?
      <div class=" px-4 sm:px-8 py-4 overflow-x-auto w-full">
      <div
        class="inline-block min-w-full shadow-md rounded-lg overflow-hidden"
      >
        <table class="min-w-full leading-normal">
          <thead>
            <tr>
              <th
                class="px-5 py-3 border-b-2 border-gray-200 bg-gray-100 text-left text-xs font-semibold text-gray-700 uppercase tracking-wider"
              >
               District Image
              </th>
              <th
                class="px-5 py-3 border-b-2 border-gray-200 bg-gray-100 text-left text-xs font-semibold text-gray-700 uppercase tracking-wider"
              >
                District Name
              </th>
        
              <th
                class="px-5 py-3 border-b-2 border-gray-200 bg-gray-100 text-left text-xs font-semibold text-gray-700 uppercase tracking-wider"
              >
                
              </th>
            </tr>
          </thead>
          <tbody>
                    {
                      CityList?.map((citylist) => (
                        <tr key={citylist?.city_id}>
              <td class="px-5 py-5 border-b border-gray-200 bg-white text-sm">
              <div class="flex items-center">
                  <div class="flex-shrink-0 w-10 h-10">
                    <img
                      class="w-full h-full rounded-full"
                      src={citylist?.city_image}
                      alt=""
                    />
                  </div>
                </div>
              </td>
              <td class="px-5 py-5 border-b border-gray-200 bg-white text-sm">
                    <p class="text-gray-900 whitespace-nowrap">
                      {citylist?.city_name}
                    </p>
              </td>
              
                          <td class="px-5 py-5 border-b border-gray-200 bg-white text-sm">
                                    <div className='flex gap-2 items-center'>        
                            <EditCities page={page} details={citylist} />
                          </div>
              </td>
            </tr>
                      ))
        }
            
          </tbody>
        </table>
      </div>
    </div>:<div className="flex justify-center items-center h-[70vh] w-full">
              <span className="text-center w-full text-xl text-[#7b8794]">
                No Cities found
              </span>
            </div>
        }
       
      </div>
      <div className="flex justify-center w-full p-4 mb-4">
      <Pagination
      className="pagination-bar"
      currentPage={page}
      totalCount={TotalCityList} // Assuming 10 items per page
      pageSize={10}
                onPageChange={(newPage) => { 
                  setpage(newPage)
                    var currentScroll = document.documentElement.scrollTop || document.body.scrollTop;
                      if (currentScroll > 0) {
                        window.scrollTo(0, currentScroll - (currentScroll / 1));
                      }
                }
                 }
    />              
    </div>
    </Template>
  );
};

export default AllCities_index;
