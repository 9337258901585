import { createSlice } from "@reduxjs/toolkit";

const NewOtaRegistered = createSlice({
    name: "otaregistered",
    initialState: {
        otaregisteredLoading: false,
        otaregisterList:[],
        selectedTabOtaRegistered:0,
      
    },
    reducers: {
         getOtaRegisteredRequest:(state)=>{
            state.otaregisteredLoading=true;
         },
         getOtaRegisteredSuccess:(state, {payload})=>{
            state.otaregisteredLoading=false;
            state.otaregisterList=payload;
         },
         getOtaRegisteredFail:(state)=>{
            state.otaregisteredLoading=false;
            state.otaregisterList=[];
         },
         setSelectedTabOtaRegistered:(state,{payload})=>{
            state.selectedTabEvent=payload
        },
    }
})  

export default NewOtaRegistered.reducer;
export const {getOtaRegisteredFail, getOtaRegisteredRequest, getOtaRegisteredSuccess, setSelectedTabOtaRegistered}= NewOtaRegistered.actions